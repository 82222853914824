import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./Image.scss";

const File = ({
  files,
  handleCloseFile,
  imageUrl = "",
  deleteImageURL,
  isLoader,
}) => {
  const imageUrlConvertArray = imageUrl.length > 0 ? imageUrl?.split("|") : "";

  return (
    <div className="overideImage">
      <div className="thumbContainer">
        {imageUrlConvertArray &&
          imageUrlConvertArray?.length > 0 &&
          imageUrlConvertArray?.map((url, index) => {
            const fileNameSplit = url?.split("/");
            const fileName =
              fileNameSplit?.length > 0 && fileNameSplit.slice(-1).pop();
            return (
              <div className="imgBox">
                <div
                  className="removeIcon"
                  onClick={(i) => deleteImageURL(url, index)}
                >
                  X
                </div>
                {/* <img src={url} alt="No Files" className="img" /> */}
                <div className="fileName fileNameFixed">{fileName}</div>
              </div>
            );
          })}
        {/* {isLoader && <p>Loading...</p>} */}
        {isLoader && (
          <Stack className="loaderDropBox" spacing={2} direction="row">
            <CircularProgress color="success" />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default File;
