import { Grid } from "@mui/material";
import React from "react";
import "./MessageUserCard.scss";
import { CHAT_LOGO } from "../../../assets/index.js";
const MessageUserCard = ({
  userName = "John",
  message = "I am looking to work with designers...",
  image = CHAT_LOGO,
  date = "03 Oct",
  onClick,
  unReadMessage,
  conId,
}) => {
  let nreadTotal = null;
  if (unReadMessage) {
    for (let ite of unReadMessage) {
      if (ite.conversation1 == conId) {
        nreadTotal = ite.unreadMessages;
      }
    }
  }
  return (
    <div className="overrideMessageUserCard">
      <Grid container item className="conversationImage" onClick={onClick}>
        <Grid className="ProfileImage">
          <img src={image} alt="" className="messengerImage" />
        </Grid>
        <Grid item textAlign="left" className="ProfileContent">
          <div className="listDate">{date}</div>

          <div className="profileDetailContent">
            <div className="profileDetail">
              <Grid>
                <h1 className="userName">{userName}</h1>
              </Grid>
              <Grid>
                <h3 className="msg">{message}</h3>
              </Grid>
            </div>
            {nreadTotal && (
              <div className="profileUnReadMsg">
                <div className="unReadMessage">{nreadTotal}</div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MessageUserCard;
