import * as React from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import "./SideBarMui.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Navbar from "../..//library/navbar/navbar";
import { sideBarIcons } from "../../assets";
import * as conversationAction from "../../redux/actions/conversationAction";
import { logos } from "../../assets";

import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Grid, Menu, MenuItem } from "@mui/material";
import { fontWeight } from "@mui/system";
import matchupITLogo from "../../assets/Landing/matchupITLogo.png";
import {
  CREATE_JOB,
  MANAGE_MENTORSHIP,
  SEARCH_MENTOR,
} from "../../constant/routeContant";
import { SEARCH_JOB } from "../../constant/routeContant";
import { red } from "@mui/material/colors";

const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const profilePic = useSelector((state) =>
    state?.userDetails?.data?.response?.basicDetails?.account_type ===
    "corporate"
      ? state?.userDetails?.data?.response?.basicDetails?.logo
      : state?.userDetails?.data?.response?.basicDetails?.profile_pic
  );

  const userName = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.first_name
  );

  const corporateName = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.name
  );

  const email = sessionStorage.getItem("email");
  const CORPORATE_ID = sessionStorage.getItem("account_type");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unReadMessage = useSelector(
    (state) => state.getUnreadMessage?.data?.arr
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let timeNow = new Date().getHours();
  let greeting =
    timeNow >= 5 && timeNow < 12
      ? "Good Morning"
      : timeNow >= 12 && timeNow < 18
      ? "Good Afternoon"
      : "Good Evening";
  // const open = Boolean(anchorEl);

  React.useEffect(() => {
    dispatch(conversationAction.getUnreadMessage());
  }, []);
  // const allUnreadMessage = unReadMessage?.[1]?.totalUnreadMessages;
  const allUnreadMessage =
    unReadMessage?.[unReadMessage?.length - 1]?.totalUnreadMessages;
  const listItemStyle = {
    fontFamily: "Montserrat",
    fontDize: "14px",
    fontEeight: 400,
    color: "#2E3A59",
    textTransform: "capitalize",
  };

  const [mentorShip, setmentorShip] = React.useState(false);
  const isMentor = useSelector(
    (state) => state.userDetails?.data?.response?.profile?.mentorship?.optIn
  );

  const drawer = (
    <div className="overrideSidebar">
      <Toolbar>
        <Link to={"/dashboard"}>
          <img src={matchupITLogo} className="light-logo" alt="no logo" />
        </Link>
      </Toolbar>
      <List>
        <ListItem
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <img src={sideBarIcons?.dashboardSideIcon} alt="" />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="dashboard"
            />
          </ListItemButton>
        </ListItem>
        {CORPORATE_ID == "corporate" && (
          <ListItem
            onClick={() => {
              navigate(CREATE_JOB);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <img src={sideBarIcons?.jobIcon} alt="" />
              </ListItemIcon>
              <ListItemText
                style={{ listItemStyle }}
                className="menuText"
                primary="Jobs"
              />
            </ListItemButton>
          </ListItem>
        )}

        <ListItem
          onClick={() => {
            navigate("/profile");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <img src={sideBarIcons?.profileSideIcon} alt="" />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="profile"
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/messages");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <img src={sideBarIcons?.messagesSideIcon} alt="" />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="messages"
            />
            {allUnreadMessage >= 1 ? (
              <span className="unreadMessages">{allUnreadMessage}</span>
            ) : (
              "" && window.location.reload()
            )}
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/search");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <img
                className="menuIcon"
                src={sideBarIcons?.searchSideIcon}
                alt=""
              />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="search"
            />
          </ListItemButton>
        </ListItem>
        {CORPORATE_ID === "individual" && (
          <ListItem
            onClick={() => {
              navigate(SEARCH_JOB);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <img className="menuIcon" src={sideBarIcons?.jobIcon} alt="" />
              </ListItemIcon>
              <ListItemText
                style={{ listItemStyle }}
                className="menuText"
                primary="Jobs"
              />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem
          className="leftMar"
          onClick={() => setmentorShip(!mentorShip)}
        >
          <ListItemButton>
            <ListItemIcon>
              <img
                className="menuIcon"
                src={sideBarIcons?.mentorshipIcon}
                alt=""
              />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="Mentorship"
            />
          </ListItemButton>
        </ListItem>
        <Collapse in={mentorShip} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className="subMenu"
              onClick={() => {
                navigate(SEARCH_MENTOR);
              }}
            >
              <ListItemText
                className="menuSubText"
                inset
                primary="Search Mentor"
              />
            </ListItem>
            {isMentor ? (
              <ListItem
                className="subMenu"
                onClick={() => {
                  navigate(MANAGE_MENTORSHIP);
                }}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Manage Mentorship"
                />
              </ListItem>
            ) : (
              ""
            )}
          </List>
        </Collapse>

        <ListItem
          color="red"
          className="leftMar"
          onClick={() => {
            navigate("/community");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <img src={sideBarIcons?.communitySideIcon} alt="" />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="community"
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          className="leftMar"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <img src={sideBarIcons?.settingSideIcon} alt="" />
            </ListItemIcon>
            <ListItemText
              style={{ listItemStyle }}
              className="menuText"
              primary="settings"
            />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const open = Boolean(anchorEl);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    sessionStorage.getItem("token") === null && window?.location?.reload();
  }, [sessionStorage]);

  function handleLogout() {
    sessionStorage.clear();
    navigate("/login");
    // window.location.reload()
  }

  return (
    <Box className="appbarContainer" sx={{ display: "flex" }}>
      <CssBaseline />
      <img scr={profilePic} />
      <AppBar
        position="fixed"
        className="appbar"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "none",
          color: "#000",
        }}
      >
        <Toolbar className="toolbar top-header">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid noWrap component="div" className="headerRes top-header">
            <h1 className="welcomeUser">
              {greeting},{" "}
              {userName ? userName : corporateName ? corporateName : email}
            </h1>
            <>
              <Avatar
                className="profilePic"
                alt="Remy Sharp"
                src={profilePic}
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                className="menu"
              >
                <MenuItem
                  onClick={() => {
                    navigate("/profile");
                    setAnchorEl(null);
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // window?.location?.reload(true);
                    handleLogout();
                    // navigate("/dashboard");
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className="drawer"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#FAFAFA",
              border: "none",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px) -20px` },
        }}
      >
        <Toolbar />
        <Grid>
          <div className="rightTop">
            {/* <Navbar /> */}
            <div className="rightBar">
              <Navbar
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleDrawerToggle={handleDrawerToggle}
              />
              <div className="container_wrapper">
                <Outlet />
              </div>
            </div>
          </div>
        </Grid>
      </Box>
    </Box>
  );
}
