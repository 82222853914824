import { Grid } from "@mui/material";
import React from "react";
import ClickableChips from "../../../library/custom/chip/ClickableChip";
import CommunityCard from "../../../library/custom/communityCard/CommunityCard";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import TrendingCommunityCard from "../../../library/custom/trendingCommunityCard/TrendingCommunityCard";
import "../../dashboard/component/Dashboard.scss";
import { ACCOUNT_TYPE } from "../../../constant/constant";
const Community = ({
  myCommunities,
  mycommunitySates,
  topCommunities,
  handleClick,
  handleSearch,
  handleMYClick,
  usrcount,
}) => {
  const accountType = ACCOUNT_TYPE();
  return (
    <div className="communityDashboard">
    <div className="overrideDashboard">
      <Grid mt={2} container>
        <Grid conainer item lg={8}>
          <Grid container item lg={9} mb={2}>
            <SearchBar placeholder={"Search"} handleSearch={handleSearch} />
          </Grid>
          <div className="communityCards">
            {myCommunities?.data?.map((i) => {
              return (
                <Grid item lg={12} xs={12} sm={12} md={12} mb={2}>
                  <CommunityCard
                    heading={i.title}
                    CommunityContent={i.description}
                    userCount={i.userCount}
                    handleClick={handleClick}
                    id={i._id}
                    id_member={i.isMember}
                    usrcount={usrcount}
                    icon={i.icon}
                  />
                </Grid>
              );
            })}
          </div>
          {/* <Grid m
           */}
        </Grid>
        <Grid container item lg={4} className="CommuntityLeftSideCard">
          <Grid container className="profileGrid" item lg={12}>
            {accountType !== "corporate" && (
              <Grid textAlign={"left"} item lg={12}>
                <h1 className="mycommunityHeadnigStyle">My Communities</h1>
                <Grid mt={1}>
                  {mycommunitySates?.data?.map((i) => {
                    return (
                      <Grid
                        mt={2}
                        item
                        lg={12}
                        xs={12}
                        sm={12}
                        md={12}
                        pl={2}
                        pr={2}
                      >
                        <TrendingCommunityCard
                          label1={i.title}
                          label2={i.userCount}
                          label3={i.description}
                          id={i._id}
                          handleClick={() => handleMYClick(i)}
                          icon={i.icon}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Grid
              textAlign={"left"}
              mt={3}
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <h1 className="mycommunityHeadnigStyle">Trending Communities</h1>
              <Grid mt={1} mb={2}>
                {topCommunities?.data?.map((i) => {
                  return (
                    <Grid
                      mt={2}
                      item
                      lg={12}
                      xs={12}
                      sm={12}
                      md={12}
                      pl={2}
                      pr={2}
                    >
                      <TrendingCommunityCard
                        label1={i.title}
                        label2={i.userCount}
                        id={i._id}
                        handleClick={() => handleMYClick(i)}
                        icon={i.icon}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
    </div>
  );
};

export default Community;
