import * as loginAction from "../actions/loginAction";
import { loginType } from "../constant/loginType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import {
  getForgotPasswordOTP,
  getRecentSearches,
  getUserProfile,
  login,
  loginAdmin,
  putResetPassword,
} from "../../services/loginService";

export function* loginSaga(params) {
  try {
    const { data, response, status, error } = yield call(login, params);
    if (!data && status !== 200 && response) throw apiError(response);
    sessionStorage.setItem("id", data.response.id);
    sessionStorage.setItem("token", data.response.token);
    sessionStorage.setItem("account_type", data.response.account_type);
    sessionStorage.setItem("email", data.response.email);
    yield put(
      loginAction.loginSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Login Failed" };
    yield put(loginAction.loginFailure(error));
  }
}

export function* adminLoginSaga(params) {
  try {
    const { data, response, status, error } = yield call(loginAdmin, params);
    if (!data && status !== 200 && response) throw apiError(response);
    sessionStorage.setItem("id", data.userObj.id);
    sessionStorage.setItem("token", data.userObj.token);
    sessionStorage.setItem("account_type", data.userObj.account_type);
    sessionStorage.setItem("email", data.userObj.email);
    yield put(
      loginAction.loginAdminSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Login Failed" };
    yield put(loginAction.loginAdminFailure(error));
  }
}

export function* getUserDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getUserProfile, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      loginAction.getUserDetailsSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Details Failed" };
    yield put(loginAction.getUserDetailsFailure(data));
  }
}

export function* getRecentSearchesSaga(params) {
  try {
    const { data, status, error } = yield call(getRecentSearches, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      loginAction.getRecentSearchesSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Top Communities Failed" };
    yield put(loginAction.getRecentSearchesFailure(data));
  }
}

export function* getForgotPasswordOTPSaga(params) {
  try {
    const { data, status, error } = yield call(
      getForgotPasswordOTP,
      params.data
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      loginAction.getForgotPasswordOTPSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get getForgotPasswordOTP Failed" };
    yield put(loginAction.getForgotPasswordOTPFailure(data));
  }
}

export function* putResetPasswordSaga(params) {
  try {
    const { data, response, status, error } = yield call(
      putResetPassword,
      params
    );
    // if (!data && status !== 200) throw apiError(error);
    if (!data && status !== 200 && response) throw apiError(response);

    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      loginAction.putResetPasswordSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get putResetPassword Failed" };
    yield put(loginAction.putResetPasswordFailure(error));
  }
}

const myLoginSaga = [
  takeLatest(loginType.POST_LOGIN_PENDING, loginSaga),
  takeLatest(loginType.POST_ADMIN_LOGIN_PENDING, adminLoginSaga),
  takeLatest(loginType.GET_USER_PROFILE_PENDING, getUserDetailsSaga),
  takeLatest(loginType.GET_RECENT_SEARCHES_PENDING, getRecentSearchesSaga),
  takeLatest(
    loginType.GET_FORGOT_PASSWORD_OTP_PENDING,
    getForgotPasswordOTPSaga
  ),
  takeLatest(loginType.PUT_RESET_PASSWORD_PENDING, putResetPasswordSaga),
];

export default myLoginSaga;
