import * as React from "react";
import Card from "@mui/material/Card";
import "./CommunityCardJoin.scss";
import Button from "../button/Button";
import CommunityPerson from "../../../../src/assets/CommunityPerson.svg";
// import CommunityIcons from "../../../../src/assets/rectangle28.svg";
import CommunityIcons from "../../../assets/communityCommonIcon.svg";

export default function CommunityCardJoin({
  heading = "Community Name",
  CommunityContent = "Community Content",
  userCount = "",
  handleClick,
  id,
  lable,
  isMember,
  getPostCommunityWiseData,
  icon,
}) {
  return (
    <div className="CommunityCardContainer">
      <Card className="CommunityCard" p={{ xs: 0, lg: 3 }}>
        <div
          className={`${
            getPostCommunityWiseData?.data?.hasOwnProperty("isMember")
              ? "CommunityBtnStyle"
              : "commmtybtn"
          }`}
        >
          <div className="communityicn">
            <img src={icon ? icon : CommunityIcons} alt="" />
          </div>

          <div className="Communityviewname">
            <h1>{heading}</h1>
          </div>

          {/* <div className="joinbtn">
            {getPostCommunityWiseData?.data?.hasOwnProperty("isMember") && (
              <Button
                className="ButtonStyle"
                varientLeaveContained={isMember ? true : false}
                varientContained={isMember ? false : true}
                label={lable}
                onClick={() => handleClick(id)}
              />
            )}
          </div> */}
        </div>
        <p className="CommunityContent">{CommunityContent}</p>
        <div className="CommunityBtnStyle">
          <div className="CommunityPersonCount1">
            <img src={CommunityPerson} alt="CommunityPerson" />{" "}
            {userCount > 1 ? userCount + " Members" : userCount + " Member"}
            {/* Members */}
          </div>

          <div className="joinbtn">
            {getPostCommunityWiseData?.data?.hasOwnProperty("isMember") && (
              <Button
                className="ButtonStyle"
                varientLeaveContained={isMember ? true : false}
                varientContained={isMember ? false : true}
                label={lable}
                onClick={() => handleClick(id)}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
