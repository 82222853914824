import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../library/custom/textBox/InputField";
import { API_STATUS, E_YEAR, MONTHS, S_YEAR } from "../../../constant/constant";
import FormDropBox from "../../../library/formDropBox/FormDropBox";
import { useSelector } from "react-redux";
import File from "../../../library/media/File";

const CertificateForm = ({
  singleCertificate,
  handleCertificateFormChange,
  setUnsavedChanges,
  onChnageFileupload,
  imageurl,
  id,
  onChangeDelete,
}) => {
  let years = [];
  for (let i = E_YEAR; i >= S_YEAR; i--) {
    years.push(i);
  }

  const [imageUrls, setimageUrls] = useState("");

  let fileName = getFileName();

  function getFileName() {
    const fileNameSplit = imageUrls?.split("/");

    return fileNameSplit?.length > 0 && fileNameSplit.slice(-1).pop();
  }

  return (
    <>
      <Grid className="workExpirience">
        <Grid item container lg={11} xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="Certificate Name"
            id="name"
            isRequired={true}
            placeholder="Enter here"
            value={singleCertificate?.name ?? ""}
            onChange={(e) => {
              handleCertificateFormChange(e, singleCertificate, "name");
              setUnsavedChanges(true);
            }}
            isError={singleCertificate.isName}
            errorMsg={
              singleCertificate.isName ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid textAlign={"left"} lg={11} mt={2}>
          <FormLabels isRequired={true} label="Time Period" />

          <Grid className="fName" item container lg={12} xs={12} sm={12} md={12}>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={true}
                label="End Month"
                id={"endM"}
                value={singleCertificate?.endM}
                name={"endM"}
                onchangehandler={(e) => {
                  handleCertificateFormChange(e, singleCertificate, "select");
                  setUnsavedChanges(true);
                }}
                listData={MONTHS}
                countries={true}
                errorMsg={
                  singleCertificate.isEndM
                    ? "Please enter this required field"
                    : ""
                }
                isError={singleCertificate.isEndM}
              />
            </Grid>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="End Year"
                id={"endY"}
                value={singleCertificate?.endY}
                name={"endY"}
                onchangehandler={(e) => {
                  handleCertificateFormChange(e, singleCertificate, "select");
                  setUnsavedChanges(true);
                }}
                listData={years}
                countries={true}
                errorMsg={
                  singleCertificate.isEndY
                    ? "Please enter this required field"
                    : ""
                }
                isError={singleCertificate.isEndY}
              />
            </Grid>
          </Grid>

          <Grid item container mt={3}>
            <Grid lg={12} xs={12} sm={12} md={12}>
              <FormDropBox
                key={singleCertificate.id}
                label={"Add Section Photo"}
                id={"certificate"}
                paraText1={"Drop the file here ..."}
                paraText2={"Drop Image/Doc here, "}
                paraText3={"or Browse"}
                fileType={10}
                isDynamics={false}
                isMuliple={false}
                handleFileChange={(e) => {
                  //setUnsavedChanges(true);
                  onChnageFileupload(singleCertificate?.id);
                }}
              />
              <div className="file-class">
                {" "}
                <File
                  //   handleCloseFile={handleCloseFile}
                  //   files={files}
                  imageUrl={imageurl}
                  //  deleteImageURL={(url, index) => setimageUrls("")}
                  deleteImageURL={(url, index) => {
                    //setUnsavedChanges(true);
                    onChangeDelete(url);
                  }}
                  //deleteImageURL={onChangeDelete(singleCertificate?.id)}
                  //isLoader={isLoader}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CertificateForm;
