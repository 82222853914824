import React, { useState, useRef, useEffect } from "react";
import "./otp.scss";
import { Button, Grid } from "@mui/material";
import SignuPic from "../../../assets/SignuPic.jpg";
import matchupITLogo from "../../../assets/Landing/matchupITLogo.png";
import { useNavigate } from "react-router-dom";

function OtpPage({ sendOTP, otpSend, email, otpVerifySignupData }) {
  const navigate = useNavigate();
  const [otpError, setOtpError] = useState(false);

  useEffect(() => {
    if (
      otpVerifySignupData.isLoading === false &&
      otpVerifySignupData.isError === true &&
      otpVerifySignupData.status === "FAILURE"
    ) {
      setOtpError(true);
    }
  }, [otpVerifySignupData]);

  const INITIAL_COUNT = 30;
  const twoDigits = (num) => String(num).padStart(2, "0");
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  const handleStart = async () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
  };

  const STATUS = {
    STARTED: "Started",
    STOPPED: (
      <b>
        <a type="button" onClick={handleStart} className="show-danger">
          Resend OTP
        </a>
      </b>
    ),
  };
  const [status, setStatus] = useState(STATUS.STOPPED);

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // set up the interval
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const [otp, setOtp] = useState("");

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const otpFilter = (event, setter) => {
    setter(event.target.value.replace(/[^\d]+$/g, ""));
  };

  return (
    <div className="overidesignup">
      <Grid container>
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
          item
          lg={5}
          xs={12}
          sm={6}
          md={6}
        >
          <img src={SignuPic} className="leftPic" alt="no logo" />
        </Grid>

        <Grid
          className="formCon"
          container
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"left"}
          item
          lg={6}
          sm={12}
          md={6}
          xs={12}
          p={5}
        >
          <Grid item xs={12} sm={9} md={12} lg={12}>
            <Grid
              container
              justifyContent={"left"}
              item
              lg={8}
              xs={8}
              sm={12}
              md={12}
              mt={6}
            >
              <img
                onClick={() => navigate("/")}
                src={matchupITLogo}
                style={{ width: "267px", height: "114px",cursor:"pointer" }}
                className="light-logo"
                alt="no logo"
              />
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  margin: "0px",
                  color: " #043133",
                  // marginTop: "20px",
                }}
              >
                An OTP has been sent to {email} Please enter the 6 digit OTP to
                create your account.
              </h1>
            </Grid>

            <Grid container item lg={11} sm={12} md={12} xs={12} mt={3}>
              <Grid mt={2} item lg={12} xs={12} md={12} sm={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0px",
                    color: "#083A50",
                  }}
                >
                  Enter OTP
                </h1>
                <Grid item lg={8} xs={12} sm={12} md={8} mt={1}>
                  <input
                    style={{ width: "100%" }}
                    className="verify-otp"
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]"
                    value={otp}
                    maxLength="6"
                    placeholder="Enter OTP Here"
                    onInput={maxLengthCheck}
                    onChange={(e) => (otpFilter(e, setOtp), setOtpError(false))}
                  />
                  {otpError && <div className="errorMsg">Invalid OTP.</div>}
                </Grid>

                <Grid container mt={1} className="resendOtp">
                  {status === STATUS.STARTED ? (
                    <div className="show-danger">
                      <b>Resend code in</b>

                      <b style={{ color: "#666666", marginLeft: "3px" }}>
                        {twoDigits(minutesToDisplay)}:
                        {twoDigits(secondsToDisplay)}
                      </b>
                    </div>
                  ) : (
                    <h2 onClick={() => otpSend(email)} className="show-danger">
                      {status}
                    </h2>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent={"left"} mt={3} item lg={10}>
                <Button
                  variant="outlined"
                  onClick={() => sendOTP(otp)}
                  style={{
                    width: "200px",
                    height: "41px",
                    border: "none",
                    background: "#778DFF",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default OtpPage;
