import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Error from "../../assets/error.svg";
import Stack from "@mui/material/Stack";
import "./DialogBox.scss";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function CustomizedDialogs(props) {
  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        // onClose={props.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="dialogBoxContainer"
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleCloseModal}
          style={{ textAlign: "center" }}
        >
          {props.header ? props?.header : "Confirm Deletion"}
        </BootstrapDialogTitle> */}
        <DialogContent className="Dioerrimg">
          <Typography className="errimg">
            {" "}
            <img src={Error} />
          </Typography>
          <Typography gutterBottom className="modalPara">
            {props.content
              ? props?.content
              : "Are you sure you want to delete this"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={props.exitHandle}
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              color: "#FF333F",
              textTransform: "capitalize",
            }}
          >
            {props.label1 ? props?.label1 : "No"}
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E3A59",
                textTransform: "capitalize",
              }}
              autoFocus
              onClick={(e) => props.handleCloseModal(e)}
            >
              {props.label2 ? props?.label2 : "Yes"}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
