import { communityType } from "../constant/communityType";

export const getTopCommunity = (data) => ({
  type: communityType.GET_TOP_COMMUNITY_PENDING,
  data,
});

export const getTopCommunitySuccess = (data) => ({
  type: communityType.GET_TOP_COMMUNITY_SUCCESS,
  data,
});

export const getTopCommunityFailure = (error) => ({
  type: communityType.GET_TOP_COMMUNITY_FAILURE,
  error,
});

export const getMyCommunity = (data) => ({
  type: communityType.GET_MY_COMMUNITY_PENDING,
  data,
});

export const getMyCommunitySuccess = (data) => ({
  type: communityType.GET_MY_COMMUNITY_SUCCESS,
  data,
});

export const getMyCommunityFailure = (error) => ({
  type: communityType.GET_MY_COMMUNITY_FAILURE,
  error,
});

export const getCommunity = (data) => ({
  type: communityType.GET_COMMUNITY_PENDING,
  data,
});

export const getCommunitySuccess = (data) => ({
  type: communityType.GET_COMMUNITY_SUCCESS,
  data,
});

export const getCommunityFailure = (error) => ({
  type: communityType.GET_COMMUNITY_FAILURE,
  error,
});

export const getCommunityAdmin = (data) => ({
  type: communityType.GET_COMMUNITY_ADMIN_PENDING,
  data,
});

export const getCommunityAdminSuccess = (data) => ({
  type: communityType.GET_COMMUNITY_ADMIN_SUCCESS,
  data,
});

export const getCommunityAdminFailure = (error) => ({
  type: communityType.GET_COMMUNITY_ADMIN_FAILURE,
  error,
});
// Join community
export const postJoinCommunity = (data) => ({
  type: communityType.POST_JOIN_COMMUNITY_PENDING,
  data,
});

export const postJoinCommunitySuccess = (data) => ({
  type: communityType.POST_JOIN_COMMUNITY_SUCCESS,
  data,
});

export const postJoinCommunityFailure = (data) => ({
  type: communityType.POST_JOIN_COMMUNITY_FAILURE,
  data,
});
export const clearJoinCommunityStatus = () => ({
  type: communityType.POST_JOIN_CLEAR_DATA,
  data: "",
});
// leave community
export const postLeaveCommunity = (data) => ({
  type: communityType.POST_LEAVE_COMMUNITY_PENDING,
  data,
});

export const postLeaveCommunitySuccess = (data) => ({
  type: communityType.POST_LEAVE_COMMUNITY_SUCCESS,
  data,
});

export const postLeaveCommunityFailure = (data) => ({
  type: communityType.POST_LEAVE_COMMUNITY_FAILURE,
  data,
});
export const clearLeaveCommunityStatus = () => ({
  type: communityType.POST_CLEAR_DATA,
  data: "",
});
//POST_CLEAR_DATA
export const postSearchCommunity = (data) => ({
  type: communityType.POST_SEARCH_COMMUNITY_PENDING,
  data,
});

export const postSearchCommunitySuccess = (data) => ({
  type: communityType.POST_SEARCH_COMMUNITY_SUCCESS,
  data,
});

export const postSearchCommunityFailure = (data) => ({
  type: communityType.POST_SEARCH_COMMUNITY_FAILURE,
  data,
});
// get posts community wise
export const getPostsCommunityWises = (data) => ({
  type: communityType.GET_POSTS_COMMUNITY_PENDING,
  data,
});

export const getPostsCommunityWisesSuccess = (data) => ({
  type: communityType.GET_POSTS_COMMUNITY_SUCCESS,
  data,
});

export const getPostsCommunityWisesFailure = (error) => ({
  type: communityType.GET_POSTS_COMMUNITY_FAILURE,
  error,
});
// ADD POST COMMUNITY WISE
export const addPostCommunityPending = (data) => ({
  type: communityType.POST_COMMUNITY_ADD_POST_PENDING,
  data,
});

export const addPostCommunitySuccess = (data) => ({
  type: communityType.POST_COMMUNITY_ADD_POST_SUCCESS,
  data,
});

export const addPostCommunityFailure = (data) => ({
  type: communityType.POST_COMMUNITY_ADD_POST_FAILURE,
  data,
});

// ADD POST COMMUNITY Comments
export const addPostCommunityCommentPending = (data) => ({
  type: communityType.POST_COMMUNITY_ADD_POST_COMMENTS_PENDING,
  data,
});

export const addPostCommunityCommentSuccess = (data) => ({
  type: communityType.POST_COMMUNITY_ADD_POST_COMMENTS_SUCCESS,
  data,
});

export const addPostCommunityCommentFailure = (data) => ({
  type: communityType.POST_COMMUNITY_ADD_POST_COMMENTS_FAILURE,
  data,
});

export const postAddReactToPostCommunity = (data) => ({
  type: communityType.POST_ADD_REACT_TO_POST_COMMUNITY_PENDING,
  data,
});

export const postAddReactToPostSuccess = (data) => ({
  type: communityType.POST_ADD_REACT_TO_POST_COMMUNITY_SUCCESS,
  data,
});

export const postAddReactToPostFailure = (error) => ({
  type: communityType.POST_ADD_REACT_TO_POST_COMMUNITY_FAILURE,
  error,
});

export const postAddReactToPostCommentCommunity = (data) => ({
  type: communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_PENDING,
  data,
});

export const postAddReactToPostCommentSuccess = (data) => ({
  type: communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_SUCCESS,
  data,
});

export const postAddReactToPostCommentFailure = (error) => ({
  type: communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_FAILURE,
  error,
});

export const postDeletePostCommunity = (data) => ({
  type: communityType.POST_DELETE_COMMUNITY_PENDING,
  data,
});

export const postDeletePostCommunitySuccess = (data) => ({
  type: communityType.POST_DELETE_COMMENT_COMMUNITY_SUCCESS,
  data,
});

export const postDeletePostCommunityFailure = (error) => ({
  type: communityType.POST_DELETE_COMMUNITY_FAILURE,
  error,
});

//POST_CLEAR_DATA

export const postToggleCommunity = (data) => ({
  type: communityType.POST_TOGGLE_COMMUNITY_PENDING,
  data,
});

export const postToggleCommunitySuccess = (data) => ({
  type: communityType.POST_TOGGLE_COMMUNITY_SUCCESS,
  data,
});

export const postToggleCommunityFailure = (error) => ({
  type: communityType.POST_TOGGLE_COMMUNITY_FAILURE,
  error,
});

export const postCreateCommunity = (data) => ({
  type: communityType.POST_CREATE_COMMUNITY_PENDING,
  data,
});

export const postCreateCommunitySuccess = (data) => ({
  type: communityType.POST_CREATE_COMMUNITY_SUCCESS,
  data,
});

export const postCreateCommunityFailure = (error) => ({
  type: communityType.POST_CREATE_COMMUNITY_FAILURE,
  error,
});

export const postEditCommunity = (data) => ({
  type: communityType.POST_EDIT_COMMUNITY_PENDING,
  data,
});

export const postEditCommunitySuccess = (data) => ({
  type: communityType.POST_EDIT_COMMUNITY_SUCCESS,
  data,
});

export const postEditCommunityFailure = (error) => ({
  type: communityType.POST_EDIT_COMMUNITY_FAILURE,
  error,
});

export const resumeBuilding = (data) => ({
  type: communityType.POST_RESUME_BULDING_PENDING,
  data,
});

export const resumeBuildingSuccess = (data) => ({
  type: communityType.POST_RESUME_BULDING_SUCCESS,
  data,
});

export const resumeBuildingFailure = (error) => ({
  type: communityType.POST_RESUME_BULDING_FAILURE,
  error,
});
