import React from "react";
import "./App.scss";
import MainRouter from "./router/";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Router>
        <MainRouter />
      </Router>
    </div>
  );
}

export default App;
