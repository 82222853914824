export const COUNTRIES = [
  "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Côte D'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France, Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthelemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const CURRIENCIES = [
  {
    value: "Afghan Afghani",
    code: "AFN",
  },
  {
    value: "Albanian Lek",
    code: "ALL",
  },
  {
    value: "Algerian Dinar",
    code: "DZD",
  },
  {
    value: "Argentine Peso",
    code: "ARS",
  },
  {
    value: "Armenian Dram",
    code: "AMD",
  },
  {
    value: "Australian Dollar",
    code: "AUD",
  },
  {
    value: "Azerbaijani Manat",
    code: "AZN",
  },
  {
    value: "Bahraini Dinar",
    code: "BHD",
  },
  {
    value: "Bangladeshi Taka",
    code: "BDT",
  },
  {
    value: "Belarusian Ruble",
    code: "BYN",
  },
  {
    value: "Belize Dollar",
    code: "BZD",
  },
  {
    value: "Bolivian Boliviano",
    code: "BOB",
  },
  {
    value: "Bosnia-Herzegovina Convertible Mark",
    code: "BAM",
  },
  {
    value: "Botswanan Pula",
    code: "BWP",
  },
  {
    value: "Brazilian Real",
    code: "BRL",
  },
  {
    value: "British Pound Sterling",
    code: "GBP",
  },
  {
    value: "Brunei Dollar",
    code: "BND",
  },
  {
    value: "Bulgarian Lev",
    code: "BGN",
  },
  {
    value: "Burundian Franc",
    code: "BIF",
  },
  {
    value: "CFA Franc BCEAO",
    code: "XOF",
  },
  {
    value: "CFA Franc BEAC",
    code: "XAF",
  },
  {
    value: "Cambodian Riel",
    code: "KHR",
  },
  {
    value: "Canadian Dollar",
    code: "C$",
  },
  {
    value: "Cape Verdean Escudo",
    code: "CVE",
  },
  {
    value: "Chilean Peso",
    code: "CLP",
  },
  {
    value: "Chinese Yuan",
    code: "CNY",
  },
  {
    value: "Colombian Peso",
    code: "COP",
  },
  {
    value: "Comorian Franc",
    code: "KMF",
  },
  {
    value: "Congolese Franc",
    code: "CDF",
  },
  {
    value: "Costa Rican Colón",
    code: "CRC",
  },
  {
    value: "Croatian Kuna",
    code: "HRK",
  },
  {
    value: "Czech Republic Koruna",
    code: "CZK",
  },
  {
    value: "Danish Krone",
    code: "DKK",
  },
  {
    value: "Djiboutian Franc",
    code: "DJF",
  },
  {
    value: "Dominican Peso",
    code: "DOP",
  },
  {
    value: "Egyptian Pound",
    code: "EGP",
  },
  {
    value: "Eritrean Nakfa",
    code: "ERN",
  },
  {
    value: "Estonian Kroon",
    code: "EEK",
  },
  {
    value: "Ethiopian Birr",
    code: "ETB",
  },
  {
    value: "Euro",
    code: "EUR",
  },
  {
    value: "Georgian Lari",
    code: "GEL",
  },
  {
    value: "Ghanaian Cedi",
    code: "GHS",
  },
  {
    value: "Guatemalan Quetzal",
    code: "GTQ",
  },
  {
    value: "Guinean Franc",
    code: "GNF",
  },
  {
    value: "Honduran Lempira",
    code: "HNL",
  },
  {
    value: "Hong Kong Dollar",
    code: "HKD",
  },
  {
    value: "Hungarian Forint",
    code: "HUF",
  },
  {
    value: "Icelandic Króna",
    code: "ISK",
  },
  {
    value: "Indian Rupee",
    code: "INR",
  },
  {
    value: "Indonesian Rupiah",
    code: "IDR",
  },
  {
    value: "Iranian Rial",
    code: "IRR",
  },
  {
    value: "Iraqi Dinar",
    code: "IQD",
  },
  {
    value: "Israeli New Sheqel",
    code: "ILS",
  },
  {
    value: "Jamaican Dollar",
    code: "JMD",
  },
  {
    value: "Japanese Yen",
    code: "JPY",
  },
  {
    value: "Jordanian Dinar",
    code: "JOD",
  },
  {
    value: "Kazakhstani Tenge",
    code: "KZT",
  },
  {
    value: "Kenyan Shilling",
    code: "KES",
  },
  {
    value: "Kuwaiti Dinar",
    code: "KWD",
  },
  {
    value: "Latvian Lats",
    code: "LVL",
  },
  {
    value: "Lebanese Pound",
    code: "LBP",
  },
  {
    value: "Libyan Dinar",
    code: "LYD",
  },
  {
    value: "Lithuanian Litas",
    code: "LTL",
  },
  {
    value: "Macanese Pataca",
    code: "MOP",
  },
  {
    value: "Macedonian Denar",
    code: "MKD",
  },
  {
    value: "Malagasy Ariary",
    code: "MGA",
  },
  {
    value: "Malaysian Ringgit",
    code: "MYR",
  },
  {
    value: "Mauritian Rupee",
    code: "MUR",
  },
  {
    value: "Mexican Peso",
    code: "MXN",
  },
  {
    value: "Moldovan Leu",
    code: "MDL",
  },
  {
    value: "Moroccan Dirham",
    code: "MAD",
  },
  {
    value: "Mozambican Metical",
    code: "MZN",
  },
  {
    value: "Myanma Kyat",
    code: "MMK",
  },
  {
    value: "Namibian Dollar",
    code: "NAD",
  },
  {
    value: "Nepalese Rupee",
    code: "NPR",
  },
  {
    value: "New Taiwan Dollar",
    code: "TWD",
  },
  {
    value: "New Zealand Dollar",
    code: "NZD",
  },
  {
    value: "Nicaraguan Córdoba",
    code: "NIO",
  },
  {
    value: "Nigerian Naira",
    code: "NGN",
  },
  {
    value: "Norwegian Krone",
    code: "NOK",
  },
  {
    value: "Omani Rial",
    code: "OMR",
  },
  {
    value: "Pakistani Rupee",
    code: "PKR",
  },
  {
    value: "Panamanian Balboa",
    code: "PAB",
  },
  {
    value: "Paraguayan Guarani",
    code: "PYG",
  },
  {
    value: "Peruvian Nuevo Sol",
    code: "PEN",
  },
  {
    value: "Philippine Peso",
    code: "PHP",
  },
  {
    value: "Polish Zloty",
    code: "PLN",
  },
  {
    value: "Qatari Rial",
    code: "QAR",
  },
  {
    value: "Romanian Leu",
    code: "RON",
  },
  {
    value: "Russian Ruble",
    code: "RUB",
  },
  {
    value: "Rwandan Franc",
    code: "RWF",
  },
  {
    value: "Saudi Riyal",
    code: "SAR",
  },
  {
    value: "Serbian Dinar",
    code: "RSD",
  },
  {
    value: "Singapore Dollar",
    code: "SGD",
  },
  {
    value: "Somali Shilling",
    code: "SOS",
  },
  {
    value: "South African Rand",
    code: "ZAR",
  },
  {
    value: "South Korean Won",
    code: "KRW",
  },
  {
    value: "Sri Lankan Rupee",
    code: "LKR",
  },
  {
    value: "Sudanese Pound",
    code: "SDG",
  },
  {
    value: "Swedish Krona",
    code: "SEK",
  },
  {
    value: "Swiss Franc",
    code: "CHF",
  },
  {
    value: "Syrian Pound",
    code: "SYP",
  },
  {
    value: "Tanzanian Shilling",
    code: "TZS",
  },
  {
    value: "Thai Baht",
    code: "THB",
  },
  {
    value: "Tongan Paʻanga",
    code: "TOP",
  },
  {
    value: "Trinidad and Tobago Dollar",
    code: "TTD",
  },
  {
    value: "Tunisian Dinar",
    code: "TND",
  },
  {
    value: "Turkish Lira",
    code: "TRY",
  },
  {
    value: "UK Pounds",
    code: "POUNDS",
  },
  {
    value: "US Dollar",
    code: "USD",
  },
  {
    value: "Ugandan Shilling",
    code: "UGX",
  },
  {
    value: "Ukrainian Hryvnia",
    code: "UAH",
  },
  {
    value: "United Arab Emirates Dirham",
    code: "AED",
  },
  {
    value: "Uruguayan Peso",
    code: "UYU",
  },
  {
    value: "Uzbekistan Som",
    code: "UZS",
  },
  {
    value: "Venezuelan Bolívar",
    code: "VEF",
  },
  {
    value: "Vievaluee Dong",
    code: "VND",
  },
  {
    value: "Yemeni Rial",
    code: "YER",
  },
  {
    value: "Zambian Kwacha",
    code: "ZMK",
  },
  {
    value: "Zimbabwean Dollar",
    code: "ZWL",
  },
];


export const DIALCODES =  [ 
  "+1", 
  "+1242", 
  "+1246", 
  "+1264", 
  "+1284", 
  "+1340", 
  "+1441", 
  "+1473", 
  "+1649", 
  "+1670", 
  "+1671", 
  "+1684", 
  "+1758", 
  "+1767", 
  "+1784", 
  "+1849", 
  "+1868", 
  "+1869", 
  "+1876", 
  "+1939", 
  "+1268", 
  "+1664", 
  "+20", 
  "+212", 
  "+213", 
  "+216", 
  "+218", 
  "+220", 
  "+221", 
  "+222", 
  "+223", 
  "+224", 
  "+225", 
  "+226", 
  "+227", 
  "+228", 
  "+229", 
  "+230", 
  "+231", 
  "+232", 
  "+233", 
  "+234", 
  "+235", 
  "+236", 
  "+237", 
  "+238", 
  "+239", 
  "+240", 
  "+241", 
  "+242", 
  "+243", 
  "+244", 
  "+245", 
  "+246", 
  "+248", 
  "+249", 
  "+250", 
  "+251", 
  "+252", 
  "+253", 
  "+254", 
  "+255", 
  "+256", 
  "+257", 
  "+258", 
  "+260", 
  "+261", 
  "+262", 
  "+263", 
  "+264", 
  "+265", 
  "+266", 
  "+267", 
  "+268", 
  "+269", 
  "+27", 
  "+290", 
  "+291", 
  "+297", 
  "+298", 
  "+299", 
  "+30", 
  "+31", 
  "+32", 
  "+33", 
  "+34", 
  "+345", 
  "+350", 
  "+351", 
  "+352", 
  "+353", 
  "+354", 
  "+355", 
  "+356", 
  "+358", 
  "+359", 
  "+36", 
  "+370", 
  "+371", 
  "+372", 
  "+373", 
  "+374", 
  "+375", 
  "+376", 
  "+377", 
  "+378", 
  "+379", 
  "+380", 
  "+381", 
  "+382", 
  "+385", 
  "+386", 
  "+387", 
  "+389", 
  "+39", 
  "+40", 
  "+41", 
  "+420", 
  "+421", 
  "+423", 
  "+43", 
  "+44", 
  "+45", 
  "+46", 
  "+47", 
  "+48", 
  "+49", 
  "+500", 
  "+501", 
  "+502", 
  "+503", 
  "+504", 
  "+505", 
  "+506", 
  "+507", 
  "+508", 
  "+509", 
  "+51", 
  "+52", 
  "+53", 
  "+537", 
  "+54", 
  "+55", 
  "+56", 
  "+57", 
  "+58", 
  "+590", 
  "+591", 
  "+593", 
  "+594", 
  "+595", 
  "+596", 
  "+597", 
  "+598", 
  "+599", 
  "+60", 
  "+61", 
  "+62", 
  "+63", 
  "+64", 
  "+65", 
  "+66", 
  "+670", 
  "+672", 
  "+673", 
  "+674", 
  "+675", 
  "+676", 
  "+677", 
  "+678", 
  "+679", 
  "+680", 
  "+681", 
  "+682", 
  "+683", 
  "+685", 
  "+686", 
  "+687", 
  "+688", 
  "+689", 
  "+690", 
  "+691", 
  "+692", 
  "+7", 
  "+77", 
  "+81", 
  "+82", 
  "+84", 
  "+850", 
  "+852", 
  "+853", 
  "+855", 
  "+856", 
  "+86", 
  "+872", 
  "+880", 
  "+886", 
  "+90", 
  "+91", 
  "+92", 
  "+93", 
  "+94", 
  "+95", 
  "+960", 
  "+961", 
  "+962", 
  "+963", 
  "+964", 
  "+965", 
  "+966", 
  "+967", 
  "+968", 
  "+970", 
  "+971", 
  "+972", 
  "+973", 
  "+974", 
  "+975", 
  "+976", 
  "+977", 
  "+98", 
  "+992", 
  "+993", 
  "+994", 
  "+995", 
  "+996", 
  "+998"
]