import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { DASHBOARD } from "../../../constant/routeContant";
import "./successScreen.scss";
import commonImages from "../../../assets";
import  succesScreenIcon  from "../../../assets/succesScreenIcon.svg";
export default function SuccessScreen({
  route,
  id,
  isUpdate,
  label1,
  label2,
  label3,
  label4,
  isImg,
  isClass,
}) {
  const [seconds, setSeconds] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      navigate(route ? route : "/dashboard");
    }
  });

  return (
    <div
      className={isClass ? isClass + " successScreenBody" : "successScreenBody"}
    >
      <div className="icon">
        <img
          src={isImg ? isImg : succesScreenIcon}
          className="succesImg"
          alt=""
        />
      </div>
      {isUpdate && <div className="heading">{isUpdate}</div>}
      <div className="report">{label1 ? label1 : "Report ID"}</div>
      <div className="CNU">{id}</div>
      <div className="generated">{label2 ? label2 : "has been generated"}</div>
      <div className="redirect">
        {/* {label3 ? label3 : "You will be redirected to"} <br></br> */}
        {label4 ? label4 : "Back to login in"} <span>{seconds} second</span>
      </div>
    </div>
  );
}
