// import * as React from "react";
// import PropTypes from "prop-types";
// import Slider, { SliderThumb } from "@mui/material/Slider";
// import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import Box from "@mui/material/Box";

// function ValueLabelComponent(props) {
//   const { children, value } = props;

//   return (
//     <Tooltip enterTouchDelay={0} placement="top" title={value}>
//       {children}
//     </Tooltip>
//   );
// }

// ValueLabelComponent.propTypes = {
//   children: PropTypes.element.isRequired,
//   value: PropTypes.number.isRequired,
// };

// const iOSBoxShadow =
//   "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

// const marks = [
//   {
//     value: 400000000,
//   },
//   {
//     value: 4500000000,
//   },
//   // {
//   //   value: 37,
//   // },
//   // {
//   //   value: 100,
//   // },
// ];

// const IOSSlider = styled(Slider)(({ theme }) => ({
//   color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
//   height: 2,
//   padding: "15px 0",
//   "& .MuiSlider-thumb": {
//     height: 28,
//     width: 28,
//     backgroundColor: "#fff",
//     boxShadow: iOSBoxShadow,
//     "&:focus, &:hover, &.Mui-active": {
//       boxShadow:
//         "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
//       // Reset on touch devices, it doesn't add specificity
//       "@media (hover: none)": {
//         boxShadow: iOSBoxShadow,
//       },
//     },
//   },
//   "& .MuiSlider-valueLabel": {
//     fontSize: 12,
//     fontWeight: "normal",
//     top: -6,
//     backgroundColor: "unset",
//     color: theme.palette.text.primary,
//     "&:before": {
//       display: "none",
//     },
//     "& *": {
//       background: "transparent",
//       color: theme.palette.mode === "dark" ? "#fff" : "#000",
//     },
//   },
//   "& .MuiSlider-track": {
//     border: "none",
//   },
//   "& .MuiSlider-rail": {
//     opacity: 0.5,
//     backgroundColor: "#bfbfbf",
//   },
//   "& .MuiSlider-mark": {
//     backgroundColor: "#bfbfbf",
//     height: 8,
//     width: 1,
//     "&.MuiSlider-markActive": {
//       opacity: 1,
//       backgroundColor: "currentColor",
//     },
//   },
// }));

// export default function CustomizedSlider() {
//   return (
//     <Box sx={{ width: 320 }}>
//       <Typography gutterBottom>iOS</Typography>
//       <IOSSlider
//         aria-label="ios slider"
//         defaultValue={60}
//         marks={marks}
//         valueLabelDisplay="on"
//       />
//     </Box>
//   );
// }

import React, { useState, useEffect } from "react";
import { Slider } from "@mui/material";

const DiscreteSliderLabel = ({ selectedOption }) => {
  const [partitions, setPartitions] = useState([]);

  const [salaryRange, setSalaryRange] = useState({ value: "" });

  useEffect(() => {
    updatePartitions();
  }, [salaryRange]);

  const updatePartitions = () => {
    switch (salaryRange.value) {
      case "r1":
        setPartitions(["0.1", "0.2", "0.3", "0.4"]);
        setMinSal(0);
        break;
      case "r2":
        setPartitions(["0.6", "0.7", "0.8", "0.9"]);
        setMinSal(500000);
        break;
      case "r3":
        setPartitions(["1.1", "1.2", "1.3", "1.4"]);
        setMinSal(1000000);
        break;
      case "r4":
        setPartitions(["1.6", "1.7", "1.8", "1.9"]);
        setMinSal(1500000);
        break;
      case "r5":
        setPartitions(["2.1", "2.2", "2.3", "2.4"]);
        setMinSal(2000000);
        break;
      case "r6":
        setPartitions(["2.6", "2.7", "2.8", "2.9"]);
        setMinSal(2500000);
        break;
      case "r7":
        setPartitions(["3.1", "3.2", "3.3", "3.4"]);
        setMinSal(3000000);
        break;
      case "r8":
        setPartitions(["3.6", "3.7", "3.8", "3.9"]);
        setMinSal(3500000);
        break;
      case "r9":
        setPartitions(["4.1", "4.2", "4.3", "4.4"]);
        setMinSal(4000000);
        break;
      case "r10":
        setPartitions(["4.6", "4.7", "4.8", "4.9"]);
        setMinSal(4500000);
        break;
      case "r11":
        setPartitions(["5.1", "5.2", "5.3", "5.4"]);
        setMinSal(5000000);
        break;
      case "r12":
        setPartitions(["5.6", "5.7", "5.8", "5.9"]);
        setMinSal(5500000);
        break;
      case "r13":
        setPartitions(["6.1", "6.2", "6.3", "6.4"]);
        setMinSal(6000000);
        break;
      case "r14":
        setPartitions(["6.6", "6.7", "6.8", "6.9"]);
        setMinSal(6500000);
        break;
      case "r15":
        setPartitions(["7.1", "7.2", "7.3", "7.4"]);
        setMinSal(7000000);
        break;
      case "r16":
        setPartitions(["7.6", "7.7", "7.8", "7.9"]);
        setMinSal(7500000);
        break;
      case "r17":
        setPartitions(["8.1", "8.2", "8.3", "8.4"]);
        setMinSal(8000000);
        break;
      case "r18":
        setPartitions(["8.6", "8.7", "8.8", "8.9"]);
        setMinSal(8500000);
        break;
      case "r19":
        setPartitions(["9.1", "9.2", "9.3", "9.4"]);
        setMinSal(9000000);
        break;
      case "r20":
        setPartitions(["9.6", "9.7", "9.8", "9.9"]);
        setMinSal(9500000);
        break;
      default:
        break;
    }
    setTimeout(() => {
      handleDrag("reset");
    }, 0);
  };

  const handleDrag = (reset) => {
    let fullWidth = document.getElementById("sliderRange").offsetWidth;
    let element = document.getElementById("rangeMarker");
    let position =
      reset === "reset"
        ? 0
        : Number(
            window
              .getComputedStyle(element)
              .webkitTransform.split(",")[4]
              .trim()
          );
    let mySalary = Math.ceil((position / fullWidth) * 500000 + minSal);
    setAmount({ value: mySalary, err: "" });
    setValidPayload(true);
    setDefaultSliderPos(position);
    setInputUnsaved(true);
  };

  const [amount, setAmount] = useState({ value: "", err: "" });
  const [validPayload, setValidPayload] = useState(false);
  const [defaultSliderPos, setDefaultSliderPos] = useState(0);
  const [inputUnsaved, setInputUnsaved] = useState(false);
  const [minSal, setMinSal] = useState(0);

  const [sliderValue, setSliderValue] = useState(0);

  const getSliderValues = () => {
    switch (selectedOption) {
      case "0.0M to 0.5M":
        return [0, 250000, 500000];
      case "0.5M to 1.0M":
        return [500000, 750000, 1000000];
      case "1.0M to 1.5M":
        return [1000000, 1250000, 1500000];
      default:
        return [0, 500000, 1000000];
    }
  };

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const [minValue, centerValue, maxValue] = getSliderValues();

  return (
    <Slider
      value={sliderValue}
      onChange={handleChange}
      min={minValue}
      max={maxValue}
      step={(maxValue - minValue) / 100}
      marks={[
        { value: minValue, label: `${minValue / 1000000}M` },
        { value: centerValue, label: `${centerValue / 1000000}M` },
        { value: maxValue, label: `${maxValue / 1000000}M` },
      ]}
      valueLabelDisplay="on"
      defaultValue={0}
    />
  );
};

export default DiscreteSliderLabel;
