import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import * as adminAction from "../actions/adminAction";
import {
  getAdminAboutToExpireUsers,
  getAdminCount,
  getAdminReport,
  updateIndustry,
} from "../../services/adminServie";
import { adminType } from "../constant/adminType";

export function* getAdminReportSaga(params) {
  try {
    const { data, status, error } = yield call(getAdminReport, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      adminAction.getAdminReportSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Top Communities Failed" };
    yield put(adminAction.getAdminReportFailure(data));
  }
}

export function* getAdminAboutToExpireUsersSaga(params) {
  try {
    const { data, status, error } = yield call(
      getAdminAboutToExpireUsers,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      adminAction.getAdminAboutToExpireUsersSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getAdminAboutToExpireUsers Failed" };
    yield put(adminAction.getAdminAboutToExpireUsersFailure(data));
  }
}

export function* postAdminUpdateIndustrySaga(params) {
  try {
    const { data, status, error } = yield call(updateIndustry, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      adminAction.postAdminUpdateIndustrySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Admin update industry Failed" };
    yield put(adminAction.postAdminUpdateIndustryFailure(data));
  }
}

export function* getAdminCountSaga(params) {
  try {
    const { data, status, error } = yield call(getAdminCount, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      adminAction.getAdminCountSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getAdminCount Failed" };
    yield put(adminAction.getAdminCountFailure(data));
  }
}

const myAdminSaga = [
  takeLatest(adminType.GET_ADMIN_REPORT_PENDING, getAdminReportSaga),
  takeLatest(
    adminType.GET_ADMIN_ABOUT_TO_EXPIRE_PENDING,
    getAdminAboutToExpireUsersSaga
  ),
  takeLatest(adminType.GET_ADMIN_COUNT_PENDING, getAdminCountSaga),
  takeLatest(
    adminType.POST_ADMIN_UPDATE_INDUSTRY_PENDING,
    postAdminUpdateIndustrySaga
  ),
];

export default myAdminSaga;
