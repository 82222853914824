// yyyy/mm/dd
// hh:mm:ss

// limit the number of charaters in input
export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

// handle the API error data
export const apiError = (errorObject) => {
  return errorObject.response ? errorObject.response : errorObject;
};

export const getFormatedDate = (date) => {
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
};

export const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return mm + "-" + dd + "-" + yyyy;
};

export const getCurrentTime = () => {
  const today = new Date();
  return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
};

export const getCurrentMinuteSecond = () => {
  const today = new Date();
  return `${convertTwoDigit(today.getHours())}:${convertTwoDigit(
    today.getMinutes()
  )}`;
};

export const convertTime = (time) => {
  const today = new Date();
  if (time) {
    const timeSplit = time.split(":");
    return timeSplit[0] + ":" + timeSplit[1] + ":" + today.getSeconds();
  } else {
    return time;
  }
};

const convertTwoDigit = (val) => {
  if (String(val).length === 1) {
    return `0${val}`;
  } else {
    return String(val);
  }
};

export const getDynamicFields = (dynamicFields, fieldId) => {
  const data = dynamicFields?.find((x) => x.fieldId === fieldId)?.fieldValue;
  return data;
};

export const removeWordSpace = (value) => {
  return value.replace(/ /g, "").toLowerCase();
};

export const findObject = (list, value) => {
  if (list && value) {
    const findData = list.find((dataFind) => dataFind.key == value);
    return `${findData.key}|${findData.value}`;
  }
  return "";
};

export const getFirstValue = (value) => {
  if (value && value.includes("|")) {
    return value.split("|")[0];
  }
  return "";
};

export const getSignatureValue = (value) => {
  let isSignature = false;
  if (value && value.includes("|")) {
    const signatureText = value.split("|")[0];
    isSignature = signatureText.toLowerCase() === "true";
  }
  return isSignature;
};

export const ConvertDate = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const suffixes = ["st", "nd", "rd"];
  const daySuffix = suffixes[(day - 1) % 10 < 3 ? (day - 1) % 10 : 3];

  //return `${monthNames[monthIndex]} ${" "} ${day} ${" "} ${year}`;
  return `${day} ${" "} ${monthNames[monthIndex]} ${" "} ${year}`;
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};


export function convertToUTC(dateTimeStr) {
  // Parse the date and time components
  const [dateStr, timeRange] = dateTimeStr.split(" ");
  const [startTime, endTime] = timeRange.split("-");

  // Parse the date parts
  const [year, month, day] = dateStr.split("-").map(Number);

  // Function to get the local timezone offset in +HH:MM format
  function getLocalOffset() {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();
    const sign = offsetInMinutes > 0 ? '-' : '+';
    
    const absOffset = Math.abs(offsetInMinutes);
    const hours = String(Math.floor(absOffset / 60)).padStart(2, '0');
    const minutes = String(absOffset % 60).padStart(2, '0');

    return `${sign}${hours}:${minutes}`;
  }

  // Function to parse time and convert to UTC with the local offset
  function parseTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Get local offset in minutes
    const localOffsetInMinutes = date.getTimezoneOffset();

    // Adjust the date for UTC
    date.setUTCMinutes(date.getUTCMinutes() + localOffsetInMinutes);

    return date;
  }

  function formatTime(date) {
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const startUTC = parseTime(startTime);
  const endUTC = parseTime(endTime);

  const formattedStartUTC = formatTime(startUTC);
  const formattedEndUTC = formatTime(endUTC);

  return `${dateStr} ${formattedStartUTC}-${formattedEndUTC}`;
}

// Example usage
// const dateTimeStr = "2024-11-04 17:00-18:00";
// const utcTime = convertToUTC(dateTimeStr);
// console.log(utcTime);

// export function convertToUTC(dateTimeStr, offset) {
//   // Parse the date and time components
//   const [dateStr, timeRange] = dateTimeStr.split(" ");
//   const [startTime, endTime] = timeRange.split("-");

//   // Parse the date parts
//   const [year, month, day] = dateStr.split("-").map(Number);

//   // Function to parse time and convert to UTC with the given offset
//   function parseTime(timeStr) {
//     const [hours, minutes] = timeStr.split(":").map(Number);

//     const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

//     const offsetMatch = offset.match(/([+-])(\d{2}):(\d{2})/);
//     if (!offsetMatch) {
//       throw new Error("Invalid offset format");
//     }

//     const offsetSign = offsetMatch[1];
//     const offsetHours = parseInt(offsetMatch[2], 10);
//     const offsetMinutes = parseInt(offsetMatch[3], 10);
//     const offsetInMinutes =
//       (offsetHours * 60 + offsetMinutes) * (offsetSign === "+" ? 1 : -1);

//     date.setUTCMinutes(date.getUTCMinutes() - offsetInMinutes);

//     return date;
//   }

//   function formatTime(date) {
//     const hours = String(date.getUTCHours()).padStart(2, "0");
//     const minutes = String(date.getUTCMinutes()).padStart(2, "0");
//     return `${hours}:${minutes}`;
//   }

//   const startUTC = parseTime(startTime);
//   const endUTC = parseTime(endTime);

//   const formattedStartUTC = formatTime(startUTC);
//   const formattedEndUTC = formatTime(endUTC);

//   return `${dateStr} ${formattedStartUTC}-${formattedEndUTC}`;
// }

// export function convertUTCToTimezone(utcDateTimeStr, offset) {
//   // Parse the date and time components
//   const [dateStr, timeRange] = utcDateTimeStr?.split(' ');
//   const [startTime, endTime] = timeRange?.split('-');

//   // Parse the date parts
//   const [year, month, day] = dateStr?.split('-')?.map(Number);

//   // Helper function to create a date object from date and time
//   function createDate(year, month, day, hours, minutes) {
//       const date = new Date(Date.UTC(year, month, day, hours, minutes));
//       return date;
//   }

//   // Helper function to apply offset
//   function applyOffset(date, offset) {
//       const [offsetSign, offsetHours, offsetMinutes] = offset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
//       const totalOffsetMinutes = (parseInt(offsetHours) * 60 + parseInt(offsetMinutes)) * (offsetSign === '+' ? 1 : -1);
//       const newDate = new Date(date.getTime() + totalOffsetMinutes * 60000);
//       return newDate;
//   }

//   // Helper function to format date to "HH:MM"
//   function formatTime(date) {
//       const hours = String(date.getUTCHours()).padStart(2, '0');
//       const minutes = String(date.getUTCMinutes()).padStart(2, '0');
//       return `${hours}:${minutes}`;
//   }

//   // Parse and convert start time
//   const [startHours, startMinutes] = startTime.split(':').map(Number);
//   const startUTCDate = createDate(year, month - 1, day, startHours, startMinutes);
//   const startLocalDate = applyOffset(startUTCDate, offset);
//   const formattedStartLocal = formatTime(startLocalDate);

//   // Parse and convert end time
//   const [endHours, endMinutes] = endTime.split(':').map(Number);
//   const endUTCDate = createDate(year, month - 1, day, endHours, endMinutes);
//   const endLocalDate = applyOffset(endUTCDate, offset);
//   const formattedEndLocal = formatTime(endLocalDate);

//   return `${dateStr} ${formattedStartLocal}-${formattedEndLocal}`;
// }

export function convertUTCToTimezone(utcDateTimeStr) {
  // Parse the date and time components
  const [dateStr, timeRange] = utcDateTimeStr?.split(" ");
  const [startTime, endTime] = timeRange?.split("-");

  // Parse the date parts
  const [year, month, day] = dateStr?.split("-")?.map(Number);

  // Helper function to create a date object from date and time
  function createDate(year, month, day, hours, minutes) {
    const date = new Date(Date.UTC(year, month, day, hours, minutes));
    return date;
  }

  // Helper function to apply local offset
  function applyLocalOffset(date) {
      const localOffset = date.getTimezoneOffset(); // Offset in minutes
      const newDate = new Date(date.getTime() - localOffset * 60000); // Adjust to local time
      return newDate;
  }

  // Helper function to format date to "HH:MM"
  function formatTime(date) {
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  // Parse and convert start time
  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const startUTCDate = createDate(year, month - 1, day, startHours, startMinutes);
  const startLocalDate = applyLocalOffset(startUTCDate);
  const formattedStartLocal = formatTime(startLocalDate);

  // Parse and convert end time
  const [endHours, endMinutes] = endTime.split(":").map(Number);
  const endUTCDate = createDate(year, month - 1, day, endHours, endMinutes);
  const endLocalDate = applyLocalOffset(endUTCDate);
  const formattedEndLocal = formatTime(endLocalDate);

  return `${dateStr} ${formattedStartLocal}-${formattedEndLocal}`;
}



export function addMinutesToTime(time, minutesToAdd) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Create a new Date object with the current date and the provided time
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // Add the specified number of minutes
  date.setMinutes(date.getMinutes() + minutesToAdd);

  // Format the new time as a string in HH:MM format
  const newHours = date.getHours().toString().padStart(2, "0");
  const newMinutes = date.getMinutes().toString().padStart(2, "0");

  return `${newHours}:${newMinutes}`;
}

// Example usage
const initialTime = "15:30";
const newTime = addMinutesToTime(initialTime, 60);
console.log(newTime); // Outputs: '16:30'
