import React from "react";
import parse from "html-react-parser";

import "./resumeTemplate.scss";

export default function ResumeTemplate({
  basicDetails,
  profile,
  Pskills,
  Oskills,
  work_experience,
  board_experience,
  certifications,
  education,
}) {
  console.log("profile", profile);
  let boardExpRows = [];
  for (let i = 0; i < board_experience?.boardName?.length; i++) {
    boardExpRows.push(
      <tr key={`board_experience-${i}`}>
        <td>
          <p>
            {board_experience &&
              board_experience.boardType.length &&
              board_experience.boardName.length &&
              board_experience.boardStartM.length &&
              board_experience.boardStartY.length &&
              board_experience.boardEndM.length &&
              board_experience.boardEndY.length &&
              `${board_experience.boardType[i]} in ${board_experience.boardName[i]}`}
          </p>
        </td>
        {board_experience.stillMember[i]
          ? board_experience.boardStartM.length &&
            board_experience.boardStartY.length &&
            board_experience.boardEndM.length &&
            board_experience.boardEndY.length && (
              <td
                className="time-line"
                style={{ width: "200px" }}
              >{`${board_experience.boardStartM[i].substring(0, 3)} ${
                board_experience.boardStartY[i]
              } - Present`}</td>
            )
          : board_experience.boardStartM.length &&
            board_experience.boardStartY.length &&
            board_experience.boardEndM.length &&
            board_experience.boardEndY.length && (
              <td
                className="time-line"
                style={{ width: "200px" }}
              >{`${board_experience.boardStartM[i].substring(0, 3)} ${
                board_experience.boardStartY[i]
              } - ${board_experience.boardEndM[i].substring(0, 3)} ${
                board_experience.boardEndY[i]
              }`}</td>
            )}
      </tr>
    );
  }
  let workExpRows = [];
  for (let i = 0; i < work_experience?.orgNames?.length; i++) {
    workExpRows.push(
      <tr key={`work_experience-${i}`}>
        <td>
          <p className="orgs-name">{`${work_experience.orgNames[i]}`}</p>
          <p className="job-title">
            {work_experience.role && work_experience.role
              ? work_experience.role[i]
              : null}
          </p>
          {work_experience.rnrs[i].length ? (
            <span className="quills">
              {work_experience.rnrs &&
              work_experience.rnrs.length &&
              work_experience.rnrs[i] &&
              work_experience.rnrs[i].length
                ? parse(work_experience.rnrs[i])
                : null}
            </span>
          ) : null}
        </td>
        {work_experience.tillDate[i] ? (
          <td
            className="time-line"
            style={{ width: "200px" }}
          >{`${work_experience.startM[i].substring(0, 3)} ${
            work_experience.startY[i]
          } - Present`}</td>
        ) : (
          <td className="time-line" style={{ width: "200px" }}>
            {`${work_experience.startM[i].substring(0, 3)} ${
              work_experience.startY[i]
            } - `}
            {work_experience.endM[i] === "Currently Working" ||
            work_experience.endY[i] === "Currently Working" ? (
              "Present"
            ) : (
              <span>
                {work_experience.endM[i].substring(0, 3)}{" "}
                {work_experience.endY[i]}
              </span>
            )}
          </td>
        )}
      </tr>
    );
  }
  let educationRows = [];
  for (let i = 0; i < education?.degree?.length; i++) {
    educationRows.push(
      <tr key={`education-${i}`}>
        <td>
          <p className="job-title">{`${education.degree[i]}, ${education.special[i]}, ${education.institute[i]}`}</p>
        </td>
        <td
          className="time-line"
          style={{ width: "200px" }}
        >{`${education.startM[i].substring(0, 3)} ${
          education.startY[i]
        } - ${education.endM[i].substring(0, 3)} ${education.endY[i]}`}</td>
      </tr>
    );
  }

  let coreCompetencies = [];
  coreCompetencies.push(
    <span key={"corecompetencies"}>
      <p>
        {Pskills && Pskills.length ? (
          <span>
            <strong>Primary Skills:</strong>{" "}
            {Pskills.toString().replace(/,/g, " | ")}
          </span>
        ) : null}
      </p>
      <p>
        {Oskills && Oskills.length ? (
          <span>
            <strong>Other/Technical Skills:</strong>{" "}
            {Oskills.toString().replace(/,/g, " | ")}
          </span>
        ) : null}
      </p>
    </span>
  );

  let certificationRows = [];
  for (let i = 0; i < certifications?.name?.length; i++) {
    certificationRows.push(
      <tr key={`certification-${i}`}>
        <td>
          <p className="job-title">{certifications.name[i]}</p>
        </td>
        <td className="time-line" style={{ width: "200px" }}>
          {certifications.endM[i] === "Skip"
            ? ""
            : certifications.endM[i].substring(0, 3)}{" "}
          {certifications.endY[i] === "Skip" ? "" : certifications.endY[i]}
        </td>
      </tr>
    );
  }

  let careerSnapshot = [];
  for (let i = 0; i < work_experience?.orgNames?.length; i++) {
    careerSnapshot.push(
      <p key={`careersnapshot-${i}`}>
        {work_experience.role && work_experience.role.length ? (
          <span>{work_experience.role[i]}, </span>
        ) : null}
        {`${work_experience.orgNames[i]}, ${work_experience.designations[i]}`}
        {work_experience.tillDate[i] ? (
          <span>{`: ${work_experience.startM[i].substring(0, 3)} ${
            work_experience.startY[i]
          } - Present`}</span>
        ) : (
          <span>
            {`: ${work_experience.startM[i].substring(0, 3)} ${
              work_experience.startY[i]
            } - `}
            {work_experience.endM[i] === "Currently Working" ||
            work_experience.endY[i] === "Currently Working" ? (
              "Present"
            ) : (
              <span>
                {work_experience.endM[i].substring(0, 3)}{" "}
                {work_experience.endY[i]}
              </span>
            )}
          </span>
        )}
      </p>
    );
  }

  return (
    <div id="TempHolder" className="tempHeader1">
      <div className="resumeTemplste4">
        <h1 style={{ textAlign: "center" }}>
          {`${basicDetails?.first_name} ${basicDetails?.last_name}`}
        </h1>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <p style={{ marginRight: "25px" }}>
                    {basicDetails?.city ? basicDetails?.city : ""},{" "}
                    {basicDetails?.state !== "" ? basicDetails?.state : ""}{" "}
                    {basicDetails?.zipcode ? basicDetails?.zipcode : ""}, <br />
                    {basicDetails?.country_name !== "-1"
                      ? basicDetails?.country_name
                      : ""}
                  </p>
                </td>
                <td>
                  <p style={{ marginRight: "25px" }}>
                    {basicDetails?.email ? `${basicDetails?.email}` : ""}
                  </p>
                </td>
                <td>
                  <p>
                    {basicDetails?.dial_code == -1
                      ? ""
                      : basicDetails?.dial_code || ""}{" "}
                    {basicDetails?.phone || ""}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p style={{ textAlign: "center", borderBottom: "1px solid #AAAAAA" }}>
          {profile?.social_links?.linkedin ? (
            <a
              rel="noopener noreferrer"
              href={`https://linkedin.com/in/${profile?.social_links?.linkedin}`}
              target="_blank"
              style={{ textDecoration: "none", color: "#000" }}
            >
              {profile?.social_links?.linkedin} |
            </a>
          ) : null}{" "}
          {profile?.social_links?.twitter ? (
            <a
              rel="noopener noreferrer"
              href={`https://twitter.com/${profile?.social_links?.twitter}`}
              target="_blank"
              style={{ textDecoration: "none", color: "#000" }}
            >
              {profile?.social_links?.twitter}
            </a>
          ) : null}
        </p>
        <p style={{ margin: "20px 0px", borderBottom: "1px solid #AAAAAA" }}>
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginTop: "10px",
            }}
          >
            {profile?.work_experience?.designations?.length > 0
              ? profile?.work_experience?.designations[0]
              : null}
          </p>
          {profile?.personal_details && profile?.personal_details?.aboutMe ? (
            <span className="quills">
              {" "}
              {parse(profile?.personal_details.aboutMe)}
            </span>
          ) : (
            <span></span>
          )}
        </p>
        {careerSnapshot.length ? (
          <div style={{ borderBottom: "1px solid #AAAAAA" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
            >
              CAREER SNAPSHOT
            </p>
            {careerSnapshot}
          </div>
        ) : null}
        {Pskills.length || Oskills.length ? (
          <div style={{ borderBottom: "1px solid #AAAAAA" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
            >
              CORE COMPETENCIES
            </p>
            {coreCompetencies}
          </div>
        ) : null}
        {workExpRows.length ? (
          <div style={{ borderBottom: "1px solid #AAAAAA" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
            >
              PROFESSIONAL EXPERIENCE
            </p>
            <table style={{ width: "100%" }}>
              <tbody>{workExpRows}</tbody>
            </table>
          </div>
        ) : null}
        {board_experience?.boardName?.length ? (
          <div style={{ borderBottom: "1px solid #AAAAAA" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
            >
              BOARD EXPERIENCE
            </p>
            <table style={{ width: "100%" }}>
              <tbody>{boardExpRows}</tbody>
            </table>
          </div>
        ) : null}

        {educationRows.length ? (
          <div style={{ borderBottom: "1px solid #AAAAAA" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
            >
              EDUCATION
            </p>
            <table style={{ width: "100%" }}>
              <tbody>{educationRows}</tbody>
            </table>
          </div>
        ) : null}

        {certificationRows.length ? (
          <div>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
            >
              CERTIFICATIONS
            </p>
            <table style={{ width: "100%" }}>
              <tbody>{certificationRows}</tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}
