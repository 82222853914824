import { CircularProgress } from "@mui/material";
import "./loader.scss";
export default function Loader() {
  return (
    <div className="fullpage_ui_loader">
      <CircularProgress />
    </div>
  );
}

export function TableLoader() {
  return (
    <div className="tableLoader_fullpage_ui">
      <CircularProgress />
    </div>
  );
}

export function FullPageLoader() {
  return (
    <div className="fullpage_ui_loader">
      <CircularProgress />
    </div>
  );
}
