import React, { useState } from "react";
import "./ViewJob.scss";
import { Grid } from "@mui/material";
import moment from "moment";
import NoUser from "../../../assets/noUser.png";
import SearchedUserCard from "../../../library/custom/searchCard/SearchedUserCard";
import { MESSAGES, VIEW_PROFILE } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../library/custom/backBtn/BackButton";

function ViewJob({
  postedJobDatas,
  getApplicatntDetails,
  getApplicantDetailsData,
}) {
  
  const navigate = useNavigate();
  const [cardDetails, setcardDetails] = useState("");

  const cardClickHandler = (data) => {
    setcardDetails(data?.jobId);
    const datas = {
      JobId: data?.jobId,
      page: 1,
      limit: 1000,
    };
    getApplicatntDetails(datas);
  };

  function onProfileClick(id) {
    navigate(VIEW_PROFILE, {
      state: { id: id },
    });
  }

  function onLoactionClick(lat, lng) {
    // navigate(SEARCH, {
    //   state: { lat: lat, lng: lng },
    // });
  }
  function onMsgClick(id) {
    navigate(MESSAGES, {
      state: { id: { individualId: id } },
    });
  }
  return (
    <>
      <Grid container>
        <BackButton label={"Back"} route={-1} />
      </Grid>
      <Grid className="viewJobContainer">
        <Grid className="Viewcontainer">
          <Grid className="box">
            <Grid className="left-section">
              <Grid item container lg={12}>
                <Grid item lg={9} xs={12} sm={12} md={6} pl={2} pb={3}>
                  <h1 className="MainHeadingDetails">View Posted Jobs</h1>
                </Grid>
              </Grid>
              <Grid>
                {postedJobDatas?.map((data) => {
                  return (
                    <div class="card" onClick={() => cardClickHandler(data)}>
                      <div className="JobHeadingContainer">
                        <div className="JobSubHeadingDeatails">
                          {data?.title}
                        </div>
                        <div className="threDot">
                          {/* <img src={threeDot} alt="Expand More Image" /> */}
                        </div>
                      </div>
                      <div className="CompanyName">{data?.company}</div>
                      {/* <div className="AdressStyle">{data?.createdAt}</div> */}
                      <div className="PostDateContainer">
                        <div className="PostDate">
                          Posted on:{" "}
                          <span>
                            {" "}
                            {` ${moment(data?.createdAt).format(
                              "MMM Do YYYY"
                            )}`}
                          </span>
                        </div>
                        <div className="AplicantNo">
                          {data?.applicationcount} Applicants
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Grid>
            </Grid>
            <Grid className="right-section">
              <Grid item container lg={12}>
                <Grid item lg={9} xs={12} sm={12} md={6} pl={3} pb={2}>
                  <h1 className="MainHeadingDetails">Applicants</h1>
                </Grid>
              </Grid>
              {getApplicantDetailsData?.length > 0 ? (
                getApplicantDetailsData?.map((item, i) => (
                  <Grid mt={1} key={item?.id} pl={3}>
                    <SearchedUserCard
                      name={item?.first_name + " " + item?.last_name}
                      designation={
                        item?.designation
                          ? item?.designation
                          : "Desgination Not Available"
                      }
                      city={item?.city ? item?.city : "City Not Available"}
                      state={item?.state}
                      country={
                        item?.country_name
                          ? item?.country_name
                          : "Country Not Available"
                      }
                      company={item?.company}
                      profilePic={
                        item?.profile_pic === null ? NoUser : item?.profile_pic
                      }
                      btnLabel={"Message"}
                      v2={true}
                      onProfileClick={() => onProfileClick(item?.id)}
                      onMsgClick={() => onMsgClick(item?.id)}
                      onLoactionClick={() =>
                        onLoactionClick(item?.latitude, item?.longitude)
                      }
                    />
                  </Grid>
                ))
              ) : (
                <h5>No Applicants</h5>
              )}
              <Grid></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ViewJob;
