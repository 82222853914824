import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import { mentorshipType } from "../constant/mentorshipType";
import * as mentorshipAction from "../actions/mentorshipAction";
import {
  getMentorsList,
  getMentorshipSubjects,
  getMentorshipUpcomingSessions,
  getMyMentees,
  getMyMentors,
  postMentorshipBookSession,
  postMentorshipRequest,
  postMentorshipRequestAccept,
  postMentorshipRequestCancel,
  postMentorshipRequestReject,
  postMentorshipSessionAccept,
  postMentorshipSessionCancel,
  postMentorshipSessionReject,
} from "../../services/mentorshipService";

export function* getMentorshipSubjectsSaga(params) {
  try {
    const { data, status, error } = yield call(getMentorshipSubjects, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.getMentorshipSubjectsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Subjects Failed" };
    yield put(mentorshipAction.getMentorshipSubjectsFailure(data));
  }
}

export function* getMentorsListSaga(params) {
  try {
    const { data, status, error } = yield call(getMentorsList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.getMentorsListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "mentorList Failed" };
    yield put(mentorshipAction.getMentorsListFailure(data));
  }
}

export function* postMentorshipRequestSaga(params) {
  try {
    const { data, status, error } = yield call(postMentorshipRequest, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postRequestMentorshipSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship Request Failed" };
    yield put(mentorshipAction.postRequestMentorshipFailure(data));
  }
}

export function* getMyMenteesSaga(params) {
  try {
    const { data, status, error } = yield call(getMyMentees, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.getMyMenteesSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get My Mentees Failed" };
    yield put(mentorshipAction.getMyMenteesFailure(data));
  }
}

export function* getMyMentorsSaga(params) {
  try {
    const { data, status, error } = yield call(getMyMentors, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.getMyMentorsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get My Mentors Failed" };
    yield put(mentorshipAction.getMyMentorsFailure(data));
  }
}

export function* postMentorshipRequestRejectSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipRequestReject,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postRequestMentorshipRejectSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship Request Reject Failed" };
    yield put(mentorshipAction.postRequestMentorshipRejectFailure(data));
  }
}

export function* postMentorshipRequestAcceptSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipRequestAccept,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postRequestMentorshipAcceptSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship Request Accept Failed" };
    yield put(mentorshipAction.postRequestMentorshipAcceptFailure(data));
  }
}

export function* postMentorshipRequestCancelSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipRequestCancel,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postRequestMentorshipCancelSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship Request Cancel Failed" };
    yield put(mentorshipAction.postRequestMentorshipCancelFailure(data));
  }
}

export function* postMentorshipBookSessionSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipBookSession,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postMentorshipBookSessionSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship book session Failed" };
    yield put(mentorshipAction.postMentorshipBookSessionFailure(data));
  }
}

export function* getMentorshipUpcomingSessionsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getMentorshipUpcomingSessions,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.getMentorshipUpcomingSessionsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get UpcomingSessions Failed" };
    yield put(mentorshipAction.getMentorshipUpcomingSessionsFailure(data));
  }
}

export function* postMentorshipSessionAcceptSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipSessionAccept,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postMentorshipSessionAcceptSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship  session Accept Failed" };
    yield put(mentorshipAction.postMentorshipSessionAcceptFailure(data));
  }
}

export function* postMentorshipSessionRejectSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipSessionReject,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postMentorshipSessionRejectSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship  session Reject Failed" };
    yield put(mentorshipAction.postMentorshipSessionRejectFailure(data));
  }
}

export function* postMentorshipSessionCancelSaga(params) {
  try {
    const { data, status, error } = yield call(
      postMentorshipSessionCancel,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      mentorshipAction.postMentorshipSessionCancelSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post MEntorship  session Cancel Failed" };
    yield put(mentorshipAction.postMentorshipSessionCancelFailure(data));
  }
}

const myMentorshipSaga = [
  takeLatest(
    mentorshipType.GET_MENTORSHIP_SUBJECTS_PENDING,
    getMentorshipSubjectsSaga
  ),
  takeLatest(mentorshipType.GET_MENTORS_LIST_PENDING, getMentorsListSaga),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_REQUEST_PENDING,
    postMentorshipRequestSaga
  ),
  takeLatest(mentorshipType.GET_MY_MENTEES_PENDING, getMyMenteesSaga),
  takeLatest(mentorshipType.GET_MY_MENTORS_PENDING, getMyMentorsSaga),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_PENDING,
    postMentorshipRequestRejectSaga
  ),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_PENDING,
    postMentorshipRequestAcceptSaga
  ),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_PENDING,
    postMentorshipRequestCancelSaga
  ),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_BOOK_SESSION_PENDING,
    postMentorshipBookSessionSaga
  ),
  takeLatest(
    mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_PENDING,
    getMentorshipUpcomingSessionsSaga
  ),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_PENDING,
    postMentorshipSessionAcceptSaga
  ),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_SESSION_REJECT_PENDING,
    postMentorshipSessionRejectSaga
  ),
  takeLatest(
    mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_PENDING,
    postMentorshipSessionCancelSaga
  ),
];

export default myMentorshipSaga;
