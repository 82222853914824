import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import IndustrySkills from "./IndustrySkills";
import ManageFunctionSkill from "./ManageFunctionSkills";
import { useDispatch, useSelector } from "react-redux";
import ManageFunctionRoles from "./ManageFunctionsRoles";
import { postAdminUpdateIndustry } from "../../redux/actions/adminAction";
import Loader from "../../library/common/Loader";
import { getIndustry } from "../../redux/actions/searchAction";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Managing = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  function handleTabsChange(e, newTabValue) {
    setTabValue(newTabValue);
  }

  const industryData = useSelector((state) => state?.industry?.data?.response);
  const postAdminUpdateIndustryData = useSelector(
    (state) => state?.postAdminUpdateIndustry
  );
  console.log(postAdminUpdateIndustryData);

  useEffect(() => {
    dispatch(getIndustry());
  }, []);
  function onSubmit(data) {
    dispatch(postAdminUpdateIndustry(data));
  }
  useEffect(() => {
    if (
      !postAdminUpdateIndustryData?.isLoading &&
      postAdminUpdateIndustryData.status === "SUCCESS"
    )
      dispatch(getIndustry());
  }, [postAdminUpdateIndustryData]);
  const showLoader = useSelector(
    (state) =>
      state?.postAdminUpdateIndustry.isLoading || state?.industry.isLoading
  );

  return (
    <div>
      {showLoader ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid className="leftMenu">
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabsChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tabcustom"
                  label="Industry/Skills"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tabcustom"
                  label="Functions/Roles"
                  {...a11yProps(1)}
                />
                <Tab
                  // className="tabcustom"
                  label="Functions/Primary Skills"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Grid>

          {tabValue === 0 && (
            <Grid container item lg={12}>
              <IndustrySkills onSubmit={onSubmit} />
            </Grid>
          )}
          {tabValue === 1 && (
            <ManageFunctionRoles
              functionOptions={industryData?.functions ?? []}
              onSubmit={onSubmit}
            />
          )}
          {tabValue === 2 && (
            <Grid container>
              <ManageFunctionSkill
                functionOptions={industryData?.functions ?? []}
                onSubmit={onSubmit}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Managing;
