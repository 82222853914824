import { getApiUrl } from "../config";
import { get, post } from "./axiosRequest";

export const searchMap = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/search/map`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const searchMapCorporate = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/corporate/search-corporate/map`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postSearchUsers = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/search`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const searchUser = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/search/profile`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const searchProfileCorporate = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/corporate/search-corporate/profile`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPopularSearches = async ({ params }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/user/search/popular`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPopularSearchesCorporate = async ({ params }) => {
  const token = sessionStorage.getItem("token");
  const url = `${
    getApiUrl()?.baseUrl
  }/corporate/search-corporate/popular-profile`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getRecentSearchesCorporate = async ({ params }) => {
  const id = sessionStorage.getItem("token");
  const url = `${
    getApiUrl().baseUrl
  }/corporate/search-corporate/recent-profile`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${id}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInudstry = async ({ params }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/industry`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
