import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./richTextEditor.scss";

function RichTextEditor({
  onChange,
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
  ],
  value,
}) {
  const [editorHtml, setEditorHtml] = useState("");
  useEffect(() => {
    value?.length > 0 && setEditorHtml(value);
  }, [value]);

  function handleEditorChange(html) {
    setEditorHtml(html);
    onChange(html);
  }

  // const toolbarOptions = [
  //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //   ["bold", "italic", "underline", "strike"],
  //   [{ list: "ordered" }, { list: "bullet" }],
  //   ["link", "image", "video"],
  //   // [{ align: [] }],
  //   [
  //     // "link",
  //     //  "image"
  //   ],
  //   //["clean"],
  // ];

  return (
    // <div style={{ height: '500px' }}>

    <ReactQuill
      value={editorHtml}
      onChange={handleEditorChange}
      modules={{ toolbar: toolbarOptions }}
      theme="snow"
      className="overrideEditor"
    />
    //   </div>
  );
}

export default RichTextEditor;
