import React from "react";
import "./clickableChips.scss";
import { Chip, Grid } from "@mui/material";
import cancelIcon from "../../../assets/cancelIcon.svg";

function ClickableChips({ label, deleteIcon, isdelete=false }) {
  const handleClick = () => {
    alert("You clicked the Chip");
  };

  return (
    <Grid className="overrideClickableChips">
      <div className="chipContainer">
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          // onClick={handleClick}
        />
      </div>{isdelete && 
      <div className="deleteUserChip">
        <img
          className="cancelIcon"
          src={cancelIcon}
          alt=""
          onClick={(e, index) => deleteIcon(label)}
        />
      </div>}
    </Grid>
  );
}

export default ClickableChips;
