import React from "react";
import NodataImage from "../../assets/NodataImage.svg";
import "./NoDataDisplayCard.scss";

function NoDataDisplayCard() {
  return (
    <div class="NodataDisplayedcontainer">
      <div class="NodataDisplayedcard">
        <img src={NodataImage} alt="No Data Image" />
        <div class="NodataDisplayedtext">No Data to be displayed</div>
      </div>
    </div>
  );
}

export default NoDataDisplayCard;
