import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DASHBOARD, ONBOARDING } from "../constant/routeContant";

// for public
const PublicRoutes = () => {
  const token = sessionStorage.getItem("token");
  const isAuth = useSelector((state) => state.login.userSuccess);
  const isOnboarded = useSelector((state) => state.login.isOnboarded);
  return  !token ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} replace={true} />
  );
};

export default PublicRoutes;
