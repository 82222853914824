import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "./profileCard.scss";
import userDefaultProfilePic from "../../../src/assets/userDefaultProfilePic.svg";
import facebookColorIcon from "../../../src/assets/facebookColorIcon.svg";
import pintrestColorIcon from "../../../src/assets/pintrestColorIcon.svg";
import linkedInColorIcon from "../../../src/assets/linkedInColorIcon.svg";
import githubColorIcon from "../../../src/assets/githubColorIcon.svg";
// import twitterColorIcon from "../../../src/assets/twitterColorIcon.svg";
import twitterColorIcon from "../../../src/assets/twitterXlogo.png";
import instaColorIcon from "../../../src/assets/instaColorIcon.svg";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../constant/routeContant";
function ProfileCard({
  alt,
  label1 = "NA",
  label2 = "NA",
  label3 = "NA",
  image = userDefaultProfilePic,
  socialAccounts,
  dialCode = "NA",
  corporate = false,
  name = "NA",
}) {
  const navigate = useNavigate();
  return (
    <div className="ProfileCardContainerr">
      <Card className="ProfileCard">
        <div className="ProfileCardImage" onClick={() => navigate(PROFILE)}>
          <CardMedia
            className="ProfileImage"
            component="img"
            image={
              image === null || undefined || "" ? userDefaultProfilePic : image
            }
            alt={alt}
          />
        </div>
        <CardContent className="columns">
          <div className="column1" onClick={() => navigate(PROFILE)}>
            <p className="ViewName">
              {corporate ? name : label1 === null ? "" : label1}
            </p>
          </div>
          <div className="column2">
            <p className="ViewRole">{label2 === null ? "" : label2}</p>
          </div>
          <div className="column3">
            <p className="ViewContact">
              {dialCode === null
                ? ""
                : dialCode + " " + label3 === null
                ? ""
                : label3}
            </p>
          </div>
          <div className="column4">
            {/* <p className="smtext">SM Links</p> */}

            {socialAccounts?.instagram?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://instagram.com/${socialAccounts?.instagram}`}
                target="_blank"
              >
                <img
                  src={instaColorIcon}
                  className="instaLogo socialIcons"
                  alt="Instagram Logo"
                />
              </a>
            )}
            {socialAccounts?.facebook?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://facebook.com/${socialAccounts?.facebook}`}
                target="_blank"
              >
                <img
                  src={facebookColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
              </a>
            )}
            {socialAccounts?.linkedin?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://linkedin.com/in/${socialAccounts?.linkedin}`}
                target="_blank"
              >
                <img
                  src={linkedInColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
              </a>
            )}

            {socialAccounts?.twitter?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://twitter.com/${socialAccounts?.twitter}`}
                target="_blank"
              >
                <img
                  src={twitterColorIcon}
                  className="twitterLogo socialIcons"
                  alt="Facebook Logo"
                />
                {/* <TwitterIcon
                        style={{
                          fontSize: "29px",
                          margin: "-6px",
                          color: "#666666",
                        }}
                      /> */}
              </a>
            )}

            {socialAccounts?.pinterest?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://pinterest.com/${socialAccounts?.pinterest}`}
                target="_blank"
              >
                <img
                  src={pintrestColorIcon}
                  className="pintrestLogo socialIcons"
                  alt="Facebook Logo"
                />
                {/* <PinterestIcon
                        style={{
                          fontSize: "29px",
                          margin: "-6px",
                          color: "#666666",
                        }}
                      /> */}
              </a>
            )}

            {socialAccounts?.github?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://github.com/${socialAccounts?.github}`}
                target="_blank"
              >
                <img
                  src={githubColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
                {/* <GitHubIcon
                        style={{
                          fontSize: "27px",
                          margin: "-6px",
                          color: "#666666",
                        }}
                      /> */}
              </a>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default ProfileCard;
