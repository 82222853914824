import { getApiUrl } from "../config";
import { get, post } from "./axiosRequest";

// get conversation
export const getAllConversion = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/conversation/get-conversations`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

// get message
export const getAllMessage = async (data) => {
  const { conversationId, page, limit } = data?.data;
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/conversation/get-messages?conversationId=${conversationId}&page=${page}&limit=${limit}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

// get unread mesages
export const getUnreadMeaasge = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/conversation/get-unreadMessages`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createConversation = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/conversation/create-conversation`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const addMessage = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/conversation/add-message`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

//get user search

export const getUserSearch = async ({ data }) => {
  const { searchText, accounType } = data;

  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/get-users?searchText=${searchText}&accounType=${accounType}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
