import { loginType } from "../constant/loginType";

const initialState = {
  flow: "Login",
  isError: false,
  isLoading: false,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginType.POST_LOGIN_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case loginType.POST_LOGIN_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginType.POST_LOGIN_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case loginType.POST_LOGIN_CLEAR:
      return {
        ...state,
        status: "SUCCESS",
        data: "",
        isError: false,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const loginAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginType.POST_ADMIN_LOGIN_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case loginType.POST_ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginType.POST_ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case loginType.POST_LOGIN_CLEAR:
      return {
        ...state,
        status: "SUCCESS",
        data: "",
        isError: false,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const userDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginType.GET_USER_PROFILE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case loginType.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginType.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getRecentSearchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginType.GET_RECENT_SEARCHES_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case loginType.GET_RECENT_SEARCHES_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginType.GET_RECENT_SEARCHES_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getForgotPasswordOTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginType.GET_FORGOT_PASSWORD_OTP_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case loginType.GET_FORGOT_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginType.GET_FORGOT_PASSWORD_OTP_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case loginType.CLEAR_DATA:
        return {
          ...state,
          isError: true,
          isLoading: false,
          data: "",
        };
    default:
      return state;
  }
};

export const putResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginType.PUT_RESET_PASSWORD_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case loginType.PUT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginType.PUT_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case loginType.CLEAR_DATA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
