import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../library/custom/textBox/InputField";
import {
  E_YEAR,
  MONTHS,
  S_YEAR,
  EMPLOYMENT_TYPE,
} from "../../../constant/constant";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import ClickableChips from "../../../library/custom/chip/ClickableChip";
import RichTextEditor from "../../../library/custom/richTextEditor/RichTextEditor";

const WorkExperianceForm = ({
  allIndustries,
  singleWork,
  handleWorkExperianceChange,
  setUnsavedChanges,
  priSkill,
  oSkill,
}) => {
  function getRoles(value) {
    let valueArray = [];
    allIndustries?.functions?.forEach((data) => {
      if (data.name === singleWork.jobTitles) {
        valueArray = data.roles;
      }
    });
    return valueArray;
  }
  const roleArray = getRoles();
  let years = [];
  for (let i = E_YEAR; i >= S_YEAR; i--) {
    years.push(i);
  }

  const [tool, setTool] = useState("");
  const [toolsList, setToolsList] = useState(oSkill);

  const [pSkill, setPSLill] = useState("");
  const [pSkillsList, setPSkillsList] = useState(priSkill);

  const autoCompTool = useRef(null);
  const autoCompPSKill = useRef(null);

  const addToolData = () => {
    if (!tool) return;
    const index = toolsList.findIndex((object) => object === tool);
    if (index === -1) {
      // setsupervisorId([...supervisorId, supervisor.key]);
      setToolsList([...toolsList, tool]);
    }
    const ele = autoCompTool.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const removeToolData = (data) => {
    const updatePrSkillList = toolsList.filter((list) => list !== data);
    setToolsList(updatePrSkillList);
  };
  const addSkillsPData = () => {
    if (!pSkill) return;
    const index = pSkillsList.findIndex((object) => object === pSkill);
    if (index === -1) {
      // setsupervisorId([...supervisorId, supervisor.key]);
      setPSkillsList([...pSkillsList, pSkill]);
    }

    const ele = autoCompPSKill.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const removeSkillsPData = (data) => {
    const updatePrSkillList = pSkillsList.filter((list) => list !== data);
    setPSkillsList(updatePrSkillList);
  };

  useEffect(() => {
    handleWorkExperianceChange(toolsList, singleWork, "autoCompOSkills");
  }, [toolsList]);

  useEffect(() => {
    handleWorkExperianceChange(pSkillsList, singleWork, "autoCompPSkills");
  }, [pSkillsList]);

  function getPSkills(value) {
    let valueArray = [];
    allIndustries?.functions?.forEach((data) => {
      if (data.name === singleWork.jobTitles) {
        valueArray = data.primarySkills;
      }
    });
    return valueArray;
  }
  const pSkillsArray = getPSkills();

  let endYears = [];

  for (let i = E_YEAR + 20; i >= singleWork.startY; i--) {
    endYears.push(i);
  }

  return (
    <>
      <Grid className="workExpirience">
        <Grid textAlign={"left"} item lg={11} gap={"10px"} xs={12}>
          <Grid container item lg={6} mt={1.5}>
            <FormLabels label={"Currently working here?"} isRequired={true} />
          </Grid>

          <Grid container>
            <Grid item lg={2}>
              <RadioButton
                value={"No"}
                id={"tillDate"}
                onChange={(e) => {
                  handleWorkExperianceChange(e, singleWork, "tillDate");
                  setUnsavedChanges(false);
                }}
                label="No"
                checked={singleWork.tillDate === "No"}
              />
            </Grid>
            <Grid item lg={2}>
              <RadioButton
                value={"Yes"}
                onChange={(e) => {
                  handleWorkExperianceChange(e, singleWork, "tillDate");
                  setUnsavedChanges(false);
                }}
                label="Yes"
                id={"tillDate"}
                checked={singleWork?.tillDate == "Yes"}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid mt={2} item container lg={11} xs={12} sm={12} md={12}>
          <InputField
            isRequired={true}
            fullWidth={true}
            type="text"
            label="Company Name"
            placeholder="Enter Name"
            value={singleWork?.orgNames}
            id={"orgNames"}
            onChange={(e) => {
              handleWorkExperianceChange(e, singleWork, "degree");

              setUnsavedChanges(false);
            }}
            isError={singleWork.orgNames}
            errorMsg={
              singleWork.isOrgNames ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid item container lg={11} xs={12} sm={12} md={12} mt={2}>
          <InputField
            fullWidth={true}
            isRequired={true}
            type="text"
            label="Title"
            placeholder="Enter here"
            id={"designations"}
            value={singleWork?.designations}
            onChange={(e) => {
              handleWorkExperianceChange(e, singleWork, "degree");

              setUnsavedChanges(false);
            }}
            isError={singleWork.designations}
            errorMsg={
              singleWork.isDesignations
                ? "Please enter this required field"
                : ""
            }
          />
        </Grid>

        <Grid item lg={11} xs={12} sm={12} md={12} mt={2}>
          <FormControl fullWidth className="selectLabel">
            <FormLabels label="Employment Type" />
            <SelectMenu
              disabledDefault={false}
              listData={EMPLOYMENT_TYPE}
              isKeyValue={true}
              value={singleWork?.empType}
              name={"empType"}
              onchangehandler={(e) => {
                handleWorkExperianceChange(e, singleWork, "select");
                setUnsavedChanges(false);
              }}
            />
          </FormControl>
        </Grid>

        <Grid textAlign={"left"} lg={11} mt={2}>
          <FormLabels isRequired={true} label="Experience" />
          <Grid
            className="fName"
            item
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item lg={5.4} xs={12}>
              <SelectMenu
                disabledDefault={true}
                label="Start Month"
                id={"startM"}
                value={singleWork.startM}
                name={"startM"}
                onchangehandler={(e) => {
                  handleWorkExperianceChange(e, singleWork, "select");
                  setUnsavedChanges(false);
                }}
                listData={MONTHS}
                countries={true}
                isError={singleWork.isStartM}
                errorMsg={
                  singleWork.isStartM ? "Please enter this required field" : ""
                }
              />
            </Grid>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="Start Year"
                id={"startY"}
                value={singleWork.startY}
                name={"startY"}
                onchangehandler={(e) => {
                  handleWorkExperianceChange(e, singleWork, "select");
                  setUnsavedChanges(false);
                }}
                listData={years}
                countries={true}
                isError={singleWork.isStartY}
                errorMsg={
                  singleWork.isStartY ? "Please enter this required field" : ""
                }
              />
            </Grid>
          </Grid>
          {singleWork.tillDate === "Yes" ? (
            <h3>Currently Working here</h3>
          ) : (
            <Grid
              className="fName"
              mt={2}
              item
              container
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <Grid item lg={5.8} xs={12}>
                <SelectMenu
                  disabledDefault={false}
                  label="End Month"
                  id={"endM"}
                  name={"endM"}
                  value={singleWork.endM}
                  onchangehandler={(e) => {
                    handleWorkExperianceChange(e, singleWork, "select");
                    setUnsavedChanges(false);
                  }}
                  listData={MONTHS}
                  countries={true}
                  isError={singleWork.isEndM}
                  errorMsg={
                    singleWork.isEndM ? "Please enter this required field" : ""
                  }
                />
              </Grid>
              <Grid item lg={5.8} xs={12}>
                <SelectMenu
                  disabledDefault={false}
                  label="End Year"
                  id={"endY"}
                  name={"endY"}
                  value={singleWork.endY}
                  onchangehandler={(e) => {
                    handleWorkExperianceChange(e, singleWork, "select");
                    setUnsavedChanges(false);
                  }}
                  listData={endYears}
                  countries={true}
                  isError={singleWork.isEndY}
                  errorMsg={
                    singleWork.isEndY ? "Please enter this required field" : ""
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid mt={4} lg={11}>
          <FormLabels label="Job Description" />
          <RichTextEditor
            value={singleWork?.rnrs}
            onChange={(event, value) => {
              handleWorkExperianceChange(event, singleWork, "rnrs");

              setUnsavedChanges(false);
            }}
            // editorHtml={editorHtml}
            // setEditorHtml={setEditorHtml}
          />
        </Grid>

        <Grid item lg={11} xs={12} sm={12} md={12} mt={2}>
          <FormControl fullWidth className="selectLabel">
            <FormLabels label="Industry" isRequired={true} />
            <SelectMenu
              disabledDefault={false}
              listData={allIndustries?.industries}
              countries={true}
              value={singleWork?.industry}
              name={"industry"}
              // value={"Consulting"}
              onchangehandler={(e) => {
                handleWorkExperianceChange(e, singleWork, "select");
                setUnsavedChanges(false);
              }}
              isError={singleWork.isIndustry}
              errorMsg={
                singleWork.isIndustry ? "Please enter this required field" : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item lg={11} xs={12} sm={12} md={12} mt={2}>
          <FormControl fullWidth className="selectLabel">
            <FormLabels label="Function" isRequired={true} />
            <SelectMenu
              disabledDefault={true}
              listData={allIndustries?.functions}
              functions={true}
              onchangehandler={(e) => {
                handleWorkExperianceChange(e, singleWork, "select");
                setUnsavedChanges(false);
              }}
              label={"Please Select"}
              name={"jobTitles"}
              value={singleWork?.jobTitles}
              isError={singleWork.isJobTitles}
              errorMsg={
                singleWork.isJobTitles ? "Please enter this required field" : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item lg={11} xs={12} sm={12} md={12} mt={2}>
          <FormControl fullWidth className="selectLabel">
            <FormLabels label="Role" isRequired={true} />
            <SelectMenu
              disabledDefault={false}
              listData={roleArray}
              functions={true}
              value={singleWork?.role}
              name={"role"}
              onchangehandler={(e) => {
                handleWorkExperianceChange(e, singleWork, "select");
                setUnsavedChanges(false);
              }}
              isError={singleWork.isRole}
              errorMsg={
                singleWork.isRole ? "Please enter this required field" : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item container lg={11} xs={12} sm={12} md={12} mt={2}>
          <Grid item lg={10} sm={10.5} md={9.8} xs={12} textAlign={"left"}>
            <FormLabels label="Primary Area of Work" isRequired={true} />
            <SearchAutoComplete
              optionsData={pSkillsArray ?? []}
              isKeyValuePair={true}
              value={pSkill}
              onChange={(e, value) => setPSLill(value)}
              typeOnchange={() => {}}
              // isLoading={isLoading}
              autoCompClear={autoCompPSKill}
            />
            {singleWork.isskillsP ? (
              <p
                style={{
                  color: "#f21d1d",
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                }}
                className="errMsg"
              >
                Please enter this required field
              </p>
            ) : (
              ""
            )}
          </Grid>

          <Grid
            item
            className="saveBtn"
            lg={1.5}
            md={1}
            sm={1}
            xs={12}
            mt={3.5}
            ml={2}
          >
            <Buttons
              varientContained={true}
              label={"Add"}
              onClick={addSkillsPData}
            ></Buttons>
          </Grid>
          <Grid mt={4} container gap={1}>
            {priSkill?.map((i) => (
              <ClickableChips
                key={i}
                label={i}
                isdelete={true}
                deleteIcon={removeSkillsPData}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item container lg={11} xs={12} sm={12} md={12} mt={2}>
          <Grid item lg={10} sm={10.5} md={9.8}>
            <FormLabels label="Tools, Technology & Framework" />
            <SearchAutoComplete
              optionsData={allIndustries?.skills ?? []}
              isKeyValuePair={true}
              value={tool}
              onChange={(e, value) => setTool(value)}
              // isLoading={isLoading}
              autoCompClear={autoCompTool}
              typeOnchange={() => {}}
            />
          </Grid>

          <Grid
            item
            // className="smallAddBtn"
            lg={1.5}
            md={1}
            sm={1}
            xs={12}
            mt={3}
            ml={2}
            className="saveBtn"
          >
            <Buttons
              varientContained={true}
              label={"Add"}
              onClick={addToolData}
            ></Buttons>
          </Grid>
          <Grid mt={4} container gap={1}>
            {oSkill?.map((i) => (
              <ClickableChips
                key={i}
                label={i}
                isdelete={true}
                deleteIcon={removeToolData}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkExperianceForm;
