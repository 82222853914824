import { jobPostType } from "../constant/jobPostType";

export const putJobPost = (data) => ({
  type: jobPostType.PUT_JOB_POST_PENDING,
  data,
});

export const putJobPostSuccess = (data) => ({
  type: jobPostType.PUT_JOB_POST_SUCCESS,
  data,
});

export const putJobPostFailure = (error) => ({
  type: jobPostType.PUT_JOB_POST_FAILURE,
  error,
});

export const updateJobPost = (data) => ({
  type: jobPostType.UPDATE_JOB_ID_PENDING,
  data,
});

export const updateJobPostSuccess = (data) => ({
  type: jobPostType.UPDATE_JOB_ID_SUCCESS,
  data,
});

export const updateJobPostFailure = (error) => ({
  type: jobPostType.UPDATE_JOB_ID_FAILURE,
  error,
});

export const postedJobs = (data) => ({
  type: jobPostType.GET_POSTED_JOB_PENDING,
  data,
});

export const postedJobsSuccess = (data) => ({
  type: jobPostType.GET_POSTED_JOB_SUCCESS,
  data,
});

export const postedJobsFailure = (data) => ({
  type: jobPostType.GET_POSTED_JOB_FAILURE,
  data,
});

export const getPostedJobsDeatils = (data) => ({
  type: jobPostType.GET_POSTED_JOB_DETAILS_PENDING,
  data,
});

export const getPostedJobsDeatilsSuccess = (data) => ({
  type: jobPostType.GET_POSTED_JOB_DETAILS_SUCCESS,
  data,
});

export const getPostedJobsDeatilsFailure = (data) => ({
  type: jobPostType.GET_POSTED_JOB_DETAILS_FAILURE,
  data,
});

export const clearJobs = () => ({
  type: jobPostType.CLEAR_JOBS,
  data: {},
});
export const getJobFind = (data) => ({
  type: jobPostType.GET_JOB_FIND_PENDING,
  data,
});

export const getJobFindSuccess = (data) => ({
  type: jobPostType.GET_JOB_FIND_SUCCESS,
  data,
});

export const getJobFindFailure = (error) => ({
  type: jobPostType.GET_JOB_FIND_FAILURE,
  error,
});

export const getJobInfo = (data) => ({
  type: jobPostType.GET_JOB_INFO_PENDING,
  data,
});

export const getJobInfoSuccess = (data) => ({
  type: jobPostType.GET_JOB_INFO_SUCCESS,
  data,
});

export const getJobInfoFailure = (error) => ({
  type: jobPostType.GET_JOB_INFO_FAILURE,
  error,
});

export const postApplyJob = (data) => ({
  type: jobPostType.POST_JOB_APPLY_PENDING,
  data,
});

export const postApplyJobSuccess = (data) => ({
  type: jobPostType.POST_JOB_APPLY_SUCCESS,
  data,
});
export const postApplyJobClear = (data) => ({
  type: jobPostType.POST_CLEAR_JOB_APPLY,
  data,
});

export const postApplyJobFailure = (error) => ({
  type: jobPostType.POST_JOB_APPLY_FAILURE,
  error,
});


export const getAppliedJobs = (data) => ({
  type: jobPostType.GET_APPLIED_JOB_PENDING,
  data,
});

export const getAppliedJobsSuccess = (data) => ({
  type: jobPostType.GET_APPLIED_JOB_SUCCESS,
  data,
});

export const getAppliedJobsFailure = (error) => ({
  type: jobPostType.GET_APPLIED_JOB_FAILURE,
  error,
});

export const getApplicantDetailsJobs = (data) => ({
  type: jobPostType.GET_APPLICANT_JOB_DETAILS_PENDING,
  data,
});

export const getApplicantDetailsSuccess = (data) => ({
  type: jobPostType.GET_APPLICANT_JOB_DETAILS_SUCCESS,
  data,
});

export const getApplicantDetailsFailure = (error) => ({
  type: jobPostType.GET_APPLICANT_JOB_DETAILS_FAILURE,
  error,
});