import { Grid } from "@mui/material";
import Camera from "../../../assets/Camera.svg";
import "./ProfileForm.scss";
import React, { useState, useRef, useEffect } from "react";
import Buttons from "../../../library/custom/button/Button";
import { useSelector } from "react-redux";
import BasicDetailsForm from "./BasicDetailsForm";
import TextButton from "../../../library/custom/button/Buttons";
import EducationAccordian from "./EducationAccordian";
import WorkExpAccordian from "./WorkExpAccordian";
import SocialHandleForm from "./SocialHandleForm";
import CertificateAccordian from "./CertificateAccordian";
import BoardExperianceAccordian from "./BoardExperianceAccordian";
import ProfileVideoForm from "./ProfileVideoForm";
import CustomizedDialogs from "../../../library/common/DialogBox";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../../constant/routeContant";
import { getFormatedDate } from "../../../utils/helper";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import { InputField } from "../../../library/custom/textBox/InputField";
import { API_STATUS, E_YEAR, MONTHS, S_YEAR } from "../../../constant/constant";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import ClickableChips from "../../../library/custom/chip/ClickableChip";

function ProfileForm({
  uploadProfileFile,
  handleTabClick,
  activeTab,
  allCountries,
  allIndustries,
  editProfile,
  headShot,
  mentorshipSubjects,
  settotal_experience,
  total_experience,
  setfromFormSub,
}) {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const userDetails = useSelector(
    (state) => state?.userDetails?.data?.response
  );
  useEffect(() => {
    setBasicDetails({
      ...basicDetails,
      profile_pic: headShot,
    });
  }, [headShot]);

  useEffect(() => {
    setBasicDetails({
      ...basicDetails,
      title: userDetails?.basicDetails?.title,
      first_name: userDetails?.basicDetails?.first_name,
      last_name: userDetails?.basicDetails?.last_name,
      recovery_email: userDetails?.basicDetails?.recovery_email,
      phone: userDetails?.basicDetails?.phone,
      dial_code: userDetails?.basicDetails?.dial_code,
      country_name: userDetails?.basicDetails?.country_name,
      zipcode: userDetails?.basicDetails?.zipcode,
      address_line: userDetails?.basicDetails?.address_line,
      state: userDetails?.basicDetails?.state,
      city: userDetails?.basicDetails?.city,
      citizenship: userDetails?.basicDetails?.citizenship,
      timezone: userDetails?.profile?.personal_details?.timezone ?? "",
      dob: userDetails?.basicDetails?.dob,
      gender: userDetails?.basicDetails?.gender,
      profile_pic: userDetails?.basicDetails?.profile_pic,
      other_country_authorization:
        userDetails?.basicDetails?.other_country_authorization,
      is_student: userDetails?.basicDetails?.is_student,
      aboutMe: userDetails?.profile?.personal_details?.aboutMe,
    });
    let personalInfo = mapPersonalDetails(
      userDetails?.profile?.personal_details
    );
    setPersonalDetails(personalInfo);
    setMentorship({
      ...mentorship,
      isMentor: userDetails?.profile?.mentorship?.optIn ?? 0,
      subjects: userDetails?.profile?.mentorship?.subjects ?? [],
    });
    setsubList(userDetails?.profile?.mentorship?.subjects ?? []);
    setSocialLinks({
      facebook: userDetails?.profile?.social_links?.facebook,
      twitter: userDetails?.profile?.social_links?.twitter,
      pinterest: userDetails?.profile?.social_links?.pinterest,
      instagram: userDetails?.profile?.social_links?.instagram,
      github: userDetails?.profile?.social_links?.github,
      linkedin: userDetails?.profile?.social_links?.linkedin,
    });
    let educations = mapEducations(userDetails?.profile?.education);
    seteduArr(educations);
    let workExp = mapWorkExperiance(userDetails?.profile?.work_experience);
    setWorkExpArr(workExp);

    let certificates = mapCertificates(userDetails?.profile?.certifications);
    setCertificateArr(certificates);

    let boards = mapBoardExperiance(userDetails?.profile?.board_experience);
    setBoardExapArr(boards);

    setVideo(userDetails?.profile?.media?.videoshot);
  }, [userDetails]);

  function mapPersonalDetails(data) {
    let arr = [];
    if (data?.countryList?.length > 0)
      data?.countryList.map((i, j) =>
        arr.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          countryList: userDetails?.profile?.personal_details?.countryList[j],
          authType: userDetails?.profile?.personal_details?.authType[j],
          sponsor: userDetails?.profile?.personal_details?.sponsor[j],
          authExp: userDetails?.profile?.personal_details?.authExp[j],
        })
      );
    return arr;
  }
  function mapEducations(data) {
    let arr = [];

    if (data?.degree?.length > 0)
      data?.degree.map((i, j) =>
        arr.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          institute: userDetails?.profile?.education?.institute[j],
          degree: userDetails?.profile?.education?.degree[j],
          special: userDetails?.profile?.education?.special[j],
          startM: userDetails?.profile?.education?.startM[j],
          endM: userDetails?.profile?.education?.endM[j],
          startY: userDetails?.profile?.education?.startY[j],
          endY: userDetails?.profile?.education?.endY[j],
          activities: userDetails?.profile?.education?.activities[j],
          societies: userDetails?.profile?.education?.societies[j],
          accomplishments: userDetails?.profile?.education?.accomplishments,
        })
      );
    return arr;
  }

  function mapWorkExperiance(data) {
    let arr = [];
    if (data?.orgNames?.length > 0) {
      data?.orgNames.map((i, j) =>
        arr.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          orgNames: userDetails?.profile?.work_experience?.orgNames[j],
          designations: userDetails?.profile?.work_experience?.designations[j],
          empType: userDetails?.profile?.work_experience?.empType[j],
          endM: userDetails?.profile?.work_experience?.endM[j],
          endY: userDetails?.profile?.work_experience?.endY[j],
          startY: userDetails?.profile?.work_experience?.startY[j],
          startM: userDetails?.profile?.work_experience?.startM[j],
          industry: userDetails?.profile?.work_experience?.industry[j],
          jobTitles: userDetails?.profile?.work_experience?.jobTitles[j],
          rnrs: userDetails?.profile?.work_experience?.rnrs[j],
          role: userDetails?.profile?.work_experience?.role[j],
          skillsO: userDetails?.profile?.work_experience?.skillsO[j],
          skillsP: userDetails?.profile?.work_experience?.skillsP
            ? userDetails?.profile?.work_experience?.skillsP[j]
            : [],
          tillDate:
            userDetails?.profile?.work_experience?.tillDate[j] === true
              ? "Yes"
              : "No",
        })
      );
      setcurrentlyWorking(
        userDetails?.profile?.work_experience?.currentlyWorking
      );
      settotal_experience(
        userDetails?.profile?.work_experience?.total_experience
      );
    }
    return arr;
  }

  function mapBoardExperiance(data) {
    let arr = [];

    if (data?.boardName?.length > 0)
      data?.boardName.map((i, j) =>
        arr.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          boardName: userDetails?.profile?.board_experience?.boardName[j],
          boardType: userDetails?.profile?.board_experience?.boardType[j],
          boardStartM: userDetails?.profile?.board_experience?.boardStartM[j],
          boardEndM: userDetails?.profile?.board_experience?.boardEndM[j],
          boardStartY: userDetails?.profile?.board_experience?.boardStartY[j],
          boardEndY: userDetails?.profile?.board_experience?.boardEndY[j],
          stillMember:
            userDetails?.profile?.board_experience?.stillMember[j] === true
              ? "Yes"
              : "No",
        })
      );
    return arr;
  }

  function mapCertificates(data) {
    let arr = [];

    if (data?.name?.length > 0)
      data?.name.map((i, j) =>
        arr.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          name: userDetails?.profile?.certifications?.name[j],
          endM: userDetails?.profile?.certifications?.endM[j],
          endY: userDetails?.profile?.certifications?.endY[j],
          copy: userDetails?.profile?.certifications?.copy[j],
          imageurl: userDetails?.profile?.certifications?.copy[j]
            ? userDetails?.profile?.certifications?.copy[j]
            : "",
        })
      );
    return arr;
  }

  function mappedData() {
    let countryListArr = [];
    let authTypeArr = [];
    let sponsorArr = [];
    let authExpArr = [];

    personalDetails.map((i) => {
      countryListArr.push(i.countryList);
      authTypeArr.push(i.authType);
      sponsorArr.push(i.sponsor);
      authExpArr.push(getFormatedDate(i.authExp));
    });

    const submissonPersonaleData = {
      countryList: countryListArr,
      authType: authTypeArr,
      sponsor: sponsorArr,
      authExp: authExpArr,
    };
    const data = {
      basic_details: {
        title: basicDetails.title,
        first_name: basicDetails.first_name,
        last_name: basicDetails.last_name,
        recovery_email: basicDetails.recovery_email,
        phone: basicDetails.phone,
        dial_code: basicDetails.dial_code,
        country_name: basicDetails.country_name,
        zipcode: basicDetails.zipcode,
        address_line: basicDetails.address_line,
        state: basicDetails.state,
        city: basicDetails.city,
        citizenship: basicDetails.citizenship,
        dob: getFormatedDate(basicDetails.dob),
        gender: basicDetails.gender,
        profile_pic: basicDetails.profile_pic,
        other_country_authorization: basicDetails.other_country_authorization,
        is_student: basicDetails.is_student,
      },

      personal_details: {
        aboutMe: basicDetails.aboutMe,
        countryList: countryListArr,
        authType: authTypeArr,
        sponsor: sponsorArr,
        authExp: authExpArr,
        timezone: basicDetails.timezone,
        language_proficiency: [],
      },
    };
    return data;
  }

  function mappedSocialData() {
    const data = {
      social_links: {
        facebook:
          socialLinks.facebook?.trim() !== "" &&
          socialLinks.facebook?.trim() !== undefined &&
          socialLinks.facebook?.trim() !== null
            ? socialLinks.facebook?.trim()?.search(".com/") >= 0
              ? socialLinks.facebook?.trim()?.split(".com/")[1]
              : socialLinks.facebook?.trim()
            : "",
        github:
          socialLinks.github?.trim() !== "" &&
          socialLinks.github?.trim() !== undefined &&
          socialLinks.github?.trim() !== null
            ? socialLinks.github?.trim()?.search(".com/") >= 0
              ? socialLinks.github?.trim()?.split(".com/")[1]
              : socialLinks.github?.trim()
            : "",

        instagram:
          socialLinks.instagram?.trim() !== "" &&
          socialLinks.instagram?.trim() !== undefined &&
          socialLinks.instagram?.trim() !== null
            ? socialLinks.instagram?.trim()?.search(".com/") >= 0
              ? socialLinks.instagram?.trim()?.split(".com/")[1]
              : socialLinks.instagram?.trim()
            : "",
        linkedin:
          socialLinks.linkedin?.trim() !== "" &&
          socialLinks.linkedin?.trim() !== undefined &&
          socialLinks.linkedin?.trim() !== null
            ? socialLinks.linkedin?.trim()?.search(".com/in/") >= 0
              ? socialLinks.linkedin?.trim()?.split(".com/in/")[1]
              : socialLinks.linkedin?.trim()
            : "",

        pinterest:
          socialLinks.pinterest?.trim() !== "" &&
          socialLinks.pinterest?.trim() !== undefined &&
          socialLinks.pinterest?.trim() !== null
            ? socialLinks.pinterest?.trim()?.search(".com/") >= 0
              ? socialLinks.pinterest?.trim()?.split(".com/")[1]
              : socialLinks.pinterest?.trim()
            : "",

        twitter:
          socialLinks.twitter?.trim() !== "" &&
          socialLinks.twitter?.trim() !== undefined &&
          socialLinks.twitter?.trim() !== null
            ? socialLinks.twitter?.trim()?.search(".com/") >= 0
              ? socialLinks.twitter?.trim()?.split(".com/")[1]
              : socialLinks.twitter?.trim()
            : "",
      },
    };
    return data;
  }

  const profileUpload = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {};
    handleProfileUploadMethod(file);
  };

  const handleProfileUploadMethod = (data) => {
    uploadProfileFile(data);
  };

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [basicDetails, setBasicDetails] = useState({
    title: "",
    first_name: "",
    last_name: "",
    recovery_email: "",
    phone: "",
    dial_code: "",
    country_name: "",
    zipcode: "",
    address_line: "",
    state: "",
    city: "",
    citizenship: "",
    timezone: "",
    dob: "",
    gender: "",
    profile_pic: "",
    other_country_authorization: "NO",
    is_student: "NO",
    aboutMe: "",
  });
  const [personalDetails, setPersonalDetails] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      countryList: "",
      authType: "",
      sponsor: "",
      authExp: "",
      isCountryList: false,
      isAuthType: false,
      isAuthExp: false,
    },
  ]);
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    pinterest: "",
    github: "",
  });
  const [mentorship, setMentorship] = useState({
    isMentor: 0,
    subjects: [],
    selectedSub: "",
  });
  const autoCompSub = useRef(null);

  const [media, setmedia] = useState({
    // headshot: "string",
    videoshot: "",
  });
  const [workExpArr, setWorkExpArr] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      orgNames: "",
      isOrgNames: false,
      designations: "",
      isDesignations: false,
      industry: "",
      isIndustry: false,
      empType: "",
      jobTitles: "",
      isJobTitles: false,
      role: "",
      isRole: false,
      skillsP: [],
      isskillsP: false,
      skillsO: [],
      rnrs: "",
      startM: undefined,
      isStartM: false,
      authType: undefined,
      endM: undefined,
      isEndM: false,
      startY: undefined,
      isStartY: false,
      endY: undefined,
      isEndY: false,
      currentlyWorking: true,
      total_experience: "",
      tillDate: false,
      // current_salary: {
      //   showCompensation: true,
      //   workCountry: "string",
      //   amount: 0,
      //   currency: "string",
      //   salary_range: "string",
      // },
    },
  ]);

  const [eduArr, seteduArr] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      institute: "",
      isInstitute: false,
      degree: "",
      isDegree: false,
      special: "",
      isSpecial: false,
      startM: undefined,
      isStartM: false,
      endM: undefined,
      isEndM: false,
      startY: undefined,
      isStartY: false,
      endY: undefined,
      isEndY: false,
      activities: "",
      societies: "",
      accomplishments: "",
    },
  ]);

  const [certificateArr, setCertificateArr] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      name: "",
      endM: "",
      endY: "",
      copy: "",
      isName: false,
      isEndM: false,
      isEndY: false,
    },
  ]);

  const [boardExpArr, setBoardExapArr] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      boardName: "",
      isboardName: false,
      boardType: "",
      isboardType: false,
      boardStartM: "",
      isboardStartM: false,
      boardEndM: "",
      isboardEndM: false,
      boardStartY: "",
      isboardStartY: false,
      boardEndY: "",
      isboardEndY: false,
      stillMember: "Yes",
    },
  ]);

  const [video, setVideo] = useState("");
  const hiddenProfileInput = useRef(null);
  const Countries = allCountries?.data?.response?.countryResult;
  const submitBasicDetails = (exit) => {
    const data = mappedData(false);
    if (!data) {
    } else {
      editProfile(data, exit);
      setfromFormSub(true);
    }
  };

  const submitSocialDetails = (exit = false) => {
    const data = mappedSocialData(false);
    if (!data) {
    } else {
      editProfile(data, exit);
      setfromFormSub(true);
    }
  };

  function mapEducationErrors() {
    let updatedCertificateArr = [...certificateArr];
    let updatedEduArr = [...eduArr];
    let error = false;
    updatedCertificateArr.forEach((data) => {
      if (data.name === "" || data.name === undefined || data.name === null) {
        data.isName = true;
        error = true;
      }
      if (data.endM === "" || data.endM === undefined || data.endM === null) {
        data.isEndM = true;
        error = true;
      }
      if (data.endY === "" || data.endY === undefined || data.endY === null) {
        data.isEndY = true;
        error = true;
      }
    });
    updatedEduArr.forEach((data) => {
      if (
        data.institute === "" ||
        data.institute === undefined ||
        data.institute === null
      ) {
        data.isInstitute = true;
        error = true;
      }
      if (
        data.degree === "" ||
        data.degree === undefined ||
        data.degree === null
      ) {
        data.isDegree = true;
        error = true;
      }
      if (
        data.special === "" ||
        data.special === undefined ||
        data.special === null
      ) {
        data.isSpecial = true;
        error = true;
      }
      if (data.endM === "" || data.endM === undefined || data.endM === null) {
        data.isEndM = true;
        error = true;
      }
      if (data.endY === "" || data.endY === undefined || data.endY === null) {
        data.isEndY = true;
        error = true;
      }

      if (
        data.startM === "" ||
        data.startM === undefined ||
        data.startM === null
      ) {
        data.isStartM = true;
        error = true;
      }
      if (
        data.startY === "" ||
        data.startY === undefined ||
        data.startY === null
      ) {
        data.isStartY = true;
        error = true;
      }
    });

    setCertificateArr(updatedCertificateArr);
    seteduArr(updatedEduArr);

    return error;
  }
  // submit function
  const submitEducationDetails = (exit = false) => {
    let hasError = mapEducationErrors();
    if (hasError) return;
    let submissonData = {};
    let submissonCertificateData = {};
    let degreeArr = [];
    let specialArr = [];
    let instituteArr = [];
    let startMArr = [];
    let endMArr = [];
    let startYArr = [];
    let endYArr = [];
    let activitiesArr = [];
    let societiesArr = [];
    let accomplishmentsArr = "";
    eduArr.map((i) => {
      degreeArr.push(i.degree);
      specialArr.push(i.special);
      instituteArr.push(i.institute);
      startMArr.push(i.startM);
      endMArr.push(i.endM);
      startYArr.push(i.startY);
      endYArr.push(i.endY);
      activitiesArr.push(i.activities);
      societiesArr.push(i.societies);
      accomplishmentsArr = i.accomplishments;
    });

    submissonData = {
      degree: degreeArr,
      special: specialArr,
      institute: instituteArr,
      startM: startMArr,
      endM: endMArr,
      startY: startYArr,
      endY: endYArr,
      activities: activitiesArr,
      societies: societiesArr,
      accomplishments: accomplishmentsArr,
    };

    let nameArr = [];
    let endMCArr = [];
    let endYCArr = [];
    let copyArr = [];

    certificateArr.map((i) => {
      nameArr.push(i.name);
      endMCArr.push(i.endM);
      endYCArr.push(i.endY);
      copyArr.push(i.imageurl);
    });

    submissonCertificateData = {
      name: nameArr,
      endM: endMCArr,
      endY: endYCArr,
      copy: copyArr,
    };

    editProfile(
      {
        education: submissonData,
        certifications: submissonCertificateData,
      },
      exit
    );

    setfromFormSub(true);
  };
  const videoUrl = useSelector(
    (state) => state?.createUploadVideoData?.data?.fileUrl
  );

  const submitMediaDetails = () => {
    let submissonData = {
      videoshot: video,
    };
    editProfile({
      media: submissonData,
    });

    setfromFormSub(true);
    setVideo("");
  };

  const submitMentorShipDetails = (exit = false) => {
    let submissonData = {
      optIn: mentorship.isMentor,
      subjects: mentorship.subjects,
    };
    editProfile(
      {
        mentorship: submissonData,
      },
      exit
    );
    setfromFormSub(true);
  };

  function mapWorkErrors() {
    let updatedWorkArr = [...workExpArr];
    let error = false;
    updatedWorkArr.forEach((data) => {
      if (
        data.orgNames === "" ||
        data.orgNames === undefined ||
        data.orgNames === null
      ) {
        data.isOrgNames = true;
        error = true;
      }
      if (
        data.designations === "" ||
        data.designations === undefined ||
        data.designations === null
      ) {
        data.isDesignations = true;
        error = true;
      }
      if (
        data.industry === "" ||
        data.industry === undefined ||
        data.industry === null
      ) {
        data.isIndustry = true;
        error = true;
      }
      if (
        data.jobTitles === "" ||
        data.jobTitles === undefined ||
        data.jobTitles === null
      ) {
        data.isJobTitles = true;
        error = true;
      }
      if (data.role === "" || data.role === undefined || data.role === null) {
        data.isRole = true;
        error = true;
      }
      if (data.endM === "" || data.endM === undefined || data.endM === null) {
        data.isEndM = true;
        error = true;
      }
      if (data.endY === "" || data.endY === undefined || data.endY === null) {
        data.isEndY = true;
        error = true;
      }
      if (
        data.startM === "" ||
        data.startM === undefined ||
        data.startM === null
      ) {
        data.isStartM = true;
        error = true;
      }
      if (
        data.startY === "" ||
        data.startY === undefined ||
        data.startY === null
      ) {
        data.isStartY = true;
        error = true;
      }
      if (
        data.skillsP.length === 0 ||
        data.skillsP === undefined ||
        data.skillsP === null
      ) {
        data.isskillsP = true;
        error = true;
      }
    });

    let updatedBoardArr = [...boardExpArr];
    if (updatedBoardArr.length > 0) {
      updatedBoardArr.forEach((data) => {
        if (
          data.boardName === "" ||
          data.boardName === undefined ||
          data.boardName === null
        ) {
          data.isboardName = true;
          error = true;
        }
        if (
          data.boardType === "" ||
          data.boardType === undefined ||
          data.boardType === null
        ) {
          data.isboardType = true;
          error = true;
        }
        if (
          data.boardEndM === "" ||
          data.boardEndM === undefined ||
          data.boardEndM === null
        ) {
          data.isboardEndM = true;
          error = true;
        }
        if (
          data.boardEndY === "" ||
          data.boardEndY === undefined ||
          data.boardEndY === null
        ) {
          data.isboardEndY = true;
          error = true;
        }
        if (
          data.boardStartM === "" ||
          data.boardStartM === undefined ||
          data.boardStartM === null
        ) {
          data.isboardStartM = true;
          error = true;
        }
        if (
          data.boardStartY === "" ||
          data.boardStartY === undefined ||
          data.boardStartY === null
        ) {
          data.isboardStartY = true;
          error = true;
        }
      });
    }
    setWorkExpArr(updatedWorkArr);
    setBoardExapArr(updatedBoardArr);

    return error;
  }
  const submitWorkDetails = (exit = false) => {
    let hasError = mapWorkErrors();
    if (hasError) return;
    let submissonData = {};
    let boardSubmissonData = {};
    let designationsArr = [];
    let orgNamesArr = [];
    let startMArr = [];
    let endMArr = [];
    let startYArr = [];
    let endYArr = [];
    let rnrsArr = [];
    let empTypeArr = [];
    let industryArr = [];
    let jobTitlesArr = [];
    let roleArr = [];
    let tillDateArr = [];
    let skillsOArr = [];
    let skillsPArr = [];

    let boardNameArr = [];
    let boardTypeArr = [];
    let boardStartMArr = [];
    let boardEndMArr = [];
    let boardStartYArr = [];
    let boardEndYArr = [];
    let stillMemberArr = [];

    workExpArr.map((i) => {
      designationsArr.push(i.designations);
      orgNamesArr.push(i.orgNames);
      startMArr.push(i.startM);
      endMArr.push(i.endM);
      startYArr.push(i.startY);
      endYArr.push(i.endY);
      rnrsArr.push(i.rnrs);
      empTypeArr.push(i.empType);
      industryArr.push(i.industry);
      jobTitlesArr.push(i.jobTitles);
      roleArr.push(i.role);
      skillsOArr.push(i.skillsO);
      skillsPArr.push(i.skillsP);
      if (i.tillDate === "No") tillDateArr.push(false);
      else if (i.tillDate === "Yes") tillDateArr.push(true);
    });

    boardExpArr.map((i) => {
      boardNameArr.push(i.boardName);
      boardTypeArr.push(i.boardType);
      boardStartMArr.push(i.boardStartM);
      boardEndMArr.push(i.boardEndM);
      boardStartYArr.push(i.boardStartY);
      boardEndYArr.push(i.boardEndY);
      stillMemberArr.push(i.stillMember);
    });
    if (currentlyWorking === false)
      submissonData = {
        currentlyWorking: currentlyWorking,
        total_experience: "",
        designations: [],
        orgNames: [],
        startM: [],
        endM: [],
        startY: [],
        endY: [],
        rnrs: [],
        empType: [],
        industry: [],
        jobTitles: [],
        role: [],
        tillDate: [],
        skillsO: [],
        skillsP: [],
      };
    else
      submissonData = {
        currentlyWorking: currentlyWorking,
        total_experience: total_experience,
        designations: designationsArr,
        orgNames: orgNamesArr,
        startM: startMArr,
        endM: endMArr,
        startY: startYArr,
        endY: endYArr,
        rnrs: rnrsArr,
        empType: empTypeArr,
        industry: industryArr,
        jobTitles: jobTitlesArr,
        role: roleArr,
        tillDate: tillDateArr,
        skillsO: skillsOArr,
        skillsP: skillsPArr,
        current_salary: {
          showCompensations:
            userDetails?.profile?.work_experience?.current_salary
              ?.showCompensations,
          workCountry:
            userDetails?.profile?.work_experience?.current_salary?.workCountry,
          amount: userDetails?.profile?.work_experience?.current_salary?.amount,
          currency:
            userDetails?.profile?.work_experience?.current_salary?.currency,
          salary_range:
            userDetails?.profile?.work_experience?.current_salary?.salary_range,
        },
      };
    boardSubmissonData = {
      boardName: boardNameArr,
      boardType: boardTypeArr,
      boardStartM: boardStartMArr,
      boardEndM: boardEndMArr,
      boardStartY: boardStartYArr,
      boardEndY: boardEndYArr,
      stillMember: stillMemberArr,
    };
    editProfile(
      {
        work_experience: submissonData,
        board_experience: boardSubmissonData,
      },
      exit
    );
    setfromFormSub(true);
  };

  function addNewAuthorizationForm() {
    let newAuthArr = [...personalDetails];
    newAuthArr.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      countryList: "",
      authType: "",
      sponsor: "",
      authExp: "",
    });
    setPersonalDetails(newAuthArr);
  }
  function addNEwEducationForm() {
    let newEduArr = [...eduArr];
    newEduArr.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      institute: "",
      degree: "",
      special: "",
      startM: undefined,
      endM: undefined,
      startY: undefined,
      endY: undefined,
      activities: "",
      societies: "",
      accomplishments: "",
    });

    seteduArr(newEduArr);
  }

  function addNewWorkForm() {
    let newWorkArr = [...workExpArr];
    newWorkArr.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      orgNames: "",
      designations: "",
      industry: "",
      empType: "",
      jobTitles: undefined,
      role: undefined,
      skillsP: [],
      isskillsP: false,
      skillsO: [],
      rnrs: "",
      startM: undefined,
      endM: undefined,
      startY: undefined,
      endY: undefined,
      currentlyWorking: true,
      total_experience: "",
      tillDate: "No",
    });

    setWorkExpArr(newWorkArr);
  }

  function addNewCertificateForm() {
    let newCertificateArr = [...certificateArr];
    newCertificateArr.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      name: "",
      endM: "",
      endY: "",
      copy: "",
      isName: false,
    });

    setCertificateArr(newCertificateArr);
  }
  const [arrayId, setarrayId] = useState("");
  const createUpload = useSelector((state) => state.createUploadContentData);
  const onChnageFileupload = (id) => {
    setarrayId(id);
  };
  const onChangeDelete = (url) => {
    const copycerarray = [...certificateArr];
    copycerarray.forEach((val) => {
      if (val?.imageurl == url) {
        val.imageurl = "";
      }
    });
    setCertificateArr(copycerarray);
  };
  useEffect(() => {
    if (createUpload?.status === API_STATUS.SUCCESS) {
      const copycerarray = [...certificateArr];
      copycerarray.forEach((val) => {
        if (val?.id == arrayId) {
          val.imageurl = createUpload.data?.fileUrl;
        }
      });
      setCertificateArr(copycerarray);
    }
  }, [createUpload]);
  function addNewBoardForm() {
    let newBoardArr = [...boardExpArr];
    newBoardArr.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      boardName: "",
      isboardName: false,
      boardType: "",
      isboardType: false,
      boardStartM: undefined,
      isboardStartM: false,
      boardEndM: undefined,
      isboardEndM: false,
      boardStartY: undefined,
      isboardStartY: false,
      boardEndY: undefined,
      isboardEndY: false,
      stillMember: "Yes",
    });

    setBoardExapArr(newBoardArr);
  }

  const handleEduFormChange = (e, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "select") {
      value = e.target.value;
      elementId = e.target.name;
    } else {
      value = e.target.value;
      elementId = e.target.id;
    }
    let updatedEduArr = [...eduArr];
    updatedEduArr.forEach((eduList) => {
      if (eduList.id === data.id) {
        if (elementId === "degree") {
          eduList.degree = value;
        } else if (elementId === "institute") {
          eduList.institute = value;
        } else if (elementId === "special") {
          eduList.special = value;
        } else if (elementId === "startM") {
          eduList.startM = value;
        } else if (elementId === "startY") {
          eduList.startY = value;
        } else if (elementId === "endM") {
          eduList.endM = value;
        } else if (elementId === "endY") {
          eduList.endY = value;
        } else if (elementId === "activities") {
          eduList.activities = value;
        } else if (elementId === "accomplishments") {
          eduList.accomplishments = value;
        }
      }
    });

    seteduArr(updatedEduArr);
  };

  const handleWorkExperianceChange = (e, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "select") {
      value = e.target.value;
      elementId = e.target.name;
    } else if (type === "autoCompOSkills") {
      value = [...e];
      elementId = "autoCompOSkills";
    } else if (type === "autoCompPSkills") {
      value = [...e];
      elementId = "autoCompPSkills";
    } else if (type === "rnrs") {
      value = e;
      elementId = "rnrs";
    } else {
      value = e.target.value;
      elementId = e.target.id;
    }
    let updatedWorkArr = [...workExpArr];
    updatedWorkArr.forEach((workList) => {
      if (workList.id === data.id) {
        if (elementId === "designations") {
          workList.designations = value;
        } else if (elementId === "orgNames") {
          workList.orgNames = value;
          workList.isOrgNames = false;
        } else if (elementId === "startM") {
          workList.startM = value;
          workList.isStartM = false;
        } else if (elementId === "startY") {
          workList.startY = value;
          workList.isStartY = false;
        } else if (elementId === "endM") {
          workList.endM = workList.tillDate === "No" ? value : "December";
          workList.isEndM = false;
        } else if (elementId === "endY") {
          workList.endY =
            workList.tillDate === "No" ? value : new Date().getFullYear();

          workList.isEndY = false;
        } else if (elementId === "rnrs") {
          workList.rnrs = value;
        } else if (elementId === "empType") {
          workList.empType = value;
        } else if (elementId === "industry") {
          workList.industry = value;
          workList.isIndustry = false;
        } else if (elementId === "jobTitles") {
          workList.jobTitles = value;
          workList.isJobTitles = false;
        } else if (elementId === "role") {
          workList.role = value;
          workList.isRole = false;
        } else if (elementId === "tillDate") {
          workList.tillDate = value;
          if (value === "Yes") {
            workList.endY = 2023;
            workList.endM = "December";
          }
        } else if (elementId === "autoCompOSkills") {
          workList.skillsO = value;
        } else if (elementId === "autoCompPSkills") {
          workList.skillsP = value;
          workList.isskillsP = false;
        }
      }
    });

    setWorkExpArr(updatedWorkArr);
  };

  const handleAuthorizationFormChange = (e, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "authExp") {
      value = e;
      elementId = "authExp";
    } else {
      value = e.target.value;
      elementId = e.target.id;
    }
    let updatedAuthArr = [...personalDetails];
    updatedAuthArr.forEach((authList) => {
      if (authList.id === data.id) {
        if (elementId === "authExp") {
          authList.authExp = value;
        } else if (elementId === "authType") {
          authList.authType = value;
        } else if (elementId === "countryList") {
          authList.countryList = value;
        } else if (elementId === "sponsor") {
          authList.sponsor = value;
        }
      }
    });

    setPersonalDetails(updatedAuthArr);
  };

  const handleCertificateFormChange = (e, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "select") {
      value = e.target.value;
      elementId = e.target.name;
    } else {
      value = e.target.value;
      elementId = e.target.id;
    }
    let updatedCertificateArr = [...certificateArr];
    updatedCertificateArr.forEach((eduList) => {
      if (eduList.id === data.id) {
        if (elementId === "name") {
          eduList.name = value;
        } else if (elementId === "endM") {
          eduList.endM = value;
        } else if (elementId === "endY") {
          eduList.endY = value;
        }
      }
    });

    setCertificateArr(updatedCertificateArr);
  };

  const handleBoardFormChange = (e, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "select") {
      value = e.target.value;
      elementId = e.target.name;
    } else {
      value = e.target.value;
      elementId = e.target.id;
    }
    let updatedBoardArr = [...boardExpArr];
    updatedBoardArr.forEach((boardList) => {
      if (boardList.id === data.id) {
        if (elementId === "boardName") {
          boardList.boardName = value;
          boardList.isboardName = false;
        } else if (elementId === "boardType") {
          boardList.boardType = value;
          boardList.isboardType = false;
        } else if (elementId === "boardStartM") {
          boardList.boardStartM = value;
          boardList.isboardStartM = false;
        } else if (elementId === "boardStartY") {
          boardList.boardStartY = value;
          boardList.isboardStartY = false;
        } else if (elementId === "boardEndM") {
          boardList.boardEndM = value;
          boardList.isboardEndM = false;
        } else if (elementId === "boardEndY") {
          boardList.boardEndY = value;
          boardList.isboardEndY = false;
        } else if (elementId === "stillMember") {
          boardList.stillMember = value;
        }
      }
    });

    seteduArr(updatedBoardArr);
  };

  const [openModal, setOpenModal] = useState(false);

  const cancelForm = (rows) => {
    unsavedChanges ? setOpenModal(true) : navigate(PROFILE);
    // setSelectedRow(rows);
  };

  function exitHandle() {
    navigate(PROFILE);
  }

  const [currentlyWorking, setcurrentlyWorking] = useState(true);

  const [subList, setsubList] = useState([]);
  const addSubjectsData = () => {
    if (!mentorship.selectedSub) return;
    const index = mentorship?.subjects.findIndex(
      (object) => object === mentorship?.selectedSub
    );
    if (index === -1) {
      setsubList([...subList, mentorship.selectedSub]);
    }
    const ele = autoCompSub.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const removeSubData = (data) => {
    const updatedSubList = subList.filter((list) => list !== data);
    setsubList(updatedSubList);
  };
  useEffect(() => {
    setMentorship({
      ...mentorship,
      subjects: subList,
    });
  }, [subList]);

  return (
    <div className="overideProfile">
      {openModal && (
        <CustomizedDialogs
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          // deletehandle={deletehandle}
          handleCloseModal={() => setOpenModal(false)}
          content={
            "Are you sure you want to Cancel? All unsaved progress will be lost"
          }
          view
          label1={"Exit"}
          label2={"Continue Editing"}
          exitHandle={exitHandle}
        />
      )}
      {activeTab === 0 ? (
        <Grid item container className="topHeaderProfile">
          <Grid item lg={12} md={6} sm={4}>
            <Grid className="profileImg">
              <img
                className="borderProfileImg"
                // src={selectedProfile ? selectedProfile : DefaultProfileImage}
                src={basicDetails?.profile_pic}
                alt=""
              />

              <input
                type="file"
                ref={hiddenProfileInput}
                onChange={(event) => {
                  profileUpload(event);
                }}
                style={{ display: "none" }}
                accept="image/*"
              />
              <label
                for="profile-btn"
                onClick={() => {
                  hiddenProfileInput.current.click();
                }}
              >
                <Grid className="editProfileicon">
                  <img src={Camera} alt="" />
                </Grid>
              </label>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <Grid container className="ProfileConatainer">
        <Grid
          item
          container
          mt={8}
          className="leftSidePanel"
          textAlign={"left"}
          lg={3}
          md={3}
          xs={12}
          // display={{xs:"none",sm:"none"}}
        >
          <Grid
            item
            // lg={10}
            className={activeTab === 0 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(0)}
          >
            Basic Details
          </Grid>

          <Grid
            item
            className={activeTab === 1 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(1)}
          >
            Education and Certifications
          </Grid>

          <Grid
            item
            className={activeTab === 2 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(2)}
          >
            Work Experience
          </Grid>
          {userDetails?.profile?.work_experience?.total_experience > 7 && (
            <Grid
              item
              className={activeTab === 3 ? "tabName active" : "tabName"}
              onClick={() => handleTabClick(3)}
            >
              Mentorship
            </Grid>
          )}

          <Grid
            item
            className={activeTab === 4 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(4)}
          >
            Social Handles
          </Grid>

          <Grid
            item
            className={activeTab === 5 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(5)}
          >
            Profile Video
          </Grid>
        </Grid>
        <Grid item container className="rightPanel">
          {activeTab === 0 && (
            <Grid className="basicDetailsForm">
              <BasicDetailsForm
                basicDetails={basicDetails}
                setBasicDetails={setBasicDetails}
                Countries={Countries}
                personalDetails={personalDetails}
                setPersonalDetails={setPersonalDetails}
                socialLinks={socialLinks}
                setSocialLinks={setSocialLinks}
                submitBasicDetails={submitBasicDetails}
                media={media}
                setmedia={setmedia}
                unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                cancelForm={cancelForm}
                handleAuthorizationFormChange={handleAuthorizationFormChange}
                addNewAuthorizationForm={addNewAuthorizationForm}
              />
            </Grid>
          )}

          {activeTab === 1 && (
            <Grid className="basicDetailsForm">
              {eduArr?.map((i, j) => (
                <EducationAccordian
                  singleEdu={i}
                  handleEduFormChange={handleEduFormChange}
                  eduArr={eduArr}
                  seteduArr={seteduArr}
                  index={j}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  cancelForm={cancelForm}
                />
              ))}

              <Grid container justifyContent={"left"} mb={2}>
                <TextButton
                  className="textButton"
                  label={"Add Education"}
                  onClick={() => addNEwEducationForm()}
                />
              </Grid>
              {certificateArr?.map((i, j) => (
                <CertificateAccordian
                  singleCertificate={i}
                  handleCertificateFormChange={handleCertificateFormChange}
                  certificateArr={certificateArr}
                  setCertificateArr={setCertificateArr}
                  index={j}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  cancelForm={cancelForm}
                  onChnageFileupload={onChnageFileupload}
                  imageurl={i?.imageurl}
                  id={i?.id}
                  onChangeDelete={onChangeDelete}
                />
              ))}

              <Grid container justifyContent={"left"}>
                <TextButton
                  className="textButton"
                  label={"Add Certificate"}
                  onClick={() => addNewCertificateForm()}
                />
              </Grid>

              <Grid className="editProfileBtn" gap={1}>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save"
                      onClick={() => submitEducationDetails(true)}
                    />
                  </Grid>
                </Grid>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save and Continue"
                      onClick={() => submitEducationDetails()}
                    />
                  </Grid>
                </Grid>
                <Grid className="cancelBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      onClick={cancelForm}
                      label="Cancel"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeTab === 2 && (
            <Grid className="basicDetailsForm">
              <Grid textAlign={"left"} item lg={11} gap={"10px"} xs={12}>
                <Grid container item lg={6} mt={1.5}>
                  <FormLabels
                    label={"Do you have work experience?"}
                    isRequired={true}
                  />
                </Grid>

                <Grid container>
                  <Grid item lg={2}>
                    <RadioButton
                      value={"No"}
                      id={"currentlyWorking"}
                      onChange={(e) => {
                        setcurrentlyWorking(false);
                        setUnsavedChanges(true);
                      }}
                      label="No"
                      checked={currentlyWorking === false}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <RadioButton
                      value={"Yes"}
                      onChange={(e) => {
                        setcurrentlyWorking(true);
                        setUnsavedChanges(true);
                      }}
                      label="Yes"
                      id={"currentlyWorking"}
                      checked={currentlyWorking === true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid mt={2} lg={10.8}>
                <InputField
                  fullWidth={true}
                  type="text"
                  label="Total Experience (in Years)"
                  placeholder="Enter here"
                  value={total_experience}
                  disabled={!currentlyWorking}
                  onChange={(e) => {
                    setUnsavedChanges(true);
                    settotal_experience(e.target.value);
                  }}
                />
              </Grid>

              {currentlyWorking !== false &&
                workExpArr?.map((i, j) => {
                  const pSkill = i?.skillsP;
                  const oSkill = i?.skillsO;
                  return (
                    <WorkExpAccordian
                      allIndustries={allIndustries}
                      singleWork={i}
                      handleWorkExperianceChange={handleWorkExperianceChange}
                      workExpArr={workExpArr}
                      setWorkExpArr={setWorkExpArr}
                      index={j}
                      unsavedChanges={unsavedChanges}
                      setUnsavedChanges={setUnsavedChanges}
                      cancelForm={cancelForm}
                      pSkill={pSkill}
                      oSkill={oSkill}
                    />
                  );
                })}
              {currentlyWorking !== false && (
                <Grid container justifyContent={"left"}>
                  <TextButton
                    className="textButton"
                    label={"Add Another"}
                    onClick={() => addNewWorkForm()}
                  />
                </Grid>
              )}
              {boardExpArr.map((i, j) => (
                <BoardExperianceAccordian
                  singleBoard={i}
                  handleBoardFormChange={handleBoardFormChange}
                  boardExpArr={boardExpArr}
                  setBoardExapArr={setBoardExapArr}
                  index={j}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  cancelForm={cancelForm}
                />
              ))}

              <Grid container justifyContent={"left"}>
                <TextButton
                  className="textButton"
                  label={"Add Board"}
                  onClick={() => addNewBoardForm()}
                />
              </Grid>

              <Grid className="editProfileBtn" gap={1}>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save"
                      onClick={() => submitWorkDetails(true)}
                    />
                  </Grid>
                </Grid>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save and Continue"
                      onClick={() => submitWorkDetails()}
                    />
                  </Grid>
                </Grid>
                <Grid className="cancelBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      onClick={cancelForm}
                      label="Cancel"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {activeTab === 3 && (
            <Grid className="basicDetailsForm">
              <Grid textAlign={"left"} item lg={11} gap={"10px"} xs={12}>
                <Grid container item lg={10} mt={1.5}>
                  <FormLabels
                    label={"Would you like to be a mentor?"}
                    isRequired={false}
                  />
                </Grid>

                <Grid container>
                  <Grid item lg={2}>
                    <RadioButton
                      value={mentorship.isMentor == 0}
                      id={"isMentor"}
                      onChange={(e) => {
                        setMentorship({
                          ...mentorship,
                          isMentor: 0,
                          subjects:[]
                        });
                        setUnsavedChanges(true);
                      }}
                      label="No"
                      checked={mentorship.isMentor == 0}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <RadioButton
                      value={mentorship.isMentor === 1}
                      onChange={(e) => {
                        setMentorship({
                          ...mentorship,
                          isMentor: 1,
                          subjects:[]
                        });
                        setUnsavedChanges(true);
                      }}
                      label="Yes"
                      id={"currentlyWorking"}
                      checked={mentorship.isMentor === 1}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container lg={11} xs={12} sm={12} md={12} mt={2}>
                <Grid item lg={10} md={10} sm={10}>
                  <Grid mb={1}>
                    <FormLabels label="Select Mentorship Topics" />
                  </Grid>
                  <SearchAutoComplete
                    optionsData={mentorshipSubjects ?? []}
                    isKeyValuePair={true}
                    value={mentorship.selectedSub}
                    onChange={(e, value) =>
                      setMentorship({
                        ...mentorship,
                        selectedSub: value,
                      })
                    }
                    // isLoading={isLoading}
                    autoCompClear={autoCompSub}
                  />
                </Grid>

                <Grid
                  item
                  lg={1.5}
                  md={1}
                  sm={1}
                  xs={3}
                  mt={4}
                  ml={2}
                  className="saveBtn"
                >
                  <Buttons
                    varientContained={true}
                    label={"Add"}
                    onClick={addSubjectsData}
                  ></Buttons>
                </Grid>
                <Grid mt={4} container gap={1}>
                  {mentorship?.subjects?.map((i) => (
                    <ClickableChips
                      key={i}
                      label={i}
                      isdelete={true}
                      deleteIcon={removeSubData}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid className="editProfileBtn" gap={1}>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save"
                      onClick={() => {
                        submitMentorShipDetails(true);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save and Continue"
                      onClick={() => {
                        submitMentorShipDetails();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="cancelBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Cancel"
                      onClick={cancelForm}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className="basicDetailsForm">
            {activeTab === 4 && (
              <SocialHandleForm
                socialLinks={socialLinks}
                setSocialLinks={setSocialLinks}
                submitSocialDetails={submitSocialDetails}
                unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                cancelForm={cancelForm}
              />
            )}
          </Grid>

          {activeTab === 5 && (
            <Grid className="basicDetailsForm">
              <ProfileVideoForm
                socialLinks={socialLinks}
                setSocialLinks={setSocialLinks}
                submitSocialDetails={submitMediaDetails}
                video={video}
                setVideo={setVideo}
                unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                cancelForm={cancelForm}
              />

              <Grid className="editProfileBtn" gap={1}>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Save and Continue"
                      onClick={() => {
                        submitMediaDetails();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="cancelBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      label="Cancel"
                      onClick={cancelForm}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfileForm;
