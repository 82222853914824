import React, { useEffect } from "react";
import ViewProfile from "../component/ViewProfile";
import { useLocation } from "react-router-dom";
import CorporateViewProfile from "../component/CorporateViewProfile";

const ViewProfileContainer = (props) => {
  const location = useLocation();
  const userType = location?.state?.userType ?? props?.user?.userType;
  return (
    <>
      {userType === "corporate" ? (
        <CorporateViewProfile user={props} />
      ) : (
        <ViewProfile
          onClick={props?.onClick}
          user={props?.user}
          fromSerach={props?.fromSerach}
        />
      )}
    </>
  );
};

export default ViewProfileContainer;
