import { Grid } from "@mui/material";
import Camera from "../../../../assets/Camera.svg";
import "./CorporateProfileForm.scss";
import React, { useState, useRef, useEffect } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { useDispatch, useSelector } from "react-redux";
import BasicDetailsForm from "../BasicDetailsForm";
import SocialHandleForm from "../SocialHandleForm";
import ProfileVideoForm from "../ProfileVideoForm";
import CustomizedDialogs from "../../../../library/common/DialogBox";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../../../constant/routeContant";
import CorporateBasicDetailsForm from "./CorporateBasicDetailsForm";
import { getFormatedDate } from "../../../../utils/helper";
import defaultCoverPic from "../../../../assets/defaultCoverPic.svg";
import * as FileUpload from "../../../../redux/actions/UploadVideoAction";
import Loader from "../../../../library/common/Loader";
import defaultLoginImage from "../../../../assets/defaultLoginImage.svg";

function CorporateProfileForm({
  uploadProfileFile,
  handleTabClick,
  activeTab,
  allCountries,
  allIndustries,
  editProfile,
  headShot,
  uploadCoverFile,
  coverShot,
}) {
  const navigate = useNavigate();
  const userDetails = useSelector(
    (state) => state?.userDetails?.data?.response
  );
  const userDetailsLoading = useSelector(
    (state) => state?.userDetails?.isLoading
  );
  useEffect(() => {
    setBasicDetails({
      ...basicDetails,
      logo: headShot,
    });
  }, [headShot]);
  useEffect(() => {
    coverShot !== undefined &&
      coverShot !== null &&
      setmedia({
        ...media,
        coverPic: coverShot,
      });
  }, [coverShot]);

  useEffect(() => {
    setBasicDetails({
      ...basicDetails,
      name: userDetails?.basicDetails?.name,
      website: userDetails?.basicDetails?.website,
      dial_code: userDetails?.basicDetails?.dial_code,
      telephone: userDetails?.basicDetails?.telephone,
      recovery_email: userDetails?.basicDetails?.recovery_email,
      type: userDetails?.basicDetails?.type,
      culture: userDetails?.basicDetails?.culture,
      industry: userDetails?.basicDetails?.industry,
      address_line: userDetails?.basicDetails?.address_line,
      country_name: userDetails?.basicDetails?.country_name,
      city: userDetails?.basicDetails?.city,
      state: userDetails?.basicDetails?.state,
      zipcode: userDetails?.basicDetails?.zipcode,
      establishment_date: userDetails?.basicDetails?.establishment_date,
      employee_count: userDetails?.basicDetails?.employee_count,
      revenue: userDetails?.basicDetails?.revenue,
      revenue_currency: userDetails?.basicDetails?.revenue_currency,
      current_road_map: userDetails?.basicDetails?.current_road_map,
      future_road_map: userDetails?.basicDetails?.future_road_map,
      logo: userDetails?.basicDetails?.logo,
    });
    setprofilePreviewUrl(userDetails?.basicDetails?.logo);

    setmedia({
      ...media,
      coverPic: userDetails?.profile?.media?.coverPic,
    });
    setcoverPreviewUrl(userDetails?.profile?.media?.coverPic);

    let addressO = mapAdress(userDetails?.profile?.address_details);
    setAddress_detailsArr(addressO);
    setSocialLinks({
      facebook: userDetails?.profile?.social_links?.facebook,
      twitter: userDetails?.profile?.social_links?.twitter,
      pinterest: userDetails?.profile?.social_links?.pinterest,
      instagram: userDetails?.profile?.social_links?.instagram,
      github: userDetails?.profile?.social_links?.github,
      linkedin: userDetails?.profile?.social_links?.linkedin,
    });

    setVideo(userDetails?.basicDetails?.video_intro);
  }, [userDetails]);

  function mapAdress(data) {
    let arr = [];

    if (data?.degree?.length > 0)
      data?.addressO.map((i, j) =>
        arr.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          addressO: userDetails?.profile?.address_details?.addressO[j],
          cityO: userDetails?.profile?.address_details?.cityO[j],
          countryO: userDetails?.profile?.address_details?.countryO[j],
          stateO: userDetails?.profile?.address_details?.stateO[j],
          zipcodeO: userDetails?.profile?.address_details?.zipcodeO[j],
        })
      );
    return arr;
  }

  function mappedData() {
    let addressOArr = [];
    let cityOArr = [];
    let stateOArr = [];
    let countryOArr = [];
    let zipcodeOArr = [];

    address_detailsArr.map((i) => {
      addressOArr.push(i.addressO);
      cityOArr.push(i.cityO);
      countryOArr.push(i.countryO);
      stateOArr.push(i.stateO);
      zipcodeOArr.push(i.zipcodeO);
    });
    const data = {
      basic_details: {
        name: basicDetails?.name,
        website: basicDetails?.website,
        dial_code: basicDetails?.dial_code,
        telephone: basicDetails?.telephone,
        recovery_email: basicDetails?.recovery_email,
        type: basicDetails?.type,
        culture: basicDetails?.culture,
        industry: basicDetails?.industry,
        address_line: basicDetails?.address_line,
        country_name: basicDetails?.country_name,
        city: basicDetails?.city,
        state: basicDetails?.state,
        zipcode: basicDetails?.zipcode,
        employee_count: basicDetails?.employee_count,
        establishment_date: getFormatedDate(basicDetails?.establishment_date),
        revenue: basicDetails?.revenue,
        revenue_currency: basicDetails?.revenue_currency,
        current_road_map: basicDetails?.current_road_map,
        future_road_map: basicDetails?.future_road_map,
        logo: basicDetails?.logo,
      },
      media: {
        coverPic: media.coverPic,
      },
      address_details: {
        addressO: addressOArr,
        cityO: cityOArr,
        stateO: stateOArr,
        countryO: countryOArr,
        zipcodeO: zipcodeOArr,
      },
    };
    return data;
  }

  function mappedSocialData() {
    const data = {
      social_links: {
        facebook:
          socialLinks.facebook?.trim() !== ""
            ? socialLinks.facebook?.trim().search(".com/") >= 0
              ? socialLinks.facebook?.trim().split(".com/")[1]
              : socialLinks.facebook?.trim()
            : "",
        github:
          socialLinks.github?.trim() !== ""
            ? socialLinks.github?.trim().search(".com/") >= 0
              ? socialLinks.github?.trim().split(".com/")[1]
              : socialLinks.github?.trim()
            : "",

        instagram:
          socialLinks.instagram?.trim() !== ""
            ? socialLinks.instagram?.trim().search(".com/") >= 0
              ? socialLinks.instagram?.trim().split(".com/")[1]
              : socialLinks.instagram?.trim()
            : "",
        linkedin:
          socialLinks.linkedin?.trim() !== ""
            ? socialLinks.linkedin?.trim().search(".com/in/") >= 0
              ? socialLinks.linkedin?.trim().split(".com/in/")[1]
              : socialLinks.linkedin?.trim()
            : "",

        pinterest:
          socialLinks.pinterest?.trim() !== ""
            ? socialLinks.pinterest?.trim().search(".com/") >= 0
              ? socialLinks.pinterest?.trim().split(".com/")[1]
              : socialLinks.pinterest?.trim()
            : "",

        twitter:
          socialLinks.twitter?.trim() !== ""
            ? socialLinks.twitter?.trim().search(".com/") >= 0
              ? socialLinks.twitter?.trim().split(".com/")[1]
              : socialLinks.twitter?.trim()
            : "",
      },
      additional_info: {},
    };
    return data;
  }

  const profileUpload = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setprofilePreviewUrl(fileReader.result);
      };
    handleProfileUploadMethod(file);
  };

  const coverUpload = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      handleCoverUploadMethod(fileReader.result);
    };
  };

  const handleProfileUploadMethod = (data) => {
    uploadProfileFile(data);
  };

  const dispatch = useDispatch();
  const [coverpreviewUrl, setcoverPreviewUrl] = useState(null);
  const [profilepreviewUrl, setprofilePreviewUrl] = useState(null);
  const [coverPicError, setcoverPicError] = useState(false);

  const handleCoverUploadMethod = (event) => {
    setcoverPicError(false);
    const reader = new FileReader();

    reader.onloadend = () => {
      setcoverPreviewUrl(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
    dispatch(FileUpload.createVideoUpload({ file: event.target.files[0] }));
  };
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [basicDetails, setBasicDetails] = useState({
    // title: userDetails?.basicDetails?.title,
    name: "",
    website: "",
    dial_code: "",
    telephone: "",
    recovery_email: "",
    type: undefined,
    culture: "",
    industry: undefined,
    address_line: "",
    country_name: "",
    city: "",
    state: "",
    zipcode: "",
    employee_count: "",
    establishment_date: "",
    revenue: "",
    revenue_currency: "",
    current_road_map: "",
    future_road_map: "",
    headShot: "",
  });
  const [personalDetails, setPersonalDetails] = useState({
    aboutMe: "",
    countryList: [],
    authType: [],
    sponsor: [],
    authExp: [],
  });
  const [address_detailsArr, setAddress_detailsArr] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      addressO: "",
      isaddressO: "",

      cityO: "",
      iscityO: false,
      stateO: "",
      isstateO: false,
      countryO: "",
      iscountryO: false,
      zipcodeO: "",
      iszipcodeO: false,
    },
  ]);
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    pinterest: "",
    github: "",
  });

  const [media, setmedia] = useState({
    coverPic: "",
    videoshot: "",
  });

  const [video, setVideo] = useState("");
  const hiddenProfileInput = useRef(null);
  const hiddenCoverInput = useRef(null);
  const Countries = allCountries?.data?.response?.countryResult;
  const submitBasicDetails = (exit = false) => {
    const data = mappedData(false);
    console.log(exit);
    if (!data) {
    } else {
      editProfile(data, exit);
    }
  };

  const submitSocialDetails = (exit = false) => {
    const data = mappedSocialData(false);
    if (!data) {
    } else {
      editProfile(data, exit);
    }
  };

  const videoUrl = useSelector(
    (state) => state?.createUploadVideoData?.data?.fileUrl
  );
  const submitMediaDetails = () => {
    let submissonData = {
      video_intro: video,
    };
    editProfile({
      basic_details: submissonData,
    });
  };

  const [openModal, setOpenModal] = useState(false);

  const cancelForm = (rows) => {
    unsavedChanges ? setOpenModal(true) : navigate(PROFILE);
    // setSelectedRow(rows);
  };

  function exitHandle() {
    navigate(PROFILE);
  }

  const createUploadVideoData = useSelector(
    (state) => state?.createUploadVideoData
  );

  useEffect(() => {
    if (
      createUploadVideoData?.status === "SUCCESS" &&
      !createUploadVideoData?.isLoading
    ) {
      setmedia({
        ...media,
        coverPic: createUploadVideoData?.data?.fileUrl,
      });
    }
    if (createUploadVideoData?.isError) {
      setcoverPreviewUrl(defaultCoverPic);
      setcoverPicError(true);
    }
  }, [createUploadVideoData]);

  const handleAdresFormChange = (e, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "select") {
      value = e.target.value;
      elementId = e.target.name;
    } else {
      value = e.target.value;
      elementId = e.target.id;
    }
    let updatedAdressArr = [...address_detailsArr];
    updatedAdressArr.forEach((addList) => {
      if (addList.id === data.id) {
        if (elementId === "addressO") {
          addList.addressO = value;
        } else if (elementId === "cityO") {
          addList.cityO = value;
        } else if (elementId === "stateO") {
          addList.stateO = value;
        } else if (elementId === "countryO") {
          addList.countryO = value;
        } else if (elementId === "zipcodeO") {
          addList.zipcodeO = value;
        }
      }
    });

    setAddress_detailsArr(updatedAdressArr);
  };

  function addNEwAdressForm() {
    let newAuthArr = [...address_detailsArr];
    newAuthArr.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      addressO: "",
      cityO: "",
      stateO: "",
      countryO: "",
      zipcodeO: "",
    });
    setAddress_detailsArr(newAuthArr);
  }
  return (
    <div className="overideProfile">
      {createUploadVideoData?.isLoading && <Loader />}
      {openModal && (
        <CustomizedDialogs
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          // deletehandle={deletehandle}
          handleCloseModal={() => setOpenModal(false)}
          content={
            "Are you sure you want to Cancel? All unsaved progress will be lost"
          }
          view
          label1={"Exit"}
          label2={"Continue Editing"}
          exitHandle={exitHandle}
        />
      )}
      <Grid item container className="topHeaderProfile">
        <Grid item lg={11} md={12} mt={2} className="coverPicGrid">
          {coverPicError && <p className="errorMsg">Error while uploading</p>}
          <Grid className="coverImg">
            <img
              className="borderProfileImg"
              src={
                coverpreviewUrl
                  ? coverpreviewUrl
                  : media.coverPic === undefined || ""
                  ? defaultCoverPic
                  : media.coverPic
              }
              alt="noimage"
            />
            <input
              type="file"
              ref={hiddenCoverInput}
              onChange={(event) => {
                handleCoverUploadMethod(event);
              }}
              style={{ display: "none" }}
              accept="image/*"
            />
            <label
              for="profile-btn"
              onClick={() => {
                hiddenCoverInput.current.click();
              }}
            >
              <Grid className="editProfileicon">
                <img src={Camera} alt="" />
              </Grid>
            </label>
          </Grid>
        </Grid>
        <Grid item lg={12} md={6} sm={4} className="profilePicGrid">
          <Grid className="profileImg">
            <img
              className="borderProfileImg"
              src={
                profilepreviewUrl
                  ? profilepreviewUrl
                  : basicDetails?.logo === undefined ||
                    basicDetails?.logo === "" ||
                    basicDetails?.logo === null
                  ? defaultLoginImage
                  : basicDetails?.logo
              }
              alt=""
            />

            <input
              type="file"
              ref={hiddenProfileInput}
              onChange={(event) => {
                profileUpload(event);
              }}
              style={{ display: "none" }}
              accept="image/*"
            />
            <label
              for="profile-btn"
              onClick={() => {
                hiddenProfileInput.current.click();
              }}
            >
              <Grid className="editProfileicon">
                <img src={Camera} alt="" />
              </Grid>
            </label>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="ProfileConatainer">
        <Grid
          item
          container
          mt={8}
          className="leftSidePanel"
          textAlign={"left"}
        >
          <Grid
            item
            // lg={10}
            className={activeTab === 0 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(0)}
          >
            Basic Details
          </Grid>

          <Grid
            item
            className={activeTab === 1 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(1)}
          >
            Social Handles
          </Grid>

          <Grid
            item
            className={activeTab === 2 ? "tabName active" : "tabName"}
            onClick={() => handleTabClick(2)}
          >
            Profile Video
          </Grid>
        </Grid>
        {userDetailsLoading ? (
          <Loader />
        ) : (
          <Grid item container className="rightPanel">
            <Grid className="basicDetailsForm">
              {activeTab === 0 && (
                <CorporateBasicDetailsForm
                  basicDetails={basicDetails}
                  setBasicDetails={setBasicDetails}
                  Countries={Countries}
                  personalDetails={personalDetails}
                  setPersonalDetails={setPersonalDetails}
                  socialLinks={socialLinks}
                  setSocialLinks={setSocialLinks}
                  submitBasicDetails={submitBasicDetails}
                  handleTabClick={handleTabClick}
                  media={media}
                  setmedia={setmedia}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  cancelForm={cancelForm}
                  allIndustries={allIndustries}
                  address_detailsArr={address_detailsArr}
                  setAddress_detailsArr={setAddress_detailsArr}
                  handleAdresFormChange={handleAdresFormChange}
                  addNEwAdressForm={addNEwAdressForm}
                />
              )}
            </Grid>

            <Grid className="basicDetailsForm">
              {activeTab === 1 && (
                <SocialHandleForm
                  socialLinks={socialLinks}
                  setSocialLinks={setSocialLinks}
                  submitSocialDetails={submitSocialDetails}
                  handleTabClick={handleTabClick}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  cancelForm={cancelForm}
                />
              )}
            </Grid>

            {activeTab === 2 && (
              <Grid className="basicDetailsForm">
                <ProfileVideoForm
                  socialLinks={socialLinks}
                  setSocialLinks={setSocialLinks}
                  submitSocialDetails={submitMediaDetails}
                  handleTabClick={handleTabClick}
                  video={video}
                  setVideo={setVideo}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  cancelForm={cancelForm}
                />

                <Grid className="editProfileBtn">
                  <Grid className="saveBtn">
                    <Grid>
                      <Buttons
                        varientContained={true}
                        label="Save"
                        onClick={() => {
                          submitMediaDetails();
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid className="cancelBtn">
                    <Grid>
                      <Buttons
                        varientContained={true}
                        label="Cancel"
                        onClick={cancelForm}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CorporateProfileForm;
