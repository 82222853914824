import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./searchBar.scss";
import locationSearchIcon from "../../../assets/locationSearchIcon.svg";
const SearchBar = ({
  searchbarInput,
  handleSearch,
  placeholder,
  locationSearch = false,
}) => {
  return (
    <>
      <div className="searchid">
        <label htmlFor="search" className="searchBar">
          <span>
            {locationSearch ? <img src={locationSearchIcon} /> : <SearchIcon />}
          </span>
          <input
            className="serchInputField"
            placeholder={placeholder}
            value={searchbarInput}
            onChange={(e) => handleSearch(e.target.value)}
            id="search"
          />
        </label>
      </div>
    </>
  );
};

export default SearchBar;
