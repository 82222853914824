import { Button } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

const Buttons = ({
  label,
  variantOutline,
  varientContained,
  varientText,
  onClick,
  varientTextGreen,
  disabled,
  primary,
  startIcon,
  type,
  redBg,
  id,
  variantOutlineSections,
  redButton,
  varientAddIconBlue,
  primaryalert,
  primaryassign,
}) => {
  return (
    <div className="overideBtn">
      <Button
        variant="text"
        disabled={disabled}
        startIcon={
          <AddIcon className={disabled ? "addIconGrey" : "addIconBtn"} />
        }
        className="textButton"
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

export default Buttons;
