import { editProfileType } from "../constant/editProfileType";

const initialState = {
  flow: "editProfile",
  isError: false,
  isLoading: false,
};

export const getIndustriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.GET_INDUSTRY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.GET_INDUSTRY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCountriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.GET_COUNTRIES_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const putEditProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.PUT_EDIT_PROFILE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.PUT_EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.PUT_EDIT_PROFILE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case editProfileType.PUT_EDIT_PROFILE_CLEAR:
      return {
        ...state,
        status: "clearJoinstatus",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getSalaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.GET_SALARY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.GET_SALARY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.GET_SALARY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postSendInviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.POST_INVITE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.POST_INVITE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.POST_INVITE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getJobTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.GET_JOB_TYPE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.GET_JOB_TYPE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.GET_JOB_TYPE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postTagUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.POST_TAG_USER_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.POST_TAG_USER_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.POST_TAG_USER_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateCompansationReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.UPDATE_COMPANSATION_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.UPDATE_COMPANSATION_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.UPDATE_COMPANSATION_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case editProfileType.GET_USER_DETAILS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case editProfileType.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case editProfileType.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
