import * as SignupAction from "../actions/SignupAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import {
  Signup,
  SignupWithGoogle,
  checkUserExist,
  otpSignup,
  otpVerifySignup,
} from "../../services/SignupService ";
import { SignupType } from "../constant/SignupType ";

export function* checkUserExistSaga(params) {
  try {
    const { data, status, error } = yield call(checkUserExist, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      SignupAction.checkUserExistSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Check User Failed" };
    yield put(SignupAction.checkUserExistFailure(data));
  }
}

export function* otpSignupSaga(params) {
  try {
    const { data, status, error } = yield call(otpSignup, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      SignupAction.otpsignupSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "SignupOtp Failed" };
    yield put(SignupAction.otpsignupFailure(data));
  }
}

export function* otpVerifySignupSaga(params) {
  try {
    const { data, status, error } = yield call(otpVerifySignup, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      SignupAction.otpVerifysignupSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "SignupOtp  Verify Failed" };
    yield put(SignupAction.otpVerifysignupFailure(data));
  }
}

export function* signupSaga(params) {
  try {
    const { data, status, error } = yield call(Signup, params);
    if (!data && status !== 200) throw apiError(error);
    sessionStorage.setItem("id", data.response.id);
    sessionStorage.setItem("token", data.response.token);
    yield put(
      SignupAction.signupSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Signup Failed" };
    yield put(SignupAction.signupFailure(data));
  }
}

export function* signupWithGoogleSaga(params) {
  try {
    const { data, status, error } = yield call(SignupWithGoogle, params);
    if (!data && status !== 200) throw apiError(error);
    sessionStorage.setItem("id", data.response.id);
    sessionStorage.setItem("token", data.response.token);
    sessionStorage.setItem("account_type", data.response.account_type);
    sessionStorage.setItem("email", data.response.email);
    yield put(
      SignupAction.signupWithGoogleSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Signup Failed With Google" };
    yield put(SignupAction.signupWithGoogleFailure(data));
  }
}

const mysignupSaga = [
  takeLatest(SignupType.POST_CHECK_USER_PENDING, checkUserExistSaga),
  takeLatest(SignupType.GET_OTPSIGNUP_PENDING, otpSignupSaga),
  takeLatest(SignupType.POST_SIGNUP_PENDING, signupSaga),
  takeLatest(SignupType.GET_OTP_VERIFY_SIGNUP_PENDING, otpVerifySignupSaga),
  takeLatest(SignupType.POST_SIGNUP_GOOGLE_PENDING, signupWithGoogleSaga),
];

export default mysignupSaga;
