import React from "react";
import Navbar from "./Navbar";
import { Grid } from "@mui/material";
import "./Homepage.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import marketicon from "../../assets/Landing/marketicon.svg";
import graphicon from "../../assets/Landing/graphicon.svg";
import financialicon from "../../assets/Landing/financialicon.svg";
import mentorshipIcon from "../../assets/Landing/market_icon.svg";
import companyLogo from "../../assets/Landing/companyLogoCompressed.png";
import FacebookLogo from "../../assets/Landing/facebookIconCompressed.png";
import gitLogo from "../../assets/Landing/githubIconCompressed.png";
import Instagramicon from "../../assets/Landing/instagramIconCompressed.png";
import linkedinLogo from "../../assets/Landing/linkedInIconCompressed.png";
import pinstrateLogos from "../../assets/Landing/pintrestIconCompressed.png";
import twitterlogo from "../../assets/Landing/twitterXlogo.svg";
import handImage from "../../assets/Landing/handImageCompressed.png";
import Carousels from "./Carousel";

import { useState } from "react";
import { INTRO_VID } from "../../constant/constant";
import ProfessionalInfoCard from "../../library/custom/professionalInfoCard/ProfessionalInfoCard";

function HomePage() {
  const navigate = useNavigate();
  const windowWith = window.innerWidth;
  const goToSignup = () => {
    navigate("/signup");
  };
  const [video, setVideo] = useState(false);
  const openVideo = () => {
    // setVideo(true);
    const section = document.getElementById("introVid");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const closeVideo = () => {
    setVideo(false);
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  console.log(windowWith > 960);
  return (
    <>
      <Grid className="homepage-override">
        <Grid className="navbarstyle">
          <Navbar />
        </Grid>

        <Grid className="bannerContainer">
          <Grid className="banner">
            <Grid className="heading">
              Connecting IT
              <br /> Professionals
              <br /> & Companies
            </Grid>
            <Grid className="subHeading">
              MatchupIT is a collaborative platform directly
              {windowWith > 960 ? <br /> : ""} connecting IT professionals to
              the companies
            </Grid>
            <Grid className="btnBlock">
              <Grid className="JoinBtn">
                <Button
                  variant="contained"
                  className="JoinbtnStyle"
                  onClick={goToSignup}
                >
                  Join Now
                </Button>
              </Grid>
              <Grid className="whybtn">
                <Button
                  variant="text"
                  className="WhyBtnStyle"
                  onClick={openVideo}
                >
                  Why MatchupIT?
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="subHeading2">
          See why over thousands of users are choosing MatchupIT
        </Grid>
        <Grid className="forProfessionals">
          <span className="subHeading3">What’s in it for </span>
          <span className="subHeading4">Professionals</span>
          <span className="subHeading3">?</span>
        </Grid>
        <Grid
          container
          p={4}
          justifyContent={"center"}
          gap={4}
          className="ProfContainer"
        >
          <ProfessionalInfoCard
            icon={graphicon}
            heading={"Elevate Your Professional Value"}
            content={
              "Whether you’re a student aspiring to make a career in technology, a tester, programmer, project manager, or C-level executive, MatchupIT is your gateway to building a solid personal brand. Connect with global industry experts, enhance your visibility, and take your professional presence to new heights on our platform."
            }
          />
          <ProfessionalInfoCard
            icon={mentorshipIcon}
            heading={"Expert Mentorship"}
            content={
              "Boost your career by connecting with seasoned mentors across diverse IT fields on MatchupIT. Receive personalized guidance tailored to your aspirations and benefit from insights that align with your unique goals."
            }
          />
          <ProfessionalInfoCard
            icon={marketicon}
            heading={"Collaborate and share knowledge"}
            content={
              "Leverage MatchupIT to connect with experts and collaborate with peers within your professional communities."
            }
          />
          <ProfessionalInfoCard
            icon={financialicon}
            heading={"Get Noticed"}
            content={
              "MatchupIT helps you craft a standout profile, making you visible to companies actively seeking your skills and expertise."
            }
          />
        </Grid>
        <Grid className="companyStyle" mt={4} mb={4}>
          <span className="subHeading5">What’s in it for </span>
          <span className="subHeading6">Companies</span>
          <span className="subHeading5">?</span>
        </Grid>
        <Grid mb={2} className="companyConatainer">
          <Grid className="companyImage">
            <img src={companyLogo} className="Comapnyicon" alt="Company" />
          </Grid>
          <Grid className="companyContent">
            <Grid className="companyHeading1">Find the Right Talent</Grid>
            <Grid className="companySubheading1">
              Grow your organization by posting jobs and connecting with top
              candidates on MatchupIT.
            </Grid>

            <Grid className="companyHeading1">
              Showcase Your Products and Services
            </Grid>
            <Grid className="companySubheading1">
              Reach a wider audience of professionals and organizations by
              showcasing your offerings on MatchupIT.
            </Grid>

            <Grid className="companyHeading1">Forge Powerful Partnerships</Grid>
            <Grid className="companySubheading1">
              Easily collaborate with other companies on MatchupIT and unlock
              new opportunities for growth and innovation.p.
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            padding: {
              lg: "0 20px",
              xs: "0 20px",
            },
          }}
          id="introVid"
          className="videoPlayer"
          mt={4}
        >
          <video
            className="player"
            style={{ width: "90%" }}
            controls
            autoPlay={false}
          >
            <source src={INTRO_VID} type="video/mp4" />
          </video>
        </Grid>
        <Grid
          sx={{
            padding: {
              lg: "0 20px",
              xs: "0 10%",
            },
          }}
          mt={4}
          className="IconHeading"
        >
          <span className="subHeading7">Integrate</span>
          <span className="subHeading8">
            {" "}
            with your existing profiles in a few clicks
          </span>
        </Grid>

        <Grid className="Icon-middle">
          <Grid className="">
            <Grid className="float-middle">
              <div className="social_icon">
                <div
                  className="socialIcons"
                  onClick={() => navigate("/signup")}
                >
                  <img
                    src={pinstrateLogos}
                    className="social-logo"
                    alt="Pinstrate logo"
                  />
                </div>
                <div
                  className="socialIcons"
                  onClick={() => navigate("/signup")}
                >
                  <img src={gitLogo} className="social-logo" alt="Git logo" />
                </div>
                <div
                  className="socialIcons"
                  onClick={() => navigate("/signup")}
                >
                  <img
                    src={linkedinLogo}
                    className="social-logo"
                    alt="Linkedin logo"
                  />
                </div>
                <div
                  className="socialIcons"
                  onClick={() => navigate("/signup")}
                >
                  <img
                    src={twitterlogo}
                    className="social-logo"
                    alt="Twitter logo"
                  />
                </div>

                <div
                  className="socialIcons"
                  onClick={() => navigate("/signup")}
                >
                  <img
                    src={Instagramicon}
                    className="social-logo"
                    alt="Instagram logo"
                  />
                </div>
                <div
                  className="socialIcons"
                  onClick={() => navigate("/signup")}
                >
                  <img
                    src={FacebookLogo}
                    className="social-logo"
                    alt="Facebook logo"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="aboutUserStyle">What our users say about us</Grid>
        <Grid className="carouserBody">
          <Carousels />
        </Grid>
        <Grid
          container
          p={3}
          alignItems={"center"}
          className="setupSection"
          mb={6}
        >
          <Grid
            item
            xs={12}
            lg={6}
            mt={5}
            md={8}
            sm={12}
            className="setupSection1"
          >
            <Grid className="minutDivStyle">
              <Grid className="minutHeading">Set up in minutes</Grid>
              <Grid className="minutSubHeading">
                Creating your MatchupIT profile is quick and easy. Our intuitive
                interface helps you effortlessly showcase your skills,
                experience, and achievements to establish a compelling
                professional digital presence in no time.
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} md={6} sm={12}>
            <Grid className="imageSection">
              <img src={handImage} className="HandImage" alt="Hand" />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default HomePage;
