import { mentorshipType } from "../constant/mentorshipType";

const initialState = {
  flow: "mentorship",
  isError: false,
  isLoading: false,
};

export const getMentorshipSubjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case mentorshipType.GET_MENTORSHIP_SUBJECTS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.GET_MENTORSHIP_SUBJECTS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.GET_MENTORSHIP_SUBJECTS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getMentorsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case mentorshipType.GET_MENTORS_LIST_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.GET_MENTORS_LIST_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.GET_MENTORS_LIST_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case mentorshipType.GET_MENTORS_LIST_CLEAR:
      return {
        ...state,
        status: "clearMEntorList",
        isError: false,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const postMentorshipRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_REQUEST_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getMyMenteesReducer = (state = initialState, action) => {
  switch (action.type) {
    case mentorshipType.GET_MY_MENTEES_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.GET_MY_MENTEES_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.GET_MY_MENTEES_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getMyMentorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case mentorshipType.GET_MY_MENTORS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.GET_MY_MENTORS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.GET_MY_MENTORS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipRequestRejectReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipRequestAcceptReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipRequestCancelReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipBookSessionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_BOOK_SESSION_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_BOOK_SESSION_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_BOOK_SESSION_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getMentorshipUpcomingSessionsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipSessionAcceptReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipSessionRejectReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_SESSION_REJECT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_SESSION_REJECT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_SESSION_REJECT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postMentorshipSessionCancelReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
