import { adminType } from "../constant/adminType";

const initialState = {
  flow: "Admin",
  isError: false,
  isLoading: false,
};

export const getAdminReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminType.GET_ADMIN_REPORT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case adminType.GET_ADMIN_REPORT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case adminType.GET_ADMIN_REPORT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAdminAboutToExpireUsersReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case adminType.GET_ADMIN_ABOUT_TO_EXPIRE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case adminType.GET_ADMIN_ABOUT_TO_EXPIRE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case adminType.GET_ADMIN_ABOUT_TO_EXPIRE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postAdminUpdateIndustryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case adminType.POST_ADMIN_UPDATE_INDUSTRY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case adminType.POST_ADMIN_UPDATE_INDUSTRY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case adminType.POST_ADMIN_UPDATE_INDUSTRY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAdminCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminType.GET_ADMIN_COUNT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case adminType.GET_ADMIN_COUNT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case adminType.GET_ADMIN_COUNT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
