import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Navbar from "./Navbar";
import { Grid } from "@mui/material";
import Footer from "./Footer";
import "./TermsCondition.scss";

function TermsCondition() {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="tndcMainbody">
      <Grid className="navbarStyle">
        <Navbar />
      </Grid>
      <Grid className="mainHeading">Terms of Use</Grid>
      <Grid container px={{ xs: 5, md: 25, lg: 30, sm: 20 }}  mb={30}>
        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Introduction</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  MatchupIT is a platform that is built and owned by EnSignis
                  Digital LLC, 2475 Northwinds Pkwy, Suite 200, Alpharetta, GA
                  30009 (“we” or “us”). This platform's mission is to (i)
                  connect the world's technology professionals and helps to
                  collaborate with communities (ii) Companies to search right
                  and genuine talent to add to their workforce. Effectively,
                  this platform brings IT professionals and Companies together.
                  The Matchup IT Platform may include both user social media
                  account services for and professional services delivered to
                  third-party companies (collectively, the “Platform” or
                  “Service”). The Platform is designed to allow IT professionals
                  to enhance their value by sharing knowledge in white papers
                  and blogs. They can also attach documents (text, picture, and
                  video). Individual professionals can also search companies to
                  find opportunities to work in those companies and connect
                  directly with them. Companies can benefit by having a database
                  of potentially millions of IT professionals searching against
                  their needs. They can also find out the location where there
                  is a cluster of desired skills.
                </p>
                <h4>1.1 Contract</h4>
                <p>
                  <p>
                    When you use our Platform, you agree to all these Terms and
                    Conditions including our{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      style={{
                        marginRight: "unset",
                        textDecoration: "underline",
                      }}
                    >
                      Privacy Policy and any other documents referred to herein
                      (collectively, the “Terms”).
                    </a>
                  </p>
                  <p>
                    If you do not agree to these Terms, do not click &quot;Sign
                    Up&quot; (or similar links) and do not access or otherwise
                    use our Platform. If you wish to terminate your use of the
                    Platform at any time, you can do so by closing your account
                    and no longer accessing or using our Platform.
                  </p>
                  <p>
                    <b>MatchupIT Platform</b>
                    <br />
                    These Terms apply to all uses of the Platform, whether by
                    you as an individual or on behalf of a corporate account,
                    and whether you are a registered Member (as defined below)
                    or not. Registered users of our Platform are
                    &quot;Individual Members&quot; and &quot;Corporate
                    Members.&quot;
                  </p>
                </p>
                <h4>1.2 Members</h4>
                <p>
                  When you register for an account on the MatchupIT Platform,
                  you become a member. The membership is free for the initial 12
                  months. After the initial period, if you have chosen not to
                  pay for our Platform's services, you may have limited access
                  to certain basic features on our Platform.
                </p>

                <h4>1.3 Change</h4>
                <p>
                  We may modify/update/change these Terms, from time to time.
                  The date these Terms were last updated is posted at the top of
                  this page. Changes become effective when we post them. If you
                  object to any changes, you may choose to close your account.
                  Your continued use of our Platform after we publish any
                  changes to these Terms means that you consent to the updated
                  terms as of their effective date.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Obligations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  {" "}
                  You agree to comply with all applicable laws as well as with
                  these Terms.
                </p>
                <h4>2.1 Eligibility</h4>
                <p>
                  You are eligible to use the Platform if you are at least our
                  &quot;Minimum Age&quot; (18 years or older) threshold. The
                  Platform is not for use by anyone under the age of 18, and if
                  you notify us that anyone under 18 has become a member, we
                  will delete their information.
                </p>
                <p>To use features on our platform, you agree that:</p>
                <li>You must be at least the &quot;Minimum Age&quot;.</li>
                <li>
                  You will only have one MatchupIT account, which must be in
                  your real name.
                </li>
                <li>
                  You are not restricted by MatchupIT from using the Platform.
                </li>
                <li>
                  Creating an account with false information is a violation of
                  our terms, including accounts registered on behalf of other
                  persons or persons under 18.
                </li>
                <li>
                  Your registered account may be at the corporate or individual
                  level.
                </li>
                <li>
                  You have full power and authority to enter these Terms for
                  yourself or your employer, as applicable, and doing so will
                  not violate any other agreement to which you are a party.
                </li>
                <li>
                  You will not violate any of our rights , including
                  intellectual property rights.
                </li>
                <br />
                <h4>2.2 Your Account</h4>
                <p>You will keep your account credentials a secret..</p>
                <p>
                  You will not share your account with anyone else and will
                  follow our rules and the law. Members are the account holders.
                  You agree to:
                </p>
                <p>(1) Use a strong password and keep it confidential.</p>
                <p>(2) Not transfer your account and </p>
                <p>
                  (3) follow the law. You are responsible for anything that
                  happens through your account unless you close it or report
                  misuse.
                </p>
                <p>
                  As between you and others (including your employer), your
                  account belongs to you. However, third party service providers
                  and business partners (including those who provide features
                  you choose to use) may have access to data in your account
                  consistent with these Terms.
                </p>
                <br />
                <h4>2.3 Payment</h4>
                <p>
                  After the initial free ( currently the platform is free till
                  Dec 2022) period, you may not use some of the advanced
                  features of the Platform unless you are a paid subscriber.
                </p>
                <p>
                  In order to use the Platform after the initial free period,
                  you must designate a payment method and provide us with
                  accurate billing, and payment information and you have the
                  continuing obligation to keep it up to date. The following
                  important provisions apply to all of our fee-based services:
                </p>
                <ol type="a">
                  <li>
                    <u>Payments.</u> You represent that you are at least the
                    minimum age required to enter into a legal agreement. You
                    agree to pay us for the Services, as well as all other
                    charges incurred under your account, including applicable
                    taxes and fees. You are responsible for all charges incurred
                    under your accounts.
                  </li>
                  <li>
                    <u>Payment Methods.</u> You authorize and direct us to
                    charge your designated payment method for these charges. You
                    are responsible for all charges even if your payment method
                    fails or is denied. You authorize and direct us to retain
                    all information about any payment method(s) associated with
                    your account.
                  </li>
                  <li>
                    <u>Payment Terms.</u> We may charge for the Services in
                    advance and on a monthly basis, as long as your subscription
                    remains active, even if you have not downloaded or used the
                    Service or accessed your online account.
                  </li>
                  <li>
                    <u>Auto-Renewal.</u> At the expiration of each subscription
                    term for our Services, we will automatically renew your
                    subscription and charge the credit card or other payment
                    method you have provided to us, unless you cancel your
                    subscription at least 48 hours before the end of the current
                    period. Your subscription will be automatically renewed at
                    the then-current price, excluding promotional and discount
                    pricing.
                  </li>
                  <li>
                    <u>Fraud Protection.</u> We may take steps to verify the
                    validity of the credit card information you provide to us,
                    including debiting amounts less than $1.00 from your credit
                    card and then immediately crediting it back. You authorize
                    us to do so for verification and anti- fraud purposes.
                  </li>
                  <li>
                    <u>No Refunds.</u> All charges are nonrefundable unless
                    provided otherwise in the terms you agree to when you
                    register for a fee-based Service, unless the laws of your
                    country require, or these Terms state, otherwise.
                  </li>
                  <li>
                    <u>Termination.</u> We, in our sole discretion, may change,
                    discontinue, or terminate any or all aspects of a fee-based
                    Service without notice, subject to providing an appropriate
                    refund for any portions of a specified but no longer
                    available term. You may cancel a fee-based Service at any
                    time by logging into your online account and terminating
                    the subscription.
                  </li>
                  <li>
                    <u>Change in Fees and Billing Method.</u> We may change our
                    fees and billing methods at any time. We will provide you
                    with notice of any price increase at least thirty (30) days
                    in advance. Subject to applicable law, (i) if you disagree
                    with any proposed change, your sole remedy is to cancel your
                    Membership for the Service before the price change takes
                    effect and (ii) your continued use of or subscription to the
                    Service after the price change takes effect constitutes your
                    agreement to pay the new price for the Service.
                  </li>
                  <li>
                    <u>Delinquency.</u> After 30 days from the date of any
                    unpaid charges, we may terminate or suspend your account and
                    fee-based Service for nonpayment. You are liable for any
                    fees, including attorney and collection fees, incurred by us
                    in our efforts to collect any remaining balances from you.
                  </li>
                  <li>
                    <u>90-Day Notice Period.</u> You must notify us about any
                    billing problems or discrepancies within 90 days after they
                    first appear on your billing method statement. If you do not
                    bring them to our attention within 90 days, you agree that
                    you waive your right to dispute such problems or
                    discrepancies.
                  </li>
                </ol>
                <br />
                <h4>2.4 Notices and Messages</h4>
                <p>
                  We will be providing notices and messages to you through our
                  websites, apps, and contact information.
                  <br />
                  You agree that we will provide notices and messages to you in
                  the following ways:
                </p>
                <p>(1) Within the Service, or </p>
                <p>
                  (2) sent to the contact information you provided us (e.g.,
                  email, mobile number, physical address).
                </p>
                <p>
                  You agree to keep your contact information up to date. In case
                  your contact information is out of date, you may miss out on
                  important notices.
                </p>
                <br />
                <h4>2.5 Sharing</h4>
                <p>
                  When you share information on our Platform with others, others
                  can see and copy the information you make available as well as
                  information that identifies you. You can change what others
                  see about you using the Settings tools in your account. Our
                  Platform allows messaging and sharing of information in many
                  ways, such as your profile, articles, group posts, pictures,
                  videos, or any other content that you have shared. We will
                  honor the choices you make about what content or information
                  (e.g., message content to your addressees, sharing content
                  only to MatchupIT corporate members restricting your profile
                  visibility to other individuals within a community).
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Rights and Limits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <h4>3.1. Your License to MatchupIT</h4>
                <p>
                  You own all the content and personal information you provide
                  to us, but you also grant us a non-exclusive license to it.
                </p>
                <p>
                  We are not obligated to publish any information or content on
                  our Platform and can remove it with or without notice.
                </p>
                <p>
                  As between you and us, you own the content and information
                  that you submit or post to the Platform, and you are only
                  granting us and our affiliates the following non-exclusive
                  license: A worldwide, perpetual, transferable, and
                  sublicensable right to use, copy, modify, edit, combine with
                  other content, distribute, publish and process via our
                  Services, all information and content that you provide through
                  our Platform in any medium, for any purpose whatsoever
                  including the promotion of our Services, without any further
                  consent, notice and/or compensation to you or others. These
                  rights are limited in the following ways:
                </p>
                <p>
                  (1) You can delete content from our Platform, or generally by
                  closing your account, except (a) to the extent you shared it
                  with others as part of the services provided by the Platform,
                  and they copied, re-shared it, or stored it and (b) for the
                  reasonable time it takes to remove from backup and other
                  systems.
                </p>
                <p>
                  (2) We will not include your content in advertisements for
                  third parties’ products and services to others without your
                  separate consent.
                </p>
                <p>
                  (3) Because you own your content and information and we only
                  have non-exclusive rights to it, you may choose to make it
                  available to others.
                </p>
                <p>
                  You agree that we may access, store, process, and use any
                  information and personal data that you provide according to
                  the terms of the
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    style={{
                      marginRight: "unset",
                      textDecoration: "underline",
                    }}
                  >
                    Privacy Policy
                  </a>{" "}
                  and your choices (including settings).
                  <br />
                  By submitting suggestions or other feedback regarding our
                  Platform features to MatchupIT, you agree that MatchupIT can
                  use and share (but does not have to) such feedback for any
                  purpose without compensation to you.
                </p>
                <p>
                  You promise only to provide information and content that you
                  have the right to share and that your MatchupIT profile will
                  be truthful.
                </p>
                <p>
                  You agree only to provide content or information that does not
                  violate the law nor anyone&#39;s rights (including
                  intellectual property rights). You also agree that your
                  profile information will be truthful. MatchupIT may be
                  required by law to remove certain information or content in
                  certain countries.
                </p>
                <br />
                <h4>3.2 Feature Availability</h4>
                <p>
                  We may change, suspend, or discontinue any of our features on
                  the Platform at any time without notice, including by
                  disabling features or content, removing, or adding content, or
                  otherwise. We may also modify our prices effective.
                </p>
                <p>
                  You agree that we have no obligation to store, maintain or
                  provide you a copy of any content or information that you or
                  others provide, except to the extent required by applicable
                  law and as noted in our Privacy Policy.
                </p>
                <br />
                <h4>3.3 Other Content, Sites and Apps</h4>
                <p>
                  Your use of others&#39; content and information posted on our
                  Platform is at your own risk.
                  <br />
                  Others may offer their information through our Platform, and
                  we are not responsible for those third-party activities.
                  <br />
                  By using matchupIT, you may encounter content or information
                  that might be inaccurate, incomplete, delayed, misleading,
                  illegal, offensive, or otherwise harmful. MatchupIT generally
                  does not review content provided by our subscribed Members or
                  other third parties. You agree that we are not responsible for
                  others&#39; (including other Members&#39;) content or
                  information. Y you agree that we are not responsible for any
                  misuse of any third-party content within the Platform.
                </p>
                <br />
                <h4>3.4 Limits</h4>
                <p>
                  We have the right to limit how you connect and interact within
                  your chosen communities on our Platform. MatchupIT reserves
                  the right to limit your use of the Services, including the
                  number of posts (including the use of video and pictures) and
                  the number of communities you can connect to. MatchupIT
                  reserves the right to restrict, suspend, or terminate your
                  account if you breach these Terms or the law or are misusing
                  any feature/service of the Platform.
                </p>
                <br />
                <h4>3.5 Intellectual Property Rights</h4>
                <p>
                  MatchupIT reserves all its intellectual property rights in and
                  to the Platform and all content available on or through it.
                  Trademarks and logos used in connection to providing services
                  are the trademarks of their respective owners. MatchupIT logo,
                  service marks, graphics, and logos used for our Services are
                  trademarks or registered trademarks of MatchupIT and EnSignis
                  Digital LLC.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">
                Warranty & Limitation of Liability
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <h4>4.1 Disclaimer</h4>
                <p>
                  MATCHUPIT AND ENSIGNIS DIGITAL LLC MAKE NO REPRESENTATION OR
                  WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT
                  THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE
                  THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN
                  &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. TO THE
                  FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, MATCHUPIT AND
                  ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY,
                  INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OR
                  AVAILABILITY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR
                  FITNESS FOR A PARTICULAR PURPOSE.
                </p>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY LAW, MATCHUPIT AND ITS
                  PARENT COMPANY ENSIGNIS DIGITAL, WILL NOT BE LIABLE FOR LOST
                  PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION, LOSS OF
                  DATA OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
                  PUNITIVE DAMAGES ARISING FROM OR IN ANY WAY RELATING TO THE
                  PLATFORM, ITS CONTENTS, ITS MEMBERS, ITS USERS, OR OTHERWISE.
                </p>
                <br />
                <h4>4.2 Limitation of Liability</h4>
                <p>
                  THE TOTAL LIABILITY OF ENSIGNIS DIGITAL, MATCHUP IT, OR THEIR
                  RESPECTIVE EMPLOYEES, OWNERS, OR AGENTS IN CONNECTION WITH THE
                  PLATOFIRM, ITS CONTENTS, ITS MEMBERS, ITS USERS, OR OTHERWISE
                  SHALL NOT UNDER ANY CIRCUMSTANCES EXCEED ONE (1) TIMES THE
                  MOST RECENT MONTHLY FEE AMOUNT PAID BY YOU, REGARDLESS OF
                  WHETHERYOU BASE YOUR CLAIM ON CONTRACT, TORT, STATUTE OR ANY
                  OTHER LEGAL THEORY, AND REGARDLESS OF WHETHER WE KNEW OR
                  REASONABLY SHOULD HAVE KNOWN ABOUT POSSIBILITY OF SUCH
                  DAMAGES.
                </p>
                <p>
                  You must bring any action regarding the Platform, if at all,
                  within one (1) year of the date it accrues or be barred.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Termination</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <ol type="a">
                  <li>
                    Continued Use of the Services. You may stop using the
                    Services at any time, but your continued use of or
                    subscription to a Service after the effective date of any
                    modifications to the Terms means that you agree to the Terms
                    as modified.
                  </li>
                  <li>
                    We may terminate all or part of the Services at any time,
                    subject to all the provisions of these Terms.
                  </li>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Governing Law</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  In the unlikely event we end up in a legal dispute, you agree
                  to resolve it in Georgia courts using Georgia law without
                  regard to its conflicts of laws rules.
                  <br />
                  If you live outside of the United States: You agree that the
                  laws of the State of Georgia, U.S.A., excluding its conflict
                  of laws rules, shall exclusively govern any dispute relating
                  to these Terms and/or the Services. You agree that all claims
                  and disputes can be litigated only in the federal or state
                  courts in Fulton County, Georgia, USA, and you agree to
                  personal jurisdiction in those courts.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">General Terms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  Here are some important details about these Terms.
                  <br />
                  These Terms (including additional terms that may be provided
                  by us when you engage with a feature of the Services) are the
                  sole and exclusive between us regarding the Services and
                  supersedes all prior agreements for the Services.
                </p>
                <p>
                  If we do not act to enforce a breach of these Terms, that does
                  not mean that MatchupIT has waived its right to enforce these
                  Terms. You may not assign or transfer these Terms (or your
                  membership or use of Services) to anyone without our consent.
                  However, you agree that MatchupIT may assign these Terms to
                  its affiliates or a party that buys it without your consent.
                </p>
                <p>There are no third-party beneficiaries to these Terms.</p>
                <p>
                  You agree that the only way to provide us legal notice is at
                  the addresses provided in Section 10.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Do's and Dont's</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  In addition to and without limiting anything else in these
                  Terms, the following list certain things that you can and
                  can&#39;t do on our Platform.
                </p>
                <h4>8.1. Do's</h4>
                <p>
                  <b>You agree that you will:</b>
                  <ol type="a">
                    <li>
                      Comply with all applicable laws, including, without
                      limitation, privacy laws, intellectual property laws,
                      anti-spam laws, export control laws, tax laws, and
                      regulatory requirements.
                    </li>
                    <li>
                      Provide accurate information to us and keep it updated.
                    </li>
                    <li>Use your real name on your profile; and</li>
                    <li>Use the Services in a professional manner.</li>
                  </ol>
                </p>
                <br />
                <h4>8.2. Don’ts</h4>
                <p>
                  <b>You agree that you will not:</b>
                  <ol type="a">
                    <li>
                      Create a false identity on MatchupIT, misrepresent your
                      identity, create a Member profile for anyone other than
                      yourself (a real person), or use or attempt to use
                      another&#39;s account.
                    </li>
                    <li>
                      Develop, support, or use software, devices, scripts,
                      robots, or any other means or processes (including
                      crawlers, browser plugins, and add-ons or any other
                      technology) to scrape the Services or otherwise copy
                      profiles and other data from the Services;
                    </li>
                    <li>
                      Override any security feature or bypass or circumvent any
                      access controls or use limits of the service (such as caps
                      on keyword searches or profile views).
                    </li>
                    <li>
                      Copy, use, disclose or distribute any information obtained
                      from the Services, whether directly or through third
                      parties (such as search engines), without the consent of
                      MatchupIT.
                    </li>
                    <li>
                      Disclose information that you do not have the consent to
                      disclose (such as others&#39; confidential information
                      (including your employer)).
                    </li>
                    <li>
                      Violate others&#39; intellectual property rights,
                      including copyrights, patents, trademarks, trade secrets,
                      or other proprietary rights. For example, do not copy or
                      distribute (except through the available sharing
                      functionality) the posts or other content of others
                      without their permission, which they may give by posting
                      under a Creative Commons license.
                    </li>
                    <li>
                      Post anything that contains software viruses, worms, or
                      any other harmful code.
                    </li>
                    <li>
                      Reverse engineer, decompile, disassemble, decipher, or
                      otherwise attempt to derive the source code for the
                      Services or any related technology that is not open
                      source.
                    </li>
                    <li>
                      Imply or state that you are affiliated with or endorsed by
                      MatchupIT without our express consent (e.g., representing
                      yourself as an accredited MatchupIT trainer).
                    </li>
                    <li>
                      Deep link to our Services for any purpose other than to
                      promote your profile or a Group on our Services, without
                      MatchupIT&#39;s consent.
                    </li>
                    <li>
                      Use bots or other automated methods to access the
                      Services, add or download contacts, send, or redirect
                      messages.
                    </li>
                    <li>
                      Monitor the Services&#39; availability, performance, or
                      functionality for any competitive purpose.
                    </li>
                    <li>
                      Engage in &quot;framing,&quot; &quot;mirroring,&quot; or
                      otherwise simulating the appearance or function of the
                      Services.
                    </li>
                    <li>
                      Overlay or otherwise modify the Services or their
                      appearance (such as by inserting elements into the
                      Services or removing, covering, or obscuring an
                      advertisement included on the Services).
                    </li>
                    <li>
                      Interfere with the operation of, or place an unreasonable
                      load on, the Services (e.g., spam, denial of service
                      attack, viruses, gaming algorithms); and/or
                    </li>
                  </ol>
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">
                Complaints Regarding Content
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  Contact information for complaint about content provided by
                  our Members.
                  <br />
                  We respect the intellectual property rights of others. We
                  require that information posted by Members be accurate and not
                  in violation of the intellectual property rights or other
                  rights of third parties. If you believe that a Member has
                  published material that violates your intellectual property
                  rights, please contact us at the below address or email
                  address, provide a way for us to contact you, and describe and
                  document the posting with enough detail that we can locate it
                  and evaluate it.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Contact Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  Our Contact information.
                  <br />
                  For general inquiries, you may contact us at
                  contact@matchupit.com . For legal notices or service of
                  process, you may write us at this address:
                </p>

                <p>
                  {" "}
                  EnSignis Digital LLC (For MatchupIT)
                  <br />
                  2475, Northwinds Pkwy, Suite 200
                  <br />
                  Alpharetta, GA 30009
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid>
        <Footer />
      </Grid>
    </div>
  );
}

export default TermsCondition;
