import { call, put, takeLatest } from "redux-saga/effects";
import {
  recoveryEmailVerify,
  deactivateAccount,
  changePasswordService,
  paymentDetails,
  updateEmail,
  addCorporateMemeber,
  removeCorporateMemeber,
} from "../../services/settingService";
import { apiError } from "../../utils/helper";
import { settingType } from "../constant/settingType";
import * as settingAction from "../actions/settingAction";

export function* deactivateAccountSaga(params) {
  try {
    const { data, status, error } = yield call(deactivateAccount, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.deactivateAccountSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Deactivate Account failed" };
    yield put(settingAction.deactivateAccountFailure(data));
  }
}

export function* recoveryEmailSaga(params) {
  try {
    const { data, status, error } = yield call(recoveryEmailVerify, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.recoveryEmailVerifySuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Recovery Email failed" };
    yield put(settingAction.recoveryEmailVerifyFailure(data));
  }
}

export function* changePasswordSaga(params) {
  try {
    const { data, status, error } = yield call(changePasswordService, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.changePasswordSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get change password failed" };
    yield put(settingAction.changePasswordFailure(data));
  }
}

export function* paymentDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(paymentDetails, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.paymentDetailsSuccessful({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get payment details failed" };
    yield put(settingAction.paymentDetailsFailure(data));
  }
}

export function* updateEmailSaga(params) {
  try {
    const { data, status, error } = yield call(updateEmail, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.updateEmailSuccessful({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Get update Email Fail" };
    yield put(settingAction.updateEmailFailure(data));
  }
}

export function* addCorporateMemberSaga(params) {
  try {
    const { data, status, error } = yield call(addCorporateMemeber, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.addCorporateMemberSuccessful({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Add Corporate member failed" };
    yield put(settingAction.addCorporateMemeberFailure(data));
  }
}

export function* removeCorporateMemberSaga(params) {
  try {
    const { data, status, error } = yield call(removeCorporateMemeber, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      settingAction.removeCorporateMemberSuccessful({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Add Corporate member failed" };
    yield put(settingAction.removeCorporateMemeberFailure(data));
  }
}

const mySettingSaga = [
  takeLatest(settingType.GET_DEACTIVATE_ACCOUNT_PENDING, deactivateAccountSaga),
  takeLatest(settingType.GET_RECOVERY_EMAIL_VERIFY_PENDING, recoveryEmailSaga),
  takeLatest(settingType.PUT_CHANGE_PASSWORD_PENDING, changePasswordSaga),
  takeLatest(settingType.GET_PAYMENT_DETAILS_PENDING, paymentDetailsSaga),
  takeLatest(settingType.GET_UPDATE_EMAIL_PENDING, updateEmailSaga),
  takeLatest(
    settingType.POST_ADD_CORPORATE_MEMBER_PENDING,
    addCorporateMemberSaga
  ),
  takeLatest(
    settingType.POST_REMOVE_CORPORATE_MEMBER_PENDING,
    removeCorporateMemberSaga
  ),
];

export default mySettingSaga;
