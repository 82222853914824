import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
} from "@mui/material";
import "../job/CreateJob.scss";
import { InputField } from "../../library/custom/textBox/InputField";
import RichTextEditor from "../../library/custom/richTextEditor/RichTextEditor";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../library/custom/selectMenu/SelectMenu";
import {
  EMP_TYPE,
  MIN_EXP,
  REMOTE_ACPT,
  allQualType,
} from "../../constant/constant";
import SearchAutoComplete from "../../library/custom/searchAutoComplete/SearchAutoComplete";
import RadioButton from "../../library/custom/radioButton/RadioButton";
import { ISwitch } from "../../constant/constant";
import FormGroup from "@mui/material/FormGroup";
import ClickableChips from "../../library/custom/chip/ClickableChip";
import JobChip from "../job/JobChip";
import { useNavigate } from "react-router";
import { VIEW_JOB } from "../../constant/routeContant";
import checkCircleJob from "../../../src/assets/checkCircleJob.svg";
import { Modal, Box } from "@mui/material";
import { CURRENCYCODE } from "../../constant/currencyCode";

function CreateJob({
  allIndustries,
  jobPost,
  userData,
  postedJobDatas,
  getpostedJobDetail,
  postedJobDetailsData,
  updatejob,
  putJobPostData,
  getPostedJob,
  updateJobPostData,
}) {
  const navigate = useNavigate();
  const history = useNavigate();
  const corptname = userData?.basicDetails?.name;
  const [jobTitle, setJobTitle] = useState("");
  const [jobCode, setjobCode] = useState("");
  const [description, setDescription] = useState("");
  const [industryExp, setIndustryExp] = useState("");
  const [functions, setFunctions] = useState("");
  const [roleName, setRole] = useState("");
  const [minExp, setminExp] = useState("");
  const [empType, setEmpType] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [remoteAcpt, setRemoteAcpt] = useState("");
  const [currency, setCurrency] = useState("");
  const [minPayrange, setminPayrange] = useState("");
  const [maxPayrange, setmaxPayrange] = useState("");
  const [jobType, setjobType] = useState("0");
  const [selectJobid, setSelectJobid] = useState("");
  const [checked, setChecked] = useState(true);
  const [days, setDays] = useState("");
  const [heading, setHeading] = useState("Post a Job");
  const [editMode, seteditMode] = useState(false);
  const [jobTitleError, setJobTitleError] = useState(false);
  const [jobCodeError, setJobCodeError] = useState(false);
  const [functionsError, setFunctionsError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [empTypeError, setEmpTypeError] = useState(false);
  const [minExpError, setMinExpError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionTemplete, setDescriptionTemplete] = useState("");
  const saveAsNewJoB =
    jobCode === postedJobDetailsData?.data?.response?.jobcode;
  const allfunction = allIndustries?.functions;

  function findDescriptionByRoleName(allfunction, roleName) {
    for (const func of allfunction) {
      const matchingRole = func.roles.find((role) => role.name === roleName);
      if (matchingRole) {
        return matchingRole.description;
      }
    }
    return null;
  }

  useEffect(() => {
    if (roleName) {
      const descriptionTempletes = findDescriptionByRoleName(
        allfunction,
        roleName
      );
      setDescriptionTemplete(descriptionTempletes);
    }
  }, [roleName]);

  const onClickOurTemplete = () => {
    setDescription(descriptionTemplete ? descriptionTemplete : " ");
  };
  const [accountType, setaccountType] = useState("0");
  function handleAccountType(e) {
    setaccountType(e);
  }

  function handleJobType(e) {
    setjobType(e);
  }

  const [pSkill, setPSLill] = useState("");
  const [pSkillsList, setPSkillsList] = useState([]);
  const [isPSkill, setPSkill] = useState(false);

  const [tool, setTool] = useState("");
  const [toolsList, setToolsList] = useState([]);

  const [minQualification, setMinQualification] = useState("");
  const [minQualificationList, setminQualificationList] = useState([]);

  const autoCompPSKill = useRef(null);
  const autoCompTool = useRef(null);
  const autoCompMinQualification = useRef(null);

  const addToolData = () => {
    if (!tool) return;
    const index = toolsList.findIndex((object) => object === tool);
    if (index === -1) {
      setToolsList([...toolsList, tool]);
    }
    const ele = autoCompTool.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const handleDeleteTool = (label) => {
    const updateToolList = toolsList.filter(
      (list) =>
        list?.replace(/\s+/g, "")?.toLowerCase() !==
        label?.replace(/\s+/g, "")?.toLowerCase()
    );
  setToolsList(updateToolList);
  };

  const addSkillsPData = () => {
    setPSkill(false);
    if (!pSkill) return;
    const index = pSkillsList.findIndex((object) => object === pSkill);
    if (index === -1) {
      setPSkillsList([...pSkillsList, pSkill]);
    }

    const ele = autoCompPSKill.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const handleDeletePskill = (label) => {
    const updatePskillsList = pSkillsList.filter(
      (list) =>
        list?.replace(/\s+/g, "")?.toLowerCase() !==
        label?.replace(/\s+/g, "")?.toLowerCase()
    );
    setPSkillsList(updatePskillsList);
  };

  const addMinQualificationPData = () => {
    if (!minQualification) return;
    const index = minQualificationList.findIndex(
      (object) => object === minQualification
    );
    if (index === -1) {
      setminQualificationList([...minQualificationList, minQualification]);
    }

    const ele = autoCompMinQualification.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const handleDeleteQulification = (label) => {
    console.log("label", label);
    const updateQualificationList = minQualificationList.filter(
      (list) =>
        list?.replace(/\s+/g, "")?.toLowerCase() !==
        label?.replace(/\s+/g, "")?.toLowerCase()
    );
    setminQualificationList(updateQualificationList);
  };

  const onChangeJobTitle = (e) => {
    setJobTitle(e.target.value);
  };
  const onChangeJobCode = (e) => {
    setjobCode(e.target.value);
    // setIsNewJob(true)
  };

  const handleDescChange = (event) => {
    setDescription(event);
  };
  const onChangeIndustryExp = (e) => {
    setIndustryExp(e.target.value);
  };

  const onChangeFunction = (e) => {
    setFunctions(e.target.value);
  };
  const onChangeRole = (e) => {
    setRole(e.target.value);
  };

  const onChangeMinExp = (e) => {
    setminExp(e.target.value);
  };

  const onChangeEmpType = (e) => {
    setEmpType(e.target.value);
  };

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
  };
  const onChangeState = (e) => {
    setState(e.target.value);
  };
  const onChangeZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const onChangeRemoteAcpt = (e) => {
    setRemoteAcpt(e.target.value);
  };
  const onChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const onChangeMinPayrange = (e) => {
    setminPayrange(e.target.value);
  };

  const onChangeMaxPayRange = (e) => {
    setmaxPayrange(e.target.value);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  function getRoles(value) {
    let valueArray = [];
    allIndustries?.functions?.forEach((data) => {
      if (data.name === functions) {
        valueArray = data.roles;
      }
    });
    return valueArray;
  }
  const roleArray = getRoles();

  useEffect(() => {
    if (postedJobDetailsData?.data?.response && editMode) {
      setHeading("Edit Job");
      setJobTitle(postedJobDetailsData?.data?.response?.title);
      setIndustryExp(postedJobDetailsData?.data?.response?.industry);
      setDescription(postedJobDetailsData?.data?.response?.description);
      setminExp(postedJobDetailsData?.data?.response?.experience);
      setEmpType(postedJobDetailsData?.data?.response?.jobtype);
      setState(postedJobDetailsData?.data?.response?.state);
      setCountry(postedJobDetailsData?.data?.response?.country);
      setZipCode(postedJobDetailsData?.data?.response?.zipcode);
      setRemoteAcpt(
        postedJobDetailsData?.data?.response?.isremote == 1 ? "Yes" : "No"
      );
      setCurrency(postedJobDetailsData?.data?.response?.compcurrency)
      setminPayrange(postedJobDetailsData?.data?.response?.mincomp);
      setmaxPayrange(postedJobDetailsData?.data?.response?.maxcomp);
      setaccountType(postedJobDetailsData?.data?.response?.comprange);
      setminQualificationList(
        postedJobDetailsData?.data?.response?.qualification.split(",")
      );
      setToolsList(postedJobDetailsData?.data?.response?.skillso.split(","));
      setPSkillsList(postedJobDetailsData?.data?.response?.skillsp.split(","));
      setFunctions(postedJobDetailsData?.data?.response?.jobfunction);
      setRole(postedJobDetailsData?.data?.response?.role);
      setChecked(
        postedJobDetailsData?.data?.response?.status == 1 ? true : false
      );
      setjobCode(postedJobDetailsData?.data?.response?.jobcode);
    }
  }, [postedJobDetailsData?.data?.response]);

  const cardOnClick = (value) => {
    setDays(value);
    const data = {
      jobId: value?.jobId,
    };
    getpostedJobDetail(data);
    seteditMode(true);
  };
  function getPSkills(value) {
    let valueArray = [];
    allIndustries?.functions?.forEach((data) => {
      if (data.name === functions) {
        valueArray = data.primarySkills;
      }
    });
    return valueArray;
  }
  const pSkillsArray = getPSkills();
  const onClickASave = () => {
    let isFieldEmpty = false;
    if (jobTitle.trim() === "") {
      setJobTitle("");
      setJobTitleError(true);
      isFieldEmpty = true;
    }
    if (jobCode.trim() === "") {
      setjobCode("");
      setJobCodeError(true);
      isFieldEmpty = true;
    }
    if (!functions) {
      setFunctionsError(true);
      isFieldEmpty = true;
    }
    if (!roleName) {
      setRoleError(true);
      isFieldEmpty = true;
    }
    if (description.trim() === "" || description == "<p><br></p>") {
      setDescription("");
      setDescriptionError(true);
      isFieldEmpty = true;
    }
    if (!minExp) {
      setMinExpError(true);
      isFieldEmpty = true;
    }
    if (!empType) {
      setEmpTypeError(true);
      isFieldEmpty = true;
    }
    if (!country) {
      setCountryError(true);
      isFieldEmpty = true;
    }
    if (!state) {
      setStateError(true);
      isFieldEmpty = true;
    }
    if (!zipCode) {
      setZipCodeError(true);
      isFieldEmpty = true;
    }
    if (pSkillsList?.length <= 0) {
      setPSkill(true);
      isFieldEmpty = true;
    }
    if (!isFieldEmpty) {
      let sendData = {
        title: jobTitle,
        company: corptname,
        description: description,
        country: country,
        state: state,
        jobcode: jobCode,
        // city: ,
        jobfunction: functions,
        role: roleName,
        zipcode: zipCode,
        qualification: minQualificationList.join(","),
        experience: minExp,
        skillsp: pSkillsList.join(","),
        skillso: toolsList.join(","),
        jobtype: empType,
        mincomp: minPayrange,
        maxcomp: maxPayrange,
        compcurrency: currency,
        comprange: accountType,
        industry: industryExp,
        isremote: remoteAcpt == "Yes" ? "1" : "0",
        status: checked ? 1 : 0,
      };

      let sendDataEdit = {
        title: jobTitle,
        company: corptname,
        description: description,
        country: country,
        state: state,
        jobcode: jobCode,
        jobfunction: functions,
        role: roleName,
        status: checked ? 1 : 0,
        // city: ,
        zipcode: zipCode,
        qualification: minQualificationList.join(","),
        experience: minExp,
        skillsp: pSkillsList.join(","),
        skillso: toolsList.join(","),
        jobtype: empType,
        mincomp: minPayrange,
        maxcomp: maxPayrange,
        compcurrency: currency,
        comprange: accountType,
        industry: industryExp,
        isremote: remoteAcpt == "Yes" ? "1" : "0",
      };

      if (heading == "Post a Job" || (!saveAsNewJoB && editMode)) {
        jobPost(sendData);
      } else {
        updatejob(sendDataEdit);
        seteditMode(false);
      }
    }
  };

  const handleClickViewJob = () => {
    navigate(VIEW_JOB);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: "none",
    p: 4,
    borderRadius: "15px",
    px: 11,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!putJobPostData?.isLoading && putJobPostData?.status === "SUCCESS") {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        getPostedJob();
        setOpen(false);
        window.location.reload();
      }, 2000);
    }
  }, [putJobPostData]);

  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  useEffect(() => {
    if (
      !updateJobPostData?.isLoading &&
      updateJobPostData?.status === "SUCCESS"
    ) {
      setOpens(true);
      setTimeout(() => {
        setOpens(false);
        getPostedJob();
        setOpens(false);
        window.location.reload();
      }, 2000);
    }
  }, [updateJobPostData]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent={"center"}>
            <img src={checkCircleJob} alt="" />
          </Grid>
          <p id="modal-modal-description" className="font700 font16">
            Job Created Successfully
          </p>
        </Box>
      </Modal>
      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent={"center"}>
            <img src={checkCircleJob} alt="" />
          </Grid>
          <p id="modal-modal-description" className="font700 font16">
            Job Updated Successfully
          </p>
        </Box>
      </Modal>

      <Grid item container lg={12}>
        <Grid item lg={9} xs={12} sm={12} md={6}>
          <h1 className="MainHeading">Manage Jobs</h1>
        </Grid>
      </Grid>

      <Grid className="container">
        <div className="box">
          <div className="left-section">
            <Grid item container lg={12}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <h1 className="jobSubHeading">{heading}</h1>
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <InputField
                  type="text"
                  label="Job Title"
                  placeholder="Enter Job Title"
                  value={jobTitle}
                  onChange={(e) => onChangeJobTitle(e)}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {jobTitleError && !jobTitle && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <InputField
                  type="text"
                  label="Job Code"
                  placeholder="Enter Job Code"
                  value={jobCode}
                  onChange={(e) => onChangeJobCode(e)}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {jobCodeError && !jobCode && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={false} label="Industry Experience" />
                  <SelectMenu
                    disabledDefault={false}
                    countries={true}
                    listData={allIndustries?.industries}
                    value={industryExp}
                    onchangehandler={(e) => onChangeIndustryExp(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={true} label="Function" />
                  <SelectMenu
                    disabledDefault={false}
                    functions={true}
                    listData={allIndustries?.functions}
                    value={functions}
                    // funtion={true}
                    onchangehandler={(e) => onChangeFunction(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {functionsError && !functions && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={true} label="Role" />
                  <SelectMenu
                    disabledDefault={false}
                    functions={true}
                    listData={roleArray}
                    value={roleName}
                    onchangehandler={(e) => onChangeRole(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {roleError && !roleName && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={2} mb={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabels">
                  <FormLabels isRequired={true} label="Job Description" />
                  <RichTextEditor
                    className="richEditor"
                    onChange={(event, value) => handleDescChange(event, value)}
                    value={description}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={6} textAlign={"left"} mb={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {descriptionError && !description && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>

            {roleName && (
              <Grid item container lg={12} mt={8}>
                <Grid item lg={9} xs={12} sm={12} md={6}>
                  <Button onClick={onClickOurTemplete} className="Buttonjob">
                    Use our Template
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item container lg={12} mt={6}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={true} label="Primary Skills" />
                  <SearchAutoComplete
                    optionsData={pSkillsArray ?? []}
                    isKeyValuePair={true}
                    value={pSkill}
                    onChange={(e, value) => setPSLill(value)}
                    // isLoading={isLoading}
                    typeOnchange={(e)=>console.log()}
                    autoCompClear={autoCompPSKill}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={1.5} md={9} sm={12} xs={12} mt={3} ml={2}>
                <Grid className="saveBtnstyle">
                  <Button className="OverridesaveBtn" onClick={addSkillsPData}>
                    Add
                  </Button>
                </Grid>
              </Grid>
              {isPSkill && pSkillsList?.length <= 0 && (
                <div className="errorMsg">Please enter this required field</div>
              )}
              <Grid mt={4} container gap={2}>
                {pSkillsList?.map((i) => (
                  <ClickableChips
                    label={i}
                    deleteIcon={(e) => handleDeletePskill(e)}
                    isdelete={true}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels
                    isRequired={false}
                    label="Tools, Technology and Framework"
                  />
                  <SearchAutoComplete
                    optionsData={allIndustries?.skills ?? []}
                    isKeyValuePair={true}
                    value={tool}
                    onChange={(e, value) => setTool(value)}
                    // isLoading={isLoading}
                    typeOnchange={(e)=>console.log()}
                    autoCompClear={autoCompTool}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={1.5} md={9} sm={12} xs={12} mt={3} ml={2}>
                <Grid className="saveBtnstyle">
                  <Button className="OverridesaveBtn" onClick={addToolData}>
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid mt={4} container gap={2}>
                {toolsList?.map((i) => (
                  <ClickableChips
                    label={i}
                    deleteIcon={(e) => handleDeleteTool(e)}
                    isdelete={true}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels
                    isRequired={false}
                    label="Minimum Qualifications"
                  />
                  <SearchAutoComplete
                    optionsData={allQualType ?? []}
                    isKeyValuePair={true}
                    value={minQualification}
                    onChange={(e, value) => setMinQualification(value)}
                    // isLoading={isLoading}
                    typeOnchange={(e)=>console.log()}
                    autoCompClear={autoCompMinQualification}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={1.5} md={9} sm={12} xs={12} mt={3} ml={2}>
                <Grid className="saveBtnstyle">
                  <Button
                    className="OverridesaveBtn"
                    onClick={addMinQualificationPData}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid mt={4} container gap={2}>
                {minQualificationList?.map((i) => (
                  <ClickableChips
                    label={i}
                    deleteIcon={(e) => handleDeleteQulification(e)}
                    isdelete={true}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels
                    isRequired={true}
                    label="Minimum Work Experience"
                  />
                  <SelectMenu
                    disabledDefault={false}
                    countries={true}
                    listData={MIN_EXP}
                    value={minExp}
                    onchangehandler={(e) => onChangeMinExp(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {minExpError && !minExp && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={true} label="Employment Type" />
                  <SelectMenu
                    disabledDefault={false}
                    countries={true}
                    listData={EMP_TYPE}
                    value={empType}
                    onchangehandler={(e) => onChangeEmpType(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {empTypeError && !empType && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid mt={8}>
              <h1 className="SubHeading">Job Location </h1>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <InputField
                  type="text"
                  label="Country"
                  placeholder="Enter Country"
                  value={country}
                  onChange={(e) => onChangeCountry(e)}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {countryError && !country && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <InputField
                  type="text"
                  label="State"
                  placeholder="Enter State"
                  value={state}
                  onChange={(e) => onChangeState(e)}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {stateError && !state && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <InputField
                  type="number"
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  value={zipCode}
                  onChange={(e) => onChangeZipCode(e)}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <Grid item container lg={12} textAlign={"left"}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                {zipCodeError && !zipCode && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={false} label="Remote Acceptable" />
                  <SelectMenu
                    disabledDefault={false}
                    countries={true}
                    listData={REMOTE_ACPT}
                    value={remoteAcpt}
                    onchangehandler={(e) => onChangeRemoteAcpt(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={false} label="Compensation Range" />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    <RadioButton
                      checked={accountType === "Hourly"}
                      value="Hourly"
                      label="Hourly"
                      onChange={(e) => handleAccountType(e.target.value)}
                    ></RadioButton>
                    <RadioButton
                      checked={accountType === "Yearly"}
                      value="Yearly"
                      label="Yearly"
                      onChange={(e) => handleAccountType(e.target.value)}
                    ></RadioButton>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            
            <Grid item container lg={12} mt={2}>
              <Grid item lg={9} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={false} label="Currency" />
                  <SelectMenu
                    disabledDefault={false}
                    countries={false}
                    listData={CURRENCYCODE.map((currency) => {
                      return { key: currency, value: currency };
                    })}
                    value={currency}
                    onchangehandler={(e) => onChangeCurrency(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={0.5} spacing={2}>
              <Grid item lg={4.5} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="selectLabel">
                  <FormLabels isRequired={false} label="Pay Range" />
                  <InputField
                    type="text"
                    placeholder="Minimum"
                    value={minPayrange}
                    onChange={(e) => onChangeMinPayrange(e)}
                    isRequired={true}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={4.5} xs={12} sm={12} md={6} mt={3}>
                <InputField
                  type="text"
                  placeholder="Maximum"
                  value={maxPayrange}
                  onChange={(e) => onChangeMaxPayRange(e)}
                  isRequired={true}
                />
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={0.5} spacing={2}>
              <Grid item lg={8} xs={12} sm={12} md={6}>
                <span className="toggleStyle">
                  <h3 className="lebelText">Job Status :</h3>{" "}
                  {
                    <Grid lg={5} xs={12} sm={6} md={4}>
                      <FormControl
                        component="fieldset"
                        variant="standard"
                        className="clientswitch"
                      >
                        <FormGroup>
                          <FormControlLabel
                          control={
                              <ISwitch
                                checked={checked}
                                onChange={handleChange}
                                name="status"
                              />
                            }
                            label={checked ? "Active" : "Inactive"}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  }
                </span>
              </Grid>
            </Grid>

            <Grid item container lg={12} mt={2} textAlign={"left"}>
              <Grid item lg={2} xs={12} sm={12} md={6}>
                <Grid className="saveBtnstyle">
                  <Button
                    className="OverridesaveBtn"
                    onClick={(e) => onClickASave(e)}
                  >
                    {editMode ? "Update" : "Save"}
                  </Button>
                </Grid>
              </Grid>
              {!saveAsNewJoB && editMode && (
                <Grid item lg={4} xs={12} sm={12} md={6}>
                  <Grid className="saveBtnstyle">
                    <Button
                      className="OverridesaveBtn"
                      onClick={(e) => onClickASave(e)}
                    >
                      Save as a New Job
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid item lg={2} xs={12} sm={12} md={6}>
                <Grid className="cancelBtnstyle">
                  <Button
                    className="overrideCancelbutton"
                    onClick={() => history(-1)}
                    label="Cancel"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className="right-section">
            <Grid item container lg={12}>
              <Grid item lg={12} xs={12} sm={12} md={6} pl={4}>
                <div className="subHeadingContainer">
                  <div className="jobSubHeading">Posted Jobs</div>
                  <div className="viewJosStyle" onClick={handleClickViewJob}>
                    View Jobs
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item container lg={12} mt={3} pl={4}>
              <Grid item lg={12} xs={12} sm={12} md={6}>
                <JobChip
                  staticCardData={true}
                  cardData={postedJobDatas}
                  selectedVehicleAccident={days}
                  cardClickHandler={cardOnClick}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default CreateJob;
