import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustry } from "../../redux/actions/searchAction";
import Loader from "../../library/common/Loader";
import LineChart from "../../library/custom/chart/LineChart";
import { FormControl, Grid, RadioGroup } from "@mui/material";
import RadioButton from "../../library/custom/radioButton/RadioButton";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../library/custom/selectMenu/SelectMenu";
import { TIME_PERIOD } from "../../constant/constant";
import { getAdminCount } from "../../redux/actions/adminAction";

const AnalyticsContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getAdminCount({
        cat: "PM",
        type: "individual",
      })
    );
  }, []);

  const adminCount = useSelector(
    (state) => state?.getAdminCount?.data?.response?.result
  );
  console.log(adminCount);
  const isLoading = useSelector((state) => state?.industry?.isLoading);
  const data = [
    [1, 30, 7, 5, 14, 0],
    [2, 50, 26, 22, 36, 8],
    [3, 24, 17, 15, 12, 1],
    [4, 18, 5, 6, 9, 0],
    [5, 11, 1, 2, 5, 0],
    [6, 15, 11, 6, 20, 0],
    [7, 6, 7, 6, 7, 0],
    [8, 32, 16, 7, 18, 0],
    [9, 3, 2, 2, 4, 0],
    [10, 3, 1, 5, 3, 0],
    [11, 1, 0, 0, 1, 0],
    [12, 38, 18, 15, 25, 2],
  ];
  const [accountType, setaccountType] = useState("individual");
  function handleAccountType(e) {
    setaccountType(e);
    dispatch(
      getAdminCount({
        cat: timePeriod,
        type: e,
      })
    );
  }
  const [timePeriod, settimePeriod] = useState("PM");
  return (
    <div>
      <Grid container>
        <Grid container alignItems={"center"} mt={2}>
          <Grid item lg={3}>
            <FormLabels label={""} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={accountType === "individual"}
                value="individual"
                label="Individual"
                // disabled={editMode ? true : false}
                onChange={(e) => handleAccountType(e.target.value)}
              ></RadioButton>
              <RadioButton
                checked={accountType === "corporate"}
                value="corporate"
                // disabled={editMode ? true : false}
                label="Corporate"
                onChange={(e) => handleAccountType(e.target.value)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
          <Grid item lg={3} textAlign={"left"}>
            <FormControl fullWidth className="selectLabel">
              <FormLabels label="Time Period" />
              <SelectMenu
                disabledDefault={false}
                listData={TIME_PERIOD}
                isKeyValue={true}
                label={"Please Select"}
                value={timePeriod}
                onchangehandler={(e) => {
                  settimePeriod(e.target.value);
                  dispatch(
                    getAdminCount({
                      cat: e.target.value,
                      type: accountType,
                    })
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item lg={9} mt={2}>
          <LineChart data={adminCount ?? []} />
          <p>
            {timePeriod === "CM"
              ? "Cureent Month"
              : timePeriod === "CY"
              ? "Current Year"
              : timePeriod === "CW"
              ? "Current Week"
              : timePeriod === "PM"
              ? "Previous Month"
              : timePeriod === "PW"
              ? "Previous Week"
              : timePeriod === "PSM"
              ? "Previous Six Month"
              : "Previous Year"}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default AnalyticsContainer;
