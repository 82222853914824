import { Slider } from "@mui/material";
import React from "react";
import "./RangeSlider.scss";

const RangeSlider = ({ searchUserData, salary }) => {
  let meanPos = 50;

  let range = "value not specified";
  let minSal = 0;
  let maxSal = 0;
  // if (salary?.meanSalary) {
  switch (salary?.salary_range) {
    case "r1":
      minSal = 0;
      maxSal = 500000;
      break;
    case "r2":
      minSal = 500000;
      maxSal = 1000000;
      break;
    case "r3":
      minSal = 1000000;
      maxSal = 1500000;
      break;
    case "r4":
      minSal = 1500000;
      maxSal = 2000000;
      break;
    case "r5":
      minSal = 2000000;
      maxSal = 2500000;
      break;
    case "r6":
      minSal = 2500000;
      maxSal = 3000000;
      break;
    case "r7":
      minSal = 3000000;
      maxSal = 3500000;
      break;
    case "r8":
      minSal = 3500000;
      maxSal = 4000000;
      break;
    case "r9":
      minSal = 4000000;
      maxSal = 40000000;
      break;
    case "r10":
      minSal = 4500000;
      maxSal = 45000000;
      break;
    case "r11":
      minSal = 5000000;
      maxSal = 50000000;
      break;
    case "r12":
      minSal = 5500000;
      maxSal = 6000000;
      break;
    case "r13":
      minSal = 6000000;
      maxSal = 6500000;
      break;
    case "r14":
      minSal = 6500000;
      maxSal = 7000000;
      break;
    case "r15":
      minSal = 7000000;
      maxSal = 7500000;
      break;
    case "r16":
      minSal = 7500000;
      maxSal = 8000000;
      break;
    case "r17":
      minSal = 8000000;
      maxSal = 8500000;
      break;
    case "r18":
      minSal = 8500000;
      maxSal = 9000000;
      break;
    case "r19":
      minSal = 9000000;
      maxSal = 9500000;
      break;
    case "r20":
      minSal = 9500000;
      minSal = 10000000;
      break;
    default:
      break;
  }
  // if (salary?.meanSalary > salary?.amount) {
  meanPos = meanPos + (salary?.meanSalary - salary?.amount) / 20000;
  range = `${(salary?.amount / 1000000).toString().slice(0, 3)}M - ${(
    salary?.amount / 1000000 +
    0.1
  )
    .toString()
    .slice(0, 3)}M`;
  // }
  // else if (salary?.amount > salary?.meanSalary) {
  meanPos = meanPos - (2 * (salary?.amount - salary?.meanSalary)) / 20000;
  range = `${(salary?.amount / 1000000).toString().slice(0, 3)}M - ${(
    salary?.amount / 1000000 +
    0.1
  )
    .toString()
    .slice(0, 3)}M`;
  // }
  if (meanPos < 0) {
    meanPos = 0;
  } else if (meanPos > 97) {
    meanPos = 98;
  }
  // }
  
  // const findSalaryRange = SALARY_RANGE_INR.find(
  //   (x) =>
  //     x?.rowNumner ==
  //     salary?.salary_range
  // );
  //   return `${findSalaryRange?.value}`;
  // }
  return (
    <Slider
      className="slider"
      valueLabelDisplay="auto"
      // value={[parseInt(salary?.amount), parseInt(salary?.meanSalary)]}
      value={
        // searchUserData?.profile?.work_experience?.current_salary
        //   ?.showCompensations
        //   ? 
          [salary?.mySalary, parseInt(salary?.meanSalary)]
          // : 
          // [parseInt(salary?.meanSalary)]
      }
      step={10}
      max={maxSal}
      min={0}
      style={{ color: "#26B893" }}
    />
  );
};

export default RangeSlider;
