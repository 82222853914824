import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import FormHelperText from "@mui/material/FormHelperText";
import "./selectMenu.scss";
import { InputAdornment, OutlinedInput, TextField } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
export default function SelectMenu({
  listData,
  onchangehandler,
  name,
  value,
  placeholder,
  disabled,
  disabledDefault = true,
  isError = false,
  errorMsg = "required",
  isKeyValue = true,
  label,
  countries,
  functions,
  roles,
  id,
  isJobId,
}) {
  return (
    <div className="overideSelectMenu">
      <TextField
        // label={label}
        placeholder={"Select"}
        select
        fullWidth
        // size="small"
        className="selectBox"
        onChange={(e) => onchangehandler(e)}
        defaultValue={"0"}
        value={value}
        disabled={disabled}
        itemID="xyz"
        name={name}
        id={id ? id : "defaultId"}
      >
        {label && (
          <MenuItem disabled value="0">
            {label}
          </MenuItem>
        )}
        {listData &&
          listData.map((data) => {
            return (
              <MenuItem
                className="menuItem"
                key={
                  countries
                    ? data
                    : functions
                    ? data.name
                    : isKeyValue
                    ? data.key
                    : isJobId
                    ? data.jobid
                    : data.articleCatSubcatId
                }
                value={
                  countries
                    ? data
                    : functions
                    ? data.name
                    : isKeyValue
                    ? data.key
                    : isJobId
                    ? data.jobid
                    : data.articleCatSubcatId
                }
              >
                {countries
                  ? data
                  : functions
                  ? data.name
                  : isKeyValue
                  ? data.value
                  : isJobId
                  ? data.jobid
                  : data.title}
              </MenuItem>
            );
          })}
      </TextField>
      {isError && (
        <FormHelperText className="errorText">{errorMsg}</FormHelperText>
      )}
    </div>
  );
}
