import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import TopCommunity from "../../../assets/topCommunityIcon.svg";
import "./TrendingCommunityCard.scss";
import JavascriptIcon from "@mui/icons-material/Javascript";
import WysiwygTwoToneIcon from "@mui/icons-material/WysiwygTwoTone";
import TerminalTwoToneIcon from "@mui/icons-material/TerminalTwoTone";
import WebhookTwoToneIcon from "@mui/icons-material/WebhookTwoTone";
import AppShortcutTwoToneIcon from "@mui/icons-material/AppShortcutTwoTone";
import { AppShortcutTwoTone } from "@mui/icons-material";
// import flutter from "../../../assets/flutter.svg";
// import jsIcon from "../../../assets/jsIcon.svg"
// import reactIcon from "../../../assets/reactIcon.png";
// import techIcon from "../../../assets/techIcon.png";
import communityCommonIcon from "../../../assets/communityCommonIcon.svg";

export default function TrendingCommunityCard({
  alt,
  label1 = "Audio Engineering",
  label2 = "234 new users",
  label3 = "text",
  handleClick,
  istop,
  icon,
}) {
  // const communityIcons = [
  //   // { icon: flutter },
  //   { icon: reactIcon },
  //   { icon: techIcon },
  // ];
  const a = Math.floor(Math.random() * (5 - 0 + 1)) + 0;

  return (
    <div className="TrendingCommunityCardContainer">
      <Card className="Card">
        <div className="cardImage">
          {/* <CardMedia component="img" image={icon} alt={TopCommunity} /> */}
          <img src={icon ? icon : communityCommonIcon} alt=""></img>
        </div>
        <div>
          <CardContent
            className="columns"
            onClick={(parmdata) => handleClick(label1, label2, label3)}
          >
            <Typography className="listViewName">{label1}</Typography>
            <Typography className="listViewRole">
              {label2 > 1 ? label2 + " members" : label2 + " member"}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
}
