import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
import "./InvitePopover.scss";
import { useState } from "react";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function InvitePopover(props) {
  const [isEmail, setisEmail] = useState(false);
  const [isFirstName, setisFirstName] = useState(false);
  const [isLastName, setisLastName] = useState(false);
  function mapErrors() {
    let error = false;
    if (
      props.formvalue.firstName.trim() === "" ||
      props.formvalue.firstName.trim() === null ||
      props.formvalue.firstName.trim() === undefined
    ) {
      setisFirstName(true);
      error = true;
    }
    if (
      props.formvalue.lastName.trim() === "" ||
      props.formvalue.lastName.trim() === null ||
      props.formvalue.lastName.trim() === undefined
    ) {
      setisLastName(true);
      error = true;
    }

    let validEMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      !props.formvalue.email.match(validEMail) ||
      props.formvalue.lastName.trim() === "" ||
      props.formvalue.lastName.trim() === null ||
      props.formvalue.lastName.trim() === undefined
    ) {
      setisEmail(true);
      error = true;
    }
    return error;
  }
  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="dialogBoxContainer"
      >
        <DialogContent className="Dioerrimg">
          <Grid container>
            <Grid mt={2} item lg={12} xs={12} md={12} sm={12}>
              <InputField
                fullWidth={true}
                type="text"
                label="First Name"
                placeholder=""
                name={"firstName"}
                onChange={(e) => {
                  {
                    setisFirstName(false);
                    props.handleOnChange(e);
                  }
                }}
                value={props.formvalue.firstName}
              />
              <p className={"errorMSg"}>
                {isFirstName ? "Please enter correct text" : ""}
              </p>
            </Grid>
            <Grid mt={2} item lg={12} xs={12} md={12} sm={12}>
              <InputField
                fullWidth={true}
                type="text"
                label="Last Name"
                placeholder=""
                name={"lastName"}
                onChange={(e) => {
                  {
                    setisLastName(false);
                    props.handleOnChange(e);
                  }
                }}
                value={props.formvalue.lastName}
              />

              <p className={"errorMSg"}>
                {isLastName ? "Please enter correct text" : ""}
              </p>
            </Grid>
            <Grid mt={2} item lg={12} xs={12} md={12} sm={12}>
              <InputField
                fullWidth={true}
                type="text"
                label="Email"
                placeholder=""
                name={"email"}
                onChange={(e) => {
                  {
                    setisEmail(false);
                    props.handleOnChange(e);
                  }
                }}
                value={props.formvalue.email}
              />

              <p className="errorMSg">
                {isEmail ? "Please enter correct text" : ""}
              </p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={props.exitHandle}
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              color: "#FF333F",
              textTransform: "capitalize",
            }}
          >
            {props.label1 ? props?.label1 : "No"}
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E3A59",
                textTransform: "capitalize",
              }}
              autoFocus
              onClick={(e) => {
                const error = mapErrors();
                if (!error) props.handleConfirm(e);
              }}
            >
              {props.label2 ? props?.label2 : "Yes"}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
