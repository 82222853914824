import { uploadVideoType } from "../constant/UploadVideoType";

const initialState = {
  flow: "upload video",
  isError: false,
  isLoading: false,
};

export const createUploadVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadVideoType.POST_UPLOAD_VIDEO_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case uploadVideoType.POST_UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case uploadVideoType.POST_UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case uploadVideoType.POST_UPLOAD_VIDEO_CANCEL:
      return {
        ...state,
        status: "PENDING",
        data: "",
        isError: false,
        isLoading: true,
      };
    case uploadVideoType.CLEAR:
      return {
        ...state,
        data: "",
      };
    default:
      return state;
  }
};

export const createUploadContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadVideoType.POST_UPLOAD_CONTENT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case uploadVideoType.POST_UPLOAD_CONTENT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case uploadVideoType.POST_UPLOAD_CONTENT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
