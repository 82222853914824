import React from "react";
import parse from "html-react-parser";

import "./resumeTemplate.scss";

export default function ResumeTemplate({
  basicDetails,
  profile,
  Pskills,
  Oskills,
  work_experience,
  board_experience,
  certifications,
  education,
}) {
  let workExpRows = [];
  for (let i = 0; i < work_experience?.orgNames?.length; i++) {
    workExpRows.push(
      <tr key={`work_experience-${i}`} className="tableP">
        <td>
          <p>{`${work_experience.orgNames[i]}`}</p>
          <p className="job-title">
            {work_experience.role && work_experience.role
              ? work_experience.role[i]
              : null}
            {work_experience.designations && work_experience.designations.length
              ? `, ${work_experience.designations[i]}`
              : null}
            {work_experience.tillDate[i]
              ? `, ${work_experience.startM[i].substring(0, 3)} ${
                  work_experience.startY[i]
                } - Present`
              : `, ${work_experience.startM[i].substring(0, 3)} ${
                  work_experience.startY[i]
                } - `}
            {work_experience.endM[i] === "Currently Working" ||
            work_experience.endY[i] === "Currently Working" ? (
              "Present"
            ) : (
              <span>
                {work_experience.endM[i].substring(0, 3)}{" "}
                {work_experience.endY[i]}
              </span>
            )}
          </p>
          {work_experience.rnrs[i].length ? (
            <span className="quills">
              {work_experience.rnrs &&
              work_experience.rnrs.length &&
              work_experience.rnrs[i] &&
              work_experience.rnrs[i].length
                ? parse(work_experience.rnrs[i])
                : null}
            </span>
          ) : null}
        </td>
      </tr>
    );
  }

  let boardExpRows = [];
  for (let i = 0; i < board_experience?.boardName?.length; i++) {
    boardExpRows.push(
      <tr key={`board_experience-${i}`}>
        <td>
          {board_experience &&
            board_experience.boardType.length &&
            board_experience.boardName.length &&
            board_experience.boardStartM.length &&
            board_experience.boardStartY.length &&
            board_experience.boardEndM.length &&
            board_experience.boardEndY.length &&
            `${board_experience.boardName[i]}, ${
              board_experience.boardType[i]
            }, ${board_experience.boardStartY[i]} - ${
              board_experience.stillMember[i]
                ? "Present"
                : `${board_experience.boardEndY[i]}`
            }`}
        </td>
      </tr>
    );
  }

  let educationRows = [];
  for (let i = 0; i < education?.degree?.length; i++) {
    educationRows.push(
      <tr key={`education-${i}`}>
        <td>
          <p className="job-title">
            {`${education.degree[i]}, ${education.special[i]}, ${education.institute[i]}`}
            , {`${education.endY[i]}`}
          </p>
        </td>
      </tr>
    );
  }

  let certificationRows = [];
  for (let i = 0; i < certifications?.name?.length; i++) {
    certificationRows.push(
      <tr key={`certification-${i}`}>
        <td>
          <p className="job-title">
            {certifications.name[i]} :{" "}
            {certifications.endM[i] === "Skip"
              ? ""
              : certifications.endM[i].substring(0, 3)}{" "}
            {certifications.endY[i] === "Skip" ? "" : certifications.endY[i]}
          </p>
        </td>
      </tr>
    );
  }

  return (
    <div id="TempHolder" className="tempHeader1">
      <div style={{ fontSize: "24px", fontWeight: "500", textAlign: "center" }}>
        {`${basicDetails?.first_name} ${basicDetails?.last_name}`}
      </div>
      <div style={{ textAlign: "center" }}>
        {`${basicDetails.city ? basicDetails.city : ""},${
          basicDetails.state !== "" ? basicDetails.state : ""
        } ${basicDetails.zipcode ? basicDetails.zipcode : ""}, ${
          basicDetails.country_name !== "-1" ? basicDetails.country_name : ""
        }`}
        <br />
        {basicDetails.dial_code == -1 ? "" : basicDetails.dial_code || ""}{" "}
        {basicDetails.phone || ""}
        <br />
        {`${basicDetails.email ? basicDetails.email : ""}`}{" "}
        {profile?.social_links?.linkedin?.length &&
        profile?.social_links?.linkedin?.length.linkedin
          ? ` | ${profile?.social_links?.linkedin?.length.linkedin}`
          : null}{" "}
        {profile?.social_links?.linkedin?.length &&
        profile?.social_links?.linkedin?.length.twitter
          ? ` | ${profile?.social_links?.linkedin?.length.twitter}`
          : null}
      </div>

      <h3 style={{ textAlign: "left" }}>
        {profile?.work_experience?.designations?.length > 0
          ? profile?.work_experience?.designations[0]
          : null}
      </h3>
      <p style={{ margin: "20px 0px" }}>
        <h4 style={{ textAlign: "left" }}>Summary</h4>
        {profile?.personal_details?.aboutMe
          ? parse(profile?.personal_details?.aboutMe)
          : " "}
      </p>

      <div>
        <h4>Primary skill: </h4>
        <p style={{ marginBottom: "15px" }}>
          {Pskills?.map((skill) => skill).join("|")}
        </p>
      </div>
      <div>
        <h4>Other/Technical Skills:: </h4>
        <p style={{ marginBottom: "15px" }}>
          {Oskills?.map((skill) => skill).join("|")}
        </p>
      </div>
      {workExpRows.length ? (
        <div>
          <h4>Professional Experience</h4>
          <table style={{ width: "100%" }}>
            <tbody>{workExpRows}</tbody>
          </table>
        </div>
      ) : null}

      {board_experience?.boardName?.length ? (
        <div>
          <h4>Board Experience</h4>
          <table style={{ width: "100%" }}>
            <tbody>{boardExpRows}</tbody>
          </table>
        </div>
      ) : null}

      {educationRows.length ? (
        <div>
          <h4>Education</h4>
          <table style={{ width: "100%" }}>
            <tbody>{educationRows}</tbody>
          </table>
        </div>
      ) : null}
      {certificationRows.length ? (
        <div>
          <h4>Certifications</h4>
          <table style={{ width: "100%" }}>
            <tbody>{certificationRows}</tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
