import React from "react";
import FormLabel from "@mui/material/FormLabel";
import "./FormLables.scss";

export default function FormLabels({ label, isRequired }) {
  return (
    <div className="overideLables">
      <FormLabel id="demo-row-radio-buttons-group-label">
        <div className="formLabel">
          {label} {isRequired && <span className="required">*</span>}
        </div>
      </FormLabel>
    </div>
  );
}
