import { Grid } from "@mui/material";
import React from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./PasswordField.scss";
const PasswordField = ({
  onChange,
  showPassword,
  value,
  placeholder = "Enter your Password",
  onFocus = () => {},
  onBlur = () => {},
  onKeyUp = () => {},
  onEyeIconClick = () => {},
}) => {
  return (
    <div className="overridePAsswordField">
      <Grid container className="searchcaseid">
        <input
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          onChange={(e) => {
            onChange(e);
          }}
          id="password"
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
        />

        {showPassword ? (
          <Visibility
            onClick={onEyeIconClick}
            className="showIcon"
            style={{ color: "#778DFF" }}
          />
        ) : (
          <VisibilityOff
            onClick={onEyeIconClick}
            className="showIcon"
            style={{ color: "#778DFF" }}
          />
        )}
      </Grid>
    </div>
  );
};

export default PasswordField;
