import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./errorPageStyle.scss";
import matchupITLogo from "../../assets/Landing/matchupITLogo.png";

import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const [seconds, setSeconds] = useState(5);
  // const navigate = useNavigate();
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      window.location.href = "/dashboard";
    }
  });

  return (
    <div className="overideErrorPage">
      <img src={matchupITLogo} alt="logo" className="img-fluid" />

      <div>
        <h1 className="header">Something went wrong</h1>
      </div>
      <div className="redirect">
        <br></br>
        <p> You will be redirected to Home Screen in</p>
        <span>{seconds} second</span>
      </div>
    </div>
  );
};

export default ErrorPage;
