import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "../component/Login";
import { getUserDetails, login } from "../../../redux/actions/loginAction";
import { useNavigate } from "react-router-dom";
import * as SignupAction from "../../../redux/actions/SignupAction";
import ReactGA from "react-ga4";
import { GA_KEY } from "../../../constant/constant";
ReactGA.initialize(GA_KEY);
const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function userLogin(email, password, accountType) {
    ReactGA.event({
      category: "login",
      action: "User Login",
      label: accountType,
    });
    dispatch(
      login({ account_type: accountType, email: email, password: password })
    );
  }
  const userData = useSelector((state) => state.login);
  useEffect(() => {
    if (
      !userData.isLoading &&
      userData.status === "SUCCESS" &&
      sessionStorage.getItem("token")
    ) {
      navigate("/dashboard");
      dispatch(getUserDetails("dcads"));
    }
  }, [userData]);
  const signupWithGoogle = (data) => {
    dispatch(SignupAction.signupWithGoogle(data));
  };
  const signupWithGoogleData = useSelector(
    (state) => state.signupWithGoogleData
  );

  useEffect(() => {
    if (
      !signupWithGoogleData.isLoading &&
      signupWithGoogleData.status === "SUCCESS" &&
      sessionStorage.getItem("token")
    ) {
      navigate("/dashboard");
      dispatch(getUserDetails("dcads"));
    }
  }, [signupWithGoogleData]);

  return (
    <div>
      <Login
        userLogin={userLogin}
        userData={userData}
        error={userData?.error?.data}
        signupWithGoogle={signupWithGoogle}
      />
    </div>
  );
};

export default LoginContainer;
