import { Grid } from "@mui/material";
import { React, useState } from "react";
import "../../dashboard/component/Dashboard.scss";
import Card from "@mui/material/Card";
import "./recentpost.scss";
import msgsideimag from "./../../../assets/messagesSideIcon.svg";
import like from "./../../../assets/like.svg";
import No_user from "./../../../assets/noUser.png";
import nolike from "./../../../assets/nolike.svg";
import Loader1 from "../../../library/common/Loader1";

import { ConvertDate, formatTime } from "../../../utils/helper";
const RecentPosts = ({
  PostContent,
  profilePic,
  name,
  createdOn,
  ischild,
  handlecommentClick,
  id,
  commnetlength,
  handleAddReactTopost,
  postEmojis,
  postEmojisComment,
  commentid,
  postid,
  isMember,
  title,
  deletepost,
  myPost,
  isloadingGetPost,
  correntcommentOrpostId,
  isloadinglike,
}) => {
  const likecount = postEmojis?.length;
  const commentLike = postEmojisComment?.length;
  const date = ConvertDate(createdOn);
  const [showFullText, setShowFullText] = useState(false);

  function toggleFullText() {
    setShowFullText(!showFullText);
  }
  return (
    <div className="overrideDashboard">
      <Grid mt={2} container>
        <div className="CommunityCardContainer" onClick={toggleFullText}>
          <Card className={`CommunityCard ${ischild ? "child" : ""}`}>
            <div className="CommunityName0" onClick={toggleFullText}>
              <div className="imageStyle">
                <img src={profilePic ? profilePic : No_user} alt="post image" />
              </div>
              <div className="NameDateStyle">
                <div className="nameStyle">{name}</div>
                <div className="DateTimeContainer">
                  <div className="posttime">{formatTime(createdOn)} </div>
                  <div className="postdate">{date} </div>
                </div>
              </div>
              {ischild == false && isMember && myPost ? (
                <div
                  onClick={(e) => deletepost(e, id, postid, commentid)}
                  className="deleteposticn"
                >
                  ...
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="communityTitleStyle">{title}</div>
            <div dangerouslySetInnerHTML={{ __html: PostContent }}></div>

            <div className="commentmaindiv0">
              {ischild == false && isMember && (
                <div
                  className="commentmaindiv"
                  onClick={(e) => handlecommentClick(e, id)}
                >
                  <div className="leftmsgimg">
                    <img src={msgsideimag} alt="post image" />
                  </div>
                  <div className="rightcomment">
                    <p>{commnetlength} Comments</p>
                  </div>
                </div>
              )}
              <div className="likemaindiv">
                <div
                  className="leftlikeimg"
                  onClick={(e) =>
                    handleAddReactTopost(e, id, postid, commentid)
                  }
                >
                  {likecount > 0 || commentLike > 0 ? (
                    <img src={like} alt="post image" />
                  ) : (
                    <img src={nolike} alt="post image" />
                  )}
                </div>
                <div className="rightlikes">
                  <p>
                    {likecount ? likecount : commentLike} Likes{" "}
                    {isloadingGetPost &&
                      isloadinglike &&
                      (correntcommentOrpostId === commentid ||
                        correntcommentOrpostId === id) && <Loader1 />}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Grid>
    </div>
  );
};

export default RecentPosts;
