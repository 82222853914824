import { loginType } from "../constant/loginType";

export const login = (data) => ({
  type: loginType.POST_LOGIN_PENDING,
  data,
});

export const loginSuccess = (data) => ({
  type: loginType.POST_LOGIN_SUCCESS,
  data,
});

export const loginFailure = (error) => ({
  type: loginType.POST_LOGIN_FAILURE,
  error,
});



export const loginClear = (data) => ({
  type: loginType.POST_LOGIN_CLEAR,
  data,
});



export const loginAdmin = (data) => ({
  type: loginType.POST_ADMIN_LOGIN_PENDING,
  data,
});

export const loginAdminSuccess = (data) => ({
  type: loginType.POST_ADMIN_LOGIN_SUCCESS,
  data,
});

export const loginAdminFailure = (error) => ({
  type: loginType.POST_ADMIN_LOGIN_FAILURE,
  error,
});

export const getUserDetails = (data) => ({
  type: loginType.GET_USER_PROFILE_PENDING,
  data,
});

export const getUserDetailsSuccess = (data) => ({
  type: loginType.GET_USER_PROFILE_SUCCESS,
  data,
});

export const getUserDetailsFailure = (error) => ({
  type: loginType.GET_USER_PROFILE_FAILURE,
  error,
});

export const getRecentSearches = (data) => ({
  type: loginType.GET_RECENT_SEARCHES_PENDING,
  data,
});

export const getRecentSearchesSuccess = (data) => ({
  type: loginType.GET_RECENT_SEARCHES_SUCCESS,
  data,
});

export const getRecentSearchesFailure = (error) => ({
  type: loginType.GET_RECENT_SEARCHES_FAILURE,
  error,
});

export const getForgotPasswordOTP = (data) => ({
  type: loginType.GET_FORGOT_PASSWORD_OTP_PENDING,
  data,
});

export const getForgotPasswordOTPSuccess = (data) => ({
  type: loginType.GET_FORGOT_PASSWORD_OTP_SUCCESS,
  data,
});

export const getForgotPasswordOTPFailure = (error) => ({
  type: loginType.GET_FORGOT_PASSWORD_OTP_FAILURE,
  error,
});

export const putResetPassword = (data) => ({
  type: loginType.PUT_RESET_PASSWORD_PENDING,
  data,
});

export const putResetPasswordSuccess = (data) => ({
  type: loginType.PUT_RESET_PASSWORD_SUCCESS,
  data,
});

export const putResetPasswordFailure = (error) => ({
  type: loginType.PUT_RESET_PASSWORD_FAILURE,
  error,
});

export const clearData = () => ({
  type: loginType.CLEAR_DATA,
  data: "",
});
