import { getApiUrl } from "../config";
import { get, post, put } from "./axiosRequest";

export const recoveryEmailVerify = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/recovery-verify?email=${data.email}&otp=${data.otp}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const deactivateAccount = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/activation-toggle`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const changePasswordService = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/${localStorage.getItem(
    "id"
  )}/password/change`;
  try {
    return await put(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const paymentDetails = async ({}) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/payments/get-payment-details`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateEmail = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/update-email`;
  try {
    return await put(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const addCorporateMemeber = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/corporate/add-corporate-member`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const removeCorporateMemeber = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/corporate/remove-corporate-member`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
