import { useEffect, useState } from "react";
import { Button, Grid, RadioGroup } from "@mui/material";
import matchupITLogo from "../../../assets/Landing/matchupITLogo.png";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import { InputField } from "../../../library/custom/textBox/InputField";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../constant/routeContant";
import SignuPic from "../../../assets/SignuPic.jpg";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import PasswordField from "../../../library/custom/passwordField/PasswordField";

const Login = ({ userLogin, error, signupWithGoogle }) => {
  const goToLogin = () => {
    window.location.href = "/signup";
  };
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [accountType, setaccountType] = useState("individual");
  const [emailEmptyError, setEmptyEmailError] = useState(false);

  function handleAccountType(e) {
    setaccountType(e);
  }
  return (
    <div className="overideLogin">
      <Grid container>
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
          item
          lg={5}
          xs={12}
          sm={6}
          md={6}
        >
          <img src={SignuPic} className="leftPic" alt="no logo" />
        </Grid>
        <Grid
          className="formCon"
          container
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"left"}
          item
          lg={6}
          sm={12}
          md={6}
          p={5}
        >
          <Grid item sm={9} xs={12} md={12} lg={12}>
            <Grid
              container
              justifyContent={"left"}
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              mt={1}
            >
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <img
                  src={matchupITLogo}
                  style={{ width: "240px", height: "102px", cursor: "pointer" }}
                  className="light-logo"
                  alt="no logo"
                  onClick={() => navigate("/")}
                />
              </Grid>

              <Grid item lg={12} xs={12} md={12} sm={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "550",
                    fontSize: "36px",
                    margin: "0px",
                    color: "#043133",
                    marginTop: "10px",
                  }}
                >
                  Welcome to MatchupIT
                </h1>
              </Grid>
            </Grid>
            <Grid item lg={10} mt={1}>
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "16px",
                  margin: "0px",
                  color: "#083A50",
                }}
              >
                Account Type
              </h1>
              <FormLabels label={""} />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={accountType === "individual"}
                  value="individual"
                  label="Individual"
                  onChange={(e) => handleAccountType(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={accountType === "corporate"}
                  value="corporate"
                  label="Corporate"
                  onChange={(e) => handleAccountType(e.target.value)}
                ></RadioButton>
              </RadioGroup>
            </Grid>

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid mt={2} item lg={12} md={12} sm={12} xs={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0px",
                    color: "#083A50",
                  }}
                >
                  Email
                </h1>
                <Grid item lg={9.65} md={10.2} sm={12} xs={12}>
                  <InputField
                    placeholder="Enter your Email here"
                    value={mobile}
                    onChange={(e) => {
                      setEmptyEmailError(false);
                      setMobile(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid mt={2} item lg={12} md={12} sm={12} xs={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0px",
                    color: "#083A50",
                  }}
                >
                  Password
                </h1>

                <Grid item lg={9.65} md={10.2} sm={12} xs={12}>
                  <PasswordField
                    onChange={(e) => {
                      setEmptyEmailError(false);
                      setPassword(e.target.value);
                    }}
                    value={password}
                    showPassword={showPassword}
                    onEyeIconClick={() => setShowPassword(!showPassword)}
                  />
                </Grid>
              </Grid>
              <Grid
                onClick={() => navigate(FORGOT_PASSWORD)}
                textAlign={"left"}
                item
                lg={10}
                sm={9}
              >
                <h1
                  className=""
                  style={{
                    border: "none",
                    color: "#5459EA",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password
                </h1>
              </Grid>
              <Grid container mt={0.5} item lg={10}>
                <Grid container justifyContent={"left"} textAlign={"left"}>
                  {!emailEmptyError && error?.err && (
                    <p className="errorMsg">{error.msg}</p>
                  )}
                  {emailEmptyError && (
                    <p className="errorMsg">Invalid Email or Password</p>
                  )}
                </Grid>
                <Grid
                  container
                  sx={{
                    justifyContent: {
                      xs: "left",
                    },
                  }}
                  justifyContent={"center"}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      let validEMail =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      if (mobile.match(validEMail) && password.trim() !== "") {
                        userLogin(mobile, password, accountType);
                      } else setEmptyEmailError(true);
                    }}
                    style={{
                      width: "200px",
                      height: "41px",
                      border: "none",
                      background: "#778DFF",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Grid
                mt={1}
                container
                display={"flex"}
                justifyContent={"left"}
                mr={"15%"}
                sx={{
                  justifyContent: {
                    xs: "left",
                    sm: "left",
                  },
                }}
              >
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    var userObject = jwt_decode(credentialResponse.credential);
                    signupWithGoogle({
                      email: userObject.email,
                      firstName: userObject.given_name,
                      lastName: userObject.family_name,
                      profilePic: userObject.picture,
                      socialId: userObject.sub,
                      socialType: "google",
                    });
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  useOneTap
                />
              </Grid>

              <Grid container>
                <p className="already-signup-txt">
                  Not a MatchupIT Member?{" "}
                  <b className="login" onClick={() => goToLogin()}>
                    Sign Up
                  </b>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
