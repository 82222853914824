import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import { searchType } from "../constant/searchType";
import * as searchAction from "../actions/searchAction";
import {
  getInudstry,
  getPopularSearches,
  getPopularSearchesCorporate,
  getRecentSearchesCorporate,
  postSearchUsers,
  searchMap,
  searchMapCorporate,
  searchProfileCorporate,
  searchUser,
} from "../../services/searchService";

export function* postSearchMapSaga(params) {
  try {
    const { data, status, error } = yield call(searchMap, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      searchAction.postSearchMapSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Search Map Failed" };
    yield put(searchAction.postSearchMapFailure(data));
  }
}

export function* postSearchMapCorporateSaga(params) {
  try {
    const { data, status, error } = yield call(searchMapCorporate, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      searchAction.postSearchMapCorporateSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Search Map Corporate Failed" };
    yield put(searchAction.postSearchMapCorporateFailure(data));
  }
}

export function* postUserSearchSaga(params) {
  try {
    const { data, status, error } = yield call(postSearchUsers, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      searchAction.postUserSearchSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Search Map Failed" };
    yield put(searchAction.postUserSearchFailure(data));
  }
}

export function* postSearchUserSaga(params) {
  try {
    const { data, status, error } = yield call(searchUser, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      searchAction.postSearchUserSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Search User Failed" };
    yield put(searchAction.postSearchUserFailure(data));
  }
}

export function* getPopularSearchesSaga(params) {
  try {
    const { data, status, error } = yield call(getPopularSearches, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      searchAction.getPopularSearchesSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Popular Searches Failed" };
    yield put(searchAction.getPopularSearchesFailure(data));
  }
}

export function* getPopularSearchesCorporateSaga(params) {
  try {
    const { data, status, error } = yield call(
      getPopularSearchesCorporate,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      searchAction.getPopularSearchesCorporateSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Popular Searches Corporate Failed" };
    yield put(searchAction.getPopularSearchesCorporateFailure(data));
  }
}

export function* getRecentSearchesCorporateSaga(params) {
  try {
    const { data, status, error } = yield call(
      getRecentSearchesCorporate,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      searchAction.getRecentSearchesCorporateSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Top Communities Failed" };
    yield put(searchAction.getRecentSearchesCorporateFailure(data));
  }
}

export function* getIndustrySaga(params) {
  try {
    const { data, status, error } = yield call(getInudstry, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      searchAction.getIndustrySuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Industries Failed" };
    yield put(searchAction.getIndustryFailure(data));
  }
}

export function* postSearchCorporateProfileSaga(params) {
  try {
    const { data, status, error } = yield call(searchProfileCorporate, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      searchAction.postSearchCorporateProfileSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Search Corporate Profile Failed" };
    yield put(searchAction.postSearchCorporateProfileFailure(data));
  }
}

const mySearchSaga = [
  takeLatest(searchType.POST_SEARCH_MAP_PENDING, postSearchMapSaga),
  takeLatest(
    searchType.POST_SEARCH_MAP_CORPORATE_PENDING,
    postSearchMapCorporateSaga
  ),
  takeLatest(searchType.POST_SEARCH_USER_PENDING, postSearchUserSaga),
  takeLatest(
    searchType.POST_CORPORATE_PROFILE_SEARCH_PENDING,
    postSearchCorporateProfileSaga
  ),
  takeLatest(searchType.GET_POPULAR_SEARCHES_PENDING, getPopularSearchesSaga),
  takeLatest(
    searchType.GET_RECENT_SEARCHES_CORPORATE_PENDING,
    getRecentSearchesCorporateSaga
  ),
  takeLatest(
    searchType.GET_POPULAR_SEARCHES_CORPORATE_PENDING,
    getPopularSearchesCorporateSaga
  ),
  takeLatest(searchType.POST_USER__SEARCH_PENDING, postUserSearchSaga),
  takeLatest(searchType.GET_INDUSTRY_PENDING, getIndustrySaga),
];

export default mySearchSaga;
