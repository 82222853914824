import { Grid } from "@mui/material";
import React from "react";
import { InputField } from "../../../library/custom/textBox/InputField";
import Buttons from "../../../library/custom/button/Button";
const SocialHandleForm = ({
  socialLinks,
  setSocialLinks,
  submitSocialDetails,
  setUnsavedChanges,
  cancelForm,
}) => {
  return (
    <>
      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Add Instagram Profile"
          placeholder="https://"
          value={socialLinks.instagram}
          onChange={(e) => {
            setSocialLinks({
              ...socialLinks,
              instagram: e.target.value,
            });
            setUnsavedChanges(true);
          }}
        />
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Add Facebook Profile"
          placeholder="https://"
          value={socialLinks.facebook}
          onChange={(e) => {
            setSocialLinks({
              ...socialLinks,
              facebook: e.target.value,
            });

            setUnsavedChanges(true);
          }}
        />
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Add Twitter Profile"
          placeholder="https://"
          value={socialLinks.twitter}
          onChange={(e) => {
            setSocialLinks({
              ...socialLinks,
              twitter: e.target.value,
            });

            setUnsavedChanges(true);
          }}
        />
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Add LinkedIn Profile"
          placeholder="https://"
          value={socialLinks.linkedin}
          onChange={(e) => {
            setSocialLinks({
              ...socialLinks,
              linkedin: e.target.value,
            });

            setUnsavedChanges(true);
          }}
        />
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Add Github Profile"
          placeholder="https://"
          value={socialLinks.github}
          onChange={(e) => {
            setSocialLinks({
              ...socialLinks,
              github: e.target.value,
            });

            setUnsavedChanges(true);
          }}
        />
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Add Pinterest Profile"
          placeholder="https://"
          value={socialLinks.pinterest}
          onChange={(e) => {
            setSocialLinks({
              ...socialLinks,
              pinterest: e.target.value,
            });

            setUnsavedChanges(true);
          }}
        />
      </Grid>

      <Grid item lg={9} className="editProfileBtn">
        <Grid className="saveBtn">
          <Grid mr={1}>
            <Buttons
              varientContained={true}
              label="Save"
              onClick={() => {
                submitSocialDetails(true);
                // handleTabClick(4);
              }}
            />
          </Grid>
        </Grid>
        <Grid className="saveBtn">
          <Grid>
            <Buttons
              varientContained={true}
              label="Save and Continue"
              onClick={() => {
                submitSocialDetails();
                // handleTabClick(4);
              }}
            />
          </Grid>
        </Grid>
        <Grid className="cancelBtn">
          <Grid>
            <Buttons
              varientContained={true}
              label="Cancel"
              onClick={cancelForm}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SocialHandleForm;
