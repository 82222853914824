import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Error from "../../../assets/error.svg";
import Stack from "@mui/material/Stack";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
// import "./DialogBox.scss";
import TextArea from "../../../library/custom/textArea/TextArea";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import moment from "moment";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function TaggedCommentsPopover(props) {
  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        // onClose={props.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="dialogBoxContainer"
      >
        <DialogContent className="Dioerrimg">
          <Grid>
            <div className="comments-form">
              {props?.taggedComments?.length ? (
                props?.taggedComments.map((i, index) => (
                  <Grid container justifyContent={"space-between"} key={index}>
                    <Grid item>
                      <span>{i.name}:</span>
                      <br />
                      {i.comment}
                    </Grid>
                    <Grid item ml={3}>
                      <span>
                        {moment(i.createdOn).format("MMM Do YYYY, h:mm:ss a")}
                      </span>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <p>
                  No Tags yet..
                  <br />
                  Be the first one to comment.
                </p>
              )}
            </div>
          </Grid>
          <Grid mt={4} lg={11}>
            <FormLabels label="Write a Comment" />
            <TextArea
              id={"comment"}
              value={props.comment}
              placeholder={"Enter here"}
              onChange={(e) => {
                props.handleCommentChange(e);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={props.exitHandle}
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              color: "#FF333F",
              textTransform: "capitalize",
            }}
          >
            {props.label1 ? props?.label1 : "Cancel"}
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E3A59",
                textTransform: "capitalize",
              }}
              autoFocus
              onClick={(e) => props.handleConfirm(e)}
            >
              {props.label2 ? props?.label2 : "Save"}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
