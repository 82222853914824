import { Grid, Slider } from "@mui/material";
import React, { useState } from "react";
import "../../search/component/ViewProfile.scss";
import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import facebookColorIcon from "../../../assets/facebookColorIcon.svg";
import pintrestColorIcon from "../../../assets/pintrestColorIcon.svg";
import linkedInColorIcon from "../../../assets/linkedInColorIcon.svg";
import githubColorIcon from "../../../assets/githubColorIcon.svg";
// import twitterColorIcon from "../../../assets/twitterColorIcon.svg";
import twitterColorIcon from "../../../assets/twitterXlogo.png";
import instaColorIcon from "../../../assets/instaColorIcon.svg";
import CallIcon from "../../../assets/callIcon.svg";
import MailIcon from "../../../assets/mailIcon.svg";
import editBtnIcon from "../../../assets/editBtnIcon.svg";
import mentorBageIcon from "../../../assets/mentorshipBadge.svg";
import createResumeIcon from "../../../assets/createResumeIcon.svg";
import inviteIcon from "../../../assets/inviteIcon.svg";
import Buttons from "../../../library/custom/button/Button";
import CustomizedSlider from "./Slider";
import RangeSlider from "./RangeSlider";
import { useNavigate } from "react-router-dom";
import { CREATE_RESUME } from "../../../constant/routeContant";
import ISwitch from "../../../library/custom/switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import * as editProfileAction from "../../../redux/actions/editProfileAction";
import InvitePopover from "./InvitePopover";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const ProfileHeader = ({ searchUserData, NavigateProfileFrom }) => {
  const dispatch = useDispatch();

  const editProfile = (e) => {
    setChecked(e.target.checked);
    dispatch(
      editProfileAction.putEditProfile({
        basic_details: {
          available_hire: e.target.checked,
        },
      })
    );
  };
  const salaryData = useSelector((state) => state?.getSalary?.data?.msg);
  const [checked, setChecked] = useState(
    searchUserData?.basicDetails?.available_hire
  );
  const navigate = useNavigate();
  const handleClickEditProfile = (event) => {
    NavigateProfileFrom();
  };

  let meanPos = 50;

  let range = "value not specified";
  let minSal = 0;
  let maxSal = 0;
  // if (salary?.meanSalary) {
  switch (
    searchUserData?.profile?.work_experience?.current_salary?.salary_range
  ) {
    case "r1":
      minSal = 0;
      maxSal = 100000;
      break;
    case "r2":
      minSal = 500000;
      maxSal = 700000;
      break;
    case "r3":
      minSal = 1000000;
      maxSal = 1500000;
      break;
    case "r4":
      minSal = 1500000;
      maxSal = 2000000;
      break;
    case "r5":
      minSal = 2000000;
      maxSal = 2500000;
      break;
    case "r6":
      minSal = 2500000;
      maxSal = 3000000;
      break;
    case "r7":
      minSal = 3000000;
      maxSal = 3500000;
      break;
    case "r8":
      minSal = 3500000;
      maxSal = 4000000;
      break;
    case "r9":
      minSal = 4000000;
      maxSal = 40000000;
      break;
    case "r10":
      minSal = 4500000;
      maxSal = 45000000;
      break;
    case "r11":
      minSal = 5000000;
      maxSal = 50000000;
      break;
    case "r12":
      minSal = 5500000;
      maxSal = 6000000;
      break;
    case "r13":
      minSal = 6000000;
      maxSal = 6500000;
      break;
    case "r14":
      minSal = 6500000;
      maxSal = 7000000;
      break;
    case "r15":
      minSal = 7000000;
      maxSal = 7500000;
      break;
    case "r16":
      minSal = 7500000;
      maxSal = 8000000;
      break;
    case "r17":
      minSal = 8000000;
      maxSal = 8500000;
      break;
    case "r18":
      minSal = 8500000;
      maxSal = 9000000;
      break;
    case "r19":
      minSal = 9000000;
      maxSal = 9500000;
      break;
    case "r20":
      minSal = 9500000;
      maxSal = 10000000;
      break;
    default:
      break;
  }

  const [openModal, setOpenModal] = useState(false);

  const [formvalue, setFormvalue] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  function handleOnChange(e) {
    setFormvalue({
      ...formvalue,
      [e.target.name]: e.target.value,
    });
  }
  function handleSendInvite() {
    const data = {
      sender_full_name: `${searchUserData?.basicDetails?.first_name} ${searchUserData?.basicDetails?.last_name}`,
      reciever_full_name: `${formvalue.firstName} ${formvalue.lastName}`,
      reciever_email: formvalue.email,
    };
    dispatch(editProfileAction.postSendInvite(data));
    setOpenModal(false);
  }
  const socialMEdiaAvailable =
    searchUserData?.profile?.social_links?.instagram?.length ||
    searchUserData?.profile?.social_links?.facebook?.length ||
    searchUserData?.profile?.social_links?.linkedin?.length ||
    searchUserData?.profile?.social_links?.twitter?.length ||
    searchUserData?.profile?.social_links?.pinterest?.length ||
    searchUserData?.profile?.social_links?.github?.length;
  return (
    <div>
      {openModal && (
        <InvitePopover
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          // deletehandle={deletehandle}
          handleConfirm={() => handleSendInvite()}
          view
          formvalue={formvalue}
          handleOnChange={handleOnChange}
          label1={"Cancel"}
          label2={"Send Invite"}
          exitHandle={() => {
            setFormvalue({
              firstName: "",
              lastName: "",
              email: "",
            });
            setOpenModal(false);
          }}
        />
      )}
      <Grid container gap={"5px"} className="profileHeader" p={2} mt={2}>
        <Grid
          container
          item
          lg={6.6}
          mt={{ xs: 1.5 }}
          className="leftDiv"
          alignItems={"center"}
        >
          <Grid container item className="imageAndContactDetails" lg={12}>
            <Grid item className="img">
              <img
                className="profileImg"
                src={
                  searchUserData?.basicDetails?.profile_pic === null || ""
                    ? defaultLoginImage
                    : searchUserData?.basicDetails?.profile_pic
                }
                alt=""
                height="200px"
                width="200px"
              />
            </Grid>
            <Grid ml={1.7} item lg={6.7} className="contactDetails">
              <Grid mt={2} className="userDetails">
                <Grid container gap={2} mt={0.5} className="userInfo">
                  <p className="font700 font24 userName">
                    {searchUserData?.basicDetails?.first_name === null ||
                    searchUserData?.basicDetails?.first_name === undefined ||
                    searchUserData?.basicDetails?.first_name === ""
                      ? "MatchupIT User"
                      : searchUserData?.basicDetails?.first_name +
                        " " +
                        searchUserData?.basicDetails?.last_name}
                    {searchUserData?.profile?.mentorship?.optIn === 1 && (
                      <span>
                        <img
                          className="mentorIcon"
                          src={mentorBageIcon}
                          alt="M"
                        />
                      </span>
                    )}
                  </p>
                </Grid>

                <Grid
                  textAlign={"left"}
                  container
                  gap={2}
                  mt={0.5}
                  className="userInfo"
                >
                  <p className="font400 font16">
                    {searchUserData?.profile?.work_experience
                      ?.designations[0] === null || undefined
                      ? "NA"
                      : searchUserData?.profile?.work_experience
                          ?.designations[0]}
                    {searchUserData?.profile?.work_experience?.orgNames[0] ===
                      null ||
                    searchUserData?.profile?.work_experience?.orgNames[0] ===
                      undefined ||
                    searchUserData?.profile?.work_experience?.orgNames[0] ===
                      "undefined"
                      ? " "
                      : ` at ${searchUserData?.profile?.work_experience?.orgNames[0]}`}
                  </p>
                </Grid>
              </Grid>

              <Grid mt={2} className="userDetails">
                <Grid container gap={2} mt={0.5} className="userInfo">
                  <img className="Icons" src={CallIcon} alt="call icon" />
                  <p className="font400 font16">
                    {searchUserData?.basicDetails?.phone === ("" || null)
                      ? "NA"
                      : searchUserData?.basicDetails?.dial_code +
                        " " +
                        searchUserData?.basicDetails?.phone}
                  </p>
                </Grid>
                <Grid container gap={2} mt={0.5} className="userInfo">
                  <img className="Icons" src={MailIcon} alt="mail icon" />
                  <p
                    className="font400 font16"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {searchUserData?.basicDetails?.email}
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                item
                lg={9}
                gap={"9px"}
                mt={2}
                style={{ marginLeft: "-1px" }}
                className="toggleBtn "
              >
                <ISwitch
                  checked={checked}
                  onChange={(e) => editProfile(e)}
                  name="status"
                />
                <p className="switchLabel">Available to hire</p>
              </Grid>
            </Grid>
            {socialMEdiaAvailable ? (
              <Grid
                container
                item
                lg={7.1}
                className="socialIconsDiv"
                gap={2}
                mt={1}
              >
                {searchUserData?.profile?.social_links?.instagram?.length >
                  0 && (
                  <a
                    rel="noopener noreferrer"
                    href={`https://instagram.com/${searchUserData?.profile?.social_links?.instagram}`}
                    target="_blank"
                  >
                    <img
                      src={instaColorIcon}
                      className="instaLogo socialIcons"
                      alt="Instagram Logo"
                    />
                  </a>
                )}
                {searchUserData?.profile?.social_links?.facebook?.length >
                  0 && (
                  <a
                    rel="noopener noreferrer"
                    href={`https://facebook.com/${searchUserData?.profile?.social_links?.facebook}`}
                    target="_blank"
                  >
                    <img
                      src={facebookColorIcon}
                      className="socialIcons"
                      alt="Facebook Logo"
                    />
                  </a>
                )}
                {searchUserData?.profile?.social_links?.linkedin?.length >
                  0 && (
                  <a
                    rel="noopener noreferrer"
                    href={`https://linkedin.com/in/${searchUserData?.profile?.social_links?.linkedin}`}
                    target="_blank"
                  >
                    <img
                      src={linkedInColorIcon}
                      className="socialIcons"
                      alt="linkedin Logo"
                    />
                  </a>
                )}

                {searchUserData?.profile?.social_links?.twitter?.length > 0 && (
                  <a
                    rel="noopener noreferrer"
                    href={`https://twitter.com/${searchUserData?.profile?.social_links?.twitter}`}
                    target="_blank"
                  >
                    <img
                      src={twitterColorIcon}
                      className="twitterIcon"
                      alt="Facebook Logo"
                    />
                  </a>
                )}

                {searchUserData?.profile?.social_links?.pinterest?.length >
                  0 && (
                  <a
                    rel="noopener noreferrer"
                    href={`https://pinterest.com/${searchUserData?.profile?.social_links?.pinterest}`}
                    target="_blank"
                  >
                    <img
                      src={pintrestColorIcon}
                      alt="Facebook Logo"
                      className="pintrestIcon"
                    />
                  </a>
                )}

                {searchUserData?.profile?.social_links?.github?.length > 0 && (
                  <a
                    rel="noopener noreferrer"
                    href={`https://github.com/${searchUserData?.profile?.social_links?.github}`}
                    target="_blank"
                  >
                    <img
                      src={githubColorIcon}
                      className="facebookLogo socialIcons"
                      alt="Facebook Logo"
                    />
                  </a>
                )}
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {/* center Div for sliders and experiance */}

        <Grid container item lg={4.2} className="centerDiv" textAlign={"left"}>
          <Grid item lg={12} xs={12} className="sliders">
            <Grid
              className="profileCompletionSlider"
              item
              lg={12}
              xs={12}
              justifyContent={"left"}
            >
              <Grid item lg={12} mt={2}>
                <p className="font700 font16">Profile Completion</p>
              </Grid>
              <Grid container mt={3}>
                <CustomizedSlider
                  value={searchUserData?.profileCompletionPercentage}
                />
              </Grid>
            </Grid>
            {searchUserData?.profile?.work_experience?.current_salary?.showCompensations ? (
              <Grid
                className="compensationSlider"
                container
                item
                lg={12}
                justifyContent={"left"}
              >
                <Grid
                  item
                  container
                  lg={12}
                  mt={1}
                  className="compensationSlide"
                >
                  <p className="font700 font16">
                    Compensation:{" "}
                    <span
                      className={
                        parseInt(salaryData?.meanSalary) < salaryData?.mySalary
                          ? "dotRed"
                          : "dotGreen"
                      }
                    >
                      Yours
                    </span>{" "}
                    vs{" "}
                    <span
                      className={
                        parseInt(salaryData?.meanSalary) < salaryData?.mySalary
                          ? "dotGreen"
                          : "dotRed"
                      }
                    >
                      {" "}
                      Median
                    </span>
                  </p>
                  {searchUserData?.profile?.work_experience?.current_salary
                    ?.amount > 0 ? (
                    <RangeSlider
                      searchUserData={searchUserData}
                      salary={salaryData}
                    />
                  ) : (
                    <p className="font400  font14">
                      Check your position by providing your compensation data.
                    </p>
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          {searchUserData?.basicDetails?.citizenship !==
            (null || undefined || "") && (
            <Grid
              container
              item
              lg={12}
              mt={1}
              justifyContent={"left"}
              className="workingDetails"
            >
              <Grid item>
                <p className="font700 font16">Citizenship :</p>
              </Grid>{" "}
              <Grid ml={0.5} item>
                <p className="font400 font16">
                  {searchUserData?.basicDetails?.citizenship}
                </p>
              </Grid>
            </Grid>
          )}
          <Grid container className="workingDetails1">
            <Grid container item lg={12} mt={3}>
              {searchUserData?.profile?.personal_details?.countryList?.length >
              0 ? (
                <Grid container item lg={12} mt={1} justifyContent={"left"}>
                  <Grid container className="workingDetails">
                    <p className="font700 font16"> Work Authorization</p>
                  </Grid>
                  <Grid container gap={"20px"} className="workingDetails">
                    <Grid item justifyContent={"left"}>
                      <Grid container>
                        <p className="font700 font14">Country</p>
                      </Grid>
                      <Grid item>
                        <p className="font400 font14">
                          {searchUserData?.profile?.personal_details?.countryList?.join(
                            ", "
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item justifyContent={"left"}>
                      <Grid container>
                        <p className="font700 font14">Visa Type</p>
                      </Grid>
                      <Grid item>
                        <p className="font400 font14">
                          {searchUserData?.profile?.personal_details?.authType?.join(
                            ", "
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item justifyContent={"left"}>
                      <Grid container>
                        <p className="font700 font14">Expiry Date</p>
                      </Grid>
                      <Grid item ml={0.5}>
                        <p className="font400 font14">
                          {searchUserData?.profile?.personal_details?.authExp?.join(
                            ", "
                          )}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {searchUserData?.profile?.work_experience?.total_experience !==
            (undefined || null || "") && (
            <Grid
              container
              item
              lg={12}
              mt={{ xs: 2, lg: 4, md: 0, sm: 0 }}
              justifyContent={"left"}
              className="workingDetails"
            >
              <Grid item>
                <p className="font700 font16">Total Years of Experience:</p>
              </Grid>
              <Grid item ml={0.5}>
                <p className="font400 font16">
                  {searchUserData?.profile?.work_experience?.total_experience}{" "}
                  years
                </p>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          className="buttons profileCompletionSlider1"
          mt={{ lg: 2, md: 2, sm: 2, xs: socialMEdiaAvailable ? 10 : 4 }}
          item
          lg={1}
          xs={12}
          ml={0.5}
        >
          <div className="editBtns">
            <Grid item>
              <Tooltip title="Edit Profile">
                <IconButton>
                  <Buttons
                    varientText={true}
                    label={""}
                    onClick={(e) => handleClickEditProfile(e)}
                    icon={editBtnIcon}
                  />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Create Resume">
                <IconButton>
                  <Buttons
                    varientText={true}
                    label={""}
                    icon={createResumeIcon}
                    onClick={() => navigate(CREATE_RESUME)}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Invite Friends">
                <IconButton>
                  <Buttons
                    varientText={true}
                    label={""}
                    icon={inviteIcon}
                    onClick={() => setOpenModal(true)}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileHeader;
