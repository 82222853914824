import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./DatePicker.scss";

export default function DatePickers({
  name,
  onChangeHandler,
  value,
  id,
  disabled = false,
  isError = false,
  errorMsg = "",
  maxDateEnable = false,
  minDate,
  views,
  inputFormat = "MM/dd/yyyy",
}) {
  return (
    <div className="overideDatePicker">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={views}
          autoFocus
          inputFormat={inputFormat}
          selected={new Date()}
          onChange={(e) => onChangeHandler(e)}
          maxDate={maxDateEnable ? "" : new Date()}
          minDate={minDate ? minDate : "01/01/1940"}
          disabled={disabled}
          value={value}
          id={id}
          name={name}
          className="formControlRoot"
          renderInput={(params) => (
            <TextField
              size="small"
              className="formControlRoot"
              InputProps={{
                className: "inputRoot",
              }}
              {...params}
              fullWidth
              name={name}
            />
          )}
          error={isError}
          helperText={errorMsg}
        />
      </LocalizationProvider>
      {isError && <p className="errorText">{errorMsg}</p>}
    </div>
  );
}
