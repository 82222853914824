import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import matchupITLogo from "../../assets/Landing/matchupITLogo.png";

import { InputField } from "../../library/custom/textBox/InputField";
import "../login/component/Login.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { ADMIN_DASHBOARD } from "../../constant/routeContant";
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../redux/actions/loginAction";
import SignuPic from "../../assets/SignuPic.jpg";

const AdminLogin = ({ userLogin, error }) => {
 
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function userLogin(email, password) {
    dispatch(
        loginAdmin({ email: email, password: password })
    );
  }
  const userData = useSelector((state) => state.loginAdmin);
  useEffect(() => {
    if (!userData.isLoading && userData.status === "SUCCESS") {
      navigate(ADMIN_DASHBOARD);
    }
  }, [userData]);
  return (
    <div className="overideLogin">
      <Grid container>
        <Grid container item lg={5} xs={12} sm={5} md={4}>
          <img
            src={SignuPic}
            style={{
              width: "100%",
              height: "100vh",
              objectFit: "cover",
              objectPosition: "center",
            }}
            className="light-logo"
            alt="no logo"
          />
        </Grid>
        <Grid
          textAlign={"left"}
          item
          lg={6}
          mt={3}
          ml={10}
          sm={5}
          md={6}
        >
          <Grid
            container
            justifyContent={"left"}
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            mt={2}
          >
            <Grid item lg={12} xs={12} md={12} sm={12}>
              
            <img
              src={matchupITLogo}
              style={{ width: "267px", height: "114px" }}
              className="light-logo"
              alt="no logo"
            />
            
            </Grid>
            
            <Grid item lg={12} xs={12} md={12} sm={12}>
              
            <h1
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "36px",
                margin: "0px",
                color: "#043133",
                marginTop: "10px",
              }}
            >
              Welcome to MatchupIT
            </h1>
          </Grid>
          </Grid>
          <Grid item lg={10} mt={2}>
            <h1
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "16px",
                margin: "0px",
                color: "#083A50",
              }}
            >
              Admin Login
            </h1>
          </Grid>

          <Grid container item lg={11}>
            <Grid mt={2} item lg={12}>
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "16px",
                  margin: "0px",
                  color: "#083A50",
                }}
              >
                Email
              </h1>
              <Grid item lg={10}>
                <InputField
                  placeholder="Enter your Email here"
                  // label="email"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid mt={2} item lg={12}>
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "16px",
                  margin: "0px",
                  color: "#083A50",
                }}
              >
                Password
              </h1>

              <Grid item lg={10}>
                <Grid container className="searchcaseid">
                  <Grid ml={1} container alignItems={"center"} item lg={10.8}>
                    <input
                      placeholder="Enter your Password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                    />
                  </Grid>
                  <Grid
                    m={1}
                    item
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Visibility style={{ color: "#778DFF" }} />
                    ) : (
                      <VisibilityOff style={{ color: "#778DFF" }} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container mt={1} item lg={10}>
              <Grid container justifyContent={"center"}>
                {error?.err && <p className="errorMsg">{error.msg}</p>}
              </Grid>
              <Grid container justifyContent={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => userLogin(mobile, password)}
                  style={{
                    width: "200px",
                    height: "41px",
                    border: "none",
                    background: "#778DFF",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminLogin;
