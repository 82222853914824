import React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../library/sidebar/sidebar";
// import Navbar from "../library/navbar/navbar";
import "../library/sidebar/sidebar.scss";
import { Drawer } from "@mui/material";

const PrivateRoutes = (props) => {
  const { window } = props;
  const navigate = useNavigate();

  const isAuth = useSelector((state) => state.login.userSuccess);
  const token = sessionStorage.getItem("token");
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 320;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      {token ? (
        <div  className="dashboard">
          {/* <div className="contentContainer">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              <Sidebar />
            </Drawer>

            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              <Sidebar />
            </Drawer> */}

          <Sidebar
          // handleRoute={handleRoute}
          />
          {/* <div className="rightTop">
            <div className="rightBar">
              <Navbar
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleDrawerToggle={handleDrawerToggle}
              />
              <div className="container_wrapper">
                <Outlet />
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <Navigate to="/login" replace={true} />
      )}
    </>
  );
};

export default PrivateRoutes;
