import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ViewProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { EDIT_PROFILE } from "../../../constant/routeContant";
import CorporateProfileHeader from "./CorporateProfileHeader";
import defaultCoverPic from "../../../assets/defaultCoverPic.svg";
import { postSearchCorporateProfile } from "../../../redux/actions/searchAction";
import BackButton from "../../../library/custom/backBtn/BackButton";

const CorporateViewProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = location?.state?.id;
  const NavigateProfileFrom = () => {
    navigate(EDIT_PROFILE);
  };

  useEffect(() => {
    dispatch(
      postSearchCorporateProfile({
        userId: userId,
      })
    );
  }, [])
  

  const searchUserData = useSelector(
    (state) => state?.postSearchCorporateProfile?.data?.response?.corpProfile
  );

  const isLoading = useSelector((state) => state?.postSearchCorporateProfile?.isLoading);

  let skillsOShow = [];
  if (
    searchUserData?.profile?.work_experience?.skillsO?.length
  ) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsO.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsO[i],
        ]),
      ];
    }
    if (ski.length > 10) {
      skillsOShow = ski.splice(0, 10);
    } else {
      skillsOShow = ski;
    }
  }
  let skillsPShow = [];
  if (
    searchUserData?.profile?.work_experience?.skillsP?.length
  ) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsP.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsP[i],
        ]),
      ];
    }
    if (ski.length > 5) {
      skillsPShow = ski.splice(0, 5);
    } else {
      skillsPShow = ski;
    }
  }
  return (
    <>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="overrideViewProfile">
            
          <Grid container>
            <BackButton label={"Back"} route={-1} />
          </Grid>
            <Grid mt={2} className="coverPicGrid">
              <img
                className="coverpic"
                src={
                  searchUserData?.profile?.media?.coverPic === 
                  undefined 
                    ? defaultCoverPic
                    : searchUserData?.profile?.media?.coverPic
                }
                alt=""
              />
            </Grid>
            <CorporateProfileHeader
              searchUserData={searchUserData}
              NavigateProfileFrom={NavigateProfileFrom}
            />
            <Grid container className="deatilsGrid" textAlign={"left"}>
              <Grid className="leftGrid" item lg={6.8} p={2}>
                <Grid className="aboutGrid" mt={2}>
                  <p className="font800 font20 sectionHeading">
                    About the Company
                  </p>
                  <p className="font400 font16 sectionDetails">
                    <span className="styled-about">
                      {searchUserData?.basicDetails?.culture
                        ? parse(searchUserData?.basicDetails?.culture)
                        : "No Summary Available"}
                    </span>
                  </p>
                </Grid>

                <Grid className="aboutGrid" mt={2}>
                  {searchUserData?.basicDetails.video_intro ? (
                    searchUserData?.basicDetails.video_intro
                      .substring(
                        searchUserData?.basicDetails.video_intro.length - 3
                      )
                      .toLowerCase() === "pdf" ? (
                      <embed
                        height={258}
                        scrolling={0}
                        className="fit-layout"
                        src={searchUserData?.basicDetails.video_intro}
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      ></embed>
                    ) : (
                      <video
                        id="vid2"
                        style={{
                          width: "100%",
                          height: "270px",
                          borderRadius: "15px",
                        }}
                        controls
                        key={searchUserData?.basicDetails.video_intro}
                        autoPlay="autoPlay"
                        muted
                        playsInline
                      >
                        <source
                          src={searchUserData?.basicDetails.video_intro}
                          type="video/mp4"
                        />
                        <source
                          src={searchUserData?.basicDetails.video_intro}
                          type="video/webm"
                        />
                      </video>
                    )
                  ) : (
                    <h1>No intro</h1>
                  )}
                </Grid>
              </Grid>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Grid className="rightGrid" item lg={5} p={2}>
                <Grid className="toolsGrid" mt={2}>
                  <p className="font800 font20 sectionHeading">
                    Tools, Technology and Framework
                  </p>
                  <Grid container mt={2} gap={"10px"}>
                    <p className="sub-text">
                      {searchUserData?.basicDetails?.current_road_map &&
                      searchUserData?.basicDetails?.current_road_map !== ""
                        ? searchUserData?.basicDetails?.current_road_map
                        : "Not Available"}
                    </p>
                  </Grid>
                </Grid>
                <Grid className="expGrid" mt={10}>
                  <p className="font800 font20 sectionHeading">Latest News</p>
                  <Grid container mt={2} gap={"10px"}>
                    <p className="sub-text">
                      {searchUserData?.basicDetails.future_road_map &&
                      searchUserData?.basicDetails.future_road_map !== ""
                        ? searchUserData?.basicDetails.future_road_map
                        : "Not Available"}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    </>
  );
};

export default CorporateViewProfile;
