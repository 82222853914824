import { Button, Grid, RadioGroup } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { InputField } from "../../library/custom/textBox/InputField";
import TextArea from "../../library/custom/textArea/TextArea";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import * as UploadFilesAction from "../../redux/actions/UploadFilesAction";
import Buttons from "../../library/custom/button/Button";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDispatch, useSelector } from "react-redux";
import {
  postCreateCommunity,
  postEditCommunity,
} from "../../redux/actions/communityAction";
import Loader from "../../library/common/Loader";
import SuccessScreen from "../../library/custom/success/successScreen";
import { ADMIN_COMMUNITY } from "../../constant/routeContant";
import { useLocation } from "react-router";
import RadioButton from "../../library/custom/radioButton/RadioButton";
import ClickableChips from "../../library/custom/chip/ClickableChip";
import SearchAutoComplete from "../../library/custom/searchAutoComplete/SearchAutoComplete";
import * as editProfileAction from "../../redux/actions/editProfileAction";
const CommunityForm = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  useEffect(() => {
    dispatch(editProfileAction?.getIndustries());
  }, []);

  const editMode = location?.state?.editMode;
  const data = location?.state?.data;

  const [formValue, setFormValue] = useState({
    communityId: "",
    title: "",
    description: "",
    icon: "",
    roles: "global",
    iconName: "",
  });

  const [successScreens, setSuccessScreens] = useState(false);
  function handleChange(e) {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  }

  const profileUpload = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      handleProfileUploadMethod(fileReader.result, file.name);
    };
  };

  const handleProfileUploadMethod = (data, name) => {
    dispatch(
      UploadFilesAction.createFileUpload({
        filename: name.slice(0, -4),
        file: data,
      })
    );
  };
  const headShot = useSelector(
    (state) => state?.uploadFiles?.data?.response?.location
  );
  useEffect(() => {
    if (headShot !== undefined || headShot !== null)
      setFormValue({
        ...formValue,
        icon: headShot,
        iconName: headShot?.substring(headShot?.indexOf("_") + 1),
      });
  }, [headShot]);

  function handleFormSubmit() {
    const roles =
      communityType === "global" || rolesList.length === 0
        ? "global"
        : rolesList.join("--&--");

    setSuccessScreens(true);

    if (editMode)
      dispatch(
        postEditCommunity({
          communityId: formValue.communityId,
          title: formValue.title,
          description: formValue.description,
          icon: formValue.icon,
          roles: roles,
        })
      );
    else
      dispatch(
        postCreateCommunity({
          title: formValue.title,
          description: formValue.description,
          icon: formValue.icon,
          roles: formValue.roles,
        })
      );
  }

  const createCommunityData = useSelector(
    (state) => state?.postCreateCommunity
  );
  const editCommunityData = useSelector((state) => state?.postEditCommunity);
  const successScreen = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={" "}
        route={ADMIN_COMMUNITY}
        label1={editMode ? "Community Edited" : "Community Created"}
        label2="Successfully!"
        label4="You will be redirected back to community dashboard in"
      ></SuccessScreen>
    );
  };
  useEffect(() => {
    if (data)
      setFormValue({
        ...formValue,
        communityId: data._id,
        title: data.title,
        description: data.description,
        icon: data.icon,
        roles: data.roles,
        iconName: data.icon?.substring(data.icon?.indexOf("_") + 1),
      });
  }, [data]);

  const [communityType, setcommunityType] = useState("global");
  function handlecommunityType(e) {
    setcommunityType(e);
  }

  const allIndustries = useSelector(
    (state) => state?.industries?.data?.response
  );
  useEffect(() => {
    postIndustriesFetch(allIndustries);
  }, [allIndustries]);

  const [roles, setroles] = useState([]);
  const postIndustriesFetch = (data) => {
    let newList = [];
    data?.functions.map((i) => {
      newList = [...newList, ...i.roles];
    });
    let list = [];
    newList.map((i) => {
      list = [...list, i.name];
    });
    newList = [...new Set(list)];
    setroles(list);
  };

  const autoComprole = useRef(null);
  const [role, setrole] = useState("");
  const [rolesList, setrolesList] = useState([]);

  const addroleData = () => {
    if (!role) return;
    const index = rolesList.findIndex((object) => object === role);
    if (index === -1) {
      // setsupervisorId([...supervisorId, supervisor.key]);
      setrolesList([...rolesList, role]);
    }
    const ele = autoComprole.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  return (
    <div>
      {createCommunityData.status === "SUCCESS" &&
      createCommunityData.data &&
      successScreens ? (
        successScreen(createCommunityData?.data?.id, "C Successfully")
      ) : editCommunityData.status === "SUCCESS" &&
        editCommunityData.data &&
        successScreens ? (
        successScreen(editCommunityData?.data?.id, "C Successfully")
      ) : (
        <Grid container>
          <Grid item container textAlign={"left"}>
            <h2>Create Community</h2>
          </Grid>
          <Grid mt={2} item container lg={11} xs={12} sm={12} md={6}>
            <InputField
              fullWidth={true}
              type="text"
              label="Title"
              id={"title"}
              name={"title"}
              isRequired={true}
              placeholder="Enter here"
              value={formValue.title}
              onChange={(e) => {
                handleChange(e);
              }}
              // isError={singleEdu.isInstitute}
              // errorMsg={
              //   singleEdu.isInstitute ? "Please enter this required field" : ""
              // }
            />
          </Grid>

          <Grid textAlign={"left"} mt={4} lg={11}>
            <FormLabels label="Description" />
            <TextArea
              id={"description"}
              name={"description"}
              placeholder={"Enter here"}
              value={formValue.description}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </Grid>
          <Grid className="fileUploadDiv" container item>
            <Button
              variant="text"
              component="label"
              style={{ color: "#083a50" }}
            >
              <h4>Community Icon</h4>
              <AttachFileIcon className="btnColor" />
              <input
                hidden
                multiple
                type="file"
                accept="image/*"
                onChange={(event) => {
                  profileUpload(event);
                }}
              />
            </Button>
            {headShot && (
              <Grid container item alignItems={"center"} className="nameTxt">
                {" "}
                <h5 style={{ margin: 0, marginRight: "10px" }}>
                  {" "}
                  Image:{" "}
                </h5>{" "}
                <span className="name">
                  {formValue?.icon?.substring(
                    formValue?.icon?.indexOf("_") + 1
                  )}
                </span>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item lg={3}>
              <FormLabels label={""} />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={communityType === "global"}
                  value="global"
                  label="Global"
                  // disabled={editMode ? true : false}
                  onChange={(e) => handlecommunityType(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={communityType === "role"}
                  value="role"
                  // disabled={editMode ? true : false}
                  label="Add Role"
                  onChange={(e) => handlecommunityType(e.target.value)}
                ></RadioButton>
              </RadioGroup>
            </Grid>
            {communityType === "role" && (
              <Grid container textAlign={"left"}>
                <Grid item lg={4}>
                  <FormLabels label="Roles" />
                  <SearchAutoComplete
                    optionsData={roles ?? []}
                    isKeyValuePair={true}
                    value={role}
                    onChange={(e, value) => setrole(value)}
                    // isLoading={isLoading}
                    autoCompClear={autoComprole}
                  />
                </Grid>

                <Grid
                  item
                  // className="smallAddBtn"
                  lg={1}
                  // md={9}
                  // sm={12}
                  // xs={12}
                  mt={3}
                  ml={2}
                  className="saveBtn"
                >
                  <Buttons
                    varientContained={true}
                    label={"Add"}
                    onClick={addroleData}
                  ></Buttons>
                </Grid>
                <Grid mt={4} container gap={1}>
                  {rolesList?.map((i) => (
                    <ClickableChips key={i} label={i} />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid textAlign={"left"} mt={4} lg={11}>
            <Buttons
              label={"Submit"}
              varientContained={true}
              onClick={() => handleFormSubmit()}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CommunityForm;
