import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoinCommunity from "../component/JoinCommunity";
import {
  postJoinCommunity,
  postSearchCommunity,
  getMyCommunity,
  getTopCommunity,
  getPostsCommunityWises,
  addPostCommunityPending,
  addPostCommunityCommentPending,
  postLeaveCommunity,
  postAddReactToPostCommunity,
  postAddReactToPostCommentCommunity,
  postDeletePostCommunity,
} from "../../../redux/actions/communityAction";
import Loader from "../../../library/common/Loader";
import { useLocation } from "react-router-dom";

const JoinCommunityContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location?.state?.data;
  const dataid = location?.state?.dataid;
  const communityId = data?._id ? data?._id : dataid;
  const usercountJoin = location?.state?.usercountJoin;
  const isdash = location?.state?.isdash;
  const datadashicoan = location?.state?.data?.icon;
  const [isdashicon, setIsdashicon] = useState(datadashicoan);
  const [communitjoincount, setCommunitjoincount] = useState(usercountJoin);
  const [cmtid, setCmtid] = useState("");
  const [communityIds, setCommunityIds] = useState(communityId);
  const [communitySate, setCommunitySate] = useState([]);
  const [mycommunitySates, setMyCommunitySates] = useState([]);
  const [correntcommentOrpostId, setCorrentcommentOrpostId] = useState("");
  const [isloadinglike, setIsloadinglike] = useState(false);

  const dataicn = location?.state?.icon;
  useEffect(() => {
    dispatch(getMyCommunity());
    dispatch(getTopCommunity());
    dispatch(getPostsCommunityWises(communityIds));
  }, []);

  const getCommunityData = useSelector((state) => state?.getCommunity?.data);
  const myCommunities = useSelector((state) => state?.myCommunity?.data);
  const topCommunities = useSelector((state) => state?.topCommunity?.data);
  const addPostCommunitResult = useSelector((state) => state?.addPostCommunity);

  const adPostisLoading = useSelector(
    (state) => state?.addPostCommunity?.isLoading
  );
  const addPostCommunityCommentsResult = useSelector(
    (state) => state?.addPostCommunityComments
  );
  const adPostCommentisLoading = useSelector(
    (state) => state?.addPostCommunityComments?.isLoading
  );
  const getPostCommunityWiseData = useSelector(
    (state) => state?.getPostsCommunityWise
  );
  useEffect(() => {
    if (getPostCommunityWiseData?.status == "SUCCESS") {
      setIsloadinglike(false);
    }
  }, [getPostCommunityWiseData]);

  const isloadingGetPost = getPostCommunityWiseData?.isLoading;
  const isMember = getPostCommunityWiseData?.data?.isMember;
  const showLoader = useSelector(
    (state) =>
      state?.getCommunity?.isLoading || state?.postJoinCommunity?.isLoading
  );
  const joinCommunity = useSelector((state) => state?.postJoinCommunity);
  const leaveCommunity = useSelector((state) => state?.postLeaveCommunity);
  const addRectTopost = useSelector((state) => state?.postAddReactToPost);
  const addRectTopostComment = useSelector(
    (state) => state?.postAddReactToPostComment
  );
  const deletepost = useSelector((state) => state?.postDeletePostComment);
  const searchCommunityData = useSelector(
    (state) => state?.postSearchCommunity
  );

  useEffect(() => {
    if (addPostCommunitResult.status == "SUCCESS") {
      dispatch(getPostsCommunityWises(communityIds));
    }
  }, [addPostCommunitResult]);

  useEffect(() => {
    if (addPostCommunityCommentsResult.status == "SUCCESS") {
      dispatch(getPostsCommunityWises(communityIds));
    }
  }, [addPostCommunityCommentsResult]);

  function handleClick(id) {
    if (isMember) {
      const data = { communityId: communityIds };
      dispatch(postLeaveCommunity(data));
    } else {
      const data = { communityId: communityIds };
      dispatch(postJoinCommunity(data));
    }
  }

  const [usrcount, setUsrcount] = useState(
    data?.userCount ? data?.userCount : communitjoincount
  );
  useEffect(() => {
    if (
      addRectTopost?.status === "SUCCESS" ||
      addRectTopostComment?.status === "SUCCESS" ||
      deletepost?.status === "SUCCESS"
    ) {
      dispatch(getPostsCommunityWises(communityIds));
    }
  }, [addRectTopost, addRectTopostComment, deletepost]);
  useEffect(() => {
    if (leaveCommunity.status === "SUCCESS") {
      dispatch(getPostsCommunityWises(communityIds));
      dispatch(getMyCommunity());
      dispatch(getTopCommunity());
      setUsrcount(usrcount - 1);
    }
  }, [leaveCommunity]);
  useEffect(() => {
    if (joinCommunity.status === "SUCCESS") {
      dispatch(getPostsCommunityWises(communityIds));
      dispatch(getMyCommunity());
      dispatch(getTopCommunity());
      setUsrcount(usrcount + 1);
    }
  }, [joinCommunity]);
  function handleSearch(input) {
    const data = {
      communityName: input,
    };
    dispatch(postSearchCommunity(data));
  }
  useEffect(() => {
    setMyCommunitySates(myCommunities);
  }, [myCommunities]);

  useEffect(() => {
    setCommunitySate(searchCommunityData.data);
  }, [searchCommunityData]);

  useEffect(() => {
    setCommunitySate(getCommunityData);
  }, [getCommunityData]);

  const addCommunityposts = async (data) => {
    dispatch(addPostCommunityPending(data));
  };

  const addCommunityCommentsposts = async (data) => {
    dispatch(addPostCommunityCommentPending(data));
  };
  const addReactToposts = async (data) => {
    setCorrentcommentOrpostId(data?.postId);
    setIsloadinglike(true);
    dispatch(postAddReactToPostCommunity(data));
  };
  const addReactTopostsComment = async (data) => {
    setCorrentcommentOrpostId(data?.commentId);
    dispatch(postAddReactToPostCommentCommunity(data));
    setIsloadinglike(true);
  };

  function handleMYClick(data) {
    dispatch(getMyCommunity());
    dispatch(getTopCommunity());
    dispatch(getPostsCommunityWises(data?._id));
    setUsrcount(data?.userCount);
    setCommunityIds(data?._id);
    setCmtid(data?._id);
    setIsdashicon(data?.icon);
  }
  const handleClickDeletePost = async (data) => {
    dispatch(postDeletePostCommunity(data));
  };
  return (
    <div>
      {(showLoader ||
        adPostisLoading ||
        adPostCommentisLoading ||
        isloadingGetPost) &&
      isloadinglike == false ? (
        <Loader />
      ) : (
        <JoinCommunity
          mycommunitySates={mycommunitySates}
          topCommunities={topCommunities}
          handleClick={handleClick}
          handleSearch={handleSearch}
          title={data?.title}
          desc={data?.description}
          communityUsercount={data?.userCount}
          getPostCommunityWiseData={getPostCommunityWiseData}
          onCommunityPostSubmit={addCommunityposts}
          communityId={communityIds}
          addCommunityCommentsposts={addCommunityCommentsposts}
          isMember={isMember}
          addReactToposts={addReactToposts}
          addReactTopostsComment={addReactTopostsComment}
          handleMYClick={handleMYClick}
          handleClickDeletePost={handleClickDeletePost}
          isdash={isdash}
          usrcount={usrcount}
          icon={isdashicon ? isdashicon : dataicn}
          correntcommentOrpostId={correntcommentOrpostId}
          isloadingGetPost={isloadingGetPost}
          isloadinglike={isloadinglike}
        />
      )}
    </div>
  );
};

export default JoinCommunityContainer;
