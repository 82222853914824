import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./FormDropBox.scss";
import * as FileUpload from "../../../src/redux/actions/UploadVideoAction";
import { useDispatch, useSelector } from "react-redux";
import dropboxUploadIcon from "../../assets/dropboxUploadIcon.svg";
import { API_STATUS, ACCOUNT_TYPE } from "../../constant/constant";
import { CircularProgress } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const FormDropBox = ({
  key,
  id,
  label,
  paraText2,
  paraText1,
  paraText3,
  handleFileChange,
  handleCancel,
  // isImage = true,
  allFiles = false,
  imageUrl = [],
  deleteImageURL,
  isMuliple = false,
  isLoading = false,
  acceptedFileType = ACCOUNT_TYPE() === "corporate"
    ? {
        "application/pdf": [".pdf"],
        "video/*": [".mp4", ".mov", ".avi", ".mkv"],
      }
    : {
        "video/*": [".mp4", ".mov", ".avi", ".mkv"],
      },
  isDoc = false,
  isMultipleIcon = false,
  filess,
}) => {
  const dispatch = useDispatch();
  console.log(acceptedFileType);

  const createUpload = useSelector((state) => state.createUploadVideoData);

  useEffect(() => {
    if (createUpload?.status === API_STATUS.SUCCESS) {
      setIsLoader(false);
      setimageUrls(createUpload.data?.fileUrl);
    }
  }, [createUpload]);

  const [imageUrls, setimageUrls] = useState(imageUrl);

  const [files, setFiles] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isMaxFile, setIsMaxFile] = useState(false);
  const [isMaxFiless, setIsMaxFiless] = useState([]);
  const [formDataState, setFormDataState] = useState(null);
  const handeleUpload = (files) => {
    setFormDataState(files);
  };

  useEffect(() => {
    if (formDataState) {
      setIsMaxFile(false);
      const fileSizes = isMaxFiless?.length + formDataState?.length;
      if (fileSizes > 10) {
        setIsMaxFile(true);
        return;
      }
      setIsLoader(true);
      const formData = new FormData();
      formDataState.forEach((singleFile) => {
        formData.append("", singleFile);
      });
    }
  }, [formDataState]);

  useEffect(() => {
    if (files) {
      const ddd = [...isMaxFiless, ...files];
      setIsMaxFiless(ddd);
    }
  }, [files]);

  useEffect(() => {
    if (filess?.length > 0) {
      setIsMaxFiless(filess);
    }
  }, [filess]);

  useEffect(() => {
    if (isMaxFiless.length > 0) {
      let filesPath = "";
      const fileData = isMaxFiless && isMaxFiless.map((x) => x.filePath);
      const fileDataId = isMaxFiless && isMaxFiless.map((x) => x.fileId);
      filesPath = fileData && fileData.join("|");
      handleFileChange(filesPath, fileDataId);
    }
  }, [isMaxFiless]);
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    console.log(acceptedFiles);
    if (acceptedFiles[0].size > 500000000) {
      setIsFileTooLarge(true);
      return;
    }

    setIsMaxFile(false);
    setIsFileTooLarge(false);
    if (rejectedFiles?.length > 1) {
      setIsMaxFile(true);
      return;
    }
    if (isMaxFiless && isMaxFiless.length <= 1) {
      let FileSize = 0;
      let totalFileSize = acceptedFiles.reduce(
        (accumVariable, curValue) => accumVariable + curValue.size,
        FileSize
      );
      if (totalFileSize >= 500000000) {
      } else {
        handeleUpload(acceptedFiles);
      }
    }

    dispatch(FileUpload.createVideoUpload({ file: acceptedFiles[0] }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".video/*",

    accept: acceptedFileType,
    // maxFiles: 1,
    // maxSize: 5242880,
    multiple: false,
  });

  return (
    <>
      {/* {isLoader ? (
        <Loader />
      ) : ( */}
      <div className="overideFormDropBox" key={key} id={id}>
        <div className="dropBoxTitle">{label}</div>
        {isLoading ? (
          <div className="dropbox">
            <p className="loadingText">Your file is being uploaded</p>
            <>
              <CircularProgress
                onClick={() => handleCancel()}
                className="progressBar"
              />
              <CloseOutlinedIcon
                onClick={() => handleCancel()}
                className="crossIcon"
              />
            </>
          </div>
        ) : (
          <div className="dropbox" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>
                <p className="dropText">{paraText1}</p>
              </div>
            ) : (
              <>
                <div>
                  <img src={dropboxUploadIcon} alt="" />
                  <p className="browseText">
                    {paraText2} <span>{paraText3}</span>
                  </p>
                </div>
              </>
            )}
          </div>
        )}
        {isFileTooLarge && (
          <div className="dropBoxError">
            The uploaded files are larger than 500 MB in size.
          </div>
        )}
        {isMaxFile && (
          <div className="dropBoxError">
            You can upload maximum 5 files or 4.5 MB files.
          </div>
        )}
        <div className="imageBody"></div>
      </div>
    </>
  );
};

export default FormDropBox;
