import React from "react";
import "./CompleteProfileCard.scss";
import { Grid } from "@mui/material";

const CompleteProfileCard = ({onClick}) => {
  return (
    <div className="overrideCompleteProfileCard">
      <Grid className="card" container alignItems={"center"} item lg={12}>
        {"To avail all benefits of MatchupIT, "}
        <strong className="link" onClick={onClick}>{` Complete your Profile `}</strong>
        {" now."}
      </Grid>
    </div>
  );
};

export default CompleteProfileCard;
