import React from "react";
import Grid from "@mui/material/Grid";
// import commonImages from "../../../assets";
import "./backBtn.scss";
import { useNavigate } from "react-router-dom";
import arrow_left_alt from "../../../assets/arrow_left_alt.svg";

const BackBtn = (props) => {
  const navigate = useNavigate();
  return (
    <Grid
      className="backBtn"
      onClick={() => {
        props?.fromSerach !== true
          ? navigate(props?.route, {
              state: { backTabId: props?.dataBack?.backTabId },
            })
          : props?.onClick();
      }}
    >
      <Grid className="backImg">
        <img src={arrow_left_alt} alt="" />
      </Grid>
      <Grid className="backTitle">{props.label}</Grid>
    </Grid>
  );
};

export default BackBtn;
