import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAllConversion,
  getAllMessage,
  getUnreadMeaasge,
  createConversation,
  addMessage,
  getUserSearch,
} from "../../services/conversationService";
import { apiError } from "../../utils/helper";
import { conversationType } from "../constant/conversationType";
import * as conversationAction from "../actions/conversationAction";

export function* getAllConversationSaga(params) {
  try {
    const { data, status, error } = yield call(getAllConversion, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      conversationAction.getAllConversationSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get All Conversation Failed" };
    yield put(conversationAction.getAllConversationFailure(data));
  }
}

export function* getAllMessageSaga(params) {
  try {
    const { data, status, error } = yield call(getAllMessage, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      conversationAction.getAllMessageSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get All Message Failed" };
    yield put(conversationAction.getAllMessageFailure(data));
  }
}

export function* getUnreadMessageSaga(params) {
  try {
    const { data, status, error } = yield call(getUnreadMeaasge, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      conversationAction.getUnreadMessageSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get All Unread Message Failed" };
    yield put(conversationAction.getUnreadMessageFailure(data));
  }
}

export function* postCreateConversationSaga(params) {
  try {
    const { data, status, error } = yield call(createConversation, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      conversationAction.postCreateConversationSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Conversation Failed" };
    yield put(conversationAction.postCreateConversationFailure(data));
  }
}

export function* postSendMessageSaga(params) {
  try {
    const { data, status, error } = yield call(addMessage, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      conversationAction.postAddMessageSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post add messages Failed" };
    yield put(conversationAction.postAddMessageFailure(data));
  }
}

export function* getUserSearchSaga(params) {
  try {
    const { data, status, error } = yield call(getUserSearch, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      conversationAction.getUserSearchSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Search Failed" };
    yield put(conversationAction.getUserSearchFailure(data));
  }
}

const myConversionSaga = [
  takeLatest(conversationType.GET_CONVERSATION_PENDING, getAllConversationSaga),
  takeLatest(conversationType.GET_MESSAGE_PENDING, getAllMessageSaga),
  takeLatest(conversationType.GET_UNREAD_MESSAGE_PENDING, getUnreadMessageSaga),
  takeLatest(
    conversationType.POST_CREATE_CONVERSATION_PENDING,
    postCreateConversationSaga
  ),
  takeLatest(conversationType.POST_ADD_MESSAGE_PENDING, postSendMessageSaga),
  takeLatest(conversationType.GET_USER_SEARCH_PENDING, getUserSearchSaga),
];

export default myConversionSaga;
