export const editProfileType = {
  GET_INDUSTRY_PENDING: "[INDUSTRY][REQUEST] GET_INDUSTRY_PENDING",
  GET_INDUSTRY_SUCCESS: "[INDUSTRY][RESPONSE] GET_INDUSTRY_SUCCESSFUL",
  GET_INDUSTRY_FAILURE: "[INDUSTRY][RESPONSE] GET_INDUSTRY_FAILURE",

  GET_COUNTRIES_PENDING: "[COUNTRIES][REQUEST] GET_COUNTRIES_PENDING",
  GET_COUNTRIES_SUCCESS: "[COUNTRIES][RESPONSE]   GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "[COUNTRIES][RESPONSE] GET_COUNTRIES_FAILURE",

  PUT_EDIT_PROFILE_PENDING: "[EDITPROFILE][REQUEST]   PUT_EDIT_PROFILE_PENDING",
  PUT_EDIT_PROFILE_SUCCESS: "[EDITPROFILE][RESPONSE]  PUT_EDIT_PROFILE_SUCCESS",
  PUT_EDIT_PROFILE_FAILURE: "[EDITPROFILE][RESPONSE] PUT_EDIT_PROFILE_FAILURE",
  PUT_EDIT_PROFILE_CLEAR: "[EDITPROFILE][RESPONSE] PUT_EDIT_PROFILE_CLEAR",

  GET_SALARY_PENDING: "[SALARY][REQUEST] GET_SALARY_PENDING",
  GET_SALARY_SUCCESS: "[SALARY][RESPONSE]   GET_SALARY_SUCCESS",
  GET_SALARY_FAILURE: "[SALARY][RESPONSE] GET_SALARY_FAILURE",

  POST_INVITE_PENDING: "[INVITE][REQUEST] POST_INVITE_PENDING",
  POST_INVITE_SUCCESS: "[INVITE][RESPONSE]   POST_INVITE_SUCCESS",
  POST_INVITE_FAILURE: "[INVITE][RESPONSE] POST_INVITE_FAILURE",

  GET_JOB_TYPE_PENDING: "[JOB_TYPE][REQUEST] GET_JOB_TYPE_PENDING",
  GET_JOB_TYPE_SUCCESS: "[JOB_TYPE][RESPONSE]   GET_JOB_TYPE_SUCCESS",
  GET_JOB_TYPE_FAILURE: "[JOB_TYPE][RESPONSE] GET_JOB_TYPE_FAILURE",

  POST_TAG_USER_PENDING: "[TAG_USER][REQUEST] POST_TAG_USER_PENDING",
  POST_TAG_USER_SUCCESS: "[TAG_USER][RESPONSE]   POST_TAG_USER_SUCCESS",
  POST_TAG_USER_FAILURE: "[TAG_USER][RESPONSE] POST_TAG_USER_FAILURE",

  UPDATE_COMPANSATION_PENDING: "[JOB_TYPE][REQUEST]UPDATE_COMPANSATION_PENDING",
  UPDATE_COMPANSATION_SUCCESS:
    "[JOB_TYPE][RESPONSE]  UPDATE_COMPANSATION_SUCCESS",
  UPDATE_COMPANSATION_FAILURE:
    "[JOB_TYPE][RESPONSE]UPDATE_COMPANSATION_FAILURE",

  GET_USER_DETAILS_PENDING: "[JOB_TYPE][REQUEST]GET_USER_DETAILS_PENDING",
  GET_USER_DETAILS_SUCCESS: "[JOB_TYPE][RESPONSE]  GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "[JOB_TYPE][RESPONSE]GET_USER_DETAILS_FAILURE",
};
