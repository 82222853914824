import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../library/custom/textBox/InputField";
import { API_STATUS, E_YEAR, MONTHS, S_YEAR } from "../../../constant/constant";
import FormDropBox from "../../../library/formDropBox/FormDropBox";
import { useSelector } from "react-redux";
import File from "../../../library/media/File";
import DatePickers from "../../../library/custom/datePicker/DatePicker";

const WorAuthorizationForm = ({
  singleAuthorization,
  handleAuthorizationFormChange,
  setUnsavedChanges,
}) => {
  let years = [];
  for (let i = E_YEAR; i >= S_YEAR; i--) {
    years.push(i);
  }

  const [imageUrls, setimageUrls] = useState("");
  const createUpload = useSelector((state) => state.createUploadVideoData);

  let fileName = getFileName();

  function getFileName() {
    const fileNameSplit = imageUrls?.split("/");

    return fileNameSplit?.length > 0 && fileNameSplit.slice(-1).pop();
  }

  useEffect(() => {
    if (createUpload?.status === API_STATUS.SUCCESS) {
      //  setIsLoader (false);
      setimageUrls(createUpload.data?.fileUrl);
      //   handleCertificateFormChange(imageUrls,singleCertificate,"dropbox")
    }
  }, [createUpload]);
  return (
    <>
      <Grid className="workExpirience">
        
      <Grid className="fName" item container lg={12} >
        <Grid item lg={5.3} xs={12} sm={12} md={6}>
          <InputField
            type="text"
            label="Country"
            placeholder="Enter here"
            id={"countryList"}
            value={singleAuthorization?.countryList}
            onChange={(e) => {
                handleAuthorizationFormChange(e, singleAuthorization, "countryList");

              setUnsavedChanges(true);
            }}
            isRequired={true}
            errorMsg={singleAuthorization.isCountryList ? "Please enter this required field" : ""}
            isError={singleAuthorization.isCountryList}
          />
        </Grid>
        <Grid item lg={5.3} xs={12} >
          <InputField
            type="text"
            label="Authorization Type"
            placeholder="Enter here"
            id={"authType"}
            value={singleAuthorization?.authType}
            onChange={(e) => {
              setUnsavedChanges(true);
              handleAuthorizationFormChange(e, singleAuthorization, "authType");

            }}
            isRequired={true}
            isError={singleAuthorization.isAuthType}
            errorMsg={singleAuthorization.isAuthType ? "Please enter this required field" : ""}
          />
        </Grid>
      </Grid>
      <Grid className="fName" item container lg={12} mt={1}>
        <Grid item lg={5.3} xs={12} sm={12} md={6}>
          <InputField
            type="text"
            label="Sponsored by"
            placeholder="Enter here"
            id={"sponsor"}
            value={singleAuthorization?.sponsor}
            onChange={(e) => {
              
                handleAuthorizationFormChange(e, singleAuthorization, "sponsor");
              setUnsavedChanges(true);
            }}
            // isRequired={true}
            // errorMsg={isFirstName ? "Please enter this required field" : ""}
            // isError={isFirstName}
          />
        </Grid>
        <Grid item lg={5.3} xs={12} >
        <FormLabels label="Expiry Date" isRequired={true}/>
          
        <DatePickers
            value={singleAuthorization?.authExp}
            name={"authExp"}
            id={"authExp"}
            onChangeHandler={(e) => {
                handleAuthorizationFormChange(e, singleAuthorization, "authExp");
                setUnsavedChanges(true);
            }}
            maxDateEnable
            
            isError={singleAuthorization.isAuthExp}
            errorMsg={singleAuthorization.isAuthExp ? "Please enter this required field" : ""}
          />
        </Grid>
      </Grid>

      </Grid>
    </>
  );
};

export default WorAuthorizationForm;
