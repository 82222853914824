import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ViewProfile.scss";
import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import CallIcon from "../../../assets/callIcon.svg";
import MailIcon from "../../../assets/mailIcon.svg";
import pdfIcon from "../../../assets/pdfIcon.svg";
import docxIcon from "../../../assets/docxIcon.svg";
import facebookColorIcon from "../../../assets/facebookColorIcon.svg";
import pintrestColorIcon from "../../../assets/pintrestColorIcon.svg";
import linkedInColorIcon from "../../../assets/linkedInColorIcon.svg";
import githubColorIcon from "../../../assets/githubColorIcon.svg";
import twitterColorIcon from "../../../assets/twitterXlogo.png";
import instaColorIcon from "../../../assets/instaColorIcon.svg";
import mentorBageIcon from "../../../assets/mentorshipBadge.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postSearchUser } from "../../../redux/actions/searchAction";
import ClickableChips from "../../../library/custom/chip/ClickableChip";
import parse from "html-react-parser";
import Loader from "../../../library/common/Loader";
import Buttons from "../../../library/custom/button/Button";
import StarIcon from "@mui/icons-material/Star";
import BookIcon from "@mui/icons-material/Book";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { MESSAGES } from "../../../constant/routeContant";

import { resumeBuilding } from "../../../redux/actions/communityAction";
import ResumeTemplate from "../../profile/component/ResumeTemplate";
import BackButton from "../../../library/custom/backBtn/BackButton";
import TaggedCommentsPopover from "./TaggedCommentsPopover";
import { postTagUser } from "../../../redux/actions/editProfileAction";
import NotFoundPage from "../../../library/errorPage/NotDoundPage";

const ViewProfile = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = location?.state?.id ?? props?.user?.id;
  useEffect(() => {
    dispatch(
      postSearchUser({
        userId: userId,
      })
    );
  }, []);

  const searchUserResponse = useSelector((state) => state?.searchUserReducer);
  const searchUserData = useSelector(
    (state) => state?.searchUserReducer?.data?.response?.userProfile
  );
  const searchUserAllData = useSelector(
    (state) => state?.searchUserReducer?.data?.response
  );
  const postTagUserData = useSelector((state) => state?.postTagUser);
  const isLoading = useSelector(
    (state) =>
      state?.searchUserReducer?.isLoading || state?.postTagUserData?.isLoading
  );

  let skillsOShow = [];
  if (searchUserData?.profile?.work_experience?.skillsO?.length) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsO.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsO[i],
        ]),
      ];
    }
    if (ski.length > 10) {
      skillsOShow = ski.splice(0, 10);
    } else {
      skillsOShow = ski;
    }
  }
  let skillsPShow = [];
  if (searchUserData?.profile?.work_experience?.skillsP?.length) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsP.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsP[i],
        ]),
      ];
    }
    if (ski.length > 5) {
      skillsPShow = ski.splice(0, 5);
    } else {
      skillsPShow = ski;
    }
  }

  const [uniqueIndustry, setuniqueIndustry] = useState([]);
  useEffect(() => {
    setuniqueIndustry([
      ...new Set(
        searchUserData?.profile?.work_experience?.industry?.map((i) => i)
      ),
    ]);
  }, [searchUserData]);
  function onMsgClick(id) {
    navigate(MESSAGES, {
      state: { id: id },
    });
  }
  const account_type = sessionStorage.getItem("account_type");

  const [downloadRES, setdownloadRES] = useState(false);
  const generatePDF = (first_name, last_name, docType) => {
    let eles;
    setdownloadRES(true);
    eles = document.getElementById("TempHolder").innerHTML;
    let content = `<html><head><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"><style>body{font-size:0.6em;font-family:Arial,Halvetica,sans-serif}table{font-size:1em}</style></head><body>${eles}</body></html>`;
    let payload = {
      type: docType,
      content: content,
      name: `${first_name}-${last_name}`,
    };
    const sdsd = dispatch(resumeBuilding({ payload }));
  };

  const resumeBuilderData = useSelector((state) => state?.resumeBuilderData);
  useEffect(() => {
    if (resumeBuilderData.status == "SUCCESS" && downloadRES) {
      pdfGenerated(resumeBuilderData.data);
      setdownloadRES(false);
    }
  }, [resumeBuilderData]);

  const pdfGenerated = (data) => {
    window.open(data.response, "_blank");
  };
  let Pskills = [],
    Oskills = [];
  for (
    let i = 0;
    i < searchUserData?.profile?.work_experience?.orgNames?.length;
    i++
  ) {
    searchUserData?.profile?.work_experience?.skillsP &&
    searchUserData?.profile?.work_experience?.skillsP?.length
      ? (Pskills = [
          ...new Set([
            ...searchUserData?.profile?.work_experience?.skillsP[i],
            ...Pskills,
          ]),
        ])
      : (Pskills = [...Pskills]);
    searchUserData?.profile?.work_experience?.skillsO &&
    searchUserData?.profile?.work_experience?.skillsO.length
      ? (Oskills = [
          ...new Set([
            ...searchUserData?.profile?.work_experience?.skillsO[i],
            ...Oskills,
          ]),
        ])
      : (Oskills = [...Oskills]);
  }

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (
      postTagUserData.status === "SUCCESS" &&
      postTagUserData.isLoading === false
    )
      dispatch(
        postSearchUser({
          userId: userId,
        })
      );
  }, [postTagUserData]);

  const [comment, setComment] = useState("");
  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="overrideViewProfile">
          {openModal && (
            <TaggedCommentsPopover
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              // deletehandle={deletehandle}
              handleConfirm={() => {
                dispatch(
                  postTagUser({
                    individualId: userId,
                    comments: comment,
                  })
                );
              }}
              exitHandle={() => {
                setOpenModal(false);
                setComment("");
              }}
              taggedComments={searchUserData?.taggedDetails?.comments}
              comment={comment}
              handleCommentChange={handleCommentChange}
            />
          )}

          <Grid container>
            <BackButton
              label={"Back"}
              route={-1}
              fromSerach={props?.fromSerach}
              onClick={props?.onClick}
            />
          </Grid>
          {searchUserResponse.isError ? (
            <NotFoundPage />
          ) : (
            <>
              <div style={{ display: "none" }}>
                <ResumeTemplate
                  basicDetails={searchUserData?.basicDetails}
                  profile={searchUserData?.profile}
                  skillsPShow={skillsPShow}
                  Pskills={Pskills}
                  Oskills={Oskills}
                />
                ;
              </div>
              <Grid container className="profileHeader" p={2}>
                <Grid
                  className="imgAndBasicDetails"
                  container
                  alignItems={"center"}
                >
                  <Grid item lg={12} container>
                    <Grid item lg={3} container>
                      <img
                        src={
                          searchUserData?.basicDetails?.profile_pic ===
                          (null || "" || undefined)
                            ? defaultLoginImage
                            : searchUserData?.basicDetails?.profile_pic
                        }
                        alt=""
                        height="200px"
                        width="200px"
                      />
                      {account_type === "corporate" && (
                        <Grid mt={1}>
                          <Buttons
                            varientContained={true}
                            label={"Message"}
                            onClick={() => onMsgClick(searchUserAllData)}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid ml={1} lg={4.5} item textAlign={"left"}>
                      <Grid mt={6}>
                        <Grid container gap={2} mt={0.5}>
                          <p className="font700 font24 userName">
                            {searchUserData?.basicDetails?.first_name ===
                            ("" || null || undefined)
                              ? "NA"
                              : `${searchUserData?.basicDetails?.first_name} 
                          ${searchUserData?.basicDetails?.last_name}`}
                            {searchUserData?.profile?.mentorship?.optIn ===
                              1 && (
                              <span>
                                <img
                                  className="mentorIcon"
                                  src={mentorBageIcon}
                                  alt="M"
                                />
                              </span>
                            )}
                          </p>
                        </Grid>

                        <Grid container gap={2} mt={0.5}>
                          <p className="font400 font16">
                            {searchUserData?.profile?.work_experience
                              ?.designations[0] === null
                              ? "NA"
                              : searchUserData?.profile?.work_experience
                                  ?.designations[0]}

                            {searchUserData?.profile?.work_experience
                              ?.orgNames[0] === (null || undefined)
                              ? "NA"
                              : ` at ${searchUserData?.profile?.work_experience?.orgNames[0]}`}
                          </p>
                        </Grid>
                      </Grid>

                      <Grid mt={2}>
                        <Grid container gap={2} mt={0.5}>
                          <img
                            className="Icons"
                            src={CallIcon}
                            alt="call icon"
                          />
                          <p className="font400 font16">
                            {searchUserData?.basicDetails?.phone ===
                            ("" || null || undefined)
                              ? "NA"
                              : searchUserData?.basicDetails?.dial_code +
                                " " +
                                searchUserData?.basicDetails?.phone}
                          </p>
                        </Grid>
                        <Grid container gap={2} mt={0.5}>
                          <img
                            className="Icons"
                            src={MailIcon}
                            alt="mail icon"
                          />
                          <p className="font400 font16">
                            <a
                              href={
                                "mailto:" + searchUserData?.basicDetails?.email
                              }
                              style={{ color: "#6464FF" }}
                            >
                              {searchUserData?.basicDetails?.email ===
                              ("" || null || undefined)
                                ? "NA"
                                : searchUserData?.basicDetails?.email}
                            </a>
                          </p>
                        </Grid>
                      </Grid>

                      {account_type === "corporate" && (
                        <Grid
                          className="buttons profileCompletionSlider"
                          container
                          item
                          lg={12}
                          xs={12}
                          justifyContent={"left"}
                          textAlign={"left"}
                          marginLeft={"-10px"}
                          // alignItems={"flex-end"}
                        >
                          <Grid item>
                            <Buttons
                              varientText={true}
                              label={"Resume Pdf"}
                              icon={pdfIcon}
                              onClick={(e) => {
                                generatePDF(
                                  searchUserData?.basicDetails?.first_name,
                                  searchUserData?.basicDetails?.last_name,
                                  "pdf"
                                );
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Buttons
                              varientText={true}
                              label={"Resume Docx"}
                              icon={docxIcon}
                              onClick={() =>
                                generatePDF(
                                  searchUserData?.basicDetails?.first_name,
                                  searchUserData?.basicDetails?.last_name,
                                  "docx"
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid ml={1} item container alignItems={"center"} lg={4}>
                      <Grid
                        container
                        item
                        lg={12}
                        mt={1}
                        justifyContent={"left"}
                      >
                        <Grid item>
                          <p className="font700 font16">Experience :</p>
                        </Grid>
                        <Grid item ml={0.5}>
                          <p className="font400 font16">
                            {searchUserData?.profile?.work_experience
                              ?.total_experience === ("" || null || undefined)
                              ? "NA"
                              : `${searchUserData?.profile?.work_experience?.total_experience} years`}
                          </p>
                        </Grid>
                      </Grid>
                      {account_type === "corporate" && (
                        <Grid
                          container
                          item
                          lg={12}
                          mt={1}
                          justifyContent={"left"}
                        >
                          <Grid item>
                            <p className="font700 font16">Citizenship :</p>
                          </Grid>
                          <Grid item ml={0.5}>
                            <p className="font400 font16">
                              {searchUserData?.basicDetails?.citizenship}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                      {account_type === "corporate" &&
                        searchUserData?.basicDetails?.available_hire !==
                          null && (
                          <Grid
                            container
                            item
                            lg={12}
                            mt={1}
                            justifyContent={"left"}
                          >
                            <Grid item>
                              <p className="font700 font16">
                                Available for Hire :
                              </p>
                            </Grid>
                            <Grid item ml={0.5}>
                              <p
                                className="font400 font16"
                                style={{
                                  padding: "0px 8px",
                                  background: searchUserData?.basicDetails
                                    ?.available_hire
                                    ? "#64E562"
                                    : "red",
                                  borderRadius: "8px",
                                }}
                              >
                                {searchUserData?.basicDetails?.available_hire
                                  ? "YES"
                                  : "NO"}
                              </p>
                            </Grid>
                          </Grid>
                        )}
                      {account_type === "corporate" && (
                        <Grid container item lg={12}>
                          {searchUserData?.profile?.personal_details
                            ?.countryList?.length > 0 ? (
                            <Grid
                              container
                              item
                              lg={12}
                              mt={1}
                              justifyContent={"left"}
                            >
                              <Grid container>
                                <p className="font700 font16">
                                  {" "}
                                  Work Authorization
                                </p>
                              </Grid>
                              <Grid container gap={"20px"}>
                                <Grid item justifyContent={"left"}>
                                  <Grid container>
                                    <p className="font700 font14">Country</p>
                                  </Grid>
                                  <Grid item>
                                    <p className="font400 font14">
                                      {searchUserData?.profile?.personal_details?.countryList?.join(
                                        ", "
                                      )}
                                    </p>
                                  </Grid>
                                </Grid>
                                <Grid item justifyContent={"left"}>
                                  <Grid container>
                                    <p className="font700 font14">Visa Type</p>
                                  </Grid>
                                  <Grid item>
                                    <p className="font400 font14">
                                      {searchUserData?.profile?.personal_details?.authType?.join(
                                        ", "
                                      )}
                                    </p>
                                  </Grid>
                                </Grid>
                                <Grid item justifyContent={"left"}>
                                  <Grid container>
                                    <p className="font700 font14">
                                      Expiry Date
                                    </p>
                                  </Grid>
                                  <Grid item ml={0.5}>
                                    <p className="font400 font14">
                                      {searchUserData?.profile?.personal_details?.authExp?.join(
                                        ", "
                                      )}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              item
                              lg={6}
                              mt={1}
                              justifyContent={"left"}
                            ></Grid>
                          )}
                        </Grid>
                      )}
                      {account_type === "corporate" && (
                        <Grid mt={1} container item lg={12}>
                          <Grid
                            style={{ display: "flex" }}
                            item
                            lg={6}
                            conatiner
                            gap={"10px"}
                          >
                            <Grid
                              item
                              // lg={1}
                              width={"35px"}
                              height={"35px"}
                              onClick={() => {
                                dispatch(
                                  postTagUser({
                                    individualId: userId,
                                    favourite:
                                      !searchUserData?.taggedDetails?.favourite,
                                  })
                                );
                              }}
                              style={{
                                background: "#D9D9D9",
                                fontSize: "30px",
                                borderRadius: "5px",
                              }}
                            >
                              <StarIcon
                                style={{
                                  color: searchUserData?.taggedDetails
                                    ?.favourite
                                    ? "red"
                                    : "#FAFAFA",
                                  fontSize: "30px",
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              width={"35px"}
                              height={"35px"}
                              onClick={() => {
                                dispatch(
                                  postTagUser({
                                    individualId: userId,
                                    shortlisted:
                                      !searchUserData?.taggedDetails
                                        ?.shortlisted,
                                  })
                                );
                              }}
                              style={{
                                background: "#D9D9D9",
                                fontSize: "30px",
                                borderRadius: "5px",
                              }}
                            >
                              <BookIcon
                                style={{
                                  color: searchUserData?.taggedDetails
                                    ?.shortlisted
                                    ? "red"
                                    : "#FAFAFA",
                                  fontSize: "30px",
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              width={"35px"}
                              height={"35px"}
                              style={{
                                background: "#D9D9D9",
                                fontSize: "30px",
                                borderRadius: "5px",
                              }}
                              onClick={() => setOpenModal(true)}
                            >
                              <MapsUgcIcon
                                style={{ color: "#1C1B1F", fontSize: "30px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="smLinksExperiance" container mt={3}>
                  <Grid container item lg={6} justifyContent={"left"}>
                    <Grid container>
                      <p className="font700 font16">Social Media</p>
                    </Grid>

                    <Grid container gap={2} mt={3}>
                      {searchUserData?.profile?.social_links?.instagram
                        ?.length > 0 && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://instagram.com/${searchUserData?.profile?.social_links?.instagram}`}
                          target="_blank"
                        >
                          <img
                            src={instaColorIcon}
                            className="instaLogo socialIconss"
                            alt="Instagram Logo"
                          />
                        </a>
                      )}
                      {searchUserData?.profile?.social_links?.facebook?.length >
                        0 && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://facebook.com/${searchUserData?.profile?.social_links?.facebook}`}
                          target="_blank"
                        >
                          <img
                            src={facebookColorIcon}
                            className="facebookLogo socialIconss"
                            alt="Facebook Logo"
                          />
                        </a>
                      )}
                      {searchUserData?.profile?.social_links?.linkedin?.length >
                        0 && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://linkedin.com/in/${searchUserData?.profile?.social_links?.linkedin}`}
                          target="_blank"
                        >
                          <img
                            src={linkedInColorIcon}
                            className="facebookLogo socialIconss"
                            alt="linkedin Logo"
                          />
                        </a>
                      )}

                      {searchUserData?.profile?.social_links?.twitter?.length >
                        0 && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://twitter.com/${searchUserData?.profile?.social_links?.twitter}`}
                          target="_blank"
                        >
                          <img
                            src={twitterColorIcon}
                            style={{
                              height: "30px",
                              width: "auto",
                            }}
                            alt="Facebook Logo"
                          />
                        </a>
                      )}

                      {searchUserData?.profile?.social_links?.pinterest
                        ?.length > 0 && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://pinterest.com/${searchUserData?.profile?.social_links?.pinterest}`}
                          target="_blank"
                        >
                          <img
                            src={pintrestColorIcon}
                            // className="twitterIcon"
                            alt="Facebook Logo"
                            style={{
                              height: "45px",
                              width: "45px",
                              margin: "-7px",
                            }}
                          />
                        </a>
                      )}

                      {searchUserData?.profile?.social_links?.github?.length >
                        0 && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://github.com/${searchUserData?.profile?.social_links?.github}`}
                          target="_blank"
                        >
                          <img
                            src={githubColorIcon}
                            className="facebookLogo socialIconss"
                            alt="Facebook Logo"
                          />
                        </a>
                      )}
                    </Grid>
                  </Grid>
                  {account_type === "corporate" && (
                    <Grid container item lg={6} justifyContent={"left"}>
                      <Grid container>
                        <p className="font700 font16">Communities Joined:</p>
                      </Grid>
                      {searchUserData?.communities?.length > 0 && (
                        <Grid container gap={1} mt={3}>
                          <ClickableChips
                            label={`${searchUserData?.communities[0]?.title} - ${searchUserData?.communities[0]?.posts} POSTS`}
                          />
                          <ClickableChips
                            label={`${searchUserData?.communities[1]?.title} - ${searchUserData?.communities[1]?.posts} POSTS`}
                          />
                          <ClickableChips
                            label={`+ ${searchUserData?.communities?.length} more`}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container className="deatilsGrid" textAlign={"left"}>
                <Grid className="leftGrid" item lg={6.8} p={2}>
                  <Grid className="aboutGrid" mt={2}>
                    <p className="font800 font20 sectionHeading">About</p>
                    <p className="font400 font16 sectionDetails">
                      <span className="styled-about">
                        {searchUserData?.profile?.personal_details?.aboutMe
                          ? parse(
                              searchUserData?.profile?.personal_details?.aboutMe
                            )
                          : "No Summary Available"}
                      </span>
                    </p>
                  </Grid>

                  <Grid className="experianceGrid" mt={3}>
                    <p className="font800 font20 sectionHeading">
                      Work Experience
                    </p>
                    {searchUserData?.profile?.work_experience?.designations?.map(
                      (details, i) => {
                        const orgNames =
                          searchUserData?.profile?.work_experience?.orgNames[i];
                        const startY =
                          searchUserData?.profile?.work_experience?.startY[i];
                        const endY =
                          searchUserData?.profile?.work_experience?.endY[i];
                        const tillDate =
                          searchUserData?.profile?.work_experience?.tillDate[i];
                        const skillsP =
                          searchUserData?.profile?.work_experience?.skillsP[i];
                        return (
                          <Grid className="experianceCard" mt={4}>
                            <Grid container justifyContent={"space-between"}>
                              <Grid>
                                <p className="font800 font16 sectionHeading">
                                  {details}
                                </p>
                                <p className="font400 font16 sectionDetails">
                                  {orgNames}
                                </p>
                              </Grid>
                              <Grid>
                                <p className="font800 font16 sectionHeading">
                                  {startY} - {tillDate ? "Present" : endY}
                                </p>
                              </Grid>
                            </Grid>
                            <Grid mt={2}>
                              <p className="font400 font16 sectionDetails">
                                {searchUserData?.profile?.work_experience
                                  ?.rnrs &&
                                searchUserData?.profile?.work_experience?.rnrs
                                  .length &&
                                searchUserData?.profile?.work_experience?.rnrs[
                                  i
                                ] &&
                                searchUserData?.profile?.work_experience?.rnrs[
                                  i
                                ].length
                                  ? parse(
                                      searchUserData?.profile?.work_experience
                                        ?.rnrs[i]
                                    )
                                  : null}
                              </p>
                            </Grid>
                            <Grid mt={2}>
                              <p className="font800 font16 sectionHeading">
                                Primary Skills
                              </p>
                              <Grid container mt={2} gap={"10px"}>
                                {skillsP?.map((i) => (
                                  <ClickableChips label={i} />
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>

                  <Grid className="educationGrid" mt={3}>
                    <p className="font800 font20 sectionHeading">Education</p>

                    {searchUserData?.profile?.education?.degree?.map(
                      (details, i) => {
                        const endY =
                          searchUserData?.profile?.education?.endY[i];
                        const startY =
                          searchUserData?.profile?.education?.startY[i];
                        const institute =
                          searchUserData?.profile?.education?.institute[i];
                        return (
                          <Grid className="educationCard" mt={3} p={2}>
                            <Grid container justifyContent={"space-between"}>
                              <Grid>
                                <p className="font800 font16 sectionHeading">
                                  {institute}
                                </p>
                                <p className="font400 font16 sectionDetails">
                                  {details}
                                </p>
                              </Grid>
                              <Grid>
                                <p className="font800 font16 sectionHeading">
                                  {startY} - {endY}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </Grid>

                <Divider orientation="vertical" variant="middle" flexItem />

                <Grid className="rightGrid" item lg={5} p={2}>
                  <Grid className="skillsGrid" mt={2}>
                    <p className="font800 font20 sectionHeading">Skills</p>
                    <Grid container mt={2} gap={"10px"}>
                      {skillsPShow &&
                        skillsPShow.map((i) => <ClickableChips label={i} />)}
                    </Grid>
                  </Grid>
                  <Grid className="toolsGrid" mt={10}>
                    <p className="font800 font20 sectionHeading">
                      Tools, Technology and Framework
                    </p>
                    <Grid container mt={2} gap={"10px"}>
                      {skillsOShow &&
                        skillsOShow.map((i) => <ClickableChips label={i} />)}
                    </Grid>
                  </Grid>
                  <Grid className="expGrid" mt={10}>
                    <p className="font800 font20 sectionHeading">
                      Industry Experience
                    </p>
                    <Grid container mt={2} gap={"10px"}>
                      {uniqueIndustry?.map((i) => (
                        <ClickableChips label={i} />
                      ))}
                    </Grid>
                  </Grid>
                  {searchUserData?.profile?.work_experience?.total_experience >
                    7 && (
                    <Grid className="expGrid mentEdit" mt={10}>
                      <p className="font700 font20 sectionHeading">
                        Subjects to Mentor
                      </p>
                      <Grid container mt={2} gap={"10px"}>
                        {searchUserData?.profile?.mentorship?.subjects?.map(
                          (i) => (
                            <ClickableChips label={i} />
                          )
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ViewProfile;
