import { Divider, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../search/component/ViewProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import ClickableChips from "../../../library/custom/chip/ClickableChip";
import parse from "html-react-parser";
import Loader from "../../../library/common/Loader";
import ProfileHeader from "./ProfileHeader";
import { useNavigate } from "react-router-dom";
import { EDIT_PROFILE } from "../../../constant/routeContant";
import { getSalary } from "../../../redux/actions/editProfileAction";
import EditIcon from "@mui/icons-material/Edit";
import RangeSlider from "./RangeSlider";
import CustomizedSlider from "./Slider";
const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSalary());
  }, []);

  const NavigateProfileFrom = (tabId = 0) => {
    navigate(EDIT_PROFILE, {
      state: { tabId: tabId },
    });
  };

  const searchUserData = useSelector(
    (state) => state?.userDetails?.data?.response
  );

  const isLoading = useSelector((state) => state?.userDetails?.isLoading);

  let skillsOShow = [];
  if (
    searchUserData?.profile?.work_experience &&
    searchUserData?.profile?.work_experience?.skillsO &&
    searchUserData?.profile?.work_experience?.skillsO.length
  ) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsO.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsO[i],
        ]),
      ];
    }
    if (ski.length > 10) {
      skillsOShow = ski.splice(0, 10);
    } else {
      skillsOShow = ski;
    }
  }
  let skillsPShow = [];
  if (
    searchUserData?.profile &&
    searchUserData?.profile?.work_experience &&
    searchUserData?.profile?.work_experience?.skillsP &&
    searchUserData?.profile?.work_experience?.skillsP?.length
  ) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile?.work_experience?.skillsP?.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile?.work_experience?.skillsP[i],
        ]),
      ];
    }
    if (ski.length > 5) {
      skillsPShow = ski.splice(0, 5);
    } else {
      skillsPShow = ski;
    }
  }
  const [uniqueIndustry, setuniqueIndustry] = useState([]);
  useEffect(() => {
    setuniqueIndustry([
      ...new Set(
        searchUserData?.profile?.work_experience?.industry?.map((i) => i)
      ),
    ]);
  }, [searchUserData]);

  const salaryData = useSelector((state) => state?.getSalary?.data?.msg);
  return (
    <>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="overrideViewProfile">
            <ProfileHeader
              searchUserData={searchUserData}
              NavigateProfileFrom={NavigateProfileFrom}
            />

            <Grid
              display={{ lg: "none", md: "none", sm: "none" }}
              container
              item
              className="centerDiv"
              textAlign={"left"}
            >
              <Grid item lg={12} xs={12} className="sliders">
                <Grid
                  className="profileCompletionSlider"
                  item
                  lg={12}
                  xs={12}
                  justifyContent={"left"}
                >
                  <Grid item lg={12} mt={2}>
                    <p className="font700 font16">Profile Completion</p>
                  </Grid>
                  <Grid container mt={3}>
                    <CustomizedSlider
                      value={searchUserData?.profileCompletionPercentage}
                    />
                  </Grid>
                </Grid>

                {searchUserData?.profile?.work_experience?.current_salary
                  ?.showCompensations ? (
                  <Grid
                    className="compensationSlider"
                    container
                    item
                    lg={12}
                    justifyContent={"left"}
                  >
                    <Grid
                      item
                      container
                      lg={12}
                      mt={1}
                      className="compensationSlide"
                    >
                      <p className="font700 font16">
                        Compensation:{" "}
                        <span
                          className={
                            parseInt(salaryData?.meanSalary) <
                            salaryData?.mySalary
                              ? "dotRed"
                              : "dotGreen"
                          }
                        >
                          Yours
                        </span>{" "}
                        vs{" "}
                        <span
                          className={
                            parseInt(salaryData?.meanSalary) <
                            salaryData?.mySalary
                              ? "dotGreen"
                              : "dotRed"
                          }
                        >
                          {" "}
                          Median
                        </span>
                      </p>
                      {searchUserData?.profile?.work_experience?.current_salary
                        ?.amount > 0 ? (
                        <RangeSlider
                          searchUserData={searchUserData}
                          salary={salaryData}
                        />
                      ) : (
                        <p className="font400  font14">
                          Check your position by providing your compensation
                          data.
                        </p>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>

              {searchUserData?.basicDetails?.citizenship !==
                (null || undefined || "") && (
                <Grid
                  container
                  item
                  lg={12}
                  mt={1}
                  justifyContent={"left"}
                  className="workingDetails"
                >
                  <Grid item>
                    <p className="font700 font16">Citizenship :</p>
                  </Grid>{" "}
                  <Grid ml={0.5} item>
                    <p className="font400 font16">
                      {searchUserData?.basicDetails?.citizenship}
                    </p>
                  </Grid>
                </Grid>
              )}
              <Grid container className="workingDetails1">
                <Grid container item lg={12} mt={3}>
                  {searchUserData?.profile?.personal_details?.countryList
                    ?.length > 0 ? (
                    <Grid container item lg={12} mt={1} justifyContent={"left"}>
                      <Grid container className="workingDetails">
                        <p className="font700 font16"> Work Authorization</p>
                      </Grid>
                      <Grid container gap={"20px"} className="workingDetails">
                        <Grid item justifyContent={"left"}>
                          <Grid container>
                            <p className="font700 font14">Country</p>
                          </Grid>
                          <Grid item>
                            <p className="font400 font14">
                              {searchUserData?.profile?.personal_details?.countryList?.join(
                                ", "
                              )}
                            </p>
                          </Grid>
                        </Grid>
                        <Grid item justifyContent={"left"}>
                          <Grid container>
                            <p className="font700 font14">Visa Type</p>
                          </Grid>
                          <Grid item>
                            <p className="font400 font14">
                              {searchUserData?.profile?.personal_details?.authType?.join(
                                ", "
                              )}
                            </p>
                          </Grid>
                        </Grid>
                        <Grid item justifyContent={"left"}>
                          <Grid container>
                            <p className="font700 font14">Expiry Date</p>
                          </Grid>
                          <Grid item ml={0.5}>
                            <p className="font400 font14">
                              {searchUserData?.profile?.personal_details?.authExp?.join(
                                ", "
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              {searchUserData?.profile?.work_experience?.total_experience !==
                (undefined || null || "") && (
                <Grid
                  container
                  item
                  lg={12}
                  mt={4}
                  justifyContent={"left"}
                  className="workingDetails"
                >
                  <Grid item>
                    <p className="font700 font16">Total Years of Experience:</p>
                  </Grid>
                  <Grid item ml={0.5}>
                    <p className="font400 font16">
                      {
                        searchUserData?.profile?.work_experience
                          ?.total_experience
                      }{" "}
                      years
                    </p>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container className="deatilsGrid" textAlign={"left"} mt={4}>
              <Grid
                className="leftGrid"
                item
                lg={6.8}
                xs={11}
                p={{ lg: 2, md: 2, sm: 2, xs: 0 }}
              >
                <Grid className="aboutGrid aboutEdit" mt={2}>
                  <p className="font700 font20 sectionHeading">
                    About{" "}
                    <span className="editIcon">
                      <IconButton
                        color="#2e3a59"
                        aria-label="add to shopping cart"
                        onClick={() => NavigateProfileFrom(0)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </p>
                  <p className="font400 font16 sectionDetails">
                    <span className="styled-about">
                      {searchUserData?.profile?.personal_details?.aboutMe
                        ? parse(
                            searchUserData?.profile?.personal_details?.aboutMe
                          )
                        : "No Summary Available"}
                    </span>
                  </p>
                </Grid>

                <Grid className="aboutGrid" mt={2}>
                  {searchUserData?.profile?.media?.videoshot ? (
                    searchUserData?.profile?.media?.videoshot
                      .substring(
                        searchUserData?.profile.media.videoshot.length - 3
                      )
                      .toLowerCase() === "pdf" ? (
                      <embed
                        height={258}
                        scrolling={0}
                        className="fit-layout"
                        src={searchUserData?.profile.media.videoshot}
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      ></embed>
                    ) : (
                      <video
                        id="vid2"
                        style={{
                          width: "100%",
                          height: "270px",
                          borderRadius: "15px",
                        }}
                        controls
                        key={searchUserData?.profile.media.videoshot}
                        autoPlay="autoPlay"
                        muted
                        playsInline
                      >
                        <source
                          src={searchUserData?.profile.media.videoshot}
                          type="video/mp4"
                        />
                        <source
                          src={searchUserData?.profile.media.videoshot}
                          type="video/webm"
                        />
                      </video>
                    )
                  ) : (
                    ""
                    // <h1>No intro</h1>
                  )}
                </Grid>
                <Grid item className="experianceGrid expEdit" mt={3}>
                  <p className="font700 font20 sectionHeading">
                    Work and Board Experience
                    <span className="editIcon">
                      <IconButton
                        color="#2e3a59"
                        aria-label="add to shopping cart"
                        onClick={() => NavigateProfileFrom(2)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </p>

                  {searchUserData?.profile?.work_experience?.designations?.map(
                    (details, i) => {
                      const orgNames =
                        searchUserData?.profile?.work_experience?.orgNames[i];
                      const startY =
                        searchUserData?.profile?.work_experience?.startY[i];
                      const endY =
                        searchUserData?.profile?.work_experience?.endY[i];
                      const tillDate =
                        searchUserData?.profile?.work_experience?.tillDate[i];
                      const skillsP = searchUserData?.profile?.work_experience
                        ?.skillsP
                        ? searchUserData?.profile?.work_experience?.skillsP[i]
                        : [];
                      return (
                        <Grid className="educationCard" mt={3} p={2}>
                          <Grid className="experianceCard" mt={2}>
                            <Grid container justifyContent={"space-between"}>
                              <Grid>
                                <p className="font700 font16 sectionHeading">
                                  {details}
                                </p>
                                <p className="font400 font16 sectionDetails">
                                  {orgNames}
                                </p>
                              </Grid>
                              <Grid>
                                <p className="font700 font16 sectionHeading">
                                  {startY} - {tillDate ? "Present" : endY}
                                </p>
                              </Grid>
                            </Grid>
                            <Grid mt={2}>
                              <p className="font400 font16 sectionDetails">
                                Working in{" "}
                                {searchUserData?.profile?.work_experience
                                  .industry &&
                                searchUserData?.profile?.work_experience
                                  .industry.length
                                  ? searchUserData?.profile?.work_experience
                                      .industry[i]
                                  : "Not Available"}{" "}
                                industry as a{" "}
                                {searchUserData?.profile?.work_experience
                                  .role &&
                                searchUserData?.profile?.work_experience.role
                                  .length
                                  ? searchUserData?.profile?.work_experience
                                      .role[i]
                                  : "Not Available"}
                                {`. Started in ${
                                  searchUserData?.profile?.work_experience
                                    .startM &&
                                  searchUserData?.profile?.work_experience
                                    .startM.length
                                    ? searchUserData?.profile?.work_experience
                                        .startM[i]
                                    : ""
                                }`}
                                ,{" "}
                                {searchUserData?.profile?.work_experience
                                  .startY &&
                                searchUserData?.profile?.work_experience.startY
                                  .length
                                  ? searchUserData?.profile?.work_experience
                                      .startY[i]
                                  : ""}{" "}
                                {searchUserData?.profile?.work_experience
                                  .tillDate[i]
                                  ? "and still working"
                                  : searchUserData?.profile?.work_experience
                                      .endM &&
                                    searchUserData?.profile?.work_experience
                                      .endM[i] === "Currently Working"
                                  ? "and still working"
                                  : `and worked till ${
                                      searchUserData?.profile?.work_experience
                                        .endM &&
                                      searchUserData?.profile?.work_experience
                                        .endM.length
                                        ? searchUserData?.profile
                                            ?.work_experience.endM[i]
                                        : ""
                                    }, ${
                                      searchUserData?.profile?.work_experience
                                        .endY &&
                                      searchUserData?.profile?.work_experience
                                        .endY.length
                                        ? searchUserData?.profile
                                            ?.work_experience.endY[i]
                                        : ""
                                    }`}
                              </p>
                            </Grid>

                            <Grid mt={2}>
                              <p className="font700 font16 sectionHeading">
                                Primary Skills
                              </p>
                              <Grid container mt={2} gap={"10px"}>
                                {skillsP?.map((i) => (
                                  <ClickableChips label={i} />
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                  <Grid className="educationCard" mt={3} p={2}>
                    <p className="font700 font20 sectionHeading">
                      Board Experience
                    </p>
                    {searchUserData?.profile?.board_experience?.boardName?.map(
                      (boardName, i) => {
                        const boardType =
                          searchUserData?.profile?.board_experience?.boardType[
                            i
                          ];
                        const boardStartM =
                          searchUserData?.profile?.board_experience
                            ?.boardStartM[i];
                        const boardStartY =
                          searchUserData?.profile?.board_experience
                            ?.boardStartY[i];

                        return (
                          <Grid
                            container
                            mt={2}
                            justifyContent={"space-between"}
                          >
                            <Grid>
                              <p className="font700 font16 sectionHeading">
                                {boardName}
                              </p>{" "}
                              <p className="font400 font16 sectionDetails">
                                {boardType}
                              </p>
                            </Grid>
                            <Grid>
                              <p className="font700 font16 sectionDetails">
                                {boardStartM} - {boardStartY}
                              </p>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Grid
                className="rightGrid"
                item
                lg={5}
                xs={11}
                p={{ lg: 2, md: 2, sm: 2, xs: 0 }}
              >
                <Grid className="skillsGrid skillEdit" mt={2}>
                  <p className="font700 font20 sectionHeading">
                    My Skills
                    <span className="editIcon">
                      <IconButton
                        color="#2e3a59"
                        aria-label="add to shopping cart"
                        onClick={() => NavigateProfileFrom(2)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </p>
                  <Grid container mt={2} gap={"10px"}>
                    {skillsPShow &&
                      skillsPShow.map((i) => <ClickableChips label={i} />)}
                  </Grid>
                </Grid>
                <Grid className="toolsGrid toolsEdit" mt={10}>
                  <p className="font700 font20 sectionHeading">
                    Tools, Technology and Framework
                    <span className="editIcon">
                      <IconButton
                        color="#2e3a59"
                        aria-label="add to shopping cart"
                        onClick={() => NavigateProfileFrom(2)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </p>
                  <Grid container mt={2} gap={"10px"}>
                    {skillsOShow &&
                      skillsOShow.map((i) => <ClickableChips label={i} />)}
                  </Grid>
                </Grid>
                <Grid className="expGrid indEdit" mt={10}>
                  <p className="font700 font20 sectionHeading">
                    Industry Experience
                    <span className="editIcon">
                      <IconButton
                        color="#2e3a59"
                        aria-label="add to shopping cart"
                        onClick={() => NavigateProfileFrom(2)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </p>
                  <Grid container mt={2} gap={"10px"}>
                    {uniqueIndustry?.map((i) => (
                      <ClickableChips label={i} />
                    ))}
                  </Grid>
                </Grid>
                {searchUserData?.profile?.work_experience?.total_experience >
                  7 && (
                  <Grid className="expGrid mentEdit" mt={10}>
                    <p className="font700 font20 sectionHeading">
                      Mentorship Topics
                      <span className="editIcon">
                        <IconButton
                          color="#2e3a59"
                          aria-label="add to shopping cart"
                          onClick={() => NavigateProfileFrom(3)}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </p>
                    <Grid container mt={2} gap={"10px"}>
                      {searchUserData?.profile?.mentorship?.subjects?.map(
                        (i) => (
                          <ClickableChips label={i} />
                        )
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </>
    </>
  );
};

export default Profile;
