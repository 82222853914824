import { settingType } from "../constant/settingType";

const initialState = {
  flow: "Setting",
  isError: false,
  isLoading: false,
};

export const getRecoveryEmailVerifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.GET_RECOVERY_EMAIL_VERIFY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.GET_RECOVERY_EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.GET_RECOVERY_EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deactivateAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.GET_DEACTIVATE_ACCOUNT_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.GET_DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.GET_DEACTIVATE_ACCOUNT_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.PUT_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.PUT_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.PUT_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const paymentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.GET_PAYMENT_DETAILS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.GET_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.GET_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.GET_UPDATE_EMAIL_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.GET_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.GET_UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addCorporateMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.POST_ADD_CORPORATE_MEMBER_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.POST_ADD_CORPORATE_MEMBER_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.POST_ADD_CORPORATE_MEMBER_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const removeCorporateMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingType.POST_REMOVE_CORPORATE_MEMBER_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case settingType.POST_REMOVE_CORPORATE_MEMBER_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case settingType.POST_REMOVE_CORPORATE_MEMBER_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
