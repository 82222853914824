import React, { useEffect, useState } from "react";
import BookSessionForm from "../component/BookSessionForm";
import { postSearchUser } from "../../../redux/actions/searchAction";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import * as mentorshipAction from "../../../redux/actions/mentorshipAction";
import { convertUTCToTimezone } from "../../../utils/helper";
const BookSessionFormContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mentorId = location?.state?.mentorId;
  const uuid = location?.state?.uuid;
  const searchUserResponse = useSelector((state) => state?.searchUserReducer);
  const bookSessionData = useSelector(
    (state) => state?.postMentorshipBookSession
  );
  const slots =
    searchUserResponse?.data?.response?.userProfile?.profile?.mentorship
      ?.availableSlots;
  const [slotsArr, setslotsArr] = useState([]);
  const timeZone = useSelector(
    (state) =>
      state?.userDetails?.data?.response?.profile?.personal_details?.timezone ??
      ""
  );
  useEffect(() => {
    let arr = [];
    console.log(arr);
    slots?.forEach((i) => {
      let utcTime = timeZone ? convertUTCToTimezone(i, timeZone) : i;
      arr.push(utcTime);
    });
    setslotsArr(arr);
  }, [slots]);

  useEffect(() => {
    dispatch(
      postSearchUser({
        userId: mentorId,
      })
    );
  }, []);

  function onBook(params) {
    // console.log(params);
    dispatch(mentorshipAction.postMentorshipBookSession(params));
  }
  useEffect(() => {
    if (!bookSessionData.isLoading && bookSessionData.status === "SUCCESS")
      navigate(-1);
  }, [bookSessionData]);

  return (
    <div>
      {bookSessionData.isLoading && <Loader />}
      <BookSessionForm uuid={uuid} slots={slotsArr ?? []} onBook={onBook} />
    </div>
  );
};

export default BookSessionFormContainer;
