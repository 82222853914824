import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GridTable from "../../library/custom/gridTable/GridTable";
import { useDispatch, useSelector } from "react-redux";
// import { getAdminAboutToExpireUsers } from "../../services/adminServie";
import moment from "moment";
import { getAdminAboutToExpireUsers } from "../../redux/actions/adminAction";

const ExpiringUsers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminAboutToExpireUsers());
  }, []);

  const getAdminAboutToExpireUsersData = useSelector(
    (state) => state?.getAdminAboutToExpireUsers?.data?.response
  );

  useEffect(() => {
    fetchList();
  }, [getAdminAboutToExpireUsersData]);

  const [corporates, setCorporates] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchList = (data) => {
    let corporateList = [];
    let userList = [];
    getAdminAboutToExpireUsersData?.userData?.map((i) => {
      i.name = i.name ? i.name : "Guest";
      i.expiryDate = moment(i.expiryDate).format("MMM Do YYYY");
      userList = [...userList, i];
    });
    getAdminAboutToExpireUsersData?.corporateData?.map((i) => {
      i.name = i.name ? i.name : "Guest";
      i.expiryDate = moment(i.expiryDate).format("MMM Do YYYY");
      corporateList = [...corporateList, i];
    });
    setUsers(userList);
    setCorporates(corporateList);
    // setcommunityList(communityList);
  };

  const columns = [
    { field: "name", headerName: "User Name", flex: 1 },
    { field: "email", headerName: "User Email", flex: 1 },
    {
      field: "expiryDate",
      headerName: "Expiring on",
      flex: 1,
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item mt={2} lg={12}>
          <Grid container>
            <h2>Expiring Users</h2>
          </Grid>
          <GridTable
            getRowId={(r) => r.name}
            rows={users ?? []}
            // rowCount={assetList?.itemsCount ?? 0}
            columns={columns}
            // loading={isAssetLoading}
            // page={page}
            // pagination
            // pageSize={PAGE_SIZE}
            // rowsPerPageOptions={[PAGE_SIZE]}
            // onPageChange={handlePagignation}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
            // components={{
            //   LoadingOverlay: TableLoader,
            //   NoRowsOverlay: NORow,
            // }}
          />
        </Grid>
        <Grid item mt={2} lg={12}>
          <Grid container>
            <h2>Expiring Corporates</h2>
          </Grid>
          <GridTable
            getRowId={(r) => r.name}
            rows={corporates ?? []}
            // rowCount={assetList?.itemsCount ?? 0}
            columns={columns}
            // loading={isAssetLoading}
            // page={page}
            // pagination
            // pageSize={PAGE_SIZE}
            // rowsPerPageOptions={[PAGE_SIZE]}
            // onPageChange={handlePagignation}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
            // components={{
            //   LoadingOverlay: TableLoader,
            //   NoRowsOverlay: NORow,
            // }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExpiringUsers;
