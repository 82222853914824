import { put, get, post } from "./axiosRequest";
import { getApiUrl } from "../config";
// post job
export const postJob = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

// update job
export const UpdateJob = async ({ data }) => {
  console.log("data", data);
  const { id, sendDataEdit } = data;
  const token = sessionStorage.getItem("token");
  const url = `
  ${getApiUrl().baseUrl}/job/info/${data.id}`;
  try {
    return await put(url, sendDataEdit, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postedJob = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/posted?page=${data.page}&limit=${
    data.limit
  }`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postedJobDetails = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/info/${data.jobId}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const jobInfo = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/info/${data}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const jobFind = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/find?search=${data?.title}&location=${
    data?.location
  }&page=1&limit=1000`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const applyJob = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/apply`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const appliedJobs = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/applied?page=1&limit=10000`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const applicantDetails = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/job/applicants/${data?.JobId}?page=${
    data?.page
  }&limit=${data?.limit}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
