import React, { Component, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";
import team1 from "../../assets/Landing/team1.png";
import team2 from "../../assets/Landing/team2.png";
import team3 from "../../assets/Landing/team3.png";
import team4 from "../../assets/Landing/team4.png";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Grid } from "@mui/material";

function Carousel() {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="sliderOverride">
      <Slider ref={sliderRef} {...settings}>
        <div>
          <div className="cardContainer">
            <div className="cardContendBody">
              <Grid  height={{ sm: "640px",md:"300px",md:"300px" }} className="content">
                I joined the MatchupIT platform. Interestingly, it allows direct
                communication channels between companies on this platform with
                an individual. I input all my data on this platform by just
                spending 20 minutes. Next week, I plan to join a couple of
                communities to write blogs and interact with other experts. This
                platform will be popular in no time.
              </Grid>
              <div className="imageBody">
                <img src={team1} className="" alt="Images" />
              </div>
              <div className="NameBody">Yogesh Mishra</div>
              <Grid  height={{ sm: "45px" }} className="Designation">
                Digital and Cloud Advisor,
                <br /> Atlanta, Georgia - USA
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="cardContainer">
            <div className="cardContendBody">
              <Grid   className="content">
                I joined the MatchupIT platform. Interestingly, it allows direct
                communication channels between companies on this platform with
                an individual. I input all my data on this platform by just
                spending 20 minutes. Next week, I plan to join a couple of
                communities to write blogs and interact with other experts. This
                platform will be popular in no time.
              </Grid>
              <div className="imageBody">
                <img src={team2} className="" alt="Images" />
              </div>
              <div className="NameBody">Shobhika Somani</div>
              <Grid  height={{ sm: "45px" }} className="Designation">
                Independent Strategy Consultant, Healthcare ,
                <br /> Atlanta, Georgia - USA
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="cardContainer">
            <div className="cardContendBody">
              <Grid  className="content">
                Creating a profile on MatchupIT is a must for every IT
                professional. It takes 15-20 minutes to create a digital profile
                on MatchupIT. The most important part is that the focus is not
                on how many people are connected socially but on growing by
                collaborating. The recruiters in the companies looking for
                talent should use this platform to recruit talent.
              </Grid>
              <div className="imageBody">
                <img src={team3} className="" alt="Images" />
              </div>
              <div className="NameBody">Amol Malhotra</div>
              <Grid height={{ sm: "45px" }} className="Designation">
                Head Software Delivery,
                <br /> Atlanta, Georgia - USA
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="cardContainer">
            <div className="cardContendBody">
              <Grid   className="content">
                I was apprehensive about creating my platform on MatchupIT, but
                once I made my profile, I realized the power of this platform.
                The ability to join a community of CIOs and collaborate with
                others with similar interests benefits. MatchupIT is an
                excellent platform for sourcing quality candidates for
                companies. It is becoming a go-to resource for collaboration and
                reaching quality technology experts.
              </Grid>
              <div className="imageBody">
                <img src={team4} className="" alt="Images" />
              </div>
              <div className="NameBody">Cindy Taylor</div>
              <Grid  height={{ sm: "45px" }} className="Designation">
                Former CIO, Fractional COO & CIO for startups,
                <br /> Atlanta, Georgia - USA
              </Grid>
            </div>
          </div>
        </div>
      </Slider>

      <div className="ArrowIcon" style={{ textAlign: "right" }}>
        <ArrowCircleLeftOutlinedIcon
          className="button"
          onClick={previous}
        ></ArrowCircleLeftOutlinedIcon>
        <ArrowCircleRightOutlinedIcon
          className="button"
          onClick={next}
        ></ArrowCircleRightOutlinedIcon>
      </div>
    </div>
  );
}

export default Carousel;
