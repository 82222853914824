import { SignupType } from "../constant/SignupType ";

export const checkUserExist = (data) => ({
  type: SignupType.POST_CHECK_USER_PENDING,
  data,
});

export const checkUserExistSuccess = (data) => ({
  type: SignupType.POST_CHECK_USER_SUCCESS,
  data,
});

export const checkUserExistFailure = (data) => ({
  type: SignupType.POST_CHECK_USER_FAILURE,
  data,
});

export const otpsignup = (data) => ({
  type: SignupType.GET_OTPSIGNUP_PENDING,
  data,
});

export const otpsignupSuccess = (data) => ({
  type: SignupType.GET_OTPSIGNUP_FAILURE,
  data,
});

export const otpsignupFailure = (data) => ({
  type: SignupType.GET_OTPSIGNUP_SUCCESS,
  data,
});

export const otpVerifysignup = (data) => ({
  type: SignupType.GET_OTP_VERIFY_SIGNUP_PENDING,
  data,
});

export const otpVerifysignupSuccess = (data) => ({
  type: SignupType.GET_OTP_VERIFY_SIGNUP_SUCCESS,
  data,
});

export const otpVerifysignupFailure = (data) => ({
  type: SignupType.GET_OTP_VERIFY_SIGNUP_FAILURE,
  data,
});

export const signup = (data) => ({
  type: SignupType.POST_SIGNUP_PENDING,
  data,
});

export const signupSuccess = (data) => ({
  type: SignupType.POST_SIGNUP_FAILURE,
  data,
});

export const signupFailure = (error) => ({
  type: SignupType.POST_SIGNUP_SUCCESS,
  error,
});

export const clearErrors = (error = {}) => ({
  type: SignupType.CLEAR_ERRORS,
  error,
});

export const signupWithGoogle = (data) => ({
  type: SignupType.POST_SIGNUP_GOOGLE_PENDING,
  data,
});

export const signupWithGoogleSuccess = (data) => ({
  type: SignupType.POST_SIGNUP_GOOGLE_SUCCESS,
  data,
});

export const signupWithGoogleFailure = (error) => ({
  type: SignupType.POST_SIGNUP_GOOGLE_FAILURE,
  error,
});
