import { FormControl, Grid, RadioGroup, Slider } from "@mui/material";
import "./setting.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../../library/custom/textBox/InputField";
import Buttons from "../../../library/custom/button/Button";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import {
  USER_ID,
  SALARY_RANGES,
  ACCOUNT_TYPE,
  SALARY_RANGE_INR,
} from "../../../constant/constant";
import { CURRENCYCODE } from "../../../constant/currencyCode";

import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { VERIFIED, NOTVERIFIED } from "../../../assets/index";
import scale from "../../../assets/scale.jpg";
import * as signupAction from "../../../redux/actions/SignupAction";
import * as settingAction from "../../../redux/actions/settingAction";
import CustomizedDialogs from "../../../library/common/DialogBox";
import { ConvertDate } from "../../../utils/helper";
import CustomizedSlider from "../../profile/component/Slider";
import { DELETEICON } from "../../../assets/index";
import SliderRange from "../../profile/component/SliderRange";
import DiscreteSliderLabel from "../../profile/component/SliderRange";
const Settings = ({
  getDeactivateAccount,
  changePassword,
  allCountries,
  paymentDetail,
  searchUserData,
  jobTypeData,
  onSubmitCompensation,
  getUserDetailsData,
  userDetails,
  putEditProfiles,
  recoveryEmailVerifys,
  otpVerifySignupData,
  searchUserData1,
  setSecondaryEmail,
  setPrimaryEmail,
  primaryEmail,
  secondaryEmail,
}) => {
  const Countries = allCountries?.data?.response?.countryResult;
  const Currencies = allCountries?.data?.response?.currencyResult;
  const currencyConcatenate = Currencies?.map(
    (currency) => `${currency?.name}(${currency?.code})`
  );
  const email = searchUserData?.basicDetails?.email;
  const email_verified = searchUserData?.basicDetails?.email_verified;
  const recovery_email = searchUserData?.basicDetails?.recovery_email;
  const recovery_email_verified =
    searchUserData?.basicDetails?.recovery_email_verified;

  // const { workCountry, amount, currency, salary_range, showCompensation } =
  //   searchUserData?.profile?.work_experience?.current_salary || {};
  const [primaryOtp, setPrimaryOtp] = useState("");
  const [secondaryOtp, setSecondaryOtp] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const emptyPassword = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const [rows, setRows] = useState(
    searchUserData?.profile?.work_experience?.current_salary?.salary_range
  );

  const [minSal, setMinSal] = useState(
    searchUserData?.profile?.work_experience?.current_salary?.amount
  );

  const [defaultSliderPos, setDefaultSliderPos] = useState(0);
  const [currencyCode, setCurrencyCode] = useState(
    searchUserData?.profile?.work_experience?.current_salary?.currency
  );

  const [selectedCountry, setSelectedCountry] = useState(
    searchUserData?.profile?.work_experience?.current_salary?.workCountry
  );

  useEffect(() => {
    if (searchUserData?.basicDetails) {
      setSecondaryEmail(searchUserData?.basicDetails?.recovery_email);
      setPrimaryEmail(searchUserData?.basicDetails?.email);
    }
  }, [searchUserData?.basicDetails]);

  const [salaryRange, setSalaryRange] = useState("");

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [marks, setmarks] = useState();
  const handleCountryChange = (event) => {
    const selectedCountrya = event.target.value;
    setSelectedCountry(selectedCountrya);
  };
  useEffect(() => {
    const findCurreny = Currencies?.find(
      (county) =>
        county?.code ==
        searchUserData?.profile?.work_experience?.current_salary?.currency
    );
    const currencyData = `${findCurreny?.name}(${findCurreny?.code})`;
    setSelectedCurrency(currencyData);
    const findSalaryRange = SALARY_RANGE_INR.find(
      (salary) =>
        salary?.rowNumner ==
        searchUserData?.profile?.work_experience?.current_salary?.salary_range
    );
    setSalaryRange(
      `${findSalaryRange?.value} (${searchUserData?.profile?.work_experience?.current_salary?.currency})`
    );
    setmarks(findSalaryRange?.marks);
    setminSliderVal(findSalaryRange?.minSal);
    setmaxSliderVal(findSalaryRange?.minSal + 500000);
    setShowCompensation(
      searchUserData?.profile?.work_experience?.current_salary
        ?.showCompensations
        ? "1"
        : "0"
    );
  }, [searchUserData?.profile?.work_experience]);
  const handleCurrencyChange = (event) => {
    const selectedCurrencys = event.target.value;
    setCurrencyCode(selectedCurrencys?.split("(")[1]);
    setSelectedCurrency(selectedCurrencys);
    setMuiSliderValue();
  };
  const [range, setRange] = useState([]);
  const [typeOfsalarys, setTypeOfSalarys] = useState(null);
  useEffect(() => {
    const typeOfSalary = currencyCode?.includes("INR")
      ? SALARY_RANGE_INR
      : SALARY_RANGES;
    setTypeOfSalarys(typeOfSalary);
    const removeLastOperator = currencyCode?.includes(")")
      ? currencyCode.slice(0, -1)
      : currencyCode;
    setRange(
      typeOfSalary?.map((item) => `${item?.value} (${removeLastOperator})`)
    );
  }, [selectedCurrency]);

  const [openModal, setOpenModal] = useState(false);
  const [showOpt, setShowOtp] = useState(false);
  const [showOtp2, setShowOtp2] = useState(false);
  const [addMember, setAddMember] = useState();
  const [showCompensations, setShowCompensation] = useState("1");
  const [sliderValue, setSliderValue] = useState("");
  const [minSliderVal, setminSliderVal] = useState();
  const [maxSliderVal, setmaxSliderVal] = useState();
  const changeSliderValue = (event, sliderValue) => {
    setSliderValue(sliderValue);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isSecondaryEmail, setisSecondaryEmail] = useState(false);

  const handleEmailEditClick = () => {
    setIsEditing(true);
  };

  function OpenOtpBox(event) {
    const value = event.target.value;
    setPrimaryOtp(event.target.value);
    if (value.length === 6) {
      const verifyPrimaryMailData = {
        email: primaryEmail,
        otp: event.target.value,
      };
      dispatch(signupAction.otpVerifysignup(verifyPrimaryMailData));
      const updateEmailDataPrimary = {
        email: primaryEmail,
        type: "recovery",
      };
    }
  }

  function OpenOtpBox2(event) {
    const value = event.target.value;
    setSecondaryOtp(event.target.value);
    if (value.length === 6) {
      const verifySecondaryMailData = {
        email: secondaryEmail,
        otp: parseInt(event.target.value),
      };
      dispatch(settingAction.recoveryEmailVerify(verifySecondaryMailData));
    }
  }

  const memberSubId = searchUserData?.accountHolder?.members?.[0]?.subId;
  const memberIsActive =
    searchUserData?.accountHolder?.members?.[0]?.is_active === 1 ? false : true;
  const removeCorporateMember = () => {
    const data = {
      userId: memberSubId,
      is_active: memberIsActive,
    };
    dispatch(settingAction.removeCorporateMemeber(data));
  };

  const dispatch = useDispatch();
  const data1 = {
    email: primaryEmail,
  };
  const data2 = {
    email: secondaryEmail,
  };

  const getsendOtp1 = () => {
    dispatch(signupAction.otpsignup(data1));
    setShowOtp(true);
  };
  const getsendOtp2 = () => {
    let validEMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (secondaryEmail.match(validEMail)) {
      setisSecondaryEmail(false);
      dispatch(signupAction.otpsignup(data2));
      setShowOtp2(true);
    } else {
      setisSecondaryEmail(true);
    }
  };

  function mappedData() {
    const data = {
      type: "individual",
      userId: USER_ID(),
      admin_reason: "string",
    };
    return data;
  }

  const deactivateAccount = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      getDeactivateAccount(data);
      sessionStorage.clear();
      // window.location.reload();
    }
  };

  function mappedChangePassword() {
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    return data;
  }

  const changeNewPassword = () => {
    const data = mappedChangePassword(false);
    if (!data) {
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordError("New Password & Confirm Password donot match");
    } else {
      setPasswordError("");
      changePassword(data);
      sessionStorage.clear();
      // window.location.reload();
    }
  };

  const membersDetails = searchUserData?.accountHolder?.members?.map(
    (element, index) => {
      return (
        <tr key={index}>
          <td> 1 </td>
          <td>{element?.email}</td>

          <td className="deleteMember">
            <span>{element?.is_active === 1 ? "Active" : "Inactive"}</span>

            <img
              src={DELETEICON}
              style={{ marginLeft: "80px" }}
              onClick={removeCorporateMember}
            />
          </td>
        </tr>
      );
    }
  );

  function handleShowCompensation(e) {
    let payload;
    console.log(e);
    setShowCompensation(e);
    if (userDetails?.profile?.work_experience !== undefined) {
      payload = {
        work_experience: {
          orgNames: userDetails?.profile?.work_experience?.orgNames,
          designations: userDetails?.profile?.work_experience?.designations,
          industry: userDetails?.profile?.work_experience?.industry,
          empType: userDetails?.profile?.work_experience?.empType,
          jobTitles: userDetails?.profile?.work_experience?.jobTitles,
          role: userDetails?.profile?.work_experience?.role,
          skillsP: userDetails?.profile?.work_experience?.skillsP,
          skillsO: userDetails?.profile?.work_experience?.skillsO,
          rnrs: userDetails?.profile?.work_experience?.rnrs,
          startM: userDetails?.profile?.work_experience?.startM,
          endM: userDetails?.profile?.work_experience?.endM,
          startY: userDetails?.profile?.work_experience?.startY,
          endY: userDetails?.profile?.work_experience?.endY,
          currentlyWorking:
            userDetails?.profile?.work_experience?.currentlyWorking,
          total_experience:
            userDetails?.profile?.work_experience?.total_experience,
          tillDate: userDetails?.profile?.work_experience?.tillDate,
          current_salary: {
            showCompensations: e == 1 ? true : false,
            workCountry: selectedCountry,
            amount: muiSliderValue ? muiSliderValue : minSliderVal,
            currency: currencyCode.split(")")[0],
            salary_range: rows,
          },
        },
      };
    } else {
      payload = {
        work_experience: {
          orgNames: Array.from(""),
          designations: Array.from(""),
          industry: Array.from(""),
          empType: Array.from(""),
          jobTitles: Array.from(""),
          role: Array.from(""),
          skillsP: [Array.from("")],
          skillsO: [Array.from("")],
          rnrs: Array.from(""),
          startM: Array.from(""),
          endM: Array.from(""),
          startY: Array.from(""),
          endY: Array.from(""),
          currentlyWorking: false,
          total_experience: 0,
          tillDate: Array.from(""),
          current_salary: {
            showCompensations: e == 1 ? true : false,
            workCountry: selectedCountry,
            amount: calMinSal ? calMinSal : minSal,
            currency: currencyCode.split(")")[0],
            salary_range: rows,
          },
        },
      };
    }
    console.log(showCompensations);
    putEditProfiles(payload);
  }

  const handleAddMember = () => {
    const data = {
      email: addMember,
    };
    dispatch(settingAction.addCorporateMemeber(data));
    setAddMember("");
  };
  const [jobTypeState, setJobTypeState] = useState("");
  const setJobTypes = (e) => {
    setJobTypeState(e.target.value);
  };

  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const setSelectedCurrencyCodes = (e) => {
    setSelectedCurrencyCode(e.target.value);
  };
  const [compensation, setCompensation] = useState("");
  const setSalaryRangeOnChange = (e) => {
    setSalaryRange(e.target.value);
  };

  const [participations, setParticipations] = useState([]);

  useEffect(() => {
    const valueSalary = salaryRange.split("(")[0];
    const findObject = typeOfsalarys?.find(
      (typeOfSal) => typeOfSal.value == valueSalary.trim()
    );
    setMinSal(findObject?.minSal);
    setRows(findObject?.rowNumner);
    setParticipations(findObject?.partitation);
    setmarks(findObject?.marks);
    setminSliderVal(findObject?.minSal);
    setmaxSliderVal(findObject?.minSal + 500000);
    setMuiSliderValue(null);
  }, [salaryRange]);
  useEffect(() => {
    handleDrag("reset");
  }, [participations]);
  const [calMinSal, setCalMinSal] = useState(0);
  const [muiSliderValue, setMuiSliderValue] = useState();
  const handleDrag = (reset, value) => {
    let fullWidth = document.getElementById("sliderRange")?.offsetWidth;
    let element = document.getElementById("rangeMarker");
    setMuiSliderValue(value);
  };

  const submitCompensation = () => {
    let payload = {
      jobTypeId: jobTypeState,
      compensationCurrency: selectedCurrencyCode,
      compensationValue: compensation,
    };
    if (getUserDetailsData.response.length > 0) {
      payload["jobId"] =
        getUserDetailsData?.response[
          getUserDetailsData?.response?.length - 1
        ].Id;
    }
    onSubmitCompensation(payload);
  };
  useEffect(() => {
    if (getUserDetailsData?.response?.length > 0) {
      const lastElement =
        getUserDetailsData?.response[getUserDetailsData?.response?.length - 1];
      setJobTypeState(lastElement?.JobTypeId);
      setSelectedCurrencyCode(lastElement?.CompensationCurrency);
      setCompensation(Math.round(lastElement?.CompensationValue * 100) / 100);
    }
  }, [getUserDetailsData?.response]);

  const onSubmitWorkExp = () => {
    let payload;
    let validPayload = true;
    if (userDetails?.profile?.work_experience !== undefined) {
      payload = {
        work_experience: {
          orgNames: userDetails?.profile?.work_experience?.orgNames,
          designations: userDetails?.profile?.work_experience?.designations,
          industry: userDetails?.profile?.work_experience?.industry,
          empType: userDetails?.profile?.work_experience?.empType,
          jobTitles: userDetails?.profile?.work_experience?.jobTitles,
          role: userDetails?.profile?.work_experience?.role,
          skillsP: userDetails?.profile?.work_experience?.skillsP,
          skillsO: userDetails?.profile?.work_experience?.skillsO,
          rnrs: userDetails?.profile?.work_experience?.rnrs,
          startM: userDetails?.profile?.work_experience?.startM,
          endM: userDetails?.profile?.work_experience?.endM,
          startY: userDetails?.profile?.work_experience?.startY,
          endY: userDetails?.profile?.work_experience?.endY,
          currentlyWorking:
            userDetails?.profile?.work_experience?.currentlyWorking,
          total_experience:
            userDetails?.profile?.work_experience?.total_experience,
          tillDate: userDetails?.profile?.work_experience?.tillDate,
          current_salary: {
            showCompensations: showCompensations == 1 ? true : false,
            workCountry: selectedCountry,
            amount: muiSliderValue ? muiSliderValue : minSliderVal,
            currency: currencyCode.split(")")[0],
            salary_range: rows,
          },
        },
      };
    } else {
      payload = {
        work_experience: {
          orgNames: Array.from(""),
          designations: Array.from(""),
          industry: Array.from(""),
          empType: Array.from(""),
          jobTitles: Array.from(""),
          role: Array.from(""),
          skillsP: [Array.from("")],
          skillsO: [Array.from("")],
          rnrs: Array.from(""),
          startM: Array.from(""),
          endM: Array.from(""),
          startY: Array.from(""),
          endY: Array.from(""),
          currentlyWorking: false,
          total_experience: 0,
          tillDate: Array.from(""),
          current_salary: {
            showCompensations: showCompensations == 1 ? true : false,
            workCountry: selectedCountry,
            amount: calMinSal ? calMinSal : minSal,
            currency: currencyCode.split(")")[0],
            salary_range: rows,
          },
        },
      };
    }
    console.log(showCompensations);
    putEditProfiles(payload);
  };

  return (
    <Grid className="overRideSettings">
      {ACCOUNT_TYPE() === "corporate" ? (
        <Grid className="manageCredentials" mt={2}>
          <Grid item container className="inlineRow">
            <Grid>
              <h1 className="settingHeading">Manage Members</h1>
            </Grid>
          </Grid>
          <Grid item container lg={12} mt={2}>
            <Grid className="label">Add members</Grid>
            <Grid item container style={{ display: "flex" }}>
              <Grid item lg={6}>
                <InputField
                  type="text"
                  placeholder="Add members"
                  value={addMember}
                  onChange={(e) => setAddMember(e.target.value)}
                />
              </Grid>
              <Grid className="saveBtn" style={{ marginLeft: "20px" }}>
                <Grid>
                  <Buttons
                    varientContained={true}
                    label="Add Member"
                    onClick={handleAddMember}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="table">
            <table>
              <tr>
                <th>Account</th>
                <th>Email Id</th>
                <th>Status</th>
              </tr>
              {membersDetails}
            </table>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <Grid className="manageCredentials" mt={2}>
        <Grid item container xs={11} className="inlineRow">
          <Grid>
            <h1 className="settingHeading">Manage Credentials</h1>
          </Grid>
          <Grid className="greyBtn">
            <Buttons
              className="editCred"
              varientContained={true}
              label="Edit Credentials"
              onClick={handleEmailEditClick}
            />
          </Grid>
        </Grid>
        <Grid item container lg={12} xs={12} mt={2}>
          <Grid className="label">Primary Email</Grid>
          <Grid
            item
            container
            justifyContent={"flex-start"}
            style={{ display: "flex" }}
          >
            <Grid item lg={6} xs={12}>
              <InputField
                type="text"
                placeholder="Primary Email"
                value={primaryEmail}
                disabled={!isEditing}
                onChange={(e) => setPrimaryEmail(e.target.value)}
              />
            </Grid>
            {/* // condition 1  */}
            {email === primaryEmail && email_verified === true ? (
              <Grid item lg={1.5} xs={6} className="verificationChip">
                <img src={VERIFIED} />
                Verified
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  lg={2}
                  xs={6}
                  className="verificationChip notverified"
                >
                  <img src={NOTVERIFIED} />
                  Not Verified
                </Grid>
                <Grid className="verifyNow">
                  <Grid>
                    <Buttons
                      varientText={true}
                      label="verify Now"
                      onClick={getsendOtp1}
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>
                {showOpt && (
                  <Grid item lg={1.5} xs={6}>
                    <InputField
                      type="text"
                      placeholder="OTP ***"
                      value={primaryOtp}
                      onChange={OpenOtpBox}
                      inputProps={{ maxLength: 6 }}
                    />
                    {otpVerifySignupData.status === "FAILURE" && (
                      <p className="error">Invalid OTP</p>
                    )}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item container lg={12} xs={12} mt={2}>
          <Grid className="label">Secondary Email</Grid>
          <Grid item container style={{ display: "flex" }}>
            <Grid item lg={6} xs={12}>
              <InputField
                type="text"
                placeholder="Secondary Email"
                value={secondaryEmail}
                disabled={!isEditing}
                onChange={(e) => {
                  setSecondaryEmail(e.target.value);
                  setisSecondaryEmail(false);
                }}
              />
            </Grid>
            {/* // condition 1  */}
            {recovery_email === secondaryEmail &&
            recovery_email_verified === true ? (
              <Grid item lg={1.5} xs={6} className="verificationChip">
                <img src={VERIFIED} />
                Verified
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  lg={2}
                  xs={6}
                  className="verificationChip notverified"
                >
                  <img src={NOTVERIFIED} />
                  Not Verified
                </Grid>
                <Grid className="verifyNow">
                  <Grid>
                    <Buttons
                      disabled={
                        secondaryEmail == null ||
                        secondaryEmail == undefined ||
                        secondaryEmail == "" ||
                        !isEditing
                      }
                      varientText={true}
                      label="verify Now"
                      onClick={getsendOtp2}
                    />
                  </Grid>
                </Grid>
                {showOtp2 && (
                  <Grid item lg={1.5} xs={6}>
                    <InputField
                      type="text"
                      placeholder="OTP ***"
                      value={secondaryOtp}
                      onChange={OpenOtpBox2}
                      inputProps={{ maxLength: 6 }}
                    />
                    {recoveryEmailVerifys.status === "FAILURE" && (
                      <p className="error">Invalid OTP</p>
                    )}
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid container>
            {isSecondaryEmail && (
              <p
                style={{
                  color: "red",
                  textAlign: "left",
                  margin: 0,
                  padding: 0,
                  fontSize: "12px",
                }}
              >
                Please enter a Valid Email
              </p>
            )}
          </Grid>
        </Grid>

        <Grid item container lg={12} xs={12} mt={2}>
          <Grid className="label">Change Password</Grid>
          <Grid item container lg={12} xs={12}>
            <Grid item lg={6} xs={12}>
              <InputField
                type="password"
                placeholder="Old Password"
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container lg={12} xs={12} mt={2}>
          <Grid item container className="password" lg={12} xs={12}>
            <Grid item container lg={6} xs={12}>
              <InputField
                type="text"
                value={newPassword}
                placeholder="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item container lg={6} xs={12}>
              <InputField
                type="text"
                value={confirmNewPassword}
                placeholder="Re-Enter Password"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
          {passwordError && (
            <span style={{ color: "red" }}>{passwordError}</span>
          )}

          <Grid className="saveOption" mt={4} mb={4}>
            <Grid className="saveBtn">
              <Grid>
                <Buttons
                  varientContained={true}
                  label="Save"
                  onClick={changeNewPassword}
                />
              </Grid>
            </Grid>
            <Grid className="cancelBtn">
              <Grid>
                <Buttons
                  varientContained={true}
                  label="Cancel"
                  onClick={emptyPassword}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid className="paymentHistory" mt={2}>
        <Grid>
          <h1 className="settingHeading"> Payment History</h1>
        </Grid>
        <Grid
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
          className="table"
        >
          <table>
            <tr>
              <th>Order No.</th>
              <th>Amount Paid</th>
              <th>Subscription Start</th>
              <th>Status</th>
              <th>Subscription End</th>
            </tr>

            {paymentDetail?.map((element, index) => {
              const compareDate = new Date(element?.subscription?.start_date);
              const formattedDate = ConvertDate(
                element?.subscription?.start_date
              );
              const formattedDate1 = ConvertDate(
                element?.subscription?.end_date
              );
              return (
                <tr key={index}>
                  <td>{element?.order_number}</td>
                  <td>{element?.order_total}</td>
                  <td>{formattedDate}</td>

                  <td>
                    {moment(formattedDate1).isAfter(moment())
                      ? "Active"
                      : "Expired"}
                  </td>
                  <td>{formattedDate1}</td>
                </tr>
              );
            })}
          </table>
        </Grid>
        <Grid item container mt={4} className="saveBtn">
          <Grid>
            <Buttons varientContained={true} label="Subscribe Now" />
          </Grid>
        </Grid>
      </Grid> */}
      {ACCOUNT_TYPE() === "corporate" ? (
        ""
      ) : (
        <Grid className="compensationData" mt={2}>
          <Grid item container className="inlineRow">
            <Grid>
              <h1 className="settingHeading">Compensation Data</h1>
            </Grid>
          </Grid>
          <Grid>
            <Grid className="label">
              <FormLabels label="Show Compensation" />
            </Grid>

            <FormControl fullWidth>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  value="1"
                  label="Yes"
                  checked={showCompensations === "1"}
                  onChange={(e) => handleShowCompensation(e.target.value)}
                ></RadioButton>
                <RadioButton
                  value="0"
                  label="No"
                  checked={showCompensations === "0"}
                  onChange={(e) => handleShowCompensation(e.target.value)}
                ></RadioButton>
              </RadioGroup>
            </FormControl>
          </Grid>
          {showCompensations === "1" ? (
            <>
              <Grid container spacing={3}>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormControl fullWidth className="selectLabel">
                    <Grid className="label">
                      {" "}
                      <FormLabels label="Country" />
                    </Grid>

                    <SelectMenu
                      id="country"
                      disabledDefault={false}
                      listData={Countries}
                      countries={true}
                      onchangehandler={handleCountryChange}
                      value={selectedCountry}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormControl fullWidth className="selectLabel">
                    <Grid className="label">
                      <FormLabels label="Currency" />
                    </Grid>

                    <SelectMenu
                      id="currency"
                      value={selectedCurrency}
                      disabledDefault={false}
                      listData={currencyConcatenate}
                      countries={true}
                      onchangehandler={handleCurrencyChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormControl fullWidth className="selectLabel">
                    <Grid className="label">
                      <FormLabels label="Salary Range" />
                    </Grid>
                    <SelectMenu
                      disabledDefault={false}
                      listData={range}
                      countries={true}
                      onchangehandler={(e) => setSalaryRangeOnChange(e)}
                      value={salaryRange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid>
                <Grid>
                  {" "}
                  <h1 className="currentSalary">Current Salary</h1>
                </Grid>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <Slider
                    style={{ color: "green" }}
                    aria-label="Restricted values"
                    defaultValue={
                      searchUserData?.profile?.work_experience?.current_salary
                        ?.amount
                    }
                    onChange={handleDrag}
                    step={100}
                    value={muiSliderValue}
                    valueLabelDisplay="auto"
                    min={minSliderVal}
                    max={maxSliderVal}
                    marks={marks}
                  />
                </Grid>
              </Grid>
              <Grid className="saveOption" mt={4} mb={4}>
                <Grid className="saveBtn">
                  <Grid>
                    <Buttons
                      varientContained={true}
                      onClick={onSubmitWorkExp}
                      label="Save"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      )}
      {ACCOUNT_TYPE() !== "corporate" && (
        <div style={{ textAlign: "left" }}>
          <h1 className="settingHeading">Manage Compensation</h1>
          <p>You can manage your compensation value here.</p>
        </div>
      )}
      {ACCOUNT_TYPE() !== "corporate" && (
        <Grid container spacing={3}>
          <Grid item lg={3} xs={12} sm={12} md={12}>
            <FormControl fullWidth className="selectLabel">
              <Grid className="label">
                {" "}
                <FormLabels label="Select Job Type" />
              </Grid>

              <SelectMenu
                id="country"
                disabledDefault={false}
                listData={jobTypeData}
                countries={false}
                onchangehandler={setJobTypes}
                value={jobTypeState}
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12} sm={12} md={12}>
            <FormControl fullWidth className="selectLabel">
              <Grid className="label">
                <FormLabels label="Select Currency Code" />
              </Grid>

              <SelectMenu
                id="currency"
                value={selectedCurrencyCode}
                disabledDefault={false}
                listData={CURRENCYCODE.map((currency) => {
                  return { key: currency, value: currency };
                })}
                countries={false}
                onchangehandler={setSelectedCurrencyCodes}
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12} sm={12} md={12}>
            <FormControl fullWidth className="selectLabel">
              <Grid className="label">
                <FormLabels label="Compensation Value" />
              </Grid>
              <InputField
                type="number"
                placeholder="Enter compensation..."
                value={compensation}
                onChange={(e) => setCompensation(e.target.value)}
                isRequired={true}
                // errorMsg={isFirstName ? "Please enter this required field" : ""}
                // isError={isFirstName}
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12} sm={12} md={3}>
            <Grid className="saveBtn" mt={3}>
              <Grid>
                <Buttons
                  varientContained={true}
                  label="Update"
                  onClick={submitCompensation}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid className="deactivateAccount" mt={4}>
        <Grid mt={2}>
          <h1 className="settingHeading">Deactivate Account</h1>
        </Grid>
        <Grid>
          {openModal && (
            <CustomizedDialogs
              open={openModal}
              // rows={[]}
              header={"Confirm Deletion"}
              // handleCloseModal={deactivateAccount}
              handleCloseModal={() => setOpenModal(false)}
              content={
                "Are you sure you want to Deactivate your account? You will not be able to access any MatchupIT services"
              }
              view
              exitHandle={deactivateAccount}
              label1={"Deactivate"}
              label2={"Cancel"}
            />
          )}
          <p className="paraFont">
            You can activate or deactivate your account. Note in case your
            subscription expires your account will be deactivated automatically.
            <span
              className=" paraFont clickLink"
              onClick={() => setOpenModal(true)}
            >
              {" "}
              Click Here{" "}
            </span>{" "}
            to deactivate your account*
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;
