import { Grid } from "@mui/material";
import React from "react";
import "./ViewProfile.scss";
import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import facebookColorIcon from "../../../assets/facebookColorIcon.svg";
import pintrestColorIcon from "../../../assets/pintrestColorIcon.svg";
import linkedInColorIcon from "../../../assets/linkedInColorIcon.svg";
import githubColorIcon from "../../../assets/githubColorIcon.svg";
import twitterColorIcon from "../../../assets/twitterXlogo.png";
import instaColorIcon from "../../../assets/instaColorIcon.svg";
import CallIcon from "../../../assets/callIcon.svg";
import MailIcon from "../../../assets/mailIcon.svg";
import BlackLocationIcon from "../../../assets/blackLocationIcon.svg";

const CorporateProfileHeader = ({ searchUserData, NavigateProfileFrom }) => {
  return (
    <div>
      <Grid container className="profileHeader" p={2}>
        <Grid container item lg={8} className="leftDiv" alignItems={"center"}>
          <Grid container item className="imageAndContactDetails">
            <Grid item className="img">
              <img
                src={
                  searchUserData?.basicDetails?.logo === null || "" || undefined
                    ? defaultLoginImage
                    : searchUserData?.basicDetails?.logo
                }
                alt=""
                height="200px"
                width="200px"
              />
            </Grid>
            <Grid ml={2} item className="contactDetails" textAlign={"left"}>
              <Grid mt={4}>
                <Grid container gap={2} mt={0.5}>
                  <p className="font700 font24">
                    {searchUserData?.basicDetails?.name}
                  </p>
                </Grid>

                <Grid container gap={2} mt={0.5}>
                  <p className="font400 font16 lineHeight0">
                    {searchUserData?.basicDetails?.website}
                  </p>
                </Grid>
              </Grid>

              <Grid mt={2}>
                <Grid container gap={2} mt={0.5}>
                  <img className="Icons" src={CallIcon} alt="call icon" />
                  <p className="font400 font16 lineHeight20">
                    {searchUserData?.basicDetails?.telephone === ("" || null)
                      ? "NA"
                      : `${searchUserData?.basicDetails?.dial_code} ${searchUserData?.basicDetails?.telephone}`}
                  </p>
                </Grid>
                <Grid container gap={2} mt={0.5}>
                  <img className="Icons" src={MailIcon} alt="mail icon" />
                  <p className="font400 font16 lineHeight20">
                    {searchUserData?.basicDetails?.email}
                  </p>
                </Grid>
                <Grid container gap={2} mt={0.5}>
                  <img
                    className="Icons"
                    src={BlackLocationIcon}
                    alt="mail icon"
                  />
                  <p className="font400 font16 lineHeight20">
                    {searchUserData?.basicDetails?.city === ("" || null)
                      ? "NA"
                      : `${searchUserData?.basicDetails.city}, ${searchUserData?.basicDetails.country_name}`}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="socialIconsDiv" gap={2} mt={3}>
            {searchUserData?.profile?.social_links?.instagram?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://instagram.com/${searchUserData?.profile?.social_links?.instagram}`}
                target="_blank"
              >
                <img
                  src={instaColorIcon}
                  className="instaLogo socialIcons"
                  alt="Instagram Logo"
                />
              </a>
            )}
            {searchUserData?.profile?.social_links?.facebook?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://facebook.com/${searchUserData?.profile?.social_links?.facebook}`}
                target="_blank"
              >
                <img
                  src={facebookColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
              </a>
            )}
            {searchUserData?.profile?.social_links?.linkedin?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://linkedin.com/in/${searchUserData?.profile?.social_links?.linkedin}`}
                target="_blank"
              >
                <img
                  src={linkedInColorIcon}
                  className="facebookLogo socialIcons"
                  alt="linkedin Logo"
                />
              </a>
            )}

            {searchUserData?.profile?.social_links?.twitter?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://twitter.com/${searchUserData?.profile?.social_links?.twitter}`}
                target="_blank"
              >
                <img
                  src={twitterColorIcon}
                  style={{ height: "30px", width: "auto" }}
                  alt="Facebook Logo"
                />
              </a>
            )}

            {searchUserData?.profile?.social_links?.pinterest?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://pinterest.com/${searchUserData?.profile?.social_links?.pinterest}`}
                target="_blank"
              >
                <img
                  src={pintrestColorIcon}
                  alt="Facebook Logo"
                  style={{ height: "45px", width: "45px", margin: "-7px" }}
                />
              </a>
            )}

            {searchUserData?.profile?.social_links?.github?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://github.com/${searchUserData?.profile?.social_links?.github}`}
                target="_blank"
              >
                <img
                  src={githubColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
              </a>
            )}
          </Grid>
        </Grid>

        {/* center Div for sliders and experiance */}

        <Grid
          container
          item
          ml={1}
          lg={3}
          className="centerDiv"
          textAlign={"left"}
          alignItems={"center"}
        >
          <Grid>
            <Grid item>
              <p className="font700 font16">No. of Employees</p>
              <p className="font400 font16">
                {searchUserData?.basicDetails.employee_count}
              </p>
            </Grid>
            <Grid item>
              <p className="font700 font16">Currently Hiring</p>
              <p className="font400 font16">
                {searchUserData?.basicDetails.currently_hiring === true
                  ? "Yes"
                  : "No"}
              </p>
            </Grid>
            <Grid item mt={0}>
              <p className="font700 font16">Company Type</p>
              <p className="font400 font16">
                {searchUserData?.basicDetails.type} Organisation
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CorporateProfileHeader;
