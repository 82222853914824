import axios from "axios";
import { post } from "./axiosRequest";
import { getApiUrl } from "../config";

export const createUploadVideo = async ({ data }) => {
  try {
    const url = `${getApiUrl().baseUrl}/media/upload`;

    const token = sessionStorage.getItem("token");
    return await post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        type: "video",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createUploadContent = async ({ data }) => {
  console.log("POST_UPLOAD_CONTENT_PENDING==================", data);
  try {
    const url = `${getApiUrl().baseUrl}/media/upload`;

    const token = sessionStorage.getItem("token");
    return await post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        type: "video",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
