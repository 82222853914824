import React, { useEffect, useState } from "react";
import OtpPage from "../component/otpPage";
import { useDispatch, useSelector } from "react-redux";
import CreatePassword from "../component/CreatePassword";
import SignUp from "../component/signUp";
import * as SignupAction from "../../../redux/actions/SignupAction";
import Loader from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../redux/actions/loginAction";

function SignupContainer() {
  const [isSigupPage, setisSigupPage] = useState(true);
  const [isOtpPage, setisOtpPage] = useState(false);
  const [isPasswordPage, SetisPassworPage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signupWithGoogleData = useSelector(
    (state) => state.signupWithGoogleData
  );
  const checkUserExistData = useSelector((state) => state.checkUserExistData);
  const otpSignupData = useSelector((state) => state.otpSignupData);
  const signupData = useSelector((state) => state.signupData);
  const otpVerifySignupData = useSelector((state) => state.otpVerifySignupData);
  const individual = "individual";

  const showLoader = useSelector(
    (state) =>
      state?.checkUserExistData.isLoading ||
      state?.signupData.isLoading ||
      state?.signupWithGoogleData.isLoading
  );

  useEffect(() => {
    if (
      checkUserExistData.isLoading === false &&
      checkUserExistData?.data?.response?.registered === false &&
      checkUserExistData.status === "SUCCESS"
    ) {
      setisOtpPage(true);
      SetisPassworPage(false);
      setisSigupPage(false);

      dispatch(SignupAction.otpsignup({ email: email }));
    }
  }, [checkUserExistData]);

  useEffect(() => {
    if (
      otpVerifySignupData.isLoading === false &&
      otpVerifySignupData?.data?.msg === "Email successfully verified"
    ) {
      SetisPassworPage(true);
      setisOtpPage(false);
      setisSigupPage(false);
    }
  }, [otpVerifySignupData]);

  useEffect(() => {
    if (
      !signupData.isLoading &&
      signupData.status === "SUCCESS" &&
      signupData?.data?.msg === "Account has been created"
    ) {
      navigate("/dashboard");
    }
  }, [signupData]);

  useEffect(() => {
    if (
      !signupWithGoogleData.isLoading &&
      signupWithGoogleData.status === "SUCCESS"
    ) {
      navigate("/dashboard");
      dispatch(getUserDetails("dcads"));
    }
  }, [signupWithGoogleData]);

  const [email, setEmail] = useState("");
  const [passwords, setPasswords] = useState("");
  const [accountType, setaccountType] = useState("individual");

  const saveEmail = (email, accountType) => {
    setEmail(email);
    setaccountType(accountType);
    dispatch(SignupAction.checkUserExist({ email: email }));
  };

  const otpSend = (email) => {
    dispatch(SignupAction.otpsignup({ email: email }));
  };

  const signups = (password) => {
    setPasswords(password);
    dispatch(
      SignupAction.signup({
        account_type: accountType,
        email: email,
        password: password,
      })
    );
  };

  const submitOtp = (otp) => {
    dispatch(SignupAction.otpVerifysignup({ email: email, otp: otp }));
  };

  const signupWithGoogle = (data) => {
    dispatch(SignupAction.signupWithGoogle(data));
  };

  return (
    <>
      {showLoader && <Loader />}
      {isSigupPage ? (
        <SignUp
          saveEmail={saveEmail}
          checkUserExistData={checkUserExistData}
          signupWithGoogle={signupWithGoogle}
        />
       ) : isOtpPage ? (
        <OtpPage
          sendOTP={submitOtp}
          otpSend={otpSend}
          email={email}
          otpVerifySignupData={otpVerifySignupData}
        />
      ) : (
        <CreatePassword signups={signups} />
      )}
    </>
  );
}

export default SignupContainer;
