export const communityType = {
  GET_TOP_COMMUNITY_PENDING: "[PROFILE][REQUEST] TOP_COMMUNITY",
  GET_TOP_COMMUNITY_FAILURE: "[PROFILE][RESPONSE] TOP_COMMUNITY Failed",
  GET_TOP_COMMUNITY_SUCCESS: "[PROFILE][RESPONSE] TOP_COMMUNITY Successful",

  GET_MY_COMMUNITY_PENDING: "[PROFILE][REQUEST] MY_COMMUNITY",
  GET_MY_COMMUNITY_FAILURE: "[PROFILE][RESPONSE] MY_COMMUNITY Failed",
  GET_MY_COMMUNITY_SUCCESS: "[PROFILE][RESPONSE] MY_COMMUNITY Successful",

  GET_COMMUNITY_PENDING: "[PROFILE][REQUEST] GET_COMMUNITY",
  GET_COMMUNITY_FAILURE: "[PROFILE][RESPONSE] GET_COMMUNITY Failed",
  GET_COMMUNITY_SUCCESS: "[PROFILE][RESPONSE] GET_COMMUNITY Successful",

  GET_COMMUNITY_ADMIN_PENDING: "[PROFILE][REQUEST] GET_COMMUNITY_ADMIN",
  GET_COMMUNITY_ADMIN_FAILURE: "[PROFILE][RESPONSE] GET_COMMUNITY_ADMIN Failed",
  GET_COMMUNITY_ADMIN_SUCCESS:
    "[PROFILE][RESPONSE] GET_COMMUNITY_ADMIN Successful",

  POST_JOIN_COMMUNITY_PENDING: "[Add ASSET][REQUEST] POST_JOIN_COMMUNITY",
  POST_JOIN_COMMUNITY_FAILURE:
    "[Add ASSET][RESPONSE] POST_JOIN_COMMUNITY Failed",
  POST_JOIN_COMMUNITY_SUCCESS:
    "[Add ASSET][RESPONSE] POST_JOIN_COMMUNITY Successfull",
  POST_JOIN_CLEAR_DATA:
    "[Join Clear success][RESPONSE] POST_JOIN_DATA Successfull",

  POST_LEAVE_COMMUNITY_PENDING:
    "[Leave pending][REQUEST] POST_LEAVE_COMMUNITY_PENDING",
  POST_LEAVE_COMMUNITY_FAILURE:
    "[Leave Failure][RESPONSE] POST_LEAVE_COMMUNITY_FAILURE Failed",
  POST_LEAVE_COMMUNITY_SUCCESS:
    "[Leave success][RESPONSE] POST_LEAVE_COMMUNITY_SUCCESS Successfull",
  POST_CLEAR_DATA:
    "[Leave Clear success][RESPONSE] POST_CLEAR_DATA Successfull",

  POST_SEARCH_COMMUNITY_PENDING: "[Add ASSET][REQUEST] POST_SEARCH_COMMUNITY",
  POST_SEARCH_COMMUNITY_FAILURE:
    "[Add ASSET][RESPONSE] POST_SEARCH_COMMUNITY Failed",
  POST_SEARCH_COMMUNITY_SUCCESS:
    "[Add ASSET][RESPONSE] POST_SEARCH_COMMUNITY Successfull",

  GET_POSTS_COMMUNITY_PENDING:
    "[GET_POSTS_COMMUNITY_PENDING][REQUEST] GET_POST_COMMUNITY",
  GET_POSTS_COMMUNITY_FAILURE:
    "[GET_POSTS_COMMUNITY_FAILURE][RESPONSE] GET_POST_COMMUNITY Failed",
  GET_POSTS_COMMUNITY_SUCCESS:
    "[GET_POSTS_COMMUNITY_SUCCESS][RESPONSE] GET_POST_COMMUNITY Successful",

  POST_COMMUNITY_ADD_POST_PENDING:
    "[Add Post][REQUEST] POST_COMMUNITY_ADD_POST_PENDING",
  POST_COMMUNITY_ADD_POST_FAILURE:
    "[Add Post fail][RESPONSE] POST_COMMUNITY_ADD_POST_FAILURE Failed",
  POST_COMMUNITY_ADD_POST_SUCCESS:
    "[Add Post Success][RESPONSE] POST_COMMUNITY_ADD_POST_SUCCESS Successfull",

  POST_COMMUNITY_ADD_POST_COMMENTS_PENDING:
    "[Add Comment][REQUEST] POST_COMMUNITY_ADD_POST_COMMENTS_PENDING",
  POST_COMMUNITY_ADD_POST_COMMENTS_FAILURE:
    "[Add Comment fail][RESPONSE] POST_COMMUNITY_ADD_POST_COMMENTS_FAILURE Failed",
  POST_COMMUNITY_ADD_POST_COMMENTS_SUCCESS:
    "[Add Comment Success][RESPONSE] POST_COMMUNITY_ADD_POST_COMMENTS_SUCCESS Successfull",

  POST_ADD_REACT_TO_POST_COMMUNITY_PENDING:
    "[Add React to post pending][REQUEST] POST_ADD_REACT_TO_POST_COMMUNITY_PENDING",
  POST_ADD_REACT_TO_POST_COMMUNITY_FAILURE:
    "[Add React to post Failure][RESPONSE] POST_ADD_REACT_TO_POST_COMMUNITY_FAILURE Failed",
  POST_ADD_REACT_TO_POST_COMMUNITY_SUCCESS:
    "[Add React to post success][RESPONSE] POST_ADD_REACT_TO_POST_COMMUNITY_SUCCESS Successfull",

  POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_PENDING:
    "[Add React to post Comment pending][REQUEST] POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_PENDING",
  POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_FAILURE:
    "[Add React to post Comment Failure][RESPONSE] POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_FAILURE Failed",
  POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_SUCCESS:
    "[Add React to post Comment success][RESPONSE] POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_SUCCESS Successfull",

  POST_DELETE_COMMUNITY_PENDING:
    "[Delete Post pending][REQUEST] POST_DELETE_COMMUNITY_PENDING",
  POST_DELETE_COMMUNITY_FAILURE:
    "[Delete Post Comment Failure][RESPONSE] POST_DELETE_COMMUNITY_FAILURE Failed",
  POST_DELETE_COMMENT_COMMUNITY_SUCCESS:
    "[Delete Post Comment success][RESPONSE] POST_DELETE_COMMENT_COMMUNITY_SUCCESS Successfull",

  POST_TOGGLE_COMMUNITY_PENDING:
    "[TOGGLE Post pending][REQUEST] POST_TOGGLE_COMMUNITY_PENDING",
  POST_TOGGLE_COMMUNITY_FAILURE:
    "[TOGGLE Post Comment Failure][RESPONSE] POST_TOGGLE_COMMUNITY_FAILURE Failed",
  POST_TOGGLE_COMMUNITY_SUCCESS:
    "[TOGGLE Post Comment success][RESPONSE] POST_TOGGLE_COMMUNITY_SUCCESS Successfull",

  POST_CREATE_COMMUNITY_PENDING:
    "[CREATE Post pending][REQUEST] POST_CREATE_COMMUNITY_PENDING",
  POST_CREATE_COMMUNITY_FAILURE:
    "[CREATE Post Comment Failure][RESPONSE] POST_CREATE_COMMUNITY_FAILURE Failed",
  POST_CREATE_COMMUNITY_SUCCESS:
    "[CREATE Post Comment success][RESPONSE] POST_CREATE_COMMUNITY_SUCCESS Successfull",

  POST_EDIT_COMMUNITY_PENDING:
    "[EDIT Post pending][REQUEST] POST_EDIT_COMMUNITY_PENDING",
  POST_EDIT_COMMUNITY_FAILURE:
    "[EDIT Post Comment Failure][RESPONSE] POST_EDIT_COMMUNITY_FAILURE Failed",
  POST_EDIT_COMMUNITY_SUCCESS:
    "[EDIT Post Comment success][RESPONSE] POST_EDIT_COMMUNITY_SUCCESS Successfull",

  POST_RESUME_BULDING_PENDING:
    "[RESUME building pending][REQUEST] POST_RESUME_BULDING_PENDING",
  POST_RESUME_BULDING_FAILURE:
    "[RESUME building Comment Failure][RESPONSE] POST_RESUME_BULDING_FAILURE Failed",
  POST_RESUME_BULDING_SUCCESS:
    "[RESUME building Comment success][RESPONSE] POST_RESUME_BULDING_SUCCESS Successfull",
};
