export const adminType = {
  GET_ADMIN_REPORT_PENDING: "[PROFILE][REQUEST] ADMIN_REPORT",
  GET_ADMIN_REPORT_FAILURE: "[PROFILE][RESPONSE] ADMIN_REPORT Failed",
  GET_ADMIN_REPORT_SUCCESS: "[PROFILE][RESPONSE] ADMIN_REPORT Successful",

  GET_ADMIN_ABOUT_TO_EXPIRE_PENDING: "[PROFILE][REQUEST] ADMIN_ABOUT_TO_EXPIRE",
  GET_ADMIN_ABOUT_TO_EXPIRE_FAILURE:
    "[PROFILE][RESPONSE] ADMIN_ABOUT_TO_EXPIRE Failed",
  GET_ADMIN_ABOUT_TO_EXPIRE_SUCCESS:
    "[PROFILE][RESPONSE] ADMIN_ABOUT_TO_EXPIRE Successful",

  GET_ADMIN_COUNT_PENDING: "[PROFILE][REQUEST] ADMIN_COUNT",
  GET_ADMIN_COUNT_FAILURE: "[PROFILE][RESPONSE] ADMIN_COUNT Failed",
  GET_ADMIN_COUNT_SUCCESS: "[PROFILE][RESPONSE] ADMIN_COUNT Successful",

  POST_ADMIN_UPDATE_INDUSTRY_PENDING:
    "[PROFILE][REQUEST] ADMIN_UPDATE_INDUSTRY",
  POST_ADMIN_UPDATE_INDUSTRY_FAILURE:
    "[PROFILE][RESPONSE] ADMIN_UPDATE_INDUSTRY Failed",
  POST_ADMIN_UPDATE_INDUSTRY_SUCCESS:
    "[PROFILE][RESPONSE] ADMIN_UPDATE_INDUSTRY Successful",
};
