import React, { useState, useEffect } from "react";
import ProfileForm from "../component/ProfileForm";
import * as UploadFilesAction from "../../../redux/actions/UploadFilesAction";
import * as editProfileAction from "../../../redux/actions/editProfileAction";
import * as mentorshipAction from "../../../redux/actions/mentorshipAction";
import { getUserDetails } from "../../../redux/actions/loginAction";
import * as FileUpload from "../../../redux/actions/UploadVideoAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import CorporateProfileForm from "../component/corporate/CorporateProfileForm";
import { useNavigate } from "react-router";
import { PROFILE } from "../../../constant/routeContant";
import { ACCOUNT_TYPE } from "../../../constant/constant";
import { useLocation } from "react-router-dom";

function ProfileFormContainer() {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const location = useLocation();
  const tabId = location?.state?.tabId;
  const [activeTab, setActiveTab] = useState(tabId ?? 0);
  const [total_experience, settotal_experience] = useState("");

  const totalExp = useSelector(
    (state) =>
      state?.userDetails?.data?.response.profile?.work_experience
        ?.total_experience
  );

  const handleTabClick = (id) => {
    setActiveTab(id);
  };
  const allIndustries = useSelector(
    (state) => state?.industries?.data?.response
  );
  const mentorshipSubjects = useSelector(
    (state) => state?.getMentorshipSubjects?.data?.response
  );
  const allCountries = useSelector((state) => state?.countries);
  const headShot = useSelector(
    (state) => state?.uploadFiles?.data?.fileUrl
  );
  const coverShot = useSelector(
    (state) => state?.uploadFiles?.data?.fileUrl
  );
  const headshotLoading = useSelector(
    (state) => state?.uploadFiles?.isLoading
  );

  const uploadProfileFile = (data) => {
    dispatch(
      UploadFilesAction.createFileUpload({
        file: data,
      })
    );
  };

  const uploadCoverFile = (data) => {};

  const [exitForm, setexitForm] = useState(false);
  const editProfile = (data, exitForm = false) => {
    dispatch(editProfileAction.putEditProfile(data));
    setexitForm(exitForm);
  };
  useEffect(() => {
    dispatch(FileUpload.clearVideo());
    dispatch(editProfileAction?.getIndustries());
    dispatch(editProfileAction?.getCountries());
    dispatch(mentorshipAction?.getMentorshipSubjects());
    dispatch(getUserDetails("dcads"));
  }, []);

  const showLoader = useSelector((state) => state?.editProfile?.isLoading);
  const editPRofileData = useSelector((state) => state?.editProfile);
  const [fromFormSub, setfromFormSub] = useState(false);
  useEffect(() => {
    if (
      activeTab < 5 &&
      editPRofileData.isLoading === false &&
      editPRofileData.status === "SUCCESS"
    ) {
      if (exitForm) naviagte(PROFILE);
      if (ACCOUNT_TYPE() === "corporate") {
        console.log(editPRofileData);
        setActiveTab(activeTab + 1);
      } else if (
        ACCOUNT_TYPE() !== "corporate" &&
        total_experience <= 7 &&
        activeTab === 2
      )
        setActiveTab(activeTab + 2);
      else setActiveTab(fromFormSub ? activeTab + 1 : activeTab);
      dispatch(getUserDetails("dcads"));
    } else if (
      (activeTab >= 5 &&
        editPRofileData.isLoading === false &&
        editPRofileData.status === "SUCCESS") ||
      (ACCOUNT_TYPE() === "corporate" && activeTab >= 2)
    ) {
      setActiveTab(0);
      naviagte(PROFILE);
    }
  }, [editPRofileData]);
  return (
    <>
      {showLoader && <Loader />}
      {headshotLoading && <Loader />}
      {ACCOUNT_TYPE() === "corporate" ? (
        <CorporateProfileForm
          uploadProfileFile={uploadProfileFile}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          allIndustries={allIndustries}
          allCountries={allCountries}
          editProfile={editProfile}
          headShot={headShot}
        />
      ) : (
        <ProfileForm
          uploadProfileFile={uploadProfileFile}
          uploadCoverFile={uploadCoverFile}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          allIndustries={allIndustries}
          allCountries={allCountries}
          editProfile={editProfile}
          headShot={headShot}
          coverShot={coverShot}
          mentorshipSubjects={mentorshipSubjects}
          total_experience={total_experience}
          settotal_experience={settotal_experience}
          setfromFormSub={setfromFormSub}
        />
      )}
    </>
  );
}

export default ProfileFormContainer;
