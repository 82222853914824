import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getMyCommunity,
  getTopCommunity,
  clearLeaveCommunityStatus,
  clearJoinCommunityStatus,
} from "../../../redux/actions/communityAction";
import Dashboard from "../component/dashboard";
import { getRecentSearches } from "../../../redux/actions/loginAction";

const DashboardContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopCommunity());
    dispatch(getMyCommunity());
    dispatch(getRecentSearches());
  }, []);
  useEffect(() => {
    dispatch(clearLeaveCommunityStatus());
    dispatch(clearJoinCommunityStatus());
  }, []);
  return (
    <div className="overrideDashboardContainer">
      <Dashboard />
    </div>
  );
};

export default DashboardContainer;
