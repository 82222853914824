import { conversationType } from "../constant/conversationType";

export const getAllConversation = (data) => ({
  type: conversationType.GET_CONVERSATION_PENDING,
  data,
});

export const getAllConversationSuccess = (data) => ({
  type: conversationType.GET_CONVERSATION_SUCCESS,
  data,
});

export const getAllConversationFailure = (error) => ({
  type: conversationType.GET_CONVERSATION_FAILURE,
  error,
});

export const getAllMessage = (data) => ({
  type: conversationType.GET_MESSAGE_PENDING,
  data,
});

export const getAllMessageSuccess = (data) => ({
  type: conversationType.GET_MESSAGE_SUCCESS,
  data,
});

export const getAllMessageFailure = (error) => ({
  type: conversationType.GET_MESSAGE_FAILURE,
  error,
});

export const getUnreadMessage = (data) => ({
  type: conversationType.GET_UNREAD_MESSAGE_PENDING,
  data,
});

export const getUnreadMessageSuccess = (data) => ({
  type: conversationType.GET_UNREAD_MESSAGE_SUCCESS,
  data,
});

export const getUnreadMessageFailure = (error) => ({
  type: conversationType.GET_UNREAD_MESSAGE_FAILURE,
  error,
});

export const postCreateConversation = (data) => ({
  type: conversationType.POST_CREATE_CONVERSATION_PENDING,
  data,
});

export const postCreateConversationSuccess = (data) => ({
  type: conversationType.POST_CREATE_CONVERSATION_SUCCESS,
  data,
});

export const postCreateConversationFailure = (error) => ({
  type: conversationType.POST_CREATE_CONVERSATION_FAILURE,
  error,
});

export const postAddMessage = (data) => ({
  type: conversationType.POST_ADD_MESSAGE_PENDING,
  data,
});

export const postAddMessageSuccess = (data) => ({
  type: conversationType.POST_ADD_MESSAGE_SUCCESS,
  data,
});

export const postAddMessageFailure = (error) => ({
  type: conversationType.POST_ADD_MESSAGE_FAILURE,
  error,
});

export const getUserSearch = (data) => ({
  type: conversationType.GET_USER_SEARCH_PENDING,
  data,
});

export const getUserSearchSuccess = (data) => ({
  type: conversationType.GET_USER_SEARCH_SUCCESS,
  data,
});

export const getUserSearchFailure = (error) => ({
  type: conversationType.GET_USER_SEARCH_FAILURE,
  error,
});
