import axios from "axios";

export const get = async (url, headers) => {
  try {
    return await axios.get(url, headers);
  } catch (err) {
    const {
      response: { data, status },
    } = err;
    if (status === 404) {
      return { error: { message: "Not Found", code: "NotFound" } };
    } else {
      return { error: { data, status } };
    }
  }
};

export const post = async (url, data, headers) => {
  return await axios.post(url, data,headers);
};


export const put = async (url, data, headers) => {
  return await axios.put(url, data,headers);
};

export const deletes = async (url, headers, data) => {
  return await axios.delete(url, headers, data);
};
