import React, { useState } from "react";
import "./CreatePassword.scss";
import { Button, Grid, RadioGroup } from "@mui/material";
import PasswordPic from "../../../assets/PasswordPic.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../../library/custom/passwordField/PasswordField";
import matchupITLogo from "../../../assets/Landing/matchupITLogo.png";

function CreatePassword({ signups }) {
  const navigate = useNavigate();
  let defaultErrorStates = {
    password: true,
    confirmPassword: true,
    otp: true,
  };

  const style = {
    boxShadow: "rgb(204 204 204) 2px 2px 3px 3px",
    border: "2px rgb(238, 238, 238)",
    padding: "2px",
    position: "absolute",
    marginLeft: "200px",
    background: "#fff",
  };

  const [password, setPassword] = useState("");
  const [pwdpopMsg, setPwdpopMsg] = useState(false);
  const [errorList, setErrorList] = useState(defaultErrorStates);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);

  function signupsData() {
    if (password !== confirmPassword) {
      setIsConfirmPasswordError(true);
      return;
    } else {
      signups(password);
    }
  }

  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });

  const handleOnKeyUp = (e) => {
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdlength = value.length >= 8;
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    const whitespace = /\s/g.test(value);
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);

      errorList.password = true;
    } else {
      setPwdpopMsg(true);
    }
  };

  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";

  return (
    <div className="overidesignup">
      <Grid container>
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
          item
          lg={6}
          xs={12}
          sm={6}
          md={6}
        >
          <img src={PasswordPic} className="leftPic" alt="no logo" />
        </Grid>

        <Grid
          className="formCon"
          container
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"left"}
          item
          lg={6}
          sm={12}
          md={6}
          p={5}
        >
          <Grid item xs={12} sm={9} md={12} lg={12}>
            <Grid
              container
              justifyContent={"left"}
              item
              lg={8}
              xs={12}
              sm={12}
              md={12}
              mt={6}
            >
              <img
                src={matchupITLogo}
                style={{ width: "267px", height: "114px" }}
                className="light-logo"
                alt="no logo"
                onClick={() => navigate("/")}
              />
            </Grid>

            <Grid container item lg={11} mt={3}>
              <Grid mt={2} item lg={12} xs={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0px",
                    color: "#083A50",
                  }}
                >
                  Password
                </h1>

                <Grid item lg={9.65} md={10.2} sm={12} xs={12}>
                  <PasswordField
                    showPassword={showPassword}
                    placeholder="Enter Your Password Here"
                    value={password}
                    onChange={(e) => setPassword(e.target.value.trim())}
                    onKeyUp={handleOnKeyUp}
                    onEyeIconClick={() => setShowPassword(!showPassword)}
                  />

                  {password && (
                    <div
                      style={{
                        display: pwdpopMsg === false ? "none" : "block",
                      }}
                    >
                      <div className="passwordStrenghtCheckStyle">
                        <form>
                          <p className={pwdlenClass}>
                            <CheckCircleIcon className="checkbox" />
                            At least 8 characters
                          </p>
                          <p className={capsClass}>
                            <CheckCircleIcon className="checkbox" />
                            At least 1 uppercase letter
                          </p>
                          <p className={sletterClass}>
                            <CheckCircleIcon className="checkbox" />
                            At least 1 lowercase letter
                          </p>
                          <p className={specClass}>
                            <CheckCircleIcon className="checkbox" />
                            At least 1 special character
                          </p>
                          <p className={numcheckClass}>
                            <CheckCircleIcon className="checkbox" />
                            At least 1 number{" "}
                          </p>
                        </form>
                      </div>
                    </div>
                  )}
                  {errorList.password ? null : (
                    <div className="show-error">
                      Please enter your new password
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid mt={2} item lg={12} xs={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0px",
                    color: "#083A50",
                  }}
                >
                  Confirm Password
                </h1>

                <Grid item lg={9.65} md={10.2} sm={12} xs={12}>
                  <PasswordField
                    showPassword={showConfirmPassword}
                    placeholder="Enter Your Password Here"
                    value={confirmPassword}
                    onChange={(e) => (
                      setConfirmPassword(e.target.value),
                      setIsConfirmPasswordError(false)
                    )}
                    onKeyUp={handleOnKeyUp}
                    onEyeIconClick={() =>
                      setShowConfirmPassword(!showConfirmPassword)
                    }
                  />

                  {isConfirmPasswordError && (
                    <div className="errorMsg">Password do not match</div>
                  )}
                </Grid>
              </Grid>

              <Grid container justifyContent={"left"} mt={3} item lg={10}>
                <Button
                  variant="outlined"
                  onClick={() => signupsData(password)}
                  style={{
                    width: "200px",
                    height: "41px",
                    border: "none",
                    background: "#778DFF",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreatePassword;
