import { Route, Routes } from "react-router-dom";
import * as React from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import CommunityContainer from "../feature/community/container/communityContainer";
import DashboardContainer from "../feature/dashboard/container/dashboardContainer";
import ProfileContainer from "../feature/profile/container/profileContainer";
import SearchContainer from "../feature/search/container/searchContainer";
import SettingsContainer from "../feature/settings/container/settingsContainer";
import MessagesContainer from "../feature/messages/container/MessagesContainer";
import LoginContainer from "../feature/login/container/LoginContainer";
import SignupContainer from "../feature/signup/container/signupContainer";
import {
  CREATE_RESUME,
  EDIT_PROFILE,
  FORGOT_PASSWORD,
  VIEW_PROFILE,
  CREATE_JOB,
  ADMIN_LOGN,
  ADMIN_DASHBOARD,
  ADMIN_COMMUNITY,
  CREATE_COMMUNITY,
  ADMIN_ANALYTICS,
  ADMIN_EXPIRING_USERS,
  ADMIN_MANAGING,
  SEARCH_JOB,
  VIEW_JOB,
  SEARCH_MENTOR,
  ADMIN_MENTORSHIP,
  MANAGE_MENTORSHIP,
  MENTORSHIP_CALENDAR,
  MENTORSHIP_BOOK_SESSION,
  MEETING,
} from "../constant/routeContant";
import ForgotPasswordContainer from "../feature/login/container/ForgotPasswordContainer";
import JoinCommunityContainer from "../feature/community/container/JoinCommunityContainer";
import ProfileFormContainer from "../feature/profile/container/ProfileFormContainer";
import CreateJobContainer from "../feature/job/createJobContainer";
import ViewProfileContainer from "../feature/search/container/ViewProfileContainer";
import CreateResume from "../feature/profile/component/CreateResume";
import AdminLogin from "../feature/admin/AdminLogin";
import AdminDashboard from "../feature/admin/AdminDashboard";
import AdminCommunity from "../feature/admin/AdminCommunity";
import CommunityForm from "../feature/admin/CommunityForm";
import HomePage from "../feature/LandingPage/HomePage";
import TermsCondition from "../feature/LandingPage/TermsCondition";
import AboutUs from "../feature/LandingPage/AboutUs";
import PrivacyPolicy from "../feature/LandingPage/PrivacyPolicy";
import AnalyticsContainer from "../feature/admin/AnalyticsContainer";
import ExpiringUsers from "../feature/admin/ExpiringUsers";
import Managing from "../feature/admin/Managing";
import SearchJobContainer from "../feature/job/container/SearchJobContainer";
import ViewJobContainer from "../feature/job/container/ViewJobContainer";
import ReactGA from "react-ga4";
import SearchMentorContainer from "../feature/mentorship/container/SearchMentorContainer";
import MentorshipSubjects from "../feature/admin/MentorshipSubjects";
import ManageMentorshipContainer from "../feature/mentorship/container/ManageMentorshipContainer";
import { MentorshipCalendarContainer } from "../feature/mentorship/container/MentorshipCalendarContainer";
import BookSessionFormContainer from "../feature/mentorship/container/BookSessionFormContainer";
import JitsiMeet from "../feature/mentorship/container/MeetingContainer";
ReactGA.initialize("G-3QX8Q8CS0E");
const MainRouter = () => {
  console.log(window.location.pathname)
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardContainer />} />
          <Route path="/search" element={<SearchContainer />} />
          <Route path="/settings" element={<SettingsContainer />} />
          <Route path="/profile" element={<ProfileContainer />} />
          <Route path="/community" element={<CommunityContainer />} />
          <Route path="/messages" element={<MessagesContainer />} />
          <Route path={VIEW_PROFILE} element={<ViewProfileContainer />} />
          <Route path="/view/community" element={<JoinCommunityContainer />} />
          <Route path={EDIT_PROFILE} element={<ProfileFormContainer />} />
          <Route path={CREATE_RESUME} element={<CreateResume />} />
          <Route path={ADMIN_DASHBOARD} element={<AdminDashboard />} />
          <Route path={ADMIN_COMMUNITY} element={<AdminCommunity />} />
          <Route path={CREATE_COMMUNITY} element={<CommunityForm />} />
          <Route path={ADMIN_ANALYTICS} element={<AnalyticsContainer />} />
          <Route path={ADMIN_MENTORSHIP} element={<MentorshipSubjects />} />
          <Route path={ADMIN_EXPIRING_USERS} element={<ExpiringUsers />} />
          <Route path={ADMIN_MANAGING} element={<Managing />} />
          <Route path={CREATE_JOB} element={<CreateJobContainer />} />
          <Route path={SEARCH_JOB} element={<SearchJobContainer />} />
          <Route path={VIEW_JOB} element={<ViewJobContainer />} />
          <Route path={SEARCH_MENTOR} element={<SearchMentorContainer />} />
          <Route path={MANAGE_MENTORSHIP} element={<ManageMentorshipContainer />} />
          <Route path={MENTORSHIP_CALENDAR} element={<MentorshipCalendarContainer />} />
          <Route path={MENTORSHIP_BOOK_SESSION} element={<BookSessionFormContainer />} />
          <Route path={MEETING} element={<JitsiMeet />} />

    </Route>

        <Route element={<PublicRoutes />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/Privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/signup" element={<SignupContainer />} />
          {/* <Route path="/" element={<LoginContainer />} /> */}
          <Route path="/login" element={<LoginContainer />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPasswordContainer />} />
          <Route path={ADMIN_LOGN} element={<AdminLogin />} />
        </Route>
      </Routes>
    </div>
    // </div>
  );
};
export default MainRouter;
