import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import "./MentorshipRequestCard.scss";
import Buttons from "../button/Button";
import { convertUTCToTimezone } from "../../../utils/helper";
export const MentorShipRequestCard = ({
  acceptLabel = "Accept",
  cancelLabel = "Cancel Mentorship",
  isAccept = false,
  isCancel = true,
  skills = "",
  name,
  onAccept,
  onReject,
  isBook = false,
  bookLabel,
  onBook,
  isAcceptMentor = false,
  time = "",
  profilePic = defaultLoginImage,
  timeZone,
}) => {
  const [localTime, setlocalTime] = useState("");
  useEffect(() => {
    setlocalTime(timeZone ? convertUTCToTimezone(time, timeZone) : time);
  }, [time]);

  return (
    <Grid
      className="overideMentorshipRequestCard"
      container
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid container alignItems={"center"} item gap={"20px"} lg={9}>
        <img
          src={profilePic}
          alt=""
          height={"48px"}
          width={"48px"}
          className="profilePic"
        />
        <Grid item lg={10.5} textAlign={"left"}>
          {isAccept ? (
            <p className="font400">
              <span className="font700">{name}</span> has requested mentorship
              of <span className="font700"> {skills}</span>
            </p>
          ) : isAcceptMentor ? (
            <p className="font400">
              <span className="font700">{name}</span> has requested mentorship
              on <span className="font700"> {localTime}</span>
            </p>
          ) : (
            <p className="font400">
              You have requested <span className="font700">{name}</span> for
              mentorship of
              <span className="font700"> {skills}</span>
            </p>
          )}
        </Grid>
      </Grid>

      <Grid container lg={3} gap={1}>
        {isAccept && (
          <Buttons
            onClick={onAccept}
            varientContained={true}
            label={acceptLabel}
          />
        )}
        {isAcceptMentor && (
          <Buttons
            onClick={onAccept}
            varientContained={true}
            label={acceptLabel}
          />
        )}
        {isCancel && (
          <Buttons
            onClick={onReject}
            varientLeaveContained={true}
            label={cancelLabel}
          />
        )}
        {isBook && (
          <Grid className="btn">
            <Buttons
              onClick={onBook}
              varientContained={true}
              label={bookLabel}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
