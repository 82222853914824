import React from "react";
import Popover from "@mui/material/Popover";
import "./FilterPopover.scss";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Grid,
} from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import Buttons from "../../../library/custom/button/Buttons";

const FilterPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleApply,
  addFilterState,
  onChange,
  account_type,
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases">
          <CardContent>
            <Box
              className=""
              sx={{
                width: 300,
                pb: 2,
                pt: 2,
              }}
            >
              <FormGroup>
                {account_type !== "corporate" && (
                  <FormControlLabel
                    className="label"
                    control={
                      <Checkbox
                        checked={addFilterState.skills}
                        onChange={onChange}
                        name="skills"
                      />
                    }
                    label="Skill"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addFilterState.country}
                      onChange={onChange}
                      name="country"
                    />
                  }
                  label="Country"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addFilterState.city}
                      onChange={onChange}
                      name="city"
                    />
                  }
                  label="City"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addFilterState.zipCode}
                      onChange={onChange}
                      name="zipCode"
                    />
                  }
                  label="Zip Code"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addFilterState.experiance}
                      onChange={onChange}
                      name="experiance"
                    />
                  }
                  label={
                    account_type === "corporate"
                      ? "Employee Count"
                      : "Experience"
                  }
                />
              </FormGroup>
            </Box>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid container justifyContent={"right"} item>
              <button
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  color: "#5061FF",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
                onClick={(e) => handleClose(e)}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  color: "#5061FF",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
                onClick={(e) => handleApply(e)}
              >
                Yes
              </button>
            </Grid>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterPopover;
