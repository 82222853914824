import React, { useEffect, useState } from "react";
import CreateJob from "./CreateJob";
import { useDispatch, useSelector } from "react-redux";
import * as editProfileAction from "../../redux/actions/editProfileAction";
import * as jobPostAction from "../../redux/actions/jobPostAction";
import SuccessScreen from "../../library/custom/success/successScreen";
import Loader from "../../library/common/Loader";

function CreateJobContainer() {
  const dispatch = useDispatch();

  const allIndustries = useSelector(
    (state) => state?.industries?.data?.response
  );
  const editProfile = (data) => {
    dispatch(editProfileAction.putEditProfile(data));
  };
  useEffect(() => {
    dispatch(editProfileAction?.getIndustries());
  }, []);

  const defaultData = {
    page: 1,
    limit: 1000,
  };

  const getPostedJob = (data) => {
    dispatch(jobPostAction.postedJobs(data));
  };

  useEffect(() => {
    getPostedJob(defaultData);
  }, []);

  const showLoader = useSelector(
    (state) =>
      state?.putJobPostData.isLoading ||
      state?.postedJobData.isLoading ||
      state?.postedJobDetailsData.isLoading ||
      state?.updateJobPostData.isLoading ||
      state?.industries.isLoading
  );
  const putJobPostData = useSelector((state) => state.putJobPostData);
  const updateJobPostData = useSelector((state) => state.updateJobPostData);
  const userData = useSelector((state) => state?.userDetails?.data?.response);
  const postedJobDatas = useSelector((state) => state.postedJobData);
  const postedJobDetailsData = useSelector(
    (state) => state.postedJobDetailsData
  );
  const industriesData = useSelector((state) => state.industries);

  const getpostedJobDetail = (data) => {
    dispatch(jobPostAction.getPostedJobsDeatils(data));
  };

  const jobPost = (data) => {
    dispatch(jobPostAction?.putJobPost(data));
    // setSetsubmit(true);
  };

  useEffect(() => {
    dispatch(jobPostAction.clearJobs());
    getpostedJobDetail();
  }, []);

  const updatejob = (sendDataEdit) => {
    const data = {
      id: postedJobDetailsData?.data?.response?.jobid,
      sendDataEdit: sendDataEdit,
    };
    dispatch(jobPostAction.updateJobPost(data));
  };

  return (
    <>
      {showLoader && <Loader />}
      <CreateJob
        allIndustries={allIndustries}
        editProfile={editProfile}
        jobPost={jobPost}
        updatejob={updatejob}
        userData={userData}
        postedJobDatas={postedJobDatas?.data?.response?.rows}
        getpostedJobDetail={getpostedJobDetail}
        postedJobDetailsData={postedJobDetailsData}
        industriesData={industriesData}
        putJobPostData={putJobPostData}
        updateJobPostData={updateJobPostData}
        getPostedJob={getPostedJob}
      />
    </>
  );
}

export default CreateJobContainer;
