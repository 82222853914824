import { mentorshipType } from "../constant/mentorshipType";

export const getMentorshipSubjects = (data) => ({
  type: mentorshipType.GET_MENTORSHIP_SUBJECTS_PENDING,
  data,
});

export const getMentorshipSubjectsSuccess = (data) => ({
  type: mentorshipType.GET_MENTORSHIP_SUBJECTS_SUCCESS,
  data,
});

export const getMentorshipSubjectsFailure = (error) => ({
  type: mentorshipType.GET_MENTORSHIP_SUBJECTS_FAILURE,
  error,
});

export const getMentorsList = (data) => ({
  type: mentorshipType.GET_MENTORS_LIST_PENDING,
  data,
});

export const getMentorsListSuccess = (data) => ({
  type: mentorshipType.GET_MENTORS_LIST_SUCCESS,
  data,
});

export const getMentorsListFailure = (error) => ({
  type: mentorshipType.GET_MENTORS_LIST_FAILURE,
  error,
});

export const clearMentorsList = () => ({
  type: mentorshipType.GET_MENTORS_LIST_CLEAR,
  data: "",
});

export const postRequestMentorship = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_PENDING,
  data,
});

export const postRequestMentorshipSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_SUCCESS,
  data,
});

export const postRequestMentorshipFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_FAILURE,
  error,
});

export const getMyMentees = (data) => ({
  type: mentorshipType.GET_MY_MENTEES_PENDING,
  data,
});

export const getMyMenteesSuccess = (data) => ({
  type: mentorshipType.GET_MY_MENTEES_SUCCESS,
  data,
});

export const getMyMenteesFailure = (error) => ({
  type: mentorshipType.GET_MY_MENTEES_FAILURE,
  error,
});

export const getMyMentors = (data) => ({
  type: mentorshipType.GET_MY_MENTORS_PENDING,
  data,
});

export const getMyMentorsSuccess = (data) => ({
  type: mentorshipType.GET_MY_MENTORS_SUCCESS,
  data,
});

export const getMyMentorsFailure = (error) => ({
  type: mentorshipType.GET_MY_MENTORS_FAILURE,
  error,
});

export const postRequestMentorshipReject = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_PENDING,
  data,
});

export const postRequestMentorshipRejectSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_SUCCESS,
  data,
});

export const postRequestMentorshipRejectFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_REJECT_FAILURE,
  error,
});

export const postRequestMentorshipAccept = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_PENDING,
  data,
});

export const postRequestMentorshipAcceptSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_SUCCESS,
  data,
});

export const postRequestMentorshipAcceptFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_ACCEPT_FAILURE,
  error,
});

export const postRequestMentorshipCancel = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_PENDING,
  data,
});

export const postRequestMentorshipCancelSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_SUCCESS,
  data,
});

export const postRequestMentorshipCancelFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_REQUEST_CANCEL_FAILURE,
  error,
});

export const postMentorshipBookSession = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_BOOK_SESSION_PENDING,
  data,
});

export const postMentorshipBookSessionSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_BOOK_SESSION_SUCCESS,
  data,
});

export const postMentorshipBookSessionFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_BOOK_SESSION_FAILURE,
  error,
});

export const getMentorshipUpcomingSessions = (data) => ({
  type: mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_PENDING,
  data,
});

export const getMentorshipUpcomingSessionsSuccess = (data) => ({
  type: mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_SUCCESS,
  data,
});

export const getMentorshipUpcomingSessionsFailure = (error) => ({
  type: mentorshipType.GET_MENTORSHIP_UPCOMING_SESSIONS_FAILURE,
  error,
});

export const postMentorshipSessionAccept = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_PENDING,
  data,
});

export const postMentorshipSessionAcceptSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_SUCCESS,
  data,
});

export const postMentorshipSessionAcceptFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_ACCEPT_FAILURE,
  error,
});

export const postMentorshipSessionReject = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_REJECT_PENDING,
  data,
});

export const postMentorshipSessionRejectSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_REJECT_SUCCESS,
  data,
});

export const postMentorshipSessionRejectFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_REJECT_FAILURE,
  error,
});

export const postMentorshipSessionCancel = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_PENDING,
  data,
});

export const postMentorshipSessionCancelSuccess = (data) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_SUCCESS,
  data,
});

export const postMentorshipSessionCancelFailure = (error) => ({
  type: mentorshipType.POST_MENTORSHIP_SESSION_CANCEL_FAILURE,
  error,
});
