import React, { useState } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../library/custom/textArea/TextArea";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../library/custom/textBox/InputField";
import { E_YEAR, MONTHS, S_YEAR } from "../../../constant/constant";

const EducationForm = ({
  singleEdu,
  handleEduFormChange,
  setUnsavedChanges,
}) => {
  let years = [];
  for (let i = E_YEAR; i >= S_YEAR; i--) {
    years.push(i);
  }

  const [endMonths, setendMonths] = useState(MONTHS)
  let endYears = [];

  for (let i = E_YEAR + 20; i >= singleEdu.startY; i--) {
    endYears.push(i);
  }

  // let endMonth = [];

  return (
    <>
      <Grid className="workExpirience">
        <Grid item container lg={11} xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="Degree / Diploma"
            id="degree"
            isRequired={true}
            placeholder="Enter here"
            value={singleEdu.degree}
            onChange={(e) => {
              handleEduFormChange(e, singleEdu, "degree");
              setUnsavedChanges(true);
            }}
            isError={singleEdu.isDegree}
            errorMsg={
              singleEdu.isDegree ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid mt={2} item container lg={11} xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="School / College"
            id={"institute"}
            isRequired={true}
            placeholder="Enter here"
            value={singleEdu.institute}
            onChange={(e) => {
              handleEduFormChange(e, singleEdu, "institute");
              setUnsavedChanges(true);
            }}
            isError={singleEdu.isInstitute}
            errorMsg={
              singleEdu.isInstitute ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid mt={2} item container lg={11} xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="Major / Specialization"
            id={"special"}
            isRequired={true}
            placeholder="Enter here"
            value={singleEdu.special}
            onChange={(e) => {
              handleEduFormChange(e, singleEdu, "special");
              setUnsavedChanges(true);
            }}
            isError={singleEdu.isSpecial}
            errorMsg={
              singleEdu.isSpecial ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid textAlign={"left"} lg={11} mt={2}>
          <FormLabels isRequired={true} label="Time Period" />

          <Grid className="fName" item container lg={12} xs={12} sm={12} md={12}>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={true}
                label="Start Month"
                id={"startM"}
                value={singleEdu.startM}
                name={"startM"}
                onchangehandler={(e) => {
                  handleEduFormChange(e, singleEdu, "select");
                  setUnsavedChanges(true);
                }}
                listData={MONTHS}
                countries={true}
                isError={singleEdu.isStartM}
                errorMsg={
                  singleEdu.isStartM ? "Please enter this required field" : ""
                }
              />
            </Grid>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="Start Year"
                id={"startY"}
                value={singleEdu.startY}
                name={"startY"}
                onchangehandler={(e) => {
                  handleEduFormChange(e, singleEdu, "select");
                  setUnsavedChanges(true);
                }}
                listData={years}
                countries={true}
                isError={singleEdu.isStartM}
                errorMsg={
                  singleEdu.isStartM ? "Please enter this required field" : ""
                }
              />
            </Grid>
          </Grid>

          <Grid
            className="fName"
            mt={2}
            item
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="End Month (or expected)"
                id={"endM"}
                name={"endM"}
                value={singleEdu.endM}
                onchangehandler={(e) => {
                  handleEduFormChange(e, singleEdu, "select");
                  setUnsavedChanges(true);
                }}
                listData={endMonths} // Use the endMonth array
                countries={true}
                isError={singleEdu.isEndM}
                errorMsg={
                  singleEdu.isEndM ? "Please enter this required field" : ""
                }
              />
            </Grid>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="End Year (or expected)"
                id={"endY"}
                name={"endY"}
                value={singleEdu.endY}
                onchangehandler={(e) => {
                  handleEduFormChange(e, singleEdu, "select");
                  setUnsavedChanges(true);
                  if (singleEdu.startY === e.target.value) {
                    const startMonthIndex = MONTHS.indexOf(singleEdu.startM);
                    setendMonths(MONTHS.slice(startMonthIndex, 12))
                  } else {
                    setendMonths(MONTHS)
                  }
                }}
                listData={endYears}
                countries={true}
                isError={singleEdu.isEndY}
                errorMsg={
                  singleEdu.isEndY ? "Please enter this required field" : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={4} lg={11}>
          <FormLabels label="Activities & Societies" />
          <TextArea
            id={"activities"}
            value={singleEdu.activities}
            placeholder={"Enter here"}
            onChange={(e) => {
              handleEduFormChange(e, singleEdu, "activities");
              setUnsavedChanges(true);
            }}
          />
        </Grid>

        <Grid mt={4} lg={11}>
          <FormLabels label="Accomplishments" />
          <TextArea
            id={"accomplishments"}
            placeholder={"Enter here"}
            value={singleEdu.accomplishments}
            onChange={(e) => {
              handleEduFormChange(e, singleEdu, "accomplishments");
              setUnsavedChanges(true);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EducationForm;
