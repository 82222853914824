import { get, post } from "./axiosRequest";
import { getApiUrl } from "../config";

export const checkUserExist = async ({ data }) => {
  const url = `${getApiUrl().baseUrl}/user/check?email=${data.email}`;
  try {
    return await get(url, data);
  } catch (err) {
    return err;
  }
};

export const otpSignup = async ({ data }) => {
  const url = `${getApiUrl().baseUrl}/user/email-verify/otp?email=${data.email}`;
  try {
    return await get(url, data);
  } catch (err) {
    return err;
  }
};

export const otpVerifySignup = async ({ data }) => {
  const url = `${getApiUrl().baseUrl}/user/email-verify?email=${data.email}&otp=${data.otp}`;
  try {
    return await get(url, data);
  } catch (err) {
    return err;
  }
};


export const Signup = async ({ data }) => {
  const url = `
  ${getApiUrl().baseUrl}/user/signup`;
  try {
    return await post(url, data);
  } catch (err) {
    return err;
  }
};

export const SignupWithGoogle = async ({ data }) => {
  const url = `
  ${getApiUrl().baseUrl}/social-account`;
  try {
    return await post(url, data);
  } catch (err) {
    return err;
  }
};
