import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Search.scss";
import {
  COMPANY_TYPE,
  EMPLOYEE_COUNT,
  EXPERIANCE,
} from "../../../constant/constant";
import Buttons from "../../../library/custom/button/Buttons";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FilterPopover from "./FilterPopover";
import SearchMap from "./Map";
import NoUser from "../../../assets/noUser.png";
import SearchedUserCard from "../../../library/custom/searchCard/SearchedUserCard";
import { COUNTRIES } from "../../../constant/countries";
import { InputField } from "./InputField";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import { useNavigate } from "react-router-dom";
import { VIEW_PROFILE } from "../../../constant/routeContant";
const Search = ({
  mapSearch,
  searchText,
  industryData,
  mapSearchCorporate,
  setAccount_type,
  account_type,
  listArr,
  setlistArr,
  setsearchText,
  mapLoading,
  handleSearchProfileClick,
  formOnchange,
  setFormOnchange,
  formOnchangeCorporate,
  setformOnchangeCorporate,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const recentSearches = useSelector(
    (state) => state?.recentSearches?.data?.response?.recentsearch
  );
  const popularSearches = useSelector(
    (state) => state?.popularSearches?.data?.response?.popularsearch
  );
  const popularSearchesCorporate = useSelector(
    (state) => state?.getPopularSearchesCorporate?.data?.response?.popularsearch
  );
  const getRecentSearchesCorporateData = useSelector(
    (state) => state?.getRecentSearchesCorporate?.data?.response?.recentsearch
  );
  const [addFilterState, setAddFilterState] = useState({
    country: false,
    city: false,
    experiance: false,
    jobType: false,
    skills: false,
  });

  const [addNewFilterState, setAddNewFilterState] = useState({
    zipCode: false,
    country: false,
    city: false,
    experiance: false,
    skills: false,
    // jobType: false,
  });
  function handleFilterList(event) {
    const newState = {
      ...addFilterState,
      [event.target.name]: event.target.checked,
    };
    setAddFilterState(newState);
  }
  function handleAddNewApply() {
    setAddNewFilterState(addFilterState);
    handleClose();
  }
  const [byNameOrSkill, setbyNameOrSkill] = useState("0");

  function handleByNameOrSKill(value) {
    setbyNameOrSkill(value);
    setFormValue({
      ...formValue,
      name: value === "0" ? true : false,
    });
  }
  const [formValue, setFormValue] = useState({
    name: true,
    searchText: "",
    zipcode: "",
    pageNo: "1",
    country: "",
    city: "",
    function: "",
    experience: "",
    role: "",
    skills: "",
  });
  const [corporateFormValue, setCorporateFormValue] = useState({
    city: "",
    country: "",
    employeeCount: "",
    industry: "",
    name: true,
    pageNo: 1,
    searchText: "",
    type: "",
    zipcode: "",
  });
  function getRoles(value) {
    let valueArray = [];
    industryData?.functions?.forEach((data) => {
      if (data.name === formValue.function) {
        valueArray = data.roles;
      }
    });
    return valueArray;
  }
  const roleArray = getRoles();

  const onchangehandlers = (e) => {
    setFormOnchange(true);
    if (e.target.name === "function") {
      setFormValue({
        ...formValue,
        role: 0,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormValue({
        ...formValue,
        [e.target.name]: e.target.value,
      });
    }
  };
  const [debouncedValue, setDebouncedValue] = useState("");
  const [corporateDebouncedValue, setCorporateDebouncedValue] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(formValue);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [formValue.searchText]);
  useEffect(() => {
    const filterObj = {
      searchText: formValue.searchText,
      zipcode: formValue.zipcode,
      pageNo: "1",
      country: formValue.country == 0 ? "" : formValue.country,
      city: formValue.city,
      function: formValue.function == 0 ? "" : formValue.function,
      experience: formValue.experience == 0 ? "" : formValue.experience,
      role: formValue.role == 0 ? "" : formValue.role,
      skills: formValue.skills == 0 ? "" : formValue.skills,
      name: formValue.name,
    };
    if (formValue.searchText !== "") {
      mapSearch(filterObj);
    }
  }, [debouncedValue]);
  useEffect(() => {
    const filterObj = {
      searchText: formValue.searchText,
      zipcode: formValue.zipcode,
      pageNo: "1",
      country: formValue.country == 0 ? "" : formValue.country,
      city: formValue.city,
      function: formValue.function == 0 ? "" : formValue.function,
      experience: formValue.experience == 0 ? "" : formValue.experience,
      role: formValue.role == 0 ? "" : formValue.role,
      skills: formValue.skills == 0 ? "" : formValue.skills,
    };
    if (formOnchange) {
      mapSearch(filterObj);
      setFormOnchange(false);
    }
  }, [formValue]);

  const onChangeForCorporate = (e) => {
    setformOnchangeCorporate(true);

    setCorporateFormValue({
      ...corporateFormValue,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const filterObj = {
      searchText: corporateFormValue.searchText,
      zipcode: corporateFormValue.zipcode,
      pageNo: "1",
      country:
        corporateFormValue.country == "0" ? "" : corporateFormValue.country,
      city: corporateFormValue.city,
      industry:
        corporateFormValue.industry == "0" ? "" : corporateFormValue.industry,
      employeeCount:
        corporateFormValue.employeeCount == "0"
          ? ""
          : corporateFormValue?.employeeCount,
      type: corporateFormValue.type == "0" ? "" : corporateFormValue.type,
      name: true,
    };
    if (formOnchangeCorporate) {
      mapSearchCorporate(filterObj);
      setformOnchangeCorporate(false);
    }
  }, [corporateFormValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCorporateDebouncedValue(corporateFormValue);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [corporateFormValue.searchText]);
  useEffect(() => {
    const filterObj = {
      searchText: corporateFormValue.searchText,
      zipcode: corporateFormValue.zipcode,
      pageNo: "1",
      country:
        corporateFormValue.country == 0 ? "" : corporateFormValue.country,
      city: corporateFormValue.city,
      industry:
        corporateFormValue.industry == 0 ? "" : corporateFormValue.industry,
      employeeCount:
        corporateFormValue.employeeCount == 0
          ? ""
          : corporateFormValue.employeeCount,
      type: corporateFormValue.type == 0 ? "" : corporateFormValue.type,
      name: true,
    };
    if (corporateFormValue.searchText !== "") {
      mapSearchCorporate(filterObj);
    }
  }, [corporateDebouncedValue]);
  return (
    <div>
      <Grid mt={2} container className="overRideSearch">
        <Grid conainer item lg={8} xs={12}>
          <Grid container item lg={10.3} xs={12}>
            {account_type === "corporate" ? (
              <SearchBar
                placeholder={"Search"}
                searchbarInput={corporateFormValue.searchText}
                handleSearch={(e) => {
                  setCorporateFormValue({
                    ...corporateFormValue,
                    searchText: e,
                  });
                  if (e === "") {
                    setlistArr([]);
                    setsearchText("");
                  }
                }}
              />
            ) : (
              <SearchBar
                placeholder={"Search"}
                searchbarInput={formValue.searchText}
                handleSearch={(e) => {
                  setFormValue({
                    ...formValue,
                    searchText: e,
                  });
                  if (e === "") {
                    setlistArr([]);
                    setsearchText("");
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid conainer item lg={12} mt={2}>
          <Grid container item lg={12} mt={2}>
            <h1 className="filterLabel">Filter By</h1>
          </Grid>
          <Grid textAlign={"left"} container gap={"10px"} item lg={4} xs={12}>
            <Grid container item lg={5.5} xl={4}>
              <RadioButton
                value="individual"
                onChange={(e) => {
                  setAccount_type(e.target.value);
                  setlistArr([]);
                  setFormValue({
                    name: true,
                    searchText: "",
                    zipcode: "",
                    pageNo: "1",
                    country: "",
                    city: "",
                    function: "",
                    experience: "",
                    role: "",
                    skills: "",
                  });
                }}
                label="Individual"
                checked={account_type === "individual"}
              />
              {account_type !== "corporate" && (
                <RadioButton
                  value="0"
                  onChange={(e) => handleByNameOrSKill(e.target.value)}
                  label="Name"
                  checked={byNameOrSkill === "0"}
                />
              )}
            </Grid>
            <Grid container item lg={5.5} xl={4}>
              <RadioButton
                value="corporate"
                onChange={(e) => {
                  setAccount_type(e.target.value);
                  setlistArr([]);
                  setFormValue({
                    name: true,
                    searchText: "",
                    zipcode: "",
                    pageNo: "1",
                    country: "",
                    city: "",
                    function: "",
                    experience: "",
                    role: "",
                    skills: "",
                  });
                }}
                label="Corporate"
                checked={account_type === "corporate"}
              />
              {account_type !== "corporate" && (
                <RadioButton
                  value="1"
                  onChange={(e) => handleByNameOrSKill(e.target.value)}
                  label="Skill"
                  checked={byNameOrSkill === "1"}
                />
              )}
            </Grid>
          </Grid>
          <Grid container gap={"10px"} mt={2} item lg={12} sm={12} xs={12}>
            {account_type === "corporate" ? (
              <Grid textAlign={"left"} item lg={2.9} xs={12}>
                <p className="filterLabel">Industry</p>
                <SelectMenu
                  disabledDefault={true}
                  listData={industryData?.functions}
                  functions={true}
                  onchangehandler={(e) => onChangeForCorporate(e)}
                  label={"Please Select"}
                  name="industry"
                  value={
                    corporateFormValue.industry
                      ? corporateFormValue.industry
                      : 0
                  }
                />
              </Grid>
            ) : (
              <Grid textAlign={"left"} item lg={2.9} xs={12}>
                <p className="filterLabel">Function</p>
                <SelectMenu
                  disabledDefault={true}
                  listData={industryData?.functions}
                  functions={true}
                  onchangehandler={(e) => onchangehandlers(e)}
                  label={"Please Select Function"}
                  name="function"
                  value={formValue.function ? formValue.function : 0}
                />
              </Grid>
            )}
            {account_type === "corporate" ? (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Organisation Type</p>
                <SelectMenu
                  disabledDefault={true}
                  listData={COMPANY_TYPE}
                  label={"Please Select"}
                  // disabled={roleArray.length > 0 ? true : false}
                  isKeyValue={true}
                  onchangehandler={(e) => onChangeForCorporate(e)}
                  value={corporateFormValue.type ? corporateFormValue?.type : 0}
                  name="type"
                />
              </Grid>
            ) : (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Role</p>
                <SelectMenu
                  disabledDefault={true}
                  listData={roleArray}
                  // disabled={roleArray.length > 0 ? true : false}
                  functions={true}
                  name="role"
                  onchangehandler={(e) => onchangehandlers(e)}
                  value={formValue.role ? formValue.role : 0}
                  label={"Please Select Role"}
                />
              </Grid>
            )}

            <Grid container alignItems={"center"} item lg={2.2} xs={6} mt={3}>
              <Buttons onClick={handlePopover} label={"Add Filter"} />
              <FilterPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleApply={handleAddNewApply}
                addFilterState={addFilterState}
                onChange={handleFilterList}
                account_type={account_type}
              />
            </Grid>
            <Grid container item lg={2.2} xs={6}></Grid>

            {addNewFilterState.skills && account_type !== "corporate" && (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Skill</p>
                <SelectMenu
                  disabledDefault={true}
                  label="Select Skill"
                  listData={industryData?.skills}
                  countries={true}
                  onchangehandler={(e) => onchangehandlers(e)}
                  name="skills"
                  value={formValue.skills ? formValue.skills : 0}
                />
              </Grid>
            )}
            {addNewFilterState.country && (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Country</p>
                {account_type === "corporate" ? (
                  <SelectMenu
                    disabledDefault={true}
                    label=" Select Country"
                    listData={COUNTRIES}
                    countries={true}
                    onchangehandler={(e) => onChangeForCorporate(e)}
                    value={
                      corporateFormValue.country
                        ? corporateFormValue.country
                        : 0
                    }
                    name="country"
                  />
                ) : (
                  <SelectMenu
                    disabledDefault={true}
                    label="Select Country"
                    listData={COUNTRIES}
                    name="country"
                    countries={true}
                    onchangehandler={(e) => onchangehandlers(e)}
                    value={formValue.country ? formValue.country : 0}
                  />
                )}
              </Grid>
            )}
            {addNewFilterState.city && (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">City</p>
                {account_type === "corporate" ? (
                  <InputField
                    placeholder={"Enter here"}
                    onChange={(e) => onChangeForCorporate(e)}
                    value={corporateFormValue.city}
                    name="city"
                  />
                ) : (
                  <InputField
                    placeholder={"Enter here"}
                    onChange={(e) => onchangehandlers(e)}
                    name="city"
                    value={formValue.city}
                  />
                )}
              </Grid>
            )}
            {addNewFilterState.zipCode && (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Zip Code</p>
                {account_type === "corporate" ? (
                  <InputField
                    placeholder={"Enter here"}
                    onChange={(e) => onChangeForCorporate(e)}
                    value={corporateFormValue.zipcode}
                    name="zipcode"
                  />
                ) : (
                  <InputField
                    placeholder={"Enter here"}
                    onChange={(e) => onchangehandlers(e)}
                    value={formValue.zipcode}
                    name="zipcode"
                  />
                )}
              </Grid>
            )}
            {addNewFilterState.experiance && account_type === "corporate" && (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Employee Count</p>
                <SelectMenu
                  disabledDefault={false}
                  label="Employee Count"
                  countries={true}
                  listData={EMPLOYEE_COUNT}
                  name={"employeeCount"}
                  value={
                    corporateFormValue.employeeCount
                      ? corporateFormValue.employeeCount
                      : 0
                  }
                  onchangehandler={(e) => {
                    onChangeForCorporate(e);
                    // setCorporateFormValue({
                    //   ...corporateFormValue,
                    //   employeeCount: e.target.value,
                    // });
                    // mapSearch({
                    //   ...corporateFormValue,
                    //   employeeCount: e.target.value,
                    // });
                  }}
                />
              </Grid>
            )}

            {addNewFilterState.experiance && account_type !== "corporate" && (
              <Grid item lg={2.9} xs={12} textAlign={"left"}>
                <p className="filterLabel">Experiance</p>
                <SelectMenu
                  disabledDefault={false}
                  label="Select Experience"
                  countries={true}
                  listData={EXPERIANCE}
                  onchangehandler={(e) => onchangehandlers(e)}
                  value={formValue.experiance ? formValue.experiance : 0}
                  name="experience"
                />
              </Grid>
            )}
          </Grid>
          <Grid mt={2} gap={"20px"} container item lg={12}>
            <Grid container item lg={12}>
              <h1 className="filterLabel">{`${listArr?.length} results found`}</h1>
            </Grid>
            <Grid className="searchGrid" item lg={7} sm={12}>
              {/* {isLoaded ? ( */}
              <SearchMap
                searchText={searchText}
                account_type={account_type}
                listArr={listArr}
                mapLoading={mapLoading}
                handleSearchProfileClick={handleSearchProfileClick}
              />
              {/* ) : (
                ""
              )} */}
            </Grid>
            <Grid item lg={4} xs={12} className="recentSearchContainer">
              {account_type === "corporate" && searchText
                ? listArr?.map((item, i) => (
                    <Grid
                      container
                      justifyContent={"left"}
                      flexWrap={"wrap"}
                      className="cardBody"
                      p={1}
                      mt={1}
                      onClick={() =>
                        navigate(VIEW_PROFILE, {
                          state: { id: item?.id, userType: "corporate" },
                        })
                      }
                    >
                      <Grid
                        lg={12}
                        item
                        container
                        justifyContent={"left"}
                        alignItems={"center"}
                        className="card"
                      >
                        <Grid item>
                          <img
                            src={
                              item?.profilePic === null
                                ? NoUser
                                : item?.profilePic
                            }
                            alt=""
                            height={"48px"}
                            width={"48px"}
                          />
                        </Grid>
                        <Grid item textAlign={"left"} ml={1}>
                          <p className="name">{item?.name}</p>
                          <p className="adress">
                            {item?.city !== null && `${item?.city}, `}
                            {item?.state !== null && `${item?.state}, `}{" "}
                            {item?.country !== null && item?.country}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                : searchText &&
                  listArr?.map((item, i) => (
                    <Grid
                      mt={i !== 0 && 3}
                      key={i}
                    >
                      <SearchedUserCard
                        onProfileClick={() =>
                          handleSearchProfileClick({
                            id: item.id,
                            userType: account_type,
                          })
                        }
                        name={item.name}
                        designation={
                          item?.details?.profile?.work_experience
                            ?.designations[0]
                        }
                        city={item.city}
                        state={item.state}
                        country={item?.country}
                        company={
                          item?.details?.profile?.work_experience?.orgNames[0]
                        }
                        profilePic={
                          item.profilePic === null ? NoUser : item.profilePic
                        }
                        btnLabel={"Message"}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={9} md={10} gap={"10px"}>
          <Grid className="" item lg={5.5} md={5.5} xl={4}>
            <Grid
              item
              lg={11}
              textAlign={"left"}
              className="recentSearchContainer"
              mt={2}
            >
              <h1 className="filterLabel">Recent Searches</h1>
              {account_type === "corporate"
                ? getRecentSearchesCorporateData?.map((item, i) => (
                    <Grid
                      container
                      justifyContent={"left"}
                      flexWrap={"wrap"}
                      className="cardBody"
                      p={1}
                      mt={1}
                      onClick={() =>
                        navigate(VIEW_PROFILE, {
                          state: { id: item?.corpId, userType: "corporate" },
                        })
                      }
                    >
                      <Grid
                        lg={12}
                        item
                        container
                        justifyContent={"left"}
                        alignItems={"center"}
                        className="card"
                      >
                        <Grid item>
                          <img
                            src={
                              item?.details.logo === null
                                ? NoUser
                                : item?.details.logo
                            }
                            alt=""
                            height={"48px"}
                            width={"48px"}
                          />
                        </Grid>
                        <Grid item textAlign={"left"} ml={1}>
                          <p className="name">{item?.details?.name}</p>
                          <p className="adress">
                            {item?.details?.city !== null &&
                              `${item?.details?.city}, `}
                            {item?.details?.state !== null &&
                              `${item?.details?.state}, `}{" "}
                            {item?.details?.country !== null &&
                              item?.details?.country}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                : recentSearches?.map((item, i) => (
                    <Grid
                      mt={3}
                      key={i}
                      onClick={() =>
                        navigate(VIEW_PROFILE, {
                          state: { id: item?.individualId },
                        })
                      }
                    >
                      <SearchedUserCard
                        name={
                          item?.details?.basicDetails?.first_name +
                          " " +
                          item?.details?.basicDetails?.last_name
                        }
                        designation={
                          item?.details?.profile?.work_experience
                            ?.designations[0]
                        }
                        city={item?.details?.basicDetails?.city}
                        state={item?.details?.basicDetails?.state}
                        country={item?.details?.basicDetails?.country_name}
                        company={
                          item?.details?.profile?.work_experience?.orgNames[0]
                        }
                        profilePic={
                          item?.details?.basicDetails?.profile_pic === null
                            ? NoUser
                            : item?.details?.basicDetails?.profile_pic
                        }
                        btnLabel={"Message"}
                        onProfileClick={
                          () => console.log()
                          // navigate(VIEW_PROFILE, {
                          //   state: { id: item?.id },
                          // })
                        }
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid mt={1.8} className="recentSearchContainer" item lg={5.5} md={5.5}>
            <Grid item lg={12} textAlign={"left"}>
              <h1 className="filterLabel">Top Searches</h1>
              {account_type === "corporate"
                ? popularSearchesCorporate?.map((item, i) => (
                    <Grid
                      container
                      justifyContent={"left"}
                      flexWrap={"wrap"}
                      className="cardBody"
                      p={1}
                      mt={1}
                      onClick={() =>
                        navigate(VIEW_PROFILE, {
                          state: { id: item?.corpId, userType: "corporate" },
                        })
                      }
                    >
                      <Grid
                        lg={12}
                        item
                        container
                        justifyContent={"left"}
                        alignItems={"center"}
                        className="card"
                      >
                        <Grid item>
                          <img
                            src={
                              item?.details.logo === null
                                ? NoUser
                                : item?.details.logo
                            }
                            alt=""
                            height={"48px"}
                            width={"48px"}
                          />
                        </Grid>
                        <Grid item textAlign={"left"} ml={1}>
                          <p className="name">{item?.details?.name}</p>
                          <p className="adress">
                            {item?.details?.city !== null &&
                              `${item?.details?.city}, `}
                            {item?.details?.state !== null &&
                              `${item?.details?.state}, `}{" "}
                            {item?.details?.country !== null &&
                              item?.details?.country}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                : popularSearches?.map((item, i) => (
                    <Grid
                      mt={3}
                      key={i}
                      onClick={() =>
                        navigate(VIEW_PROFILE, {
                          state: { id: item?.individualId },
                        })
                      }
                    >
                      <SearchedUserCard
                        name={
                          item?.details?.basicDetails?.first_name +
                          " " +
                          item?.details?.basicDetails?.last_name
                        }
                        designation={
                          item?.details?.profile?.work_experience
                            ?.designations[0]
                        }
                        city={item?.details?.basicDetails?.city}
                        state={item?.details?.basicDetails?.state}
                        country={item?.details?.basicDetails?.country_name}
                        company={
                          item?.details?.profile?.work_experience?.orgNames[0]
                        }
                        profilePic={
                          item?.details?.basicDetails?.profile_pic === null
                            ? NoUser
                            : item?.details?.basicDetails?.profile_pic
                        }
                        btnLabel={"Message"}
                        onProfileClick={
                          () => console.log()
                          // navigate(VIEW_PROFILE, {
                          //   state: { id: item?.id },
                          // })
                        }
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </div>
  );
};

export default Search;
