import { get, post } from "./axiosRequest";
import { getApiUrl } from "../config";

export const getTopCommunities = async ({ params }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/get-top-communities`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getMyCommunities = async ({ params }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/get-my-communities`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCommunities = async ({ data = false }) => {
  const token = sessionStorage.getItem("token");
  const url = `${
    getApiUrl()?.baseUrl
  }/community/get-communities?limit=100&page=0&isMember=${data}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const joinCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/join-community`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCommunitiesAdmin = async () => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/get-communities`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
export const LeaveCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/leave-community`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const searchCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/search-communities`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getMyCommunitiesPosts = async (params) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/get-posts?communityId=${
    params?.data
  }`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const CreateCommunityPost = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/add-post`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const CreateCommunityPostComments = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/add-comment`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
export const addReactToPostCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/add-react-to-post`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const addReactToPostCommentCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/add-react-to-comment`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const deletePostsCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/delete-post`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const toggleCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/toggle-community`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/create-community`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const editCommunity = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/community/edit-community`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
export const resumeBuilderServices = async (payload) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/user/download-pdf`;
  try {
    return await post(
      url,
      { payload: payload.data.payload },
      {
        headers: {
          accept: "application/json, text/plain, */*",
          authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};
