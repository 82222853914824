export const settingType = {
  GET_RECOVERY_EMAIL_VERIFY_PENDING: "[SETTING][REQUEST] RECOVERY_EMAIL_VERIFY",
  GET_RECOVERY_EMAIL_VERIFY_SUCCESS:
    "[SETTING][RESPONSE] RECOVERY_EMAIL_VERIFY succesful",
  GET_RECOVERY_EMAIL_VERIFY_FAILURE:
    "[SETTING][RESPONSE] RECOVERY_EMAIL_VERIFY failure",

  GET_DEACTIVATE_ACCOUNT_PENDING: "[SETTING][REQUEST] DEACTIVATE_ACCOUNT",
  GET_DEACTIVATE_ACCOUNT_SUCCESS:
    "[SETTING][RESPONSE] DEACTIVATE_ACCOUNT succesful",
  GET_DEACTIVATE_ACCOUNT_FAILURE:
    "[SETTING][RESPONSE] DEACTIVATE_ACCOUNT failure",

  PUT_CHANGE_PASSWORD_PENDING: "[SETTING][REQUEST] CHANGE_PASSWORD",
  PUT_CHANGE_PASSWORD_SUCCESS: "[SETTING][RESPONSE] CHANGE_PASSWORD succesful",
  PUT_CHANGE_PASSWORD_FAILURE: "[SETTING][RESPONSE] CHANGE_PASSWORD failure",

  GET_PAYMENT_DETAILS_PENDING: "[SETTING][REQUEST] PAYMENT_DETAILS",
  GET_PAYMENT_DETAILS_SUCCESS: "[SETTING][RESPONSE] PAYMENT_DETAILS_SUCCESS",
  GET_PAYMENT_DETAILS_FAILURE: "[SETTING][RESPONSE] PAYMENT_DETAILS_FAILURE",

  GET_UPDATE_EMAIL_PENDING: "[SETTING][REQUEST] UPDATE_EMAIL",
  GET_UPDATE_EMAIL_SUCCESS: "[SETTING][RESPONSE] UPDATE_EMAIL_SUCCESS",
  GET_UPDATE_EMAIL_FAILURE: "[SETTING][RESPONSE] UPDATE_EMAIL_FAILURE",

  POST_ADD_CORPORATE_MEMBER_PENDING: "[SETTING][REQUEST] ADD_CORPORATE_MEMBER",
  POST_ADD_CORPORATE_MEMBER_SUCCESS:
    "[SETTING][RESPONSE] ADD_CORPORATE_MEMBER_SUCCESS",
  POST_ADD_CORPORATE_MEMBER_FAILURE:
    "[SETTING][RESPONSE] ADD_CORPORATE_MEMBER_FAILURE",

  POST_REMOVE_CORPORATE_MEMBER_PENDING:
    "[SETTING][REQUEST] REMOVE_CORPORATE_MEMBER",
  POST_REMOVE_CORPORATE_MEMBER_SUCCESS:
    "[SETTING][RESPONSE] REMOVE_CORPORATE_MEMBER_SUCCESS",
  POST_REMOVE_CORPORATE_MEMBER_FAILURE:
    "[SETTING][RESPONSE] REMOVE_CORPORATE_MEMBER_FAILURE",
};
