import React, { useState, useEffect } from "react";
import "./signup.scss";
import { Button, Grid, RadioGroup } from "@mui/material";
import SignuPic from "../../../assets/SignuPic.jpg";
import matchupITLogo from "../../../assets/Landing/matchupITLogo.png";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import { InputField } from "../../../library/custom/textBox/InputField";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { LOGIN } from "../../../constant/routeContant";
import { useNavigate } from "react-router";

function SignUp({ saveEmail, checkUserExistData, signupWithGoogle }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailEmptyError, setEmptyEmailError] = useState(false);

  const [accountType, setaccountType] = useState("individual");
  function handleAccountType(e) {
    setaccountType(e);
  }
  useEffect(() => {
    if (
      checkUserExistData.isLoading === false &&
      checkUserExistData?.isError === true &&
      checkUserExistData.status === "FAILURE"
    ) {
      setEmptyEmailError(true);
    }
  }, [checkUserExistData]);

  useEffect(() => {
    if (
      checkUserExistData.isLoading === false &&
      checkUserExistData?.data?.response?.registered === true &&
      checkUserExistData.status === "SUCCESS"
    ) {
      setEmailError(true);
    }
  }, [checkUserExistData]);

  return (
    <div className="overidesignup">
      <Grid container>
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
          item
          lg={6}
          xs={12}
          sm={6}
          md={6}
        >
          <img
            src={SignuPic}
            className="leftPic" alt="no logo"
          />
        </Grid>
        <Grid
          className="formCon"
          container
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"left"}
          item
          lg={6}
          sm={12}
          md={6}
          p={5}
        >
          
          <Grid item xs={12} lg={12} md={12} sm={9}>
          <Grid
              container
              justifyContent={"left"}
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              mt={1}
            >
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <img
                  src={matchupITLogo}
                  style={{ width: "240px", height: "102px", cursor: "pointer" }}
                  className="light-logo"
                  alt="no logo"
                  onClick={() => navigate("/")}
                />
              </Grid>

              <Grid item lg={12} xs={12} md={12} sm={12}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "550",
                    fontSize: "36px",
                    margin: "0px",
                    color: "#043133",
                    marginTop: "10px",
                  }}
                >
                  Welcome to MatchupIT
                </h1>
              </Grid>
            </Grid>
          
          <Grid item lg={10} mt={1}>
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "16px",
                  margin: "0px",
                  color: "#083A50",
                }}
              >
              Account Type
            </h1>
            <FormLabels label={""} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={accountType === "individual"}
                value="individual"
                label="Individual"
                onChange={(e) => handleAccountType(e.target.value)}
              ></RadioButton>
              <RadioButton
                checked={accountType === "corporate"}
                value="corporate"
                label="Corporate"
                onChange={(e) => handleAccountType(e.target.value)}
              ></RadioButton>
            </RadioGroup>
          </Grid> 

          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid mt={2} item lg={11} md={11} sm={12} xs={12}>
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "16px",
                  margin: "0px",
                  color: "#083A50",
                }}
              >
                Email
              </h1>
              <Grid item lg={10}>
                <InputField
                  placeholder="Enter your Email here"
                  value={email}
                  onChange={(e) => (
                    setEmail(e.target.value),
                    setEmailError(false),
                    setEmptyEmailError(false)
                  )}
                />
              </Grid>
              {emailError && (
                <div className="errorMsg">
                  This email address already exist. Try another
                </div>
              )}
              {emailEmptyError && (
                <div className="errorMsg">Please enter valid email</div>
              )}
            </Grid>
            <Grid container justifyContent={"left"} mt={2} item lg={10}>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: {
                    // xs:-12,
                    // sm:-14
                  },
                }}
                onClick={() => {
                  let validEMail =
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                  if (email.match(validEMail)) {
                    saveEmail(email, accountType);
                  } else setEmptyEmailError(true);
                }}
                style={{
                  width: "200px",
                  height: "41px",
                  border: "none",
                  background: "#778DFF",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontFamily: "Montserrat",
                }}
              >
                Continue
              </Button>
            </Grid>

            <Grid
              sx={{
                marginLeft: {
                  // xs:-2,  sm:-5
                },
              }}
              container
              display={"flex"}
              justifyContent={"left"}
            >
              <h2
                style={{
                  marginRight: "15%",
                  marginTop: "5%",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#043133",
                }}
              >
                OR
              </h2>
            </Grid>
            <Grid
              container
              display={"flex"}
              justifyContent={"left"}
              mr={"15%"}
              sx={{
                marginLeft: {
                  // xs:-4,  sm:-6
                },
              }}
            >
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  var userObject = jwt_decode(credentialResponse.credential);
                  signupWithGoogle({
                    email: userObject.email,
                    firstName: userObject.given_name,
                    lastName: userObject.family_name,
                    profilePic: userObject.picture,
                    socialId: userObject.sub,
                    socialType: "google",
                  });
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                useOneTap
                style={{ width: "200px" }}
              />
            </Grid>
            <Grid
              container
              sx={{
                marginLeft: {
                  // xs:-2
                },
              }}
              textAlign={"left"}
            >
              <p className="already-signup-txt">
                Already a MatchupIT Member?
                <b className="login" onClick={() => navigate(LOGIN)}>
                  Login
                </b>
              </p>
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignUp;
