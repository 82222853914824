import React from "react";
import parse from "html-react-parser";
import "./resumeTemplate.scss";
import ResumeTemplateHeading from "./ResumeTemplateHeading";
import ResumeTemplateProfessional from "./ResumeTemplateProfessional";

export default function ResumeTemplate({ basicDetails, profile }) {
  let workExpRows = [];
  for (let i = 0; i < profile?.work_experience?.orgNames.length; i++) {
    workExpRows.push(
      <tr
        key={`work_experience-${i}`}
        style={{ verticalAlign: "top", paddingBottom: "15px" }}
      >
        {profile?.work_experience?.tillDate[i] ? (
          <td style={{ width: "200px" }}>{`${profile?.work_experience?.startM[
            i
          ].substring(0, 3)} ${
            profile?.work_experience?.startY[i]
          } - Present`}</td>
        ) : (
          <td style={{ width: "200px" }}>
            {`${profile?.work_experience?.startM[i].substring(0, 3)} ${
              profile?.work_experience?.startY[i]
            } - `}
            {profile?.work_experience?.endM[i] === "Currently Working" ||
            profile?.work_experience?.endY[i] === "Currently Working" ? (
              "Present"
            ) : (
              <span>
                {profile?.work_experience?.endM[i].substring(0, 3)}{" "}
                {profile?.work_experience?.endY[i]}
              </span>
            )}
          </td>
        )}
        <td>
          <p>
            <b>
              {profile?.work_experience?.role && profile?.work_experience?.role
                ? profile?.work_experience?.role[i]
                : null}
            </b>
          </p>
          <p>{`${profile?.work_experience?.orgNames[i]}`}</p>
          <span>
            {profile?.work_experience?.rnrs &&
            profile?.work_experience?.rnrs.length &&
            profile?.work_experience?.rnrs[i] &&
            profile?.work_experience?.rnrs[i].length
              ? parse(profile?.work_experience?.rnrs[i])
              : null}
          </span>
        </td>
      </tr>
    );
  }

  let educationRows = [];
  for (let i = 0; i < profile?.education?.degree?.length; i++) {
    educationRows.push(
      <tr
        key={`education-${i}`}
        style={{ verticalAlign: "top", paddingBottom: "15px" }}
      >
        <td style={{ width: "200px" }}>{`${profile?.education?.startM[
          i
        ].substring(0, 3)} ${
          profile?.education?.startY[i]
        } - ${profile?.education?.endM[i].substring(0, 3)} ${
          profile?.education?.endY[i]
        }`}</td>
        <td>
          <p>
            <b>{`${profile?.education?.degree[i]}, ${profile?.education?.special[i]}, ${profile?.education?.institute[i]}`}</b>
          </p>
        </td>
      </tr>
    );
  }
  let certificationRows = [];
  for (let i = 0; i < profile?.certifications?.name.length; i++) {
    certificationRows.push(
      <tr
        key={`certification-${i}`}
        style={{ verticalAlign: "top", paddingBottom: "15px" }}
      >
        <td style={{ width: "200px" }}>
          {profile?.certifications.endM[i] === "Skip"
            ? ""
            : profile?.certifications.endM[i].substring(0, 3)}{" "}
          {profile?.certifications.endY[i] === "Skip"
            ? ""
            : profile?.certifications.endY[i]}
        </td>
        <td>
          <p>
            <b>{profile?.certifications.name[i]}</b>
          </p>
        </td>
      </tr>
    );
  }
  let Pskills = [],
    Oskills = [];
  for (let i = 0; i < profile?.work_experience?.orgNames?.length; i++) {
    profile?.work_experience?.skillsP && profile?.work_experience?.skillsP.length
      ? (Pskills = [
          ...new Set([...profile?.work_experience?.skillsP[i], ...Pskills]),
        ])
      : (Pskills = [...Pskills]);
    profile?.work_experience?.skillsO && profile?.work_experience?.skillsO?.length
      ? (Oskills = [
          ...new Set([...profile?.work_experience?.skillsO[i], ...Oskills]),
        ])
      : (Oskills = [...Oskills]);
  }

  let skillRows = [];
  skillRows.push(
    <tr key="skills" style={{ verticalAlign: "top", paddingBottom: "15px" }}>
      <td>
        {Pskills && Pskills.length ? (
          <p style={{ marginBottom: "10px" }}>
            <b>Primary Skills:</b> {Pskills.toString().replace(/,/g, ", ")}
          </p>
        ) : null}
        {Oskills && Oskills.length ? (
          <p>
            <b>Other Skills:</b> {Oskills.toString().replace(/,/g, ", ")}
          </p>
        ) : null}
      </td>
    </tr>
  );

  let boardExpRows = [];
  for (let i = 0; i < profile?.board_experience?.boardName?.length; i++) {
    boardExpRows.push(
      <tr
        key={`board_experience-${i}`}
        style={{ verticalAlign: "top", paddingBottom: "15px" }}
      >
        {profile?.board_experience?.stillMember[i]
          ? profile?.board_experience?.boardStartM?.length &&
            profile?.board_experience?.boardStartY?.length &&
            profile?.board_experience?.boardEndM?.length &&
            profile?.board_experience?.boardEndY?.length && (
              <td
                style={{ width: "200px" }}
              >{`${profile?.board_experience?.boardStartM[i]?.substring(0, 3)} ${
                profile?.board_experience?.boardStartY[i]
              } - Present`}</td>
            )
          : profile?.board_experience?.boardStartM?.length &&
            profile?.board_experience?.boardStartY?.length &&
            profile?.board_experience?.boardEndM?.length &&
            profile?.board_experience?.boardEndY?.length && (
              <td
                style={{ width: "200px" }}
              >{`${profile?.board_experience?.boardStartM[i]?.substring(0, 3)} ${
                profile?.board_experience?.boardStartY[i]
              } - ${profile?.board_experience?.boardEndM[i]?.substring(0, 3)} ${
                profile?.board_experience?.boardEndY[i]
              }`}</td>
            )}
        <td>
          <b>
            {profile?.board_experience &&
              profile?.board_experience?.boardType?.length &&
              profile?.board_experience?.boardName?.length &&
              `${profile?.board_experience?.boardName[i]}, ${profile?.board_experience?.boardType[i]}`}
          </b>
        </td>
      </tr>
    );
  }

  return (
    <div id="TempHolder" className="tempHeader1">
      {basicDetails?.first_name && (
        <ResumeTemplateHeading
          name={` ${basicDetails?.first_name} ${basicDetails?.last_name}`}
        />
      )}
      {profile?.work_experience?.designations?.length > 0 && (
        <ResumeTemplateProfessional
          professional={profile?.work_experience?.designations[0]}
        />
      )}

      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ verticalAlign: "top", paddingBottom: "15px" }}>
            <td>
              <p>
                {basicDetails?.dial_code == -1
                  ? ""
                  : (
                      <span>
                        <span className="fa fa-phone"> </span>{" "}
                        {basicDetails?.dial_code}
                      </span>
                    ) || ""}{" "}
                {basicDetails?.phone || ""}
              </p>
            </td>
            <td>
              {profile?.social_links?.linkedin ? (
                <p>
                  <span className="fa fa-linkedin"> </span>
                  {` ${profile?.social_links?.linkedin}`}
                </p>
              ) : null}
            </td>
          </tr>
          <tr style={{ verticalAlign: "top", paddingBottom: "15px" }}>
            <td>
              <p>
                <span className="fa fa-envelope"> </span>
                {` ${basicDetails?.email}`}
              </p>
            </td>
            <td>
              {profile?.social_links?.twitter ? (
                <p>
                  <span className="fa fa-twitter"> </span>
                  {` ${profile?.social_links?.twitter}`}
                </p>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>

      {profile?.personal_details?.aboutMe ? (
        <p style={{ margin: "10px 0px", fontSize: "14px" }}>
          <span style={{ textAlign: "justify" }}>
            {" "}
            {parse(profile?.personal_details?.aboutMe)}
          </span>
        </p>
      ) : (
        <span></span>
      )}

      {workExpRows.length ? (
        <div>
          <p
            style={{
              marginTop: "30px",
              fontWeight: "bold",
              borderBottom: "1px solid #AAAAAA",
            }}
          >
            <span className="fa fa-briefcase"></span> EXPERIENCE
          </p>
          <table style={{ width: "100%" }}>
            <tbody>{workExpRows}</tbody>
          </table>
        </div>
      ) : null}

      {profile?.board_experience &&
      profile?.board_experience.boardType.length &&
      profile?.board_experience.boardName.length ? (
        <div>
          <p
            style={{
              marginTop: "30px",
              fontWeight: "bold",
              borderBottom: "1px solid #AAAAAA",
            }}
          >
            <span className="fa fa-users"></span> BOARD EXPERIENCE
          </p>
          <table style={{ width: "100%" }}>
            <tbody>{boardExpRows}</tbody>
          </table>
        </div>
      ) : null}
      {educationRows.length ? (
        <div>
          <p
            style={{
              marginTop: "30px",
              fontWeight: "bold",
              borderBottom: "1px solid #AAAAAA",
            }}
          >
            <span className="fa fa-graduation-cap"></span> EDUCATION
          </p>
          <table style={{ width: "100%" }}>
            <tbody>{educationRows}</tbody>
          </table>
        </div>
      ) : null}
      {certificationRows.length ? (
        <div>
          <p
            style={{
              marginTop: "30px",
              fontWeight: "bold",
              borderBottom: "1px solid #AAAAAA",
            }}
          >
            <span className="fa fa-certificate"></span> CERTIFICATIONS
          </p>
          <table style={{ width: "100%" }}>
            <tbody>{certificationRows}</tbody>
          </table>
        </div>
      ) : null}
      {}
      {Pskills.length || Oskills.length ? (
        <div>
          <p
            style={{
              marginTop: "30px",
              fontWeight: "bold",
              borderBottom: "1px solid #AAAAAA",
            }}
          >
            <span className="fa fa-puzzle-piece"></span> SKILLS
          </p>
          <table style={{ width: "100%" }}>
            <tbody>{skillRows}</tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
