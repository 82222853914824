import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as mentorshipAction from "../../redux/actions/mentorshipAction";
import { Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { postAdminUpdateIndustry } from "../../redux/actions/adminAction";
import Loader from "../../library/common/Loader";
import { InputField } from "../../library/custom/textBox/InputField";
import Buttons from "../../library/custom/button/Button";

const MentorshipSubjects = () => {
  const dispatch = useDispatch();
  const mentorshipSubjects = useSelector(
    (state) => state?.getMentorshipSubjects
  );
  const postAdminUpdateIndustryData = useSelector(
    (state) => state?.postAdminUpdateIndustry
  );
  const [subjects, setsubjects] = useState([]);
  useEffect(() => {
    dispatch(mentorshipAction?.getMentorshipSubjects());
  }, []);
  useEffect(() => {
    if (
      !postAdminUpdateIndustryData.isLoading &&
      postAdminUpdateIndustryData?.status === "SUCCESS"
    )
      dispatch(mentorshipAction?.getMentorshipSubjects());
  }, [postAdminUpdateIndustryData]);
  useEffect(() => {
    if (
      !mentorshipSubjects.isLoading &&
      mentorshipSubjects.status === "SUCCESS"
    ) {
      let arr = [];
      mentorshipSubjects?.data?.response?.map((i, ind) => {
        arr.push({
          id: ind,
          subject: i,
        });
      });
      setsubjects(arr);
    }
  }, [mentorshipSubjects]);

  const [editingId, setEditingId] = useState(null);
  const [newText, setNewText] = useState("");

  const handleEdit = (id) => {
    setEditingId(id);
    const subToEdit = subjects.find((sub) => sub.id === id);
    setNewText(subToEdit.subject);
  };

  const handleUpdate = () => {
    const updatedSubs = subjects.map((sub) => {
      if (sub.id === editingId) {
        return { ...sub, subject: newText };
      }
      return sub;
    });
    setsubjects(updatedSubs);
    setEditingId(null);
    let arr = [];
    updatedSubs.forEach((i) => arr.push(i.subject));
    dispatch(
      postAdminUpdateIndustry({
        schema: "mentorshipsubjects",
        payload: arr,
      })
    );
    setNewText("");
  };

  const handleDelete = (id) => {
    const updatedSubs = subjects.filter((sub) => sub.id !== id);
    console.log(updatedSubs);
    setsubjects(updatedSubs);
    setEditingId(null);
    let arr = [];
    updatedSubs.forEach((i) => arr.push(i.subject));
    dispatch(
      postAdminUpdateIndustry({
        schema: "mentorshipsubjects",
        payload: arr,
      })
    );
  };
  const addTodo = (id) => {
    let arr = [];
    subjects.forEach((i) => arr.push(i.subject));
    arr.push(newText);
    setNewText("");
    dispatch(
      postAdminUpdateIndustry({
        schema: "mentorshipsubjects",
        payload: arr,
      })
    );
  };

  return (
    <div>
      {postAdminUpdateIndustryData.isLoading && <Loader />}
      <h1>Mentorship Subjects</h1>
      <Grid
        mb={2}
        container
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Grid item lg={6}>
          <InputField
            fullWidth={true}
            type="text"
            label="Subject"
            value={newText}
            onChange={(e) => {
              setNewText(e.target.value);
            }}
          />
        </Grid>
        <Buttons
          varientContained={true}
          label="Add"
          onClick={() => {
            addTodo();
          }}
        />
      </Grid>
      {subjects.map((i) => (
        <>
          {editingId === i.id ? (
            <Grid container justifyContent={"space-between"}>
              <Grid item lg={6}>
                <InputField
                  fullWidth={true}
                  type="text"
                  label=""
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                />
              </Grid>

              <Buttons
                varientContained={true}
                label="Update"
                onClick={() => {
                  handleUpdate();
                }}
              />
            </Grid>
          ) : (
            <Grid container justifyContent={"space-between"}>
              <span>{i.subject}</span>
              <Grid>
                <IconButton
                  color="#2e3a59"
                  aria-label="add to shopping cart"
                  onClick={() => handleDelete(i.id)}
                >
                  <DeleteForeverIcon />
                </IconButton>
                <IconButton
                  color="#2e3a59"
                  aria-label="add to shopping cart"
                  onClick={() => handleEdit(i.id)}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </div>
  );
};

export default MentorshipSubjects;
