import { searchType } from "../constant/searchType";

export const postSearchMap = (data) => ({
  type: searchType.POST_SEARCH_MAP_PENDING,
  data,
});

export const postSearchMapSuccess = (data) => ({
  type: searchType.POST_SEARCH_MAP_SUCCESS,
  data,
});

export const postSearchMapFailure = (data) => ({
  type: searchType.POST_SEARCH_MAP_FAILURE,
  data,
});

export const postSearchMapCorporate = (data) => ({
  type: searchType.POST_SEARCH_MAP_CORPORATE_PENDING,
  data,
});

export const postSearchMapCorporateSuccess = (data) => ({
  type: searchType.POST_SEARCH_MAP_CORPORATE_SUCCESS,
  data,
});

export const postSearchMapCorporateFailure = (data) => ({
  type: searchType.POST_SEARCH_MAP_CORPORATE_FAILURE,
  data,
});

export const postUserSearch = (data) => ({
  type: searchType.POST_USER__SEARCH_PENDING,
  data,
});

export const postUserSearchSuccess = (data) => ({
  type: searchType.POST_USER__SEARCH_SUCCESS,
  data,
});

export const postUserSearchFailure = (data) => ({
  type: searchType.POST_USER__SEARCH_FAILURE,
  data,
});

export const postSearchUser = (data) => ({
  type: searchType.POST_SEARCH_USER_PENDING,
  data,
});

export const postSearchUserSuccess = (data) => ({
  type: searchType.POST_SEARCH_USER_SUCCESS,
  data,
});

export const postSearchUserFailure = (data) => ({
  type: searchType.POST_SEARCH_USER_FAILURE,
  data,
});

export const getPopularSearches = (data) => ({
  type: searchType.GET_POPULAR_SEARCHES_PENDING,
  data,
});

export const getPopularSearchesSuccess = (data) => ({
  type: searchType.GET_POPULAR_SEARCHES_SUCCESS,
  data,
});

export const getPopularSearchesFailure = (error) => ({
  type: searchType.GET_POPULAR_SEARCHES_FAILURE,
  error,
});

export const getPopularSearchesCorporate = (data) => ({
  type: searchType.GET_POPULAR_SEARCHES_CORPORATE_PENDING,
  data,
});

export const getPopularSearchesCorporateSuccess = (data) => ({
  type: searchType.GET_POPULAR_SEARCHES_CORPORATE_SUCCESS,
  data,
});

export const getPopularSearchesCorporateFailure = (error) => ({
  type: searchType.GET_POPULAR_SEARCHES_CORPORATE_FAILURE,
  error,
});

export const getIndustry = (data) => ({
  type: searchType.GET_INDUSTRY_PENDING,
  data,
});

export const getIndustrySuccess = (data) => ({
  type: searchType.GET_INDUSTRY_SUCCESS,
  data,
});

export const getIndustryFailure = (error) => ({
  type: searchType.GET_INDUSTRY_FAILURE,
  error,
});

export const getRecentSearchesCorporate = (data) => ({
  type: searchType.GET_RECENT_SEARCHES_CORPORATE_PENDING,
  data,
});

export const getRecentSearchesCorporateSuccess = (data) => ({
  type: searchType.GET_RECENT_SEARCHES_CORPORATE_SUCCESS,
  data,
});

export const getRecentSearchesCorporateFailure = (error) => ({
  type: searchType.GET_RECENT_SEARCHES_CORPORATE_FAILURE,
  error,
});

export const postSearchCorporateProfile = (data) => ({
  type: searchType.POST_CORPORATE_PROFILE_SEARCH_PENDING,
  data,
});

export const postSearchCorporateProfileSuccess = (data) => ({
  type: searchType.POST_CORPORATE_PROFILE_SEARCH_SUCCESS,
  data,
});

export const postSearchCorporateProfileFailure = (data) => ({
  type: searchType.POST_CORPORATE_PROFILE_SEARCH_FAILURE,
  data,
});
