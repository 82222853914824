import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import NoUser from "../../../assets/noUser.png";
import "./Search.scss";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { Icon } from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
// import "react-leaflet-fullscreen/styles.css";
import "react-leaflet-fullscreen/styles.css";

const SearchMap = ({
  searchText,
  account_type,
  listArr,
  mapLoading,
  handleSearchProfileClick,
}) => {
  const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    // iconUrl: require("./icons/placeholder.png"),
    iconSize: [30, 30], // size of the icon
  });

  return (
    <>
      {!mapLoading ? (
        <Grid className="overrideMap">
          <MapContainer  center={[48.8566, 2.3522]} zoom={1}>
          {/* <FullscreenControl position="topright" /> */}
            {/* OPEN STREEN MAPS TILES */}
            <TileLayer
              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // attribution="Google Maps"
              // url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup chunkedLoading>
              {searchText !== "" &&
                listArr?.map((i) => {
                  if (i?.lat !== "null" || i?.lng !== "null")
                    return (
                      <Marker position={i.geocode} icon={customIcon}>
                        <Popup>
                          <Grid
                            key={i.id}
                            latitude={i?.lat}
                            longitude={i?.lng}
                            closeButton={true}
                            closeOnClick={false}
                            // onClose={() => setCurrentPlaceId(null)}
                            anchor="left"
                            style={{ padding: 0 }}
                            className="popup"
                          >
                            <Grid
                              container
                              justifyContent={"left"}
                              flexWrap={"wrap"}
                              className="cardBody"
                              p={1}
                              onClick={() =>
                                handleSearchProfileClick({
                                  id: i.id,
                                  userType: account_type,
                                })
                              }
                            >
                              <Grid
                                lg={12}
                                item
                                justifyContent={"left"}
                                alignItems={"center"}
                                className="card"
                                container
                              >
                                <Grid item>
                                  <img
                                    src={
                                      i.profilePic === null
                                        ? NoUser
                                        : i.profilePic
                                    }
                                    alt=""
                                    height={"48px"}
                                    width={"48px"}
                                  />
                                </Grid>
                                <Grid item textAlign={"left"} ml={1}>
                                  <p className="name">{i.name}</p>
                                  <p className="adress">
                                    {i.city !== null && `${i.city}, `}
                                    {i.state !== null && `${i.state}, `}{" "}
                                    {i.country !== null &&
                                    i.country === "United States of America"
                                      ? "USA"
                                      : i.country}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Popup>
                      </Marker>
                    );
                })}
            </MarkerClusterGroup>
            
        <FullscreenControl position="topright" />
          </MapContainer>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default SearchMap;
