import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

const CalendarComponent = ({ slots = [], handleSelectEvent = () => {} ,handleDateClick=()=>{}}) => {
  const handleEventClick = (info) => {
    const event = info.event;
    const start = new Date(event.start);
    const end = new Date(event.end);

    const startDate = `${start.getFullYear()}-${(start.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${start.getDate().toString().padStart(2, "0")}`;
    const startTime = `${start.getHours().toString().padStart(2, "0")}:${start
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${start.getSeconds().toString().padStart(2, "0")}`;

    const endDate = `${end.getFullYear()}-${(end.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${end.getDate().toString().padStart(2, "0")}`;
    const endTime = `${end.getHours().toString().padStart(2, "0")}:${end
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${end.getSeconds().toString().padStart(2, "0")}`;

    handleSelectEvent({ startDate, startTime, endDate, endTime });
  };
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        events={slots}
        eventClick={handleEventClick}
        selectable={true}
        editable={true}
        validRange={{
          start: new Date().toISOString().split("T")[0],
        }}
        dateClick={(e)=>handleDateClick(e)}
        // eventClick={(e)=>console.log(E)}
      />
    </div>
  );
};

export default CalendarComponent;
