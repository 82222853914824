import React, { useEffect } from "react";
import ForgotPassword from "../component/ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  getForgotPasswordOTP,
  putResetPassword,
} from "../../../redux/actions/loginAction";
import Loader from "../../../library/common/Loader";
import { useState } from "react";
const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearData());
  }, []);
  const [passwordREsetCalled, setpasswordREsetCalled] = useState(false);
  const forgotPasswordData = useSelector(
    (state) => state?.getForgotPasswordOTP
  );

  const resetPasswordErrorMsg = useSelector(
    (state) => state?.resetPassword?.error?.data?.msg
  );
  const showLoader = useSelector(
    (state) =>
      state?.getForgotPasswordOTP.isLoading || state?.resetPassword.isLoading
  );
  function sendOTP(email) {
    dispatch(getForgotPasswordOTP({ email: email }));
  }
  useEffect(() => {}, []);
  function resetPassword(email, account_type, new_password, otp) {
    dispatch(
      putResetPassword({
        email: email,
        account_type: account_type,
        new_password: new_password,
        otp: otp,
      })
    );
  }
  const resetPasswordData = useSelector((state) => state.resetPassword);

  return (
    <div>
      {showLoader && <Loader />}
      <ForgotPassword
        sendOTP={sendOTP}
        forgotPasswordData={forgotPasswordData}
        getOTPErrorMsg={forgotPasswordData?.error?.message?.data?.msg}
        resetPassword={resetPassword}
        resetPasswordData={resetPasswordData}
        resetPasswordErrorMsg={resetPasswordErrorMsg}
        passwordREsetCalled={passwordREsetCalled}
        setpasswordREsetCalled={setpasswordREsetCalled}
      />
    </div>
  );
};

export default ForgotPasswordContainer;
