import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClickableChips from "../../../library/custom/chip/ClickableChip";
import CommunityCardJoin from "../../../library/custom/communityCard/CommunityCardJoin";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import TrendingCommunityCard from "../../../library/custom/trendingCommunityCard/TrendingCommunityCard";
import Button from "./../../../library/custom/button/Button";
import TextArea from "./../../../library/custom/textArea/TextArea";
import RecentPostCommunity from "./RecentPosts";
import { InputField } from "./../../../library/custom/textBox/InputField";
import "./joincommunity.scss";
import RichTextEditor from "../../../library/custom/richTextEditor/RichTextEditor";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DialogBox from "../../../library/common/DialogBox";

const JoinCommunity = ({
  mycommunitySates,
  topCommunities,
  handleClick,
  handleSearch,
  title,
  desc,
  communityUsercount,
  getPostCommunityWiseData,
  onCommunityPostSubmit,
  communityId,
  addCommunityCommentsposts,
  isMember,
  addReactToposts,
  addReactTopostsComment,
  handleMYClick,
  handleClickDeletePost,
  isdash,
  usrcount,
  icon,
  isloadingGetPost,
  correntcommentOrpostId,
  isloadinglike,
}) => {
  const [isDescription, setIsDescription] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [postcommentvisable, setPostcommentvisable] = useState([]);
  const [isText, setIsText] = useState(false);
  const [showDiv, setShowDiv] = useState(false);

  const [formValue, setFormValue] = useState({
    title: "",
    description: "",
    communityId: communityId,
    tags: [""],
  });
  useEffect(() => {
    if (isdash) {
      setShowDiv(!showDiv);
    }
  }, [isdash]);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [formValueComment, setFormValueComment] = useState({});
  const [commentError, setcommentError] = useState(false);
  const communityName = getPostCommunityWiseData?.data?.communityName;
  const communityDescription =
    getPostCommunityWiseData?.data?.communityDescription;
  const handleDescChange = (event, value) => {
    setFormValue({
      ...formValue,
      description: event,
    });
  };
  const idAssign = open ? "simple-popover" : undefined;

  const handleTextChange = (event, value, id) => {
    const postid = event.target?.id;
    let text = event.target.value;
    let tempvar = formValueComment;
    tempvar[postid] = text;
    setFormValueComment(tempvar);
  };
  const setTitleChange = (event, value) => {
    let title = event.target.value;
    setPosttitle(false);
    setFormValue({
      ...formValue,

      title: title,
    });
    setIsTitle(false);
  };
  const [posttitle, setPosttitle] = useState(false);

  function mappedData() {
    let errors = false;
    if (formValue?.title?.length === 0) {
      setPosttitle(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    const data = {
      title: formValue?.title,
      description: formValue?.description,
      communityId: communityId,
      tags: ["testing"],
    };
    return data;
  }

  const handleOnclickPost = () => {
    const data = mappedData(false);

    if (!data) {
    } else {
      onCommunityPostSubmit(data);
    }
  };
  const handleOnclickComments = (e, id) => {
    const data = {
      text: formValueComment[id],
      postId: id,
    };
    if (data?.text?.trim() === "" || data?.text?.trim() === undefined) {
      setcommentError(true);
    } else {
      addCommunityCommentsposts(data);
      setcommentError(false);
    }
  };
  const handleAddReactTopost = (e, id) => {
    const data = {
      type: "like",
      postId: id,
    };

    addReactToposts(data);
  };
  const handleAddReactCommentTopost = (e, id, postid, commentid) => {
    const data = {
      type: "like",
      postId: postid,
      commentId: commentid,
    };

    addReactTopostsComment(data);
  };
  const getPostCommentVisable = (e, id) => {
    if (isMember === true) {
      let prevdata = postcommentvisable;
      if (postcommentvisable.length == 0) {
        setPostcommentvisable([...postcommentvisable, id]);
      } else if (postcommentvisable?.includes(id)) {
        var filtered = postcommentvisable.filter(function (value, index, arr) {
          return value != id;
        });

        setPostcommentvisable(filtered);
      } else {
        setPostcommentvisable([...postcommentvisable, id]);
      }
    }
  };

  function handleOnclickNewPost() {
    setShowDiv(!showDiv);
  }
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const deleteItem = (e, id) => {
    setOpenModal(true);
    setSelectedRow(id);
  };
  const deletehandle = (e, params) => {
    setOpenModal(false);
    e.preventDefault();
    const data = {
      postId: selectedRow,
    };

    handleClickDeletePost(data);
  };
  const exitHandle = () => {
    setOpenModal(false);
  };
  return (
    <div className="overrideDashboard">
      <Grid mt={2} container lg={12} xs={12} sm={12} md={12}>
        <Grid conainer item lg={8} xs={12} sm={12} md={12}>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              handleCloseModal={(e) => deletehandle(e)}
              content={"Are you sure you want to delete this Post?"}
              label1={"No"}
              label2={"Yes"}
              view
              exitHandle={exitHandle}
            />
          )}

          <Grid mt={2} item lg={11} xs={12} sm={12} md={12}>
            <CommunityCardJoin
              heading={communityName}
              CommunityContent={communityDescription}
              userCount={usrcount}
              handleClick={handleClick}
              lable={isMember == true ? "Leave" : "Join"}
              isMember={isMember}
              id={communityId}
              getPostCommunityWiseData={getPostCommunityWiseData}
              icon={icon}
            />
          </Grid>

          {getPostCommunityWiseData?.data?.isMember || isMember == true ? (
            <Grid mt={3} item lg={3} xs={12} sm={3} md={3} textAlign={"left"}>
              <div className="addBox">
                <Button
                  variantOutlineSections={true}
                  label=" Create New Post"
                  onClick={handleOnclickNewPost}
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
          <Grid mt={3} item lg={10}>
            {showDiv && isMember == true && (
              <>
                <Grid>
                  <p className="posttitle">
                    Post Title<span className="required"> *</span>
                  </p>
                </Grid>
                <Grid container letterSpacing={12} lg={12}>
                  <Grid lg={12} xs={12} sm={12} md={12}>
                    <InputField
                      placeholder="Enter your Title here..."
                      onChange={(event, value) => setTitleChange(event, value)}
                      isError={posttitle}
                      errorMsg={posttitle ? "Title is Required" : ""}
                      className="posttitle1"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {showDiv && isMember == true && (
            <Grid mt={3} item lg={10} md={12} style={{ height: "135px" }}>
              <RichTextEditor
                onChange={(event, value) => handleDescChange(event, value)}
                value={formValue.description}
                toolbarOptions={[
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["image"],
                ]}
              />
            </Grid>
          )}

          {showDiv && isMember == true && (
            <Grid mt={10} item lg={10} className="createPostStyle">
              <Button
                varientPostContained={true}
                label=" Create Post"
                onClick={handleOnclickPost}
              />
            </Grid>
          )}

          <Grid container lg={12} xs={12} sm={12} md={12}>
            <Grid item lg={12} textAlign={"left"}>
              {getPostCommunityWiseData?.data?.posts.length > 0 ? (
                <p className="recentposttitle">Recent Posts</p>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid mt={3} item lg={11} xs={12} sm={12} md={12}>
            {getPostCommunityWiseData?.data?.posts?.map((i) => {
              return (
                <>
                  <RecentPostCommunity
                    name={i?.name}
                    PostContent={i?.description}
                    profilePic={i?.profilePic}
                    createdOn={i?.createdOn}
                    ischild={false}
                    id={i?._id}
                    handlecommentClick={getPostCommentVisable}
                    commnetlength={i.comments.length}
                    handleAddReactTopost={handleAddReactTopost}
                    postEmojis={i?.postEmojis?.likes}
                    isMember={isMember}
                    handleClickDeletePost={handleClickDeletePost}
                    title={i?.title}
                    deletepost={deleteItem}
                    myPost={i?.myPost}
                    isloadingGetPost={isloadingGetPost}
                    correntcommentOrpostId={correntcommentOrpostId}
                    isloadinglike={isloadinglike}
                  />

                  {postcommentvisable?.includes(i?._id) &&
                    i?.comments.map((j) => {
                      return (
                        <RecentPostCommunity
                          name={j?.name}
                          PostContent={j?.text}
                          profilePic={j?.profilePic}
                          createdOn={j?.createdOn}
                          ischild={true}
                          className="recentpostcomment"
                          postEmojisComment={j?.commentEmojis?.likes}
                          handleAddReactTopost={handleAddReactCommentTopost}
                          commentid={j?._id}
                          postid={i?._id}
                          isloadingGetPost={isloadingGetPost}
                          correntcommentOrpostId={correntcommentOrpostId}
                          isloadinglike={isloadinglike}
                        />
                      );
                    })}

                  {isMember == true && (
                    <Grid mt={3} item lg={12}>
                      {postcommentvisable?.includes(i?._id) && (
                        <TextArea
                          className="textareastyle"
                          name={"issuefound"}
                          placeholder="Enter here Comments"
                          value={formValueComment.text}
                          onChange={(event, value) =>
                            handleTextChange(event, value)
                          }
                          id={i?._id}
                          isError={commentError}
                          errorMsg={commentError ? "Comment is Required" : ""}
                        />
                      )}
                    </Grid>
                  )}
                  {isMember == true && (
                    <Grid mt={3} item lg={12} className="addcomments">
                      {postcommentvisable?.includes(i?._id) && (
                        <Button
                          varientCommentContained={true}
                          label="Add Comment"
                          onClick={(e) => handleOnclickComments(e, i?._id)}
                        />
                      )}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          className="mycommunityGrid"
          p={{ xs: 0, lg: 1 }}
          container
          item
          lg={4}
        >
          <Grid container item lg={12} xs={12} sm={12} md={12}>
            <Grid textAlign={"left"} item lg={12} xs={12} sm={12} md={12}>
              <h1>My Communities</h1>
              <Grid mt={1} xs={12} sm={12} md={12}>
                {mycommunitySates?.data?.map((i) => {
                  return (
                    <Grid mt={2} item lg={12} xs={12} sm={12} md={12}>
                      <TrendingCommunityCard
                        label1={i.title}
                        label2={i.userCount}
                        id={i._id}
                        handleClick={() => handleMYClick(i)}
                        icon={i?.icon}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              textAlign={"left"}
              item
              mt={3}
              lg={12}
              xs={12}
              sm={12}
              md={12}
            >
              <h1>Trending Communities</h1>
              <Grid mt={1} xs={12} sm={12} md={12}>
                {topCommunities?.data?.map((i) => {
                  return (
                    <Grid mt={2} item lg={12} xs={12} sm={12} md={12}>
                      <TrendingCommunityCard
                        label1={i.title}
                        label2={i.userCount}
                        id={i._id}
                        handleClick={() => handleMYClick(i)}
                        icon={i?.icon}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default JoinCommunity;
