import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Community from "../component/community";
import {
  getCommunity,
  postJoinCommunity,
  postSearchCommunity,
  getMyCommunity,
  getTopCommunity,
  clearLeaveCommunityStatus,
  clearJoinCommunityStatus,
} from "../../../redux/actions/communityAction";
import Loader from "../../../library/common/Loader";

import { useNavigate } from "react-router-dom";

const CommunityContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommunity());
    dispatch(getMyCommunity());
    dispatch(getTopCommunity());
  }, []);
  const [communitySate, setCommunitySate] = useState([]);
  const [mycommunitySates, setMyCommunitySates] = useState([]);

  const getCommunityData = useSelector((state) => state?.getCommunity?.data);
  const myCommunities = useSelector((state) => state?.myCommunity?.data);
  const topCommunities = useSelector((state) => state?.topCommunity?.data);
  const showLoader = useSelector(
    (state) =>
      state?.getCommunity?.isLoading || state?.postJoinCommunity?.isLoading
  );
  const joinCommunity = useSelector((state) => state?.postJoinCommunity);
  const searchCommunityData = useSelector(
    (state) => state?.postSearchCommunity
  );
  useEffect(() => {
    dispatch(clearLeaveCommunityStatus());
    dispatch(clearJoinCommunityStatus());
  }, []);
  const [usrcount, setUsrcount] = useState("");
  const [usrcounts, setUsrcounts] = useState("");

  function handleClick(id, userCount, icon) {
    setUsrcounts(userCount);
    const data = { communityId: id };
    dispatch(postJoinCommunity(data));
  }
  useEffect(() => {
    if (joinCommunity?.status === "SUCCESS") {
      dispatch(getCommunity());
      dispatch(getMyCommunity());
      dispatch(getTopCommunity());
      setUsrcount(usrcounts);
    }
  }, [joinCommunity]);

  function handleSearch(input) {
    const data = {
      communityName: input,
    };
    if (input) {
      dispatch(postSearchCommunity(data));
    } else {
      dispatch(getCommunity());
    }
  }
  useEffect(() => {
    setMyCommunitySates(myCommunities);
  }, [myCommunities]);
  window.history.replaceState({}, document.title);
  useEffect(() => {
    setCommunitySate(searchCommunityData.data);
  }, [searchCommunityData]);
  useEffect(() => {
    setCommunitySate(getCommunityData);
  }, [getCommunityData]);
  const navigate = useNavigate();

  const MYCOMMUNITYURL = "/view/community";
  function handleMYClick(data) {
    navigate(MYCOMMUNITYURL, {
      state: {
        data: data,
      },
    });
    setUsrcount(data?.userCount);
  }
  return (
    <div>
      {showLoader ? (
        <Loader />
      ) : (
        <Community
          myCommunities={communitySate}
          mycommunitySates={mycommunitySates}
          topCommunities={topCommunities}
          handleClick={handleClick}
          handleSearch={handleSearch}
          handleMYClick={handleMYClick}
          usrcount={usrcount}
        />
      )}
    </div>
  );
};

export default CommunityContainer;
