import * as UploadFilesAction from "../actions/UploadFilesAction";
import { uploadFilesType } from "../constant/UploadFilesType";
import { createUploadFiles } from "../../services/UploadFilesService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createUploadFilesSaga(params) {
  try {
    const { data, status, error } = yield call(createUploadFiles, params);
    if (!data && status !== 200) throw apiError(error);
    const uploadFilePage = uploadFilesType.UPLOAD_FILE_PAGE;
    yield put(
      UploadFilesAction.createFileUploadSuccess({
        uploadFilePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "File upload fail Failed" };
    yield put(UploadFilesAction.createFileUploadFailure(data));
  }
}

const myFileUploadSaga = [
  takeLatest(uploadFilesType.POST_UPLOAD_FILE_PENDING, createUploadFilesSaga),
];

export default myFileUploadSaga;
