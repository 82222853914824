import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Error from "../../assets/error.svg";
import Stack from "@mui/material/Stack";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../library/custom/textBox/InputField";
import "./DialogBox.scss";
import TextArea from "../../library/custom/textArea/TextArea";
import FormLabels from "../../library/custom/formLabel/FormLabels";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function StatusPopover(props) {
  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        // onClose={props.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="dialogBoxContainer"
      >
        <DialogContent className="Dioerrimg">
          <p>
            <strong>Account Deactivation Alert</strong>
          </p>
          <p>
            You are trying to deactivate this account. The means the user's
            subscription will be lost and the user will be logged out automatically.
            If you are sure please write <strong>confirm</strong> in the below
            input and press the confirm button.
          </p>
          <Grid container>
            <Grid item lg={8}>
              <InputField
                fullWidth={true}
                type="text"
                label=""
                placeholder=""
                name={"confirm"}
                onChange={(e) => {
                  props.handleOnChange(e);
                }}
                value={props.formvalue.confirm}
                isError={props.isConfirm}
                errorMsg={props.isConfirm ? "Please enter correct text" : ""}
              />
            </Grid>
            <Grid mt={2} className="selectLabel" lg={11}>
              <FormControl fullWidth textAlign="left">
                <FormLabels label="Reason:" />{" "}
                <TextArea
                  fullWidth={true}
                  value={props.formvalue.reason}
                  placeholder={"Enter here"}
                  onChange={(e) => {
                    props.handleOnChange(e);
                  }}
                  name={"reason"}
                  isError={props.isreason}
                  errorMsg={
                    props.isreason ? "Please enter this required field" : ""
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={props.exitHandle}
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              color: "#FF333F",
              textTransform: "capitalize",
            }}
          >
            {props.label1 ? props?.label1 : "No"}
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "#2E3A59",
                textTransform: "capitalize",
              }}
              autoFocus
              onClick={(e) => props.handleConfirm(e)}
            >
              {props.label2 ? props?.label2 : "Yes"}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
