import React, { useEffect, useState } from "react";
import MentorshipCalendar from "../component/MentorshipCalendar";
import * as editProfileAction from "../../../redux/actions/editProfileAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { getUserDetails } from "../../../redux/actions/loginAction";
export const MentorshipCalendarContainer = () => {
  const dispatch = useDispatch();
  const userDetailsData = useSelector((state) => state?.editProfile);
  const editProfile = (data) => {
    dispatch(editProfileAction.putEditProfile(data));
  };
  function getProfileDetails(params) {
    dispatch(getUserDetails("dcads"));
  }

  const showLoader = useSelector((state) => state?.userDetails?.isLoading);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!userDetailsData.isLoading && userDetailsData.status === "SUCCESS") {
      getProfileDetails();
      setOpenModal(false);
    }
  }, [userDetailsData]);

  return (
    <div>
      {userDetailsData.isLoading && <Loader />}
      {showLoader ? (
        <Loader />
      ) : (
        <MentorshipCalendar
          editProfile={editProfile}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
};
