import * as UploadVideoAction from "../actions/UploadVideoAction";
import { uploadVideoType } from "../constant/UploadVideoType";
import {
  createUploadVideo,
  createUploadContent,
} from "../../services/UploadVideoService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
export function* createUploadVideoSaga(params) {
  try {
    const { data, status, error } = yield call(createUploadVideo, params);
    if (!data && status !== 200) throw apiError(error);
    const uploadVideoPage = uploadVideoType.UPLOAD_VIDEO_PAGE;
    yield put(
      UploadVideoAction.createVideoUploadSuccess({
        uploadVideoPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Video upload fail Failed" };
    yield put(UploadVideoAction.createVideoUploadFailure(data));
  }
}
export function* createUploadContentSaga(params) {
  try {
    const { data, status, error } = yield call(createUploadContent, params);
    if (!data && status !== 200) throw apiError(error);
    const uploadVideoPage = uploadVideoType.UPLOAD_CONTENT_PAGE;
    yield put(
      UploadVideoAction.createContentUploadSuccess({
        uploadVideoPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Content upload fail Failed" };
    yield put(UploadVideoAction.createContentUploadFailure(data));
  }
}

const myVideoUploadSaga = [
  takeLatest(uploadVideoType.POST_UPLOAD_VIDEO_PENDING, createUploadVideoSaga),
  takeLatest(
    uploadVideoType.POST_UPLOAD_CONTENT_PENDING,
    createUploadContentSaga
  ),
];

export default myVideoUploadSaga;
