import { Grid } from "@mui/material";
import React from "react";
import "./ProfessionalInfoCard.scss";

const ProfessionalInfoCard = ({ icon, heading, content }) => {
  return (
    // <div className='overrideInfoCard'>
    <Grid className="valueContainer" item lg={5} sm={5} md={5} xs={12} p={6}>
      <Grid className="IconHeading">
        <Grid className="ValueIcon">
          <img src={icon} className="graphicon" alt="Graph Icon" />
        </Grid>
        <Grid mt={2} className="valueHeading">
          {heading}
        </Grid>
      </Grid>

      <Grid mt={2} className="valueContent">
        {content}
      </Grid>
    </Grid>
    // </div>
  );
};

export default ProfessionalInfoCard;
