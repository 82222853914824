import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React from "react";
// import DownloadCsv from "../../../feature/download_file/DownloadCsv";
// import commonImages from "../../../assets";
import editIcon from "../../../assets/edit-icon.svg"

export default function TableRowAction(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    publish,
    publishText,
    publishOnClick,
    edit,
    download,
    view,
    deleted,
    deleteOnClick,
    viewLabel = "View Incident",
    deleteLabel = "Delete Incident",
    editLabel = "Edit Incident",
    downloadLabel = "Download Incident",
    viewOnAttendeeClick,
    VerifyAttendees,
    viewAttendeeLabel,
    CloseIncident = false,
    CloseIncidentLabel,
    closeOnClick,
    AssignAttendees,
    AssignAttendeeOnClick,
    ScheduleAttendeeLabel,
    scheduleMeetingOnclick,
    assignLabel,
    ScheduleMeetings,
    selfAssignClick,
    SelfAssign,
    selfAssignLabel,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const statusClosed = row?.status === "Closed" ? true : false;
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui" style={{padding:"10px"}}>
            
              {view && (
                <p onClick={() => viewOnClick(row)}>
                  <RemoveRedEye /> <span>{viewLabel}</span>
                </p>
              )}

              {edit && (
                <p
                  className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => editOnClick(row)}
                  
                style={{
                  fontFamily:"Montserrat",
                  colot: "#333333",
                  fontWeight:"500",
                  margin:0
                }}
                >
                  {/* <img
                    src={editIcon}
                    className="deleteImg"
                    alt=""
                 />  */}
                 <span>{editLabel}</span>
                </p>
              )}
              {deleted && (
                <p
                style={{
                  fontFamily:"Montserrat",
                  colot: "#333333",
                  fontWeight:"500",
                  margin:0
                }
              }
                  className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => deleteOnClick(row)}
                >
                  {/* <img
                    src={commonImages.deleteblueIcon}
                    className="deleteImg"
                    alt=""
                  /> */}
                  <span> {deleteLabel}</span>
                </p>
              )}
          
          </div>
        </div>
      </Menu>
      
    </>
  );
}
