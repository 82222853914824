export const loginType = {
  POST_LOGIN_PENDING: "[LOGIN][REQUEST] LOGIN",
  POST_LOGIN_FAILURE: "[LOGIN][RESPONSE] LOGIN Failed",
  POST_LOGIN_SUCCESS: "[LOGIN][RESPONSE] LOGIN Successfull",

  POST_ADMIN_LOGIN_PENDING: "_ADMIN[LOGIN][REQUEST]_ADMIN LOGIN",
  POST_ADMIN_LOGIN_FAILURE: "_ADMIN[LOGIN][RESPONSE]_ADMIN LOGIN Failed",
  POST_ADMIN_LOGIN_SUCCESS: "_ADMIN[LOGIN][RESPONSE]_ADMIN LOGIN Successfull",

  POST_LOGIN_CLEAR: "[LOGIN][RESPONSE] LOGIN Clear",
  GET_USER_PROFILE_PENDING: "[PROFILE][REQUEST] PROFILE",
  GET_USER_PROFILE_FAILURE: "[PROFILE][RESPONSE] PROFILE Failed",
  GET_USER_PROFILE_SUCCESS: "[PROFILE][RESPONSE] PROFILE Successful",

  GET_RECENT_SEARCHES_PENDING: "[PROFILE][REQUEST] _RECENT_SEARCHES",
  GET_RECENT_SEARCHES_FAILURE: "[PROFILE][RESPONSE] _RECENT_SEARCHES Failed",
  GET_RECENT_SEARCHES_SUCCESS:
    "[PROFILE][RESPONSE] _RECENT_SEARCHES Successful",

  GET_FORGOT_PASSWORD_OTP_PENDING: "[PROFILE][REQUEST] FORGOT_PASSWORD_OTP",
  GET_FORGOT_PASSWORD_OTP_FAILURE:
    "[PROFILE][RESPONSE] FORGOT_PASSWORD_OTP Failed",
  GET_FORGOT_PASSWORD_OTP_SUCCESS:
    "[PROFILE][RESPONSE] FORGOT_PASSWORD_OTP Successful",

  PUT_RESET_PASSWORD_PENDING: "[PROFILE][REQUEST] PUT_RESET_PASSWORD",
  PUT_RESET_PASSWORD_FAILURE: "[PROFILE][RESPONSE] PUT_RESET_PASSWORD Failed",
  PUT_RESET_PASSWORD_SUCCESS:
    "[PROFILE][RESPONSE] PUT_RESET_PASSWORD Successful",
    CLEAR_DATA:"Clear login Data"
};
