import { Grid, Skeleton } from "@mui/material";
import React from "react";
import "./JobCard.scss";
import Buttons from "../../../library/custom/button/Button";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import parse from "html-react-parser";

const JobCard = ({
  showDescription = false,
  item,
  onJobClick,
  getJobInfoData,
  isLoading,
  applyJob,
  appliedJobIds,
}) => {
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  useEffect(() => {
    appliedJobIds.map((i) => {
      if (i === item.jobId) setAlreadyApplied(true);
    });
  }, [appliedJobIds]);
  return (
    <div className="overrideJobCard">
      <Grid container className="card" p={2}>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            onClick={(i) => {
              onJobClick(i, item);
            }}
          >
            <p className="font700 font18 blackText">{item?.title}</p>
          </Grid>
          <Grid
            container
            onClick={(i) => {
              onJobClick(i, item);
            }}
          >
            <p className="shadow-grey font700 font16">{item?.company}</p>
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Grid
            container
            item
            lg={6}
            onClick={(i) => {
              onJobClick(i, item);
            }}
          >
            <Grid container>
            </Grid>
            <Grid container gap={1}>
              <p className="shadow-grey font400 font12">Posted on : </p>
              <p className="blackText font700 font12">
                {` ${moment(item?.createdAt).format("MMM Do YYYY")}`}
              </p>
            </Grid>
          </Grid>

          <Grid container mt={{xs:1}} justifyContent={"right"} item lg={3.5}>
            <Buttons
              onClick={(e) => {
                applyJob(e, item?.jobId);
              }}
              disabled={alreadyApplied}
              variantJobApply={true}
              label={alreadyApplied ? "Applied" : "Apply Now"}
            />
          </Grid>
        </Grid>
        {showDescription && <Grid className="partitian" mt={2}></Grid>}
        {showDescription && (
          <Grid mt={2} className="jobRequirements" container>
            <Grid container>
              <Grid container item gap={1} lg={6}>
                <p className="shadow-grey font400 font12">
                  Compensation Range:
                </p>
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "12px" }}
                    width={150}
                  />
                ) : (
                  <p className="blackText font700 font12">{`${
                    getJobInfoData?.compcurrency ?? ""
                  } ${getJobInfoData?.mincomp ?? ""} - ${
                    getJobInfoData?.maxcomp ?? ""
                  }`}</p>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid container gap={1} item lg={6}>
                <p className="shadow-grey font400 font12">
                  Minimum Qualifications:
                </p>
                {isLoading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{ fontSize: "12px" }}
                    width={150}
                  />
                ) : (
                  <p className="blackText font700 font12">
                    {getJobInfoData?.qualification ?? ""}
                  </p>
                )}
              </Grid>
              <Grid container item gap={1} lg={6}>
                <p className="shadow-grey font400 font12">Job Type:</p>
                {isLoading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{ fontSize: "12px" }}
                    width={100}
                  />
                ) : (
                  <p className="blackText font700 font12">
                    {getJobInfoData?.jobtype ?? ""}
                  </p>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid container gap={1} item lg={8}>
                <p className="shadow-grey font400 font12">Primary Skills</p>
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "12px" }}
                    width={100}
                  />
                ) : (
                  <p className="blackText font700 font12">
                    {getJobInfoData?.skillsp ?? ""}
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {showDescription && (
          <Grid mt={2} className="jobDesc" container>
            <Grid container>
              <p className="blackText font700 font14">Job Description</p>
            </Grid>
            <Grid container mt={2}>
              {isLoading ? (
                <Skeleton
                  variant="rounded"
                  sx={{ fontSize: "12px" }}
                  width={"100%"}
                  height={600}
                />
              ) : (
                <span style={{textAlign:"left"}} className="">
                  {getJobInfoData?.description
                    ? parse(getJobInfoData?.description)
                    : ""}
                </span>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default JobCard;
