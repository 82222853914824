import { communityType } from "../constant/communityType";

const initialState = {
  flow: "Community",
  isError: false,
  isLoading: false,
};

export const getTopCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.GET_TOP_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.GET_TOP_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.GET_TOP_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getMyCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.GET_MY_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.GET_MY_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.GET_MY_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getPostsCommunityWiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.GET_POSTS_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.GET_POSTS_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.GET_POSTS_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.GET_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.GET_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.GET_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCommunityAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.GET_COMMUNITY_ADMIN_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.GET_COMMUNITY_ADMIN_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.GET_COMMUNITY_ADMIN_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postJoinCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_JOIN_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_JOIN_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_JOIN_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case communityType.POST_JOIN_CLEAR_DATA:
      return {
        ...state,
        status: "clearJoinstatus",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const postSearchCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_SEARCH_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_SEARCH_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_SEARCH_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const addPostCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_COMMUNITY_ADD_POST_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_COMMUNITY_ADD_POST_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_COMMUNITY_ADD_POST_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const addPostCommunityCommentsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case communityType.POST_COMMUNITY_ADD_POST_COMMENTS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_COMMUNITY_ADD_POST_COMMENTS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_COMMUNITY_ADD_POST_COMMENTS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postLeaveCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_LEAVE_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_LEAVE_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_LEAVE_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case communityType.POST_CLEAR_DATA:
      return {
        ...state,
        status: "clearleavestatus",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const postAddReactToPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_ADD_REACT_TO_POST_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_ADD_REACT_TO_POST_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_ADD_REACT_TO_POST_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postAddReactToPostCommentReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postDeletePostCommentReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_DELETE_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_DELETE_COMMENT_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_DELETE_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postToggleCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_TOGGLE_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_TOGGLE_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_TOGGLE_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postCreateCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_CREATE_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_CREATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_CREATE_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postEditCommunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_EDIT_COMMUNITY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_EDIT_COMMUNITY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_EDIT_COMMUNITY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const resumeBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case communityType.POST_RESUME_BULDING_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case communityType.POST_RESUME_BULDING_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case communityType.POST_RESUME_BULDING_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
