export const conversationType = {
  GET_CONVERSATION_PENDING: "[CONVERSATION][REQUEST] GET_CONVERSATION_PENDING",
  GET_CONVERSATION_SUCCESS:
    "[CONVERSATION][RESPONSE] GET_CONVERSATION_SUCCESSFUL",
  GET_CONVERSATION_FAILURE: "[CONVERSATION][RESPONSE] GET_CONVERSATION_FAILURE",

  GET_MESSAGE_PENDING: "[MESSAGE][REQUEST] GET_MESSAGE_PENDING",
  GET_MESSAGE_SUCCESS: "[MESSAGE][RESPONSE]   GET_MESSAGE_SUCCESS",
  GET_MESSAGE_FAILURE: "[MESSAGE][RESPONSE] GET_MESSAGE_FAILURE",

  GET_UNREAD_MESSAGE_PENDING: "[MESSAGE][REQUEST] GET_UNREAD_MESSAGE_PENDING",
  GET_UNREAD_MESSAGE_SUCCESS: "[MESSAGE][RESPONSE] GET_UNREAD_MESSAGE_SUCCESS",
  GET_UNREAD_MESSAGE_FAILURE: "[MESSAGE][RESPONSE]  GET_UNREAD_MESSAGE_FAILURE",

  POST_CREATE_CONVERSATION_PENDING:
    "[MESSAGE][REQUEST] POST_CREATE_CONVERSATION_PENDING",
  POST_CREATE_CONVERSATION_SUCCESS:
    "[MESSAGE][RESPONSE] POST_CREATE_CONVERSATION_SUCCESS",
  POST_CREATE_CONVERSATION_FAILURE:
    "[MESSAGE][RESPONSE] POST_CREATE_CONVERSATION_FAILURE",

  POST_ADD_MESSAGE_PENDING: "[MESSAGE][REQUEST] POST_ADD_MESSAGE_PENDING",
  POST_ADD_MESSAGE_SUCCESS: "[MESSAGE][RESPONSE] POST_ADD_MESSAGE_SUCCESS",
  POST_ADD_MESSAGE_FAILURE: "[MESSAGE][RESPONSE] POST_ADD_MESSAGE_FAILURE",

  GET_USER_SEARCH_PENDING: "[MESSAGE][REQUEST] GET_USER_SEARCH_PENDING",
  GET_USER_SEARCH_SUCCESS: "[MESSAGE][RESPONSE] GET_USER_SEARCH_SUCCESS",
  GET_USER_SEARCH_FAILURE: "[MESSAGE][RESPONSE] GET_USER_SEARCH_FAILURE",
};
