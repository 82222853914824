import logo from "./logo.svg";
import dashboardSideIcon from "./dashboardSideIcon.svg";
import communitySideIcon from "./communitySideIcon.svg";
import profileSideIcon from "./profileSideIcon.svg";
import searchSideIcon from "./searchSideIcon.svg";
import settingSideIcon from "./settingSideIcon.svg";
import messagesSideIcon from "./messagesSideIcon.svg";
import profileBg from "./profileBg.svg";
// import businessmanLogin from "./businessmanLogin.svg";
import noConversation from "./noConversation.svg";
import noUser from "./noUser.png";
// import SignupImage from "./SignupImage.svg";
import AttachFile from "./attachFile.svg";
import Emoji from "./emoji.svg";
import sendVoice from "./sendVoice.svg";
import sendMessage from "./sendMessage.svg";
import videoDropBox from "./videoDropBox.svg";
import verified from "./verified.svg";
import notVerified from "./notverified.svg";
import jobIcon from "./jobIcon.svg";
import mentorshipIcon from "./mentorshipSideIcon.svg";

import deleteIcon from "./deleteIcon.svg";
export const logos = {
  logo,
};
// export const LOGIN_PAGE_PIC = businessmanLogin;
// export const SIGNUP_PAGE_PIC = SignupImage;

export const sideBarIcons = {
  dashboardSideIcon,
  communitySideIcon,
  profileSideIcon,
  searchSideIcon,
  settingSideIcon,
  messagesSideIcon,
  jobIcon,
  mentorshipIcon,
};
export const CHAT_LOGO = profileBg;
export const NO_CONVERSATION = noConversation;
export const NoUser = noUser;
export const VIDEO_DROPBOX = videoDropBox;
export const VERIFIED = verified;
export const NOTVERIFIED = notVerified;
export const DELETEICON = deleteIcon;
export const messageIcons = {
  AttachFile,
  Emoji,
  sendVoice,
  sendMessage,
};
