import * as React from "react";
import "./GridTable.scss";

import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";

function GridTable({
  rows,
  columns,
  pageSize,
  rowsPerPageOptions,
  onPageChange,
  onClick,
  onRowClick,
  checkboxSelection,
  loading,
  components,
  disableSelectionOnClick,
  onSelectionModelChange,
  rowCount,
  getRowId,
  isRowSelectable,
}) {
  return (
    
    <Box sx={{ height: 400, width: '90%' }}>
    <DataGrid
      className="overrideDataGrid"
      getRowId={getRowId}
      rows={rows}
      columns={columns}
      pageSize={1000000}
      rowsPerPageOptions={10000000}
      // isRowSelectable={isRowSelectable}
      onPageChange={onPageChange}
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      // onCellClick={onClick}
      // onRowClick={onRowClick}
      // checkboxSelection={checkboxSelection}
      hideFooter={true}
      paginationMode="server"
      // rowCount={rowCount}
      CanUserAddRows="False"
      IsReadOnly="True"
      onSelectionModelChange={onSelectionModelChange}
      disableSelectionOnClick={disableSelectionOnClick}
      // components={components}
      loading={loading}
      rowLength={1000000}
      columnBuffer={10}
    />
    </Box>
  );
}

GridTable.propTypes = {};

export default GridTable;
