export const searchType = {
  POST_SEARCH_MAP_PENDING: "[Add ASSET][REQUEST] POST_SEARCH_MAP",
  POST_SEARCH_MAP_FAILURE: "[Add ASSET][RESPONSE] POST_SEARCH_MAP Failed",
  POST_SEARCH_MAP_SUCCESS: "[Add ASSET][RESPONSE] POST_SEARCH_MAP Successfull",

  POST_SEARCH_MAP_CORPORATE_PENDING:
    "[Add ASSET][REQUEST] POST_SEARCH_MAP_CORPORATE",
  POST_SEARCH_MAP_CORPORATE_FAILURE:
    "[Add ASSET][RESPONSE] POST_SEARCH_MAP_CORPORATE Failed",
  POST_SEARCH_MAP_CORPORATE_SUCCESS:
    "[Add ASSET][RESPONSE] POST_SEARCH_MAP_CORPORATE Successfull",

  POST_SEARCH_USER_PENDING: "[Add ASSET][REQUEST] POST_SEARCH_USER",
  POST_SEARCH_USER_FAILURE: "[Add ASSET][RESPONSE] POST_SEARCH_USER Failed",
  POST_SEARCH_USER_SUCCESS:
    "[Add ASSET][RESPONSE] POST_SEARCH_USER Successfull",

  GET_POPULAR_SEARCHES_PENDING: "[PROFILE][REQUEST] POPULAR_SEARCHES",
  GET_POPULAR_SEARCHES_FAILURE: "[PROFILE][RESPONSE] POPULAR_SEARCHES Failed",
  GET_POPULAR_SEARCHES_SUCCESS:
    "[PROFILE][RESPONSE] POPULAR_SEARCHES Successful",

  GET_POPULAR_SEARCHES_CORPORATE_PENDING:
    "[PROFILE][REQUEST] POPULAR_SEARCHES_CORPORATE",
  GET_POPULAR_SEARCHES_CORPORATE_FAILURE:
    "[PROFILE][RESPONSE] POPULAR_SEARCHES_CORPORATE Failed",
  GET_POPULAR_SEARCHES_CORPORATE_SUCCESS:
    "[PROFILE][RESPONSE] POPULAR_SEARCHES_CORPORATE Successful",

  POST_USER__SEARCH_PENDING: "[users][REQUEST] POST_SEARCH_USERs",
  POST_USER__SEARCH_FAILURE: "[users][RESPONSE] POST_SEARCH_USERs Failed",
  POST_USER__SEARCH_SUCCESS: "[users][RESPONSE] POST_SEARCH_USERs Successfull",

  GET_INDUSTRY_PENDING: "[PROFILE][REQUEST] INDUSTRY",
  GET_INDUSTRY_FAILURE: "[PROFILE][RESPONSE] INDUSTRY Failed",
  GET_INDUSTRY_SUCCESS: "[PROFILE][RESPONSE] INDUSTRY Successful",

  GET_RECENT_SEARCHES_CORPORATE_PENDING:
    "[PROFILE][REQUEST] _RECENT_SEARCHES_CORPORATE",
  GET_RECENT_SEARCHES_CORPORATE_FAILURE:
    "[PROFILE][RESPONSE] _RECENT_SEARCHES_CORPORATE Failed",
  GET_RECENT_SEARCHES_CORPORATE_SUCCESS:
    "[PROFILE][RESPONSE] _RECENT_SEARCHES_CORPORATE Successful",

  POST_CORPORATE_PROFILE_SEARCH_PENDING:
    "[users][REQUEST] POST_SEARCH_CORPORATE_PROFILE",
  POST_CORPORATE_PROFILE_SEARCH_FAILURE:
    "[users][RESPONSE] POST_SEARCH_CORPORATE_PROFILE Failed",
  POST_CORPORATE_PROFILE_SEARCH_SUCCESS:
    "[users][RESPONSE] POST_SEARCH_CORPORATE_PROFILE Successfull",
};
