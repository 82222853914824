import { Grid, RadioGroup } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Chat from "./Chat";
import MessageUserCard from "./MessageUserCard";
import "./MessageDashboard.scss";
import { NO_CONVERSATION } from "../../../assets/index.js";
import { createConversation } from "../../../services/conversationService";
import { ACCOUNT_TYPE, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { ConvertDate } from "../../../utils/helper";
import SearchAutoNew from "../../../library/custom/searchAutoComplete/searchAutoNew/SearchAutoNew";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../library/custom/radioButton/RadioButton";

const MessagesDashboard = ({
  allConversation,
  getAllMessages,
  allMessage,
  handleUserSearch,
  isLoading,
  userSearch,
  startMessaging,
  sendMessage,
  userData,
  chatId,
  uploadProfileFile,
  headShot,
  uploadedImage,
  unReadMessage,
  handleClearUserSearch,
}) => {
  const [sendersName, setSendersName] = useState();
  const [profilePic, setProfilePic] = useState();
  const [conversationId, setConversationId] = useState();
  const [messageList, setMessageList] = useState(allConversation);
  const [userID, setUserID] = useState(allConversation);
  const [conData, setConData] = useState();
  const [accountType, setAccountType] = useState("all");
  const [conversationUser, setConversationUser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const myDivRef = useRef(null);
  const bottomRef = useRef(null);
  const autoCompUser = useRef(null);

  const handleClick = (conversationId, username, profile_pic, userId) => {
    setSendersName(username);
    setProfilePic(profile_pic);
    setConversationId(conversationId);
    setUserID(userId);
    const findUser = messageList.find((i) => i._id === conversationId);
    setConversationUser(findUser);

    const messageData = {
      conversationId: conversationId,
      page: 1,
      limit: PAGE_SIZE,
    };

    getAllMessages(messageData);
  };
  useEffect(() => {
    setMessageList(allConversation);
  }, [allConversation]);

  const handleSearch = (searchText) => {
    if (!searchText) return;
    {
      ACCOUNT_TYPE() === "corporate"
        ? handleUserSearch("individual", searchText)
        : handleUserSearch(accountType, searchText);
    }
  };
  const [newUser, setNewUser] = useState(null);
  const handleAccountTypeSearch = (e) => {
    setAccountType(e?.target?.value);
    const ele = autoCompUser.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const handleUserSelect = async (event, user) => {
    const messageReceiver = allConversation.find(
      (x) => x.receiver.userId === user.id
    );
    if (messageReceiver) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      const conversationIndex = messageList.findIndex(
        (conversation) => conversation.receiver.userId === user.id
      );
      if (conversationIndex !== -1) {
        const [conversation] = messageList.splice(conversationIndex, 1);
        setMessageList([conversation, ...messageList]);
        setSendersName(conversation?.receiver?.username);
        setProfilePic(conversation?.receiver?.profile_pic);
        setConversationId(conversation?.message?.conversationId);
        setUserID(user.id);

        const messageData = {
          conversationId: conversation?.message?.conversationId,
          page: 1,
          limit: PAGE_SIZE,
        };
        getAllMessages(messageData);
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      myDivRef.current.scrollTop = 0;
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      if (myDivRef.current) {
        myDivRef.current.scrollTop = 0;
      }
    }
    if (!messageReceiver) {
      const data = {
        senderId: userData.id,
        senderName: `${userData?.first_name} ${userData?.last_name}`,
        senderImage: userData?.profile_pic,
        receiverId: user.id,
        receiverName: user.name,
        receiverImage: user.profile_pic,
      };

      const {
        data: {
          conversation: { sender, receiver, _id },
        },
      } = await createConversation({ data });

      const currentMessage = {
        sender: sender,
        receiver: receiver,
        _id: _id,
        __v: 0,
        message: {
          message: {
            createdOn: new Date(),
            timeStamp: new Date(),
            text: "No Conversation",
            owner: userData.id,
            messageType: "text",
          },
          _id: 0,
          rstatus: "nread",
          recieverUserId: user.id,
          conversationId: _id,
          __v: 0,
        },
      };
      const tempMessage = [...allConversation];
      tempMessage.unshift(currentMessage);
      setNewUser({ sender, receiver });
      setMessageList(tempMessage);
    }
  };

  useEffect(() => {
    if (chatId?.individualId) {
      const messageReceiverChat = messageList?.find(
        (x) => x.receiver.userId === chatId?.individualId
      );
      if (messageReceiverChat) {
        const conversationIndex = messageList?.findIndex(
          (conversation) =>
            conversation.receiver.userId === chatId?.individualId
        );
        if (conversationIndex !== -1) {
          const [conversation] = messageList.splice(conversationIndex, 1);
          setMessageList([conversation, ...messageList]);
          setSendersName(conversation?.receiver?.username);
          setProfilePic(conversation?.receiver?.profile_pic);
          setConversationId(conversation?.message?.conversationId);
          setUserID(chatId?.individualId);

          const messageData = {
            conversationId: conversation?.message?.conversationId,
            page: 1,
            limit: PAGE_SIZE,
          };

          getAllMessages(messageData);
        }
        myDivRef.current.scrollTop = 0;
        if (myDivRef.current) {
          myDivRef.current.scrollTop = 0;
        }
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      if (!messageReceiverChat) {
        const createChat = async () => {
          try {
            const conversationIndex = messageList?.findIndex(
              (conversation) =>
                conversation.receiver.userId === chatId?.individualId
            );
            if (conversationIndex === -1) {
              const [conversation] = messageList.splice(conversationIndex, 1);
              const data = {
                senderId: userData.id,
                senderName: `${userData?.first_name} ${userData?.last_name}`,
                senderImage: userData?.profile_pic,
                receiverId:
                  chatId?.details !== undefined
                    ? chatId?.details?.basicDetails?.id
                    : chatId?.userProfile?.basicDetails?.id,
                receiverName:
                  chatId?.details !== undefined
                    ? `${chatId?.details?.basicDetails?.first_name} ${chatId?.userProfile?.basicDetails?.last_name}`
                    : `${chatId?.userProfile?.basicDetails?.first_name} ${chatId?.userProfile?.basicDetails?.last_name}`,
                receiverImage:
                  chatId?.details !== undefined
                    ? chatId?.details?.basicDetails?.profile_pic
                    : chatId?.userProfile?.basicDetails?.profile_pic,
              };
              const {
                data: {
                  conversation: { sender, receiver, _id },
                },
              } = await createConversation({ data });
              const currentMessage = {
                sender: sender,
                receiver: receiver,
                _id: _id,
                __v: 0,
                message: {
                  message: {
                    createdOn: new Date(),
                    timeStamp: new Date(),
                    text: "No Conversation",
                    owner: userData.id,
                    messageType: "text",
                  },
                  _id: 0,
                  rstatus: "nread",
                  recieverUserId: chatId?.details?.basicDetails?.id,
                  conversationId: _id,
                  __v: 0,
                },
              };
              const tempMessage = [...messageList];
              tempMessage.unshift(currentMessage);
              setMessageList(tempMessage);
            }
          } catch {}
        };
        createChat();
      }
    }
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatId?.individualId]);

  useEffect(() => {
    if (sendMessage && sendMessage.responseCode === 200) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sendMessage]);

  useEffect(() => {
    if (allMessage) {
      setConData(allMessage);
    }
  }, [allMessage]);

  useEffect(() => {
    setConData();
  }, []);

  return (
    <>
      <Grid item container className="chatBox">
        <Grid item md={5} xs={12} className="leftChatBox">
          <Grid item className="topChatHeader">
            <h2>Conversation</h2>
            {ACCOUNT_TYPE() === "corporate" ? (
              ""
            ) : (
              <Grid item lg={12} mt={2}>
                <FormLabels label={""} />
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup conversationTypeRadio"
                >
                  <RadioButton
                    checked={accountType === "all"}
                    value="all"
                    label="All"
                    onChange={handleAccountTypeSearch}
                  ></RadioButton>
                  <RadioButton
                    checked={accountType === "individual"}
                    value="individual"
                    label="Individual"
                    onChange={handleAccountTypeSearch}
                  ></RadioButton>
                  <RadioButton
                    checked={accountType === "corporate"}
                    value="corporate"
                    label="Corporate"
                    onChange={handleAccountTypeSearch}
                  ></RadioButton>
                </RadioGroup>
              </Grid>
            )}

            <Grid item className="chatSearchBox" md={10} xs={12}>
              <SearchAutoNew
                name="Search Users"
                isUserDetails={false}
                isKeyValuePair={true}
                optionsData={userSearch ?? []}
                typeOnchange={(event) => handleSearch(event.target.value)}
                onChange={(event, value) => handleUserSelect(event, value)}
                isLoading={isLoading}
                placeholderText="Search"
                autoCompClear={autoCompUser}
              />
            </Grid>

            <div className="chatListBox" ref={myDivRef}>
              {messageList?.map((item) => {
                const formattedDate = ConvertDate(
                  item?.message?.message?.createdOn
                );
                return (
                  <>
                    {USER_ID() === item?.receiver?.userId && (
                      <>
                        <Grid key={item?._id} mt={1}>
                          <MessageUserCard
                            date={formattedDate}
                            image={item?.sender?.profile_pic}
                            userName={item?.sender?.username}
                            message={item?.message?.message?.text}
                            conId={item?._id}
                            unReadMessage={unReadMessage?.data?.arr ?? []}
                            unReadText={false}
                            onClick={() =>
                              handleClick(
                                item?.message?.conversationId,
                                item?.sender?.username,
                                item?.sender?.profile_pic
                              )
                            }
                          />
                        </Grid>
                      </>
                    )}
                    {USER_ID() === item?.sender?.userId && (
                      <>
                        <Grid key={item?._id} mt={1}>
                          <MessageUserCard
                            date={formattedDate}
                            conId={item?._id}
                            image={item?.receiver?.profile_pic}
                            userName={item?.receiver?.username}
                            message={item?.message?.message?.text}
                            unReadMessage={unReadMessage?.data?.arr ?? []}
                            unReadText={false}
                            onClick={() =>
                              handleClick(
                                item?.message?.conversationId,
                                item?.receiver?.username,
                                item?.receiver?.profile_pic
                              )
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid md={7} xs={12} className="rightBox">
          {conData?.data ? (
            <Grid item>
              <Chat
                sendersName={sendersName}
                profilePic={profilePic}
                allMessage={conData}
                conversationId={conversationId}
                startMessaging={startMessaging}
                sendMessage={sendMessage}
                getAllMessages={getAllMessages}
                userID={userID}
                conversationUser={conversationUser}
                bottomRef={bottomRef}
                uploadProfileFile={uploadProfileFile}
                headShot={headShot}
                uploadedImage={uploadedImage}
              />
            </Grid>
          ) : (
            <Grid className="noChat">
              <img src={NO_CONVERSATION} alt="" />
              <h1 className="conversationTitle">
                Select a user to start a conversation.
              </h1>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MessagesDashboard;
