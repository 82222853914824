import { SignupType } from "../constant/SignupType ";

const initialState = {
  flow: "Signup",
  isError: false,
  isLoading: false,
};

export const checkUserExistReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignupType.POST_CHECK_USER_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case SignupType.POST_CHECK_USER_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SignupType.POST_CHECK_USER_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const otpSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignupType.GET_OTPSIGNUP_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case SignupType.GET_OTPSIGNUP_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SignupType.GET_OTPSIGNUP_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const otpVerifySignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignupType.GET_OTP_VERIFY_SIGNUP_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case SignupType.GET_OTP_VERIFY_SIGNUP_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SignupType.GET_OTP_VERIFY_SIGNUP_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignupType.POST_SIGNUP_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case SignupType.POST_SIGNUP_FAILURE:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SignupType.POST_SIGNUP_SUCCESS:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const signupWithGoogleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignupType.POST_SIGNUP_GOOGLE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case SignupType.POST_SIGNUP_GOOGLE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SignupType.POST_SIGNUP_GOOGLE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
