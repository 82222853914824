import { Box, Button, Card, CardContent, CardMedia, Grid } from "@mui/material";
import React from "react";

import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import msgBtnIcon from "../../../assets/msgBtnIcon.svg";
import locationIcon from "../../../assets/locationIcon.svg";
// import MessageIcon from "../../../assets/MessageIcon.svg";
import "./SearchedUserCard.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MessageIcon from "@mui/icons-material/Message";
const SearchedUserCard = ({
  name = "User Name",
  designation,
  company,
  state,
  city,
  country,
  profilePic,
  btnLabel,
  mapCard = false,
  v2 = true,
  onProfileClick,
  onMsgClick,
  onLoactionClick,
}) => {
  return (
    <div className="SearchedUserCard">
      <Grid className="cardBody" p={2}>
        <Grid
          lg={12}
          item
          container
          justifyContent={"left"}
          alignItems={"center"}
          className=""
          onClick={() => onProfileClick()}
        >
          <Grid item className="icon">
            <img
              src={profilePic === "" ? defaultLoginImage : profilePic}
              alt=""
              height={"48px"}
              width={"48px"}
              className="profilePic"
            />
          </Grid>
          <Grid item textAlign={"left"} ml={1} lg={9}>
            <p className="name">{name}</p>

            <p className="designation">
              {designation} {designation && company && "at"} {company}
            </p>
          </Grid>
        </Grid>
        <Grid
          className="pointer"
          container
          justifyContent={"space-between"}
          mt={1}
        >
          <Grid item container lg={10} mt={1} alignItems={"center"}>
            <Grid item lg={1} onClick={() => onLoactionClick()}>
              {/* <p>{<LocationOnIcon className="icon" />}</p> */}
              <img className="locationIcon" src={locationIcon} alt="" />
            </Grid>
            <Grid item lg={10}>
              <p className="adress">{city !== null && city !== undefined && `${city}, `}</p>
              <p className="adress">
                {state !== null && state !== undefined && `${state}, `}{" "}
                <span className="country">
                  {country !== undefined && country !== null &&
                    (country === "United States of America" ? "USA" : country)}
                </span>
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent={"right"}
            gap={1}
            item
            lg={1}
            onClick={() => onMsgClick()}
          >
            <img className="msgBtn" src={msgBtnIcon} alt="" />
          </Grid>
        </Grid>
      </Grid>
      {/* )} */}
    </div>
  );
};

export default SearchedUserCard;
