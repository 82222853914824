import { Grid } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
import "./AboutUs.scss";
import networkgraphic from "../../assets/Landing/networkgraphicCompressed.png";
import imageL from "../../assets/Landing/imageL.svg";
import Footer from "./Footer";

function AboutUs() {
  return (
    <Grid className="Aboutus-override">
      <Grid className="NavbarStyle">
        <Navbar />
      </Grid>
      <Grid>
      <Grid className="HeadingContainer" px={{ xs: 5, md: 25, lg: 25, sm: 5 }}>
        <Grid className="heading" textAlign={{ xs: "left" }} mt={10}>
          Welcome to Matchup IT
        </Grid>
        <Grid className="Subheading" textAlign={{ xs: "left" }} mt={5} mb={{ xs: 5, md: 15, lg: 15, sm: 15 }}>
          An innovative platform has been developed to facilitate the seamless
          connection between companies and a diverse pool of IT professionals
          from around the world, enabling efficient and effective collaboration
          on various projects and ensuring access to top talent in the field of
          information technology on a global scale.
        </Grid>
      </Grid>
      <Grid className="visionMainConatiner" px={5}>
        <Grid className="visionImagem">
          <img
            src={networkgraphic}
            className="VisionimagesStyle"
            alt="Vision"
          />
        </Grid>
        <Grid className="VisionSubContainer">
          <Grid className="VisionmainHeading" textAlign={{ xs: "left" }}>
            Our Vision
          </Grid>
          <Grid mt={{xs:4}} display={{lg:"none",md:"none",xs:"block",sm:"block"}} className="visionImagemMob">
          <img
            src={networkgraphic}
            className="VisionimagesStyle"
            alt="Vision"
          />
        </Grid>
          <Grid className="visionHeading" pt={{xs:3,sm:3,md:3,lg:9}} textAlign={{ xs: "left" }}>
            The ultimate social portal for IT professionals
          </Grid>
          <Grid
            className="visionSubHeading"
            pt={2.5}
            textAlign={{ xs: "left" }}
          >
            Our aim is to establish ourselves as the ultimate social portal for
            IT professionals, serving as a comprehensive platform where they can
            effortlessly connect with like-minded individuals within their
            preferred communities, enhance their productivity, and explore
            potential career opportunities with companies that align with their
            aspirations.
          </Grid>

          <Grid className="visionHeading" pt={{xs:3,sm:3,md:3,lg:9}} textAlign={{ xs: "left" }}>
            Sharing and showcasing
          </Grid>
          <Grid
            className="visionSubHeading"
            pt={2.5}
            textAlign={{ xs: "left" }}
          >
            Our objective is to be the primary portal for companies to
            disseminate their information, showcase the integration of
            technology in their operations, and effectively scout for IT talent
            to fulfill their technological requirements.
          </Grid>
        </Grid>
      </Grid>
      <Grid  p={{lg:10,md:10,sm:8,xs:5}} className="aboutmatchupIt" >
        <Grid className="aboutmatchupItText">
          “Matchup IT is a framework to connect IT and BPO professionals to
          companies directly.”
        </Grid>
      </Grid>
      <Grid
        className="howtartedConatiner"
        px={{ lg: 20, md: 20, sm: 5, xs: 5 }}
        mt={{ lg: 15, md: 15, sm: 12, xs: 5 }}
        mb={{ lg: 15, md: 15, sm: 12, xs: 5 }}
        // mt={15}
        // mb={15}
      >
        <Grid className="ownerImage">
          <img src={imageL} className="imageStyle" alt="Owner" />
        </Grid>
        <Grid className="howstartedContainer">
          <Grid className="howstartedMainHeading" textAlign={{ xs: "left" }}>
            How we started
          </Grid>
          <Grid mt={{xs:4}} display={{lg:"none",md:"none",xs:"block",sm:"block"}} className="ownerImageMob">
            <img src={imageL} className="imageStyle" alt="Owner" />
          </Grid>
          <Grid
            className="howstartedSubHeading"
            mt={5}
            textAlign={{ xs: "left" }}
          >
            The inception of MatchupIT traces back to its founder{" "}
            <b>Lalit Dhingra</b>, who recognized the pressing need for a
            platform that would bridge the gap between IT professionals and
            companies.{" "}
            <p>
              {" "}
              In the year 2021, after months of dedicated efforts and meticulous
              development, MatchupIT was officially launched, marking the
              beginning of its journey to become the leading platform connecting
              IT professionals with companies worldwide.
            </p>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
      <Grid mt={{ xs: 10 }}>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default AboutUs;
