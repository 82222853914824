import React from "react";
import notFoundImg from "../../assets/notFoundImg.png";
const NotFoundPage = () => {
  return (
    <div
      className="notFoundPage"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={notFoundImg} />
    </div>
  );
};

export default NotFoundPage;
