import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAllIndustry,
  getAllCountries,
  editProfile,
  getSalary,
  sendInvite,
  getJobType,
  tagUser,
  updateCompansation,
  getUserDetails,
} from "../../services/editProfileService";
import { apiError } from "../../utils/helper";
import { editProfileType } from "../constant/editProfileType";
import * as editProfileAction from "../actions/editProfileAction";

export function* getIndustriesSaga(params) {
  try {
    const { data, status, error } = yield call(getAllIndustry, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.getIndustriesSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Industries Failed" };
    yield put(editProfileAction.getIndustriesFailure(data));
  }
}

export function* getCountriesSaga(params) {
  try {
    const { data, status, error } = yield call(getAllCountries, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.getConutriesSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Countries  Failed" };
    yield put(editProfileAction.getCountriesFailure(data));
  }
}

export function* putEditProfileSaga(params) {
  try {
    const { data, status, error } = yield call(editProfile, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.putEditProfileSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Industries Failed" };
    yield put(editProfileAction.putEditProfileFailure(data));
  }
}

export function* getSalarySaga(params) {
  try {
    const { data, status, error } = yield call(getSalary, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.getSalarySuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Salary Failed" };
    yield put(editProfileAction.getSalaryFailure(data));
  }
}

export function* postSendInviteSaga(params) {
  try {
    const { data, status, error } = yield call(sendInvite, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.postSendInviteSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Invite Failed" };
    yield put(editProfileAction.postSendInviteFailure(data));
  }
}

export function* getJobTypeSaga(params) {
  try {
    const { data, status, error } = yield call(getJobType, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.getJobTypeSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Countries  Failed" };
    yield put(editProfileAction.getJobTypeFailure(data));
  }
}

export function* postTagUserSaga(params) {
  try {
    const { data, status, error } = yield call(tagUser, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.postTagUserSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Tag User Failed" };
    yield put(editProfileAction.postTagUserFailure(data));
  }
}


export function* updateCompansationSaga(params) {
  try {
    const { data, status, error } = yield call(updateCompansation, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.updateCompansationSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Compansatrion  Failed" };
    yield put(editProfileAction.updateCompansationFailure(data));
  }
}

export function* getUserDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getUserDetails, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      editProfileAction.getUserDetailsSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Compansatrion  Failed" };
    yield put(editProfileAction.getUserDetailsFailure(data));
  }
}

const myEditProfileSaga = [
  takeLatest(editProfileType.GET_INDUSTRY_PENDING, getIndustriesSaga),
  takeLatest(editProfileType.GET_COUNTRIES_PENDING, getCountriesSaga),
  takeLatest(editProfileType.GET_SALARY_PENDING, getSalarySaga),
  takeLatest(editProfileType.POST_INVITE_PENDING, postSendInviteSaga),
  takeLatest(editProfileType.PUT_EDIT_PROFILE_PENDING, putEditProfileSaga),
  takeLatest(editProfileType.GET_JOB_TYPE_PENDING, getJobTypeSaga),
  takeLatest(editProfileType.POST_TAG_USER_PENDING, postTagUserSaga),
  takeLatest(
    editProfileType.UPDATE_COMPANSATION_PENDING,
    updateCompansationSaga
  ),
  takeLatest(editProfileType.GET_USER_DETAILS_PENDING, getUserDetailsSaga),
];

export default myEditProfileSaga;
