export const SignupType = {
  POST_CHECK_USER_PENDING: "[CHECK_USER][REQUEST] Check User",
  POST_CHECK_USER_FAILURE: "[CHECK_USER][RESPONSE] Check User Failed",
  POST_CHECK_USER_SUCCESS: "[CHECK_USER][RESPONSE] Check User Successfull",

  GET_OTPSIGNUP_PENDING: "[SIGNUPOTP][REQUEST] Try To Register",
  GET_OTPSIGNUP_FAILURE: "[SIGNUPOTP][RESPONSE] SignupOtp Failed",
  GET_OTPSIGNUP_SUCCESS: "[SIGNUPOTP][RESPONSE] SignupOtp Successfull",

  GET_OTP_VERIFY_SIGNUP_PENDING:
    "[SIGNUPOTP_VERIFY][REQUEST] Try To Verify Register",
  GET_OTP_VERIFY_SIGNUP_FAILURE:
    "[SIGNUPOTP_VERIFY][RESPONSE] SignupOtp  Verify Failed",
  GET_OTP_VERIFY_SIGNUP_SUCCESS:
    "[SIGNUPOTP_VERIFY][RESPONSE] SignupOtp  Verify Successfull",

  POST_SIGNUP_PENDING: "[SIGNUP][REQUEST] Try To Register",
  POST_SIGNUP_FAILURE: "[SIGNUP][RESPONSE] Signup Failed",
  POST_SIGNUP_SUCCESS: "[SIGNUP][RESPONSE] Signup Successfull",
  CLEAR_ERRORS: "[SIGNUP][REQUEST] CLEAR_ERRORS",

  POST_SIGNUP_GOOGLE_PENDING:
    "[SIGNUP_GOOGLE][REQUEST] Try To Register With Google",
  POST_SIGNUP_GOOGLE_FAILURE:
    "[SIGNUP_GOOGLE][RESPONSE] Signup Failed With Google",
  POST_SIGNUP_GOOGLE_SUCCESS:
    "[SIGNUP_GOOGLE][RESPONSE] Signup Successfull With Google",
};
