import React, { useEffect, useState } from "react";
import Search from "../component/search";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndustry,
  getPopularSearches,
  getPopularSearchesCorporate,
  getRecentSearchesCorporate,
  postSearchMap,
  postSearchMapCorporate,
} from "../../../redux/actions/searchAction";
import Loader from "../../../library/common/Loader";
import { getRecentSearches } from "../../../redux/actions/loginAction";
import ViewProfileContainer from "./ViewProfileContainer";
const SearchContainer = () => {
  const [account_type, setAccount_type] = useState("individual");

  const dispatch = useDispatch();
  useEffect(() => {}, []);
  useEffect(() => {
    if (account_type === "corporate") {
      dispatch(getPopularSearchesCorporate());
      dispatch(getRecentSearchesCorporate());
    } else {
      dispatch(getPopularSearches());
      dispatch(getRecentSearches());
      dispatch(getIndustry());
    }
  }, [account_type]);

  const searchMapData = useSelector(
    (state) => state?.searchMap?.data?.response?.userList
  );

  const searchMapCorporateData = useSelector(
    (state) => state?.postSearchMapCorporate?.data?.response?.userList
  );
  const mapLoading = useSelector(
    (state) =>
      state?.searchMap?.isLoading || state?.postSearchMapCorporate?.isLoading
  );
  const industryData = useSelector((state) => state?.industry?.data?.response);
  const industryLoading = useSelector((state) => state?.industry?.isLoading);
  // const isLoading = useSelector((state) => state?.searchMap?.isLoading);
  const [searchText, setsearchText] = useState("");
  function mapSearch(input) {
    setsearchText(input);
    dispatch(postSearchMap(input));
  }
  function mapSearchCorporate(input) {
    setsearchText(input);
    dispatch(postSearchMapCorporate(input));
  }
  const [listArr, setlistArr] = useState([]);

  const [zoom, setzoom] = useState(15);
  function userMap() {
    let arr = [];
    if (account_type === "corporate")
      searchMapCorporateData?.map((i) => {
        i?.users?.map((j) => {
          arr.push({
            lat: i.lat,
            lng: i.lng,
            profilePic: j.logo,
            name: j.name,
            id: j.id,
            city: j.city,
            state: j.state,
            country: j.country,
            geocode: [i.lat, i.lng],
          });
        });
      });
    else
      searchMapData?.map((i) => {
        i?.users?.map((j) => {
          arr.push({
            lat: i.lat,
            lng: i.lng,
            profilePic: j.profilePic,
            name: j.name,
            id: j.id,
            city: j.city,
            state: j.state,
            country: j.country,
            geocode: [i.lat, i.lng],
          });
        });
      });
    setlistArr(arr);
    setzoom(1);
    return arr;
  }
  useEffect(() => {
    userMap();
    setzoom(1);
  }, [searchMapData, searchMapCorporateData]);
  const [profileClick, setProfileClick] = useState(false);
  const [selectedProfileID, setselectedPRofileID] = useState("");
  function handleSearchProfileClick(id, userType) {
    setselectedPRofileID(id);
    setProfileClick(true);
  }
  function handleProfileBackClick() {
    // setselectedPRofileID(id);
    setProfileClick(false);
  }
  const [formOnchange, setFormOnchange] = useState(false);
  const [formOnchangeCorporate, setformOnchangeCorporate] = useState(false);

  // useEffect(() => {
  //   if(searchMapStatus === "SUCCESS")
  //   setFormOnchange(false)
  // }, [searchMapStatus])
  
  return (
    <div>
      {industryLoading ? (
        <Loader />
      ) : profileClick ? (
        <ViewProfileContainer
          user={selectedProfileID}
          onClick={handleProfileBackClick}
          fromSerach={true}
        />
      ) : (
        <Search
          zoom={zoom}
          setzoom={setzoom}
          searchText={searchText}
          setsearchText={setsearchText}
          searchMapData={searchMapData}
          searchMapCorporateData={searchMapCorporateData}
          mapSearch={mapSearch}
          mapSearchCorporate={mapSearchCorporate}
          industryData={industryData}
          account_type={account_type}
          setAccount_type={setAccount_type}
          listArr={listArr}
          setlistArr={setlistArr}
          mapLoading={mapLoading}
          handleSearchProfileClick={handleSearchProfileClick}
          formOnchange={formOnchange}
          setFormOnchange={setFormOnchange}
          formOnchangeCorporate={formOnchangeCorporate}
          setformOnchangeCorporate={setformOnchangeCorporate}
        />
      )}
    </div>
  );
};

export default SearchContainer;
