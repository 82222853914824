import React from "react";
import SearchAutoNew from "../../../library/custom/searchAutoComplete/searchAutoNew/SearchAutoNew";
import { Box, Grid, Modal, Typography } from "@mui/material";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import "./SearchJob.scss";
import JobCard from "./JobCard";
import { useState } from "react";
import checkCircleJob from "../../../assets/checkCircleJob.svg";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: "none",
  p: 4,
  borderRadius: "15px",
};

const SearchJob = ({
  items,
  handleLocation,
  handleTitle,
  getJobInfo,
  getJobInfoData,
  applyJob,
  appliedJobIds,
  applyJobData,
  getJobs,
}) => {
  const [selectedJob, setSelectedJob] = useState();
  function onJobClick(e, i) {
    if (i.jobId === selectedJob) {
      setSelectedJob();
    } else {
      setSelectedJob(i.jobId);
      getJobInfo(e, i.jobId);
    }
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!applyJobData?.isLoading && applyJobData?.status === "SUCCESS") {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        getJobs();
        setOpen(false);
        window?.location?.reload();
      }, 3000);
    }
  }, [applyJobData]);
  return (
    <div className="overrideSearchJob">
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container justifyContent={"center"}>
              <img src={checkCircleJob} alt="" />
            </Grid>
            <p id="modal-modal-description" className="font700 font16">
              You have successfully applied for this job!
            </p>
          </Box>
        </Modal>
      <Grid item container>
        <p className="font24 font700">Search Jobs</p>
      </Grid>
      <Grid container item lg={6} xs={12} mt={3}>
        <SearchBar
          placeholder={"Title, Skill, Company"}
          locationSearch={false}
          handleSearch={handleTitle}
        />
      </Grid>
      <Grid container item lg={6} xs={12} mt={3}>
        <SearchBar
          placeholder={"City, State, Country"}
          locationSearch={true}
          handleSearch={handleLocation}
        />
      </Grid>
      <Grid container item lg={9} md={12} sm={12} xs={12} mt={3}>
        {items?.map((i) => (
          <Grid key={i?.id} mt={2}>
            <JobCard
              showDescription={selectedJob == i.jobId}
              item={i}
              onJobClick={onJobClick}
              getJobInfoData={getJobInfoData?.data?.response}
              isLoading={getJobInfoData?.isLoading}
              applyJob={applyJob}
              appliedJobIds={appliedJobIds}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SearchJob;
