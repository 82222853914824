import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button as MUIBtn, Grid } from "@mui/material";
import "./InvitePopover.scss";
import { useState } from "react";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../library/custom/button/Button";
import { E_YEAR } from "../../../constant/constant";
import ClickableChips from "../../../library/custom/chip/ClickableChip";
import TextArea from "../../../library/custom/textArea/TextArea";
import { useEffect } from "react";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function RequestMentorshipForm({
  open,
  mentorshipSubjects,
  handleCancel,
  submitRequest,
  mentorData,
}) {
  const [mentorshipFormValue, setMentorshipFormValue] = useState({
    selectedSub: "",
    subjects: [],
    month: undefined,
    year: undefined,
    slot: "",
    message: "",
    monthInd: 0,
  });
  const [subjectERR, setsubjectERR] = useState(false);
  let years = [];
  for (let i = E_YEAR; i <= 2030; i++) {
    years.push(i);
  }
  const autoCompSub = React.useRef(null);
  const [subList, setsubList] = useState([]);
  const addSubjectsData = () => {
    setsubjectERR(false);
    if (!mentorshipFormValue.selectedSub) return;
    const index = mentorshipFormValue?.subjects.findIndex(
      (object) => object === mentorshipFormValue?.selectedSub
    );
    if (index === -1) {
      setsubList([...subList, mentorshipFormValue.selectedSub]);
    }
    const ele = autoCompSub.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const removeSubData = (data) => {
    const updatedSubList = subList.filter((list) => list !== data);
    setsubList(updatedSubList);
  };

  React.useEffect(() => {
    setMentorshipFormValue({
      ...mentorshipFormValue,
      subjects: subList,
    });
  }, [subList]);
  function sendRequest(params) {
    if (mentorshipFormValue.subjects.length === 0) setsubjectERR(true);
    else
      submitRequest({
        mentorId: mentorData.id,
        skills: mentorshipFormValue.subjects,
        message: mentorshipFormValue.message,
      });
  }
  useEffect(() => {
    const monthIndex = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    }[mentorshipFormValue.month];
    setMentorshipFormValue({
      ...mentorshipFormValue,
      monthInd: monthIndex,
    });
  }, [mentorshipFormValue.month]);
  const [slotsArr, setslotsArr] = useState({
    view: [],
    payload: [],
  });
  useEffect(() => {
    let arr = [];
    mentorData?.mentorship?.availableSlots?.forEach((i) => {
      let itemarr = i.split(" ");
      let dateArr = itemarr[0].split("-");
      arr.push({
        booked: false,
        time: itemarr[1],
        year: dateArr[0],
        month: dateArr[1],
        day: dateArr[2],
      });
    });
    let currMnthSlot = [];
    let currMnthSlotView = [];
    arr.forEach((i) => {
      if (
        i.month == mentorshipFormValue.monthInd &&
        i.year == mentorshipFormValue.year
      ) {
        currMnthSlot.push(i);
        currMnthSlotView.push(
          `${mentorshipFormValue.month} ${i.day} | ${i.time}`
        );
      }
    });
    setslotsArr({
      ...slotsArr,
      payload: currMnthSlot,
      view: currMnthSlotView,
    });
  }, [mentorshipFormValue]);

  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className="dialogBoxContainer"
      >
        <DialogContent className="Dioerrimg">
          <h1>Request Mentorship</h1>

          <Grid alignItems={"flex-end"} item container lg={12} xs={12} sm={12} md={12} mt={2}>
            <Grid item lg={10} xs={9}>
              <FormLabels label="Select subjects to mentor" />
              <SearchAutoComplete
                optionsData={mentorshipSubjects ?? []}
                isKeyValuePair={true}
                value={mentorshipFormValue.selectedSub}
                onChange={(e, value) =>
                  setMentorshipFormValue({
                    ...mentorshipFormValue,
                    selectedSub: value,
                  })
                }
                // // isLoading={isLoading}
                autoCompClear={autoCompSub}
              />
            </Grid>

            <Grid
              item
              lg={1.5}
              md={1}
              sm={1}
              xs={1}
              ml={1}
              className="saveBtn"
            >
              <Buttons
                varientContained={true}
                label={"Add"}
                onClick={addSubjectsData}
              ></Buttons>
            </Grid>
            <Grid mt={4} container gap={1}>
              {mentorshipFormValue?.subjects?.map((i) => (
                <ClickableChips
                  key={i}
                  label={i}
                  isdelete={true}
                  deleteIcon={removeSubData}
                />
              ))}
            </Grid>
          </Grid>
          <Grid textAlign={"left"} mt={4} lg={12}>
            <FormLabels label="Add Message  (Why do you want to be mentored?)" />
            <TextArea
              id={"description"}
              name={"description"}
              placeholder={"Enter here"}
              value={mentorshipFormValue.message}
              onChange={(e) => {
                setMentorshipFormValue({
                  ...mentorshipFormValue,
                  message: e.target.value,
                });
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container gap={1}>
          {subjectERR &&<Grid container> <p className="errMsg">Please select subject</p></Grid>}
          <Grid container item xs={12} sm={4.5} md={4.2} lg={4.2} xl={4} className="reqBtn">
            <Buttons
              onClick={() => sendRequest()}
              varientContained={true}
              label={"Request Mentorship"}
            />
          </Grid>

          <Grid container item xs={12} sm={3} md={3} className="reqBtn">
            {" "}
            <Buttons
              onClick={() => handleCancel()}
              varientLeaveContained={true}
              label={"Cancel"}
            />
          </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
