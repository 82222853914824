import { jobPostType } from "../constant/jobPostType";

const initialState = {
  flow: "jobPost",
  isError: false,
  isLoading: false,
};

export const putJobPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.PUT_JOB_POST_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.PUT_JOB_POST_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.PUT_JOB_POST_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateJobPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.UPDATE_JOB_ID_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.UPDATE_JOB_ID_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.UPDATE_JOB_ID_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postedJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.GET_POSTED_JOB_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
      case jobPostType.GET_POSTED_JOB_SUCCESS:
        return {
          ...state,
          status: "SUCCESS",
          ...action.data,
          isError: false,
          isLoading: false,
        };
        case jobPostType.GET_POSTED_JOB_FAILURE:
          return {
            ...state,
            status: "FAILURE",
            isError: true,
            isLoading: false,
            error: { ...action.error },
          };
        default:
          return state;
      }
    };

export const getJobFindReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.GET_JOB_FIND_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.GET_JOB_FIND_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.GET_JOB_FIND_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postedJobDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.GET_POSTED_JOB_DETAILS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.GET_POSTED_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.GET_POSTED_JOB_DETAILS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jobPostType.CLEAR_JOBS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
      default:
        return state;
    }
  };

      


      

export const getJobInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.GET_JOB_INFO_PENDING:
      
return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.GET_JOB_INFO_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.GET_JOB_INFO_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};


export const postJobApplyReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.POST_JOB_APPLY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.POST_JOB_APPLY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.POST_JOB_APPLY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      
    case jobPostType.POST_CLEAR_JOB_APPLY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getAppliedJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.GET_APPLIED_JOB_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.GET_APPLIED_JOB_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.GET_APPLIED_JOB_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getApplicantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobPostType.GET_APPLICANT_JOB_DETAILS_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case jobPostType.GET_APPLICANT_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jobPostType.GET_APPLICANT_JOB_DETAILS_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
