import { editProfileType } from "../constant/editProfileType";

export const getIndustries = (data) => ({
  type: editProfileType.GET_INDUSTRY_PENDING,
  data,
});

export const getIndustriesSuccess = (data) => ({
  type: editProfileType.GET_INDUSTRY_SUCCESS,
  data,
});

export const getIndustriesFailure = (error) => ({
  type: editProfileType.GET_INDUSTRY_FAILURE,
  error,
});

export const getCountries = (data) => ({
  type: editProfileType.GET_COUNTRIES_PENDING,
  data,
});

export const getConutriesSuccess = (data) => ({
  type: editProfileType.GET_COUNTRIES_SUCCESS,
  data,
});

export const getCountriesFailure = (error) => ({
  type: editProfileType.GET_COUNTRIES_FAILURE,
  error,
});

export const putEditProfile = (data) => ({
  type: editProfileType.PUT_EDIT_PROFILE_PENDING,
  data,
});

export const putEditProfileSuccess = (data) => ({
  type: editProfileType.PUT_EDIT_PROFILE_SUCCESS,
  data,
});

export const putEditProfileFailure = (error) => ({
  type: editProfileType.PUT_EDIT_PROFILE_FAILURE,
  error,
});

export const putEditProfileClear = () => ({
  type: editProfileType.PUT_EDIT_PROFILE_CLEAR,
  data: "",
});

export const getSalary = (data) => ({
  type: editProfileType.GET_SALARY_PENDING,
  data,
});

export const getSalarySuccess = (data) => ({
  type: editProfileType.GET_SALARY_SUCCESS,
  data,
});

export const getSalaryFailure = (error) => ({
  type: editProfileType.GET_SALARY_FAILURE,
  error,
});

export const postSendInvite = (data) => ({
  type: editProfileType.POST_INVITE_PENDING,
  data,
});

export const postSendInviteSuccess = (data) => ({
  type: editProfileType.POST_INVITE_SUCCESS,
  data,
});

export const postSendInviteFailure = (error) => ({
  type: editProfileType.POST_INVITE_FAILURE,
  error,
});

export const getJobType = (data) => ({
  type: editProfileType.GET_JOB_TYPE_PENDING,
  data,
});

export const getJobTypeSuccess = (data) => ({
  type: editProfileType.GET_JOB_TYPE_SUCCESS,
  data,
});

export const getJobTypeFailure = (error) => ({
  type: editProfileType.GET_JOB_TYPE_FAILURE,
  error,
});

export const postTagUser = (data) => ({
  type: editProfileType.POST_TAG_USER_PENDING,
  data,
});

export const postTagUserSuccess = (data) => ({
  type: editProfileType.POST_TAG_USER_SUCCESS,
  data,
});

export const postTagUserFailure = (error) => ({
  type: editProfileType.POST_TAG_USER_FAILURE,
  error,
});
export const updateCompansation = (data) => ({
  type: editProfileType.UPDATE_COMPANSATION_PENDING,
  data,
});

export const updateCompansationSuccess = (data) => ({
  type: editProfileType.UPDATE_COMPANSATION_SUCCESS,
  data,
});

export const updateCompansationFailure = (error) => ({
  type: editProfileType.UPDATE_COMPANSATION_FAILURE,
  error,
});

export const getUserDetails = (data) => ({
  type: editProfileType.GET_USER_DETAILS_PENDING,
  data,
});

export const getUserDetailsSuccess = (data) => ({
  type: editProfileType.GET_USER_DETAILS_SUCCESS,
  data,
});

export const getUserDetailsFailure = (error) => ({
  type: editProfileType.GET_USER_DETAILS_FAILURE,
  error,
});
