import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Navbar from "./Navbar";
import { Grid } from "@mui/material";
import Footer from "./Footer";
import "./PrivacyPolicy.scss";

function PrivacyPolicy() {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid className="PrivacyContainer">
      <Grid className="navbarStyle">
        <Navbar />
      </Grid>
      <Grid className="mainHeading">Privacy Policy</Grid>
      <Grid container mt={3} mb={3} px={{ lg: 30, md: 28, sm: 20, xs: 2 }}>
        <Grid item lg={12} xs={12} mb={2} className="mainsubHeading">
          MatchupIT's mission is to create a go to platform for global
          information technology (IT) professionals where they can share
          knowledge, communicate with other experts in their area of expertise,
          present their credentials and browse through companies that may be
          looking for technology skills. Companies will also be able to use this
          platform to represent themselves to millions of potential IT
          professionals. MatchupIT is committed to being transparent about the
          data it collects about you (including personal information), how it is
          used and with whom it is shared.
        </Grid>
      </Grid>
      <Grid container mb={{ lg: 8, md: 8, sm: 4, xs: 2 }} px={{ lg: 30, md: 28, sm: 20, xs: 2 }}>
        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Introduction</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  We are an online platform for professionals and companies that
                  intensively use Information technology (IT). Companies use our
                  platform to promote their businesses, products, services,
                  latest news and search right professionals for hire.
                  Individual users use our services to get noticed, increase
                  value, connect with the communities of their likings, and be
                  found for companies to connect and may be get hired. Our
                  Privacy Policy applies to any registered user (Professional or
                  Company, both as defined below) of MatchupIT.com or other
                  offerings (such as apps and email communications) that link to
                  this Privacy Policy (collectively the “Service”).
                </p>
                <p>
                  Our Individual registered users <b>("Professionals")</b> share
                  their professional identities, engage with their network of
                  specialized communities, exchange knowledge and professional
                  insights, post and view relevant content, and find business
                  and career opportunities.
                </p>
                <p>
                  Our corporate users <b>(“Companies”)</b> can promote their
                  products and services and view some, or all the individual
                  registered members&#39; information. If you establish a
                  Company account, this Privacy Policy applies to information
                  regarding the Company as well as you individually.
                </p>
                <h4>Services</h4>
                <p>
                  This Privacy Policy applies when you are a registered user
                  <b>(Professional & Company)</b> of our service. This Privacy
                  Policy applies to MatchupIT.com.
                </p>
                <h4>Controlling and Contracting Party</h4>
                <p>
                  MatchupIT.com will be the controller of your data provided to
                  or collected by or for, or processed in connection with, our
                  Services.
                </p>
                <p>
                  As a registered user of our Services, the collection, use, and
                  sharing of your personal data are subject to this Privacy
                  Policy and other documents referenced in this Privacy Policy,
                  as well as updates.
                </p>
                <h4>Change</h4>
                <p>
                  Changes to the Privacy Policy apply to your use of our
                  services after the &quot;effective date.&quot; MatchupIT.com
                  can modify this Privacy Policy. The date this Privacy Policy
                  was last updated is posted at the top of this page. It is your
                  responsibility to check this page for updates periodically. If
                  you have objections to any changes, you may close your
                  account.
                </p>
                <p>
                  You acknowledge that your continued use of our services after
                  we publish our changes to this Privacy Policy means that the
                  collection, use, and sharing of your personal data is subject
                  to the updated Privacy Policy as of its effective date.{" "}
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading"> Data We Collect</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <h4>1.1 Data You Provide</h4>
                <p>
                  You provide data to create an account with us. In addition,
                  you provide, or we collect data when you use our Service or
                  interact with us via email, “contact us” links, and chat
                  support.
                </p>
                <p>
                  <span>
                    <b>Registration</b>
                  </span>
                  <br />
                  To create an account, you need to provide data, including your
                  name, email address and/or mobile number, and a password.
                  After an initial period of 12 months, you will need to provide
                  payment (e.g., credit card) and billing information. We use a
                  secure third-party platform to manage all payments and require
                  them to take steps to ensure that your credit card and billing
                  information is secured and safe.
                </p>
                <p>
                  <span>
                    <b>Profile</b>
                  </span>
                  <br />
                  You may a MatchupIT.com complete profile to be beneficial to
                  you. The more information you provide, the more helpful your
                  profile may be. All the information you provide is subject to
                  this Privacy Policy. You have choices about your profile
                  information, such as your basic information like month and
                  year of birth, address, countries where you have authorization
                  to work, education, work experience, skills, photo, video, and
                  any certification. This profile information helps you to get
                  more from our services, including helping recruiters and
                  business opportunities find you.
                </p>
                <p>
                  <span>
                    <b>Posting and Uploading</b>
                  </span>
                  <br />
                  We also collect data on your technical blogs or posts to the
                  communities that you chose to interact with. You can upload
                  data from files (text, picture, or video) while posting a blog
                  or creating a post for others to view and comment on.
                </p>
                <h4>1.2 Data from Other sources</h4>
                <p>
                  Others may post or comment about your post by responding to
                  your post. Others that include Companies can also send you a
                  secured message using our platform. We may also collect
                  information about you from third party service providers,
                  including other social media platforms or your Company
                  account.
                </p>
                <p>
                  <span>
                    <b> Content and News</b>
                  </span>
                  <br />
                  You and others may post content that includes information
                  about you (as part of articles, posts, comments, videos). We
                  do not actively collect information from the contents of such
                  submissions. We only collect information about you that is
                  provided by you at the time of registering and as you use the
                  Service.{" "}
                </p>
                <h4>1.3 Service Use</h4>
                <p>
                  We log your visits and use of our Services. We log usage data
                  when you visit or otherwise use our services, including a
                  message to the companies for a job inquiry. We use logins,
                  cookies, device information and internet protocol
                  (&quot;IP&quot;) addresses to identify you and log your use.
                </p>
                <br />
                <h4>1.4 Cookies and Similar Technologies</h4>
                <p>
                  We may collect data through cookies and similar technologies.
                </p>
                <br />
                <h4>1.5 Your Device and Location</h4>
                <p>
                  We receive data from your devices and networks, including
                  location data. When you visit or leave our services (including
                  some plugins and our cookies or similar technology on the
                  sites of others), we receive the URL of both the site you came
                  from and the one you go to, and the time of your visit. We
                  also get information about your network and device (e.g., IP
                  address, proxy server, operating system, web browser, add-ons,
                  device identifier and features, cookie IDs and/or ISP your
                  mobile carrier). If you use your mobile device services, that
                  device will send us data about your location based on your
                  phone settings. We will ask you to opt-in before we use GPS or
                  other tools to identify your precise location.
                </p>
                <br />
                <h4>1.6 Messages</h4>
                <p>
                  If you communicate or send messages to others through our
                  services, our system has knowledge about that. We collect
                  information about you when you send, receive, or engage with
                  messages in connection with our services.
                </p>
                <br />
                <h4>1.7 Other</h4>
                <p>
                  We are continuously improving our services, which means we get
                  new data and create new ways to use data. Our services are
                  dynamic, and we often introduce new features, which may
                  require the collection of new information. If we collect
                  materially different personal data or materially change how we
                  collect, use, or share your data, we may modify this Privacy
                  Policy as appropriate.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">Using your Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <p>
                  We use your data to provide, support, personalize and develop
                  our services. How we use your personal data will depend on
                  which services you use, how you use those services and the
                  choices you make in your settings. We use the data that we
                  have about you to provide and personalize our services,
                  including with the help of automated systems and inferences we
                  make so that our services (including ads) can be more relevant
                  and useful to you and others.{" "}
                </p>
                <h4>2.1 Services</h4>
                <p>
                  Our Services help you connect with others in your specialized
                  area, find and be found for work and business opportunities,
                  stay informed, and be more productive. We use your data to
                  authorize access to our Services and honor your settings.{" "}
                </p>
                <p>
                  <b>Stay Informed</b>
                  <br />
                  Our Services allow you to stay informed about news, events,
                  and ideas regarding professional topics you care about and
                  respect from professionals. We use the data we have about you
                  to personalize our services. We use your content, activity,
                  and other data, including your name and photo, to provide
                  notices to Companies on our network.{" "}
                </p>
                <p>
                  <b>Career</b>
                  <br />
                  Our Services allow Companies to explore your profile and the
                  information you have posted in it. The objectives are for the
                  Company to find you rather than you apply for the
                  opportunities. We do not control what any Company does with
                  the information it gathers from your profile.{" "}
                </p>
                <p>
                  <b> Productivity</b>
                  <br />
                  Our Services allow you to collaborate with colleagues, search
                  for potential clients, customers, partners, and others to do
                  business with. Our Services allow you to communicate with
                  other individual members, or members of your selected
                  community.
                </p>
                <br />
                <h4>2.2 Communications</h4>
                <p>
                  We contact you and enable communications between you and
                  Companies. We offer settings that allow you to control what
                  messages you receive and how often you receive some types of
                  messages.
                  <br />
                  We will contact you through email, mobile phone, notices
                  posted on our websites or apps, including messages to your
                  MatchupIT.com message box. We will send you messages about the
                  availability of our services, security, or other
                  service-related issues. We also send messages about how to use
                  our Services, updates, reminders, suggestions, and messages
                  from our corporate members and us. You may change your
                  communication preferences at any time. Please be aware that
                  you cannot opt-out of receiving service messages from us,
                  including security and legal notices.
                </p>
                <br />
                <h4>2.3 Advertising</h4>
                <p>
                  In future , we may allow advertisement on our Platform. Those
                  advertisers may use your information for personalizing the
                  advertisement of their product and services to you.
                </p>
                <br />
                <h4>2.4 Marketing</h4>
                <p>
                  We promote our services directly to you and others. We use
                  information you provide to personalize the marketing you
                  receive.
                </p>
                <br />
                <h4>2.5 Customer Support</h4>
                <p>
                  We use data to help you and fix problems. We use data (which
                  can include your communications and information) to
                  investigate, respond to and resolve complaints and Service
                  issues (e.g., bugs).
                </p>
                <br />
                <h4>2.6 Insights That Do Not Identify Yo</h4>
                <p>
                  We use data to generate insights that do not identify you. For
                  example, we may use your data to generate statistics for our
                  internal use about our members, their profession, or industry,
                  calculate ad impressions served or clicked on, or publish
                  visitor demographics for a service or create demographic
                  workforce insights.
                </p>
                <br />
                <h4>2.7 Security and Investigations</h4>
                <p>
                  We use data for security, fraud prevention, and
                  investigations. We use your data (including your
                  communications) for security purposes or to prevent or
                  investigate possible fraud or attempts to harm our individual
                  and corporate registered users.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">
                Sharing your Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <h4>3.1 Our Services</h4>
                <p>
                  Any data that you include on your profile and any content you
                  post or social action (e.g., likes, follows, comments, shares)
                  you take on our Services will be seen by others, consistent
                  with your settings.
                </p>
                <p>
                  <b>Profile</b>
                  <br />
                  Your profile is visible to all registered companies but all
                  the compensation data, if you have shared that on the platform
                  is masked. Your limited profile is visible to other individual
                  members.
                </p>
                <br />
                <h4>3.2 Communication Archival</h4>
                <p>
                  Regulated users may need to store communications outside of
                  our service.
                </p>
                <br />
                <h4>3.3 Service Providers</h4>
                <p>
                  We use others to help us provide our Services (e.g.,
                  maintenance, analysis, audit, payments, fraud detection,
                  marketing, and development). They will have access to your
                  information as reasonably necessary to perform these tasks on
                  our behalf and are obligated not to disclose or use it for
                  other purposes. For example, if you use services that require
                  payment, your information will be processed by a third-party
                  payment provider.
                </p>
                <br />
                <h4>3.4 Legal Disclosures</h4>
                <p>
                  We may need to share your data when we believe it is required
                  by law or to help protect the rights and safety of you, us, or
                  others. It is possible that we will need to disclose
                  information, including personal information, about you when
                  required by law, subpoena, or other legal process or if we
                  have a good faith belief that disclosure is reasonably
                  necessary to (1) investigate, prevent or take action regarding
                  suspected or actual illegal activities or to assist government
                  enforcement agencies; (2) enforce our agreements with you; (3)
                  investigate and defend ourselves against any third-party
                  claims or allegations; (4) protect the security or integrity
                  of our Services (such as by sharing with law enforcement or
                  with companies facing similar threats); or (5) exercise or
                  protect the rights and safety of MatchupIT.com, our Members,
                  personnel or others. We attempt to notify our individual users
                  about legal demands for their personal data when appropriate
                  in our judgment, unless prohibited by law or court order or
                  when the request is an emergency. We may dispute such demands
                  when we believe, at our discretion, that the requests are
                  overbroad, vague or lack proper authority, but we do not
                  promise to challenge every demand.
                </p>
                <br />
                <h4>3.5 Change in Control or Sale</h4>
                <p>
                  We can also share your personal data as part of a sale,
                  merger, or change in control or in preparation for any of
                  these events.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">
                Your Choices &amp; Obligations
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <h4>4.1 Data Retention</h4>
                <p>
                  We keep most of your personal data for as long as your account
                  is open. We generally retain your personal data if you keep
                  your account open or as needed to provide you services. This
                  includes data you or others provided to us, and data generated
                  or inferred from your use of our services. We will delete the
                  data once you leave our platform and want us to delete the
                  data.
                </p>
                <br />
                <h4>4.2 Rights to Access and Control Your Personal Data</h4>
                <p>
                  You can access or delete your personal data at any time but
                  doing so may impair your access to certain features of the
                  Service . You have many choices about how your data is
                  collected, used, and shared.
                </p>
                <p>
                  For personal data that we have about you, you can contact us
                  at{" "}
                  <a href="mailto: contact@matchupit.com">
                    contact@matchupit.com
                  </a>
                  with any of the following requests:
                  <ul>
                    <li>
                      <b>Delete Data:</b> You can ask us to erase or delete all
                      or some of your personal data (e.g., if it is no longer
                      necessary to provide services to you).
                    </li>
                    <li>
                      <b>Change or Correct Data:</b> You can edit some of your
                      personal data through your account. You have full control
                      of your data; we do not have control to update your data.
                    </li>
                    <li>
                      <b>Object to, or Limit or Restrict, Use of Data:</b> You
                      can ask us to stop using all or some of your personal data
                      (e.g., if we have no legal right to keep using it) or to
                      limit our use of it (e.g., if your personal data is
                      inaccurate or unlawfully held).
                    </li>
                    <li>
                      <b>Right to Access and/or Take Your Data:</b> You can
                      ask us for a copy of your personal data and can ask for a
                      copy of personal data you provided in machine- readable
                      form.
                    </li>
                  </ul>
                </p>
                <br />
                <h4>4.3 Account Closure</h4>
                <p>
                  We keep some of your data even after you close your account.
                  We will retain de- personalized information after your account
                  has been closed. Information you have shared with others
                  (e.g., through messages, blogs, dialogues within selected
                  communities) will remain visible after you close your account
                  or delete the information from your own profile, and we do not
                  control data that other Members have copied out of our
                  Services. Groups content and ratings or review content
                  associated with closed accounts will show as guest user as the
                  source. Your profile may continue to be displayed in the
                  services of others (e.g., search engine results) until they
                  refresh their cache.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item lg={12} mb={2}>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            className="AccordionStyle"
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="AccordionSummyStyle"
            >
              <Typography className="heading">
                Other Important Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="pannelBody">
                <h4>5.1 Security</h4>
                <p>
                  We take steps to keep your information secure. You agree to
                  use the security features available through our Services. We
                  implement security safeguards designed to protect your data,
                  such as HTTPS. However, we cannot warrant the security of any
                  information that you send us. There is no guarantee that data
                  may not be accessed, disclosed, altered, or destroyed by
                  breach of any of our physical, technical, or managerial
                  safeguards.
                </p>
                <br />
                <h4>5.2 Global Data Transfers</h4>
                <p>
                  We currently use AWS serves in United States to store and use
                  your data We process data only through secured manner. AWS may
                  use servers both inside and outside of the United States and
                  rely on legally provided mechanisms to lawfully transfer data
                  across borders.
                </p>
                <br />
                <h4>5.3 Direct Marketing and Do Not Track Signals</h4>
                <p>
                  We currently do not share personal data with third parties for
                  their direct marketing purposes without your permission.
                </p>
                <br />
                <h4>5.4 Contact Information</h4>
                <p>
                  You can contact us to resolve any complaints. Our email id is{" "}
                  <br />
                  <a href="contact@matchupit.com">contact@matchupit.com</a>
                </p>
                <br />
                <h4>5.5 United States Law</h4>
                <p>
                  MatchupIT.com is a product of EnSignis Digital which is
                  located in the United States and is subject to the laws of the
                  United States. We will treat your data in accordance with the
                  applicable laws of the United States and this Privacy Policy.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;
