import { searchType } from "../constant/searchType";

const initialState = {
  flow: "MAP",
  isError: false,
  isLoading: false,
};

export const postSearchMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchType.POST_SEARCH_MAP_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.POST_SEARCH_MAP_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.POST_SEARCH_MAP_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postSearchMapCorporateReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchType.POST_SEARCH_MAP_CORPORATE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.POST_SEARCH_MAP_CORPORATE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.POST_SEARCH_MAP_CORPORATE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postUserSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchType.POST_USER__SEARCH_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.POST_USER__SEARCH_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.POST_USER__SEARCH_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postSearchUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchType.POST_SEARCH_USER_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.POST_SEARCH_USER_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.POST_SEARCH_USER_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getPopularSearchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchType.GET_POPULAR_SEARCHES_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.GET_POPULAR_SEARCHES_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.GET_POPULAR_SEARCHES_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getPopularSearchesCorporateReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case searchType.GET_POPULAR_SEARCHES_CORPORATE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.GET_POPULAR_SEARCHES_CORPORATE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.GET_POPULAR_SEARCHES_CORPORATE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getRecentSearchesCorporateReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case searchType.GET_RECENT_SEARCHES_CORPORATE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.GET_RECENT_SEARCHES_CORPORATE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.GET_RECENT_SEARCHES_CORPORATE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIndustryReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchType.GET_INDUSTRY_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.GET_INDUSTRY_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postSearchCorporateProfileReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case searchType.POST_CORPORATE_PROFILE_SEARCH_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case searchType.POST_CORPORATE_PROFILE_SEARCH_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case searchType.POST_CORPORATE_PROFILE_SEARCH_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
