import React from "react";
import Profile from "../component/profile";
import CorporateProfile from "../component/corporate/CorporateProfile";
import { ACCOUNT_TYPE } from "../../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { getUserDetails } from "../../../redux/actions/loginAction";
import * as editProfileAction from "../../../redux/actions/editProfileAction";

const ProfileContainer = () => {
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state?.userDetails?.isLoading);
  React.useEffect(() => {
    dispatch(getUserDetails("dcads"));
    setTimeout(() => {
      dispatch(editProfileAction.putEditProfileClear());
    }, 3000);
  }, []);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div className="overrideProfileContainer">
          {ACCOUNT_TYPE() === "corporate" ? <CorporateProfile /> : <Profile />}
        </div>
      )}
    </>
  );
};

export default ProfileContainer;
