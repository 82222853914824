import { Grid, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import FormDropBox from "../../../library/formDropBox/VideoDropBox";
import { useDispatch, useSelector } from "react-redux";
import * as FileUpload from "../../../redux/actions/UploadVideoAction";
import Buttons from "../../../library/custom/button/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const ProfileVideoForm = ({ video, setVideo, setUnsavedChanges }) => {
  const dispatch = useDispatch();
  const createUploadVideoData = useSelector(
    (state) => state?.createUploadVideoData
  );
  useEffect(() => {
    if (
      createUploadVideoData.isLoading === false &&
      createUploadVideoData?.data?.msg === "File uploaded"
      // &&
      // createUploadVideoData?.data?.type === "video"
    ) {
      setUnsavedChanges(true);
      setVideo(createUploadVideoData?.data?.fileUrl);
    }
  }, [createUploadVideoData]);
  return (
    <>
      <Grid mt={2} lg={11}>
        <FormLabels label={"Upload Profile Video (Maximum size 500mb)"} />
        <FormDropBox
          id={"certificate"}
          handleFileChange={(e) => console.log(e)}
          isLoading={createUploadVideoData?.isLoading}
          handleCancel={() => dispatch(FileUpload.createVideoUpload("cancel"))}
        />
      </Grid>
      {video && (
        <Grid container alignItems={"right"} className="saveBtn">
          <Grid>
            <Buttons
              varientContained={true}
              label="Remove Video"
              onClick={() => setVideo("")}
            />
          </Grid>
        </Grid>
      )}
      { video ? (
        <Grid mt={2} lg={11}>
          {video.substring(video.length - 3).toLowerCase() === "pdf" ? (
            <embed
              height={258}
              scrolling={0}
              className="fit-layout"
              src={video}
              style={{
                width: "100%",
                borderRadius: "15px",
              }}
            ></embed>
          ) : (
            <video controls width="100%" style={{ height: "300px" }}>
              <source src={video} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileVideoForm;
