export const jobPostType = {
  PUT_JOB_POST_PENDING: "[JOBPOST][REQUEST]   PUT_JOB_POST_PENDING",
  PUT_JOB_POST_SUCCESS: "[JOBPOST][RESPONSE]  PUT_JOB_POST_SUCCESS",
  PUT_JOB_POST_FAILURE: "[JOBPOST][RESPONSE]  PUT_JOB_POST_FAILURE",

  UPDATE_JOB_ID_PENDING: "[UPDATEJOBID][REQUEST]   UPDATE_JOB_ID_PENDING",
  UPDATE_JOB_ID_SUCCESS: "[UPDATEJOBID][RESPONSE]  UPDATE_JOB_ID_SUCCESS",
  UPDATE_JOB_ID_FAILURE: "[UPDATEJOBID][RESPONSE]  UPDATE_JOB_ID_FAILURE",

  GET_POSTED_JOB_PENDING: "[POSTED_JOB][REQUEST] GET_POSTED_JOB_PENDING",
  GET_POSTED_JOB_SUCCESS: "[POSTED_JOB][RESPONSE] GET_POSTED_JOB_SUCCESS",
  GET_POSTED_JOB_FAILURE: "[POSTED_JOB][RESPONSE] GET_POSTED_JOB_FAILURE",

  GET_POSTED_JOB_DETAILS_PENDING: "[POSTED_JOB_DETAILS][REQUEST] GET_POSTED_JOB_DETAILS_PENDING",
  GET_POSTED_JOB_DETAILS_SUCCESS: "[POSTED_JOB_DETAILS][RESPONSE] GET_POSTED_JOB_DETAILS_SUCCESS",
  GET_POSTED_JOB_DETAILS_FAILURE: "[POSTED_JOB_DETAILS][RESPONSE] GET_POSTED_JOB_DETAILS_FAILURE",

  CLEAR_JOBS: "[CLEAR_JOBS][REQUEST] Clear Jobs",

  GET_JOB_FIND_PENDING: "[PROFILE][REQUEST] JOB_FIND",
  GET_JOB_FIND_FAILURE: "[PROFILE][RESPONSE] JOB_FIND Failed",
  GET_JOB_FIND_SUCCESS: "[PROFILE][RESPONSE] JOB_FIND Successful",

  GET_JOB_INFO_PENDING: "[PROFILE][REQUEST] JOB_INFO",
  GET_JOB_INFO_FAILURE: "[PROFILE][RESPONSE] JOB_INFO Failed",
  GET_JOB_INFO_SUCCESS: "[PROFILE][RESPONSE] JOB_INFO Successful",

  POST_JOB_APPLY_PENDING: "[PROFILE][REQUEST] JOB_APPLY",
  POST_JOB_APPLY_FAILURE: "[PROFILE][RESPONSE] JOB_APPLY Failed",
  POST_JOB_APPLY_SUCCESS: "[PROFILE][RESPONSE] JOB_APPLY Successful",
  POST_CLEAR_JOB_APPLY: "[PROFILE][RESPONSE] JOB_APPLY Clear",
  
  GET_APPLIED_JOB_PENDING: "[Job][REQUEST] APPLIED_JOB",
  GET_APPLIED_JOB_FAILURE: "[Job][RESPONSE] APPLIED_JOB Failed",
  GET_APPLIED_JOB_SUCCESS: "[Job][RESPONSE] APPLIED_JOB Successful",

  GET_APPLICANT_JOB_DETAILS_PENDING: "[JOB_APLICANT][REQUEST] GET_APPLICANT_JOB_DETAILS_PENDING",
  GET_APPLICANT_JOB_DETAILS_FAILURE: "[JOB_APLICANT][RESPONSE] GET_APPLICANT_JOB_DETAILS_FAILURE",
  GET_APPLICANT_JOB_DETAILS_SUCCESS: "[JOB_APLICANT][RESPONSE] GET_APPLICANT_JOB_DETAILS__SUCCESS",
};

