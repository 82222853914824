import { get, post, put } from "./axiosRequest";
import { USER_ID } from "../constant/constant";
import { getApiUrl } from "../config";
// get industry
export const getAllIndustry = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/industry`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

// get countries
export const getAllCountries = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/countries/country-currency`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
// edit profile
export const editProfile = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/profile/${USER_ID()}`;
  try {
    return await put(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSalary = async ({ params }) => {
  const id = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/${sessionStorage.getItem(
    "id"
  )}/salary`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${id}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const sendInvite = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/send-invite`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

// get Job Type
export const getJobType = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/getJobTypes`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

//Tag User

export const tagUser = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/corporate/tag-user`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

// Update Compansation
export const updateCompansation = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/updateUserJobDetails`;
  try {
    return await put(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

//Get User Details
export const getUserDetails = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${
    getApiUrl().baseUrl
  }/user/getUserJobDetails?userId=${sessionStorage.getItem("id")}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
