import { call, put, takeLatest } from "redux-saga/effects";
import { UpdateJob, applicantDetails, appliedJobs, applyJob, jobFind, jobInfo, postJob ,postedJob ,postedJobDetails } from "../../services/jobPostService";
import { apiError } from "../../utils/helper";
import { jobPostType } from "../constant/jobPostType";
import * as jobPostAction from "../actions/jobPostAction";

export function* JobPostSaga(params) {
  try {
    const { data, status, error } = yield call(postJob, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.putJobPostSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "PUT_JOB_POST_FAILURE" };
    yield put(jobPostAction.putJobPostFailure(data));
  }
}

export function* UpdatePostSaga(params) {
  try {
    const { data, status, error } = yield call(UpdateJob, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.updateJobPostSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "UPDATE_JOB_ID_FAILURE" };
    yield put(jobPostAction.updateJobPostFailure(data));
  }
}

export function* postedJobSaga(params) {
  try {
    const { data, status, error } = yield call(postedJob, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.postedJobsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "GET_POSTED_JOB_FAILURE" };
        yield put(jobPostAction.postedJobsFailure(data));
      }
    }

 export function* postedJobDetailsSaga(params) {
      try {
        const { data, status, error } = yield call(postedJobDetails, params);
        if (!data && status !== 200) throw apiError(error);
        yield put(
          jobPostAction.getPostedJobsDeatilsSuccess({
            data,
          })
        );
      } catch (error) {
        const data =
          error && error.message
            ? error
            : { message: error || "GET_POSTED_JOB_DETAILS_FAILURE" };
            yield put(jobPostAction.getPostedJobsDeatilsFailure(data));
          }
        }


export function* jobFindSaga(params) {
  try {
    const { data, status, error } = yield call(jobFind, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.getJobFindSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "UPDATE_JOB_ID_FAILURE" };
    yield put(jobPostAction.getJobFindFailure(data));
  }
}

export function* jobInfoSaga(params) {
  try {
    const { data, status, error } = yield call(jobInfo, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.getJobInfoSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "UPDATE_JOB_ID_FAILURE" };
    yield put(jobPostAction.getJobInfoFailure(data));
  }
}


export function* postApplyJobSaga(params) {
  try {
    const { data, response, status, error} = yield call(applyJob, params);
    if (!data && status !== 200) throw apiError(response);
    yield put(
      jobPostAction.postApplyJobSuccess({
        // page,
        data,
      })
    );
  }
  catch (error) {
    const data =
      error && error.message ? error : { message: error || "Login Failed" };
    yield put(jobPostAction.postApplyJobFailure(error));
  }
}



export function* appliedJobsSaga(params) {
  try {
    const { data, status, error } = yield call(appliedJobs, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.getAppliedJobsSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "UPDATE_JOB_ID_FAILURE" };
    yield put(jobPostAction.getAppliedJobsFailure(data));
  }
}

export function* applicantDeatailsSaga(params) {
  try {
    const { data, status, error } = yield call(applicantDetails, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jobPostAction.getApplicantDetailsSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "GET_APPLICANT_JOB_DETAILS_FAILURE" };
    yield put(jobPostAction.getApplicantDetailsFailure(data));
  }
}


const myJobPostSaga = [
  takeLatest(jobPostType.PUT_JOB_POST_PENDING, JobPostSaga),
  takeLatest(jobPostType.UPDATE_JOB_ID_PENDING, UpdatePostSaga),
  takeLatest(jobPostType.GET_JOB_FIND_PENDING, jobFindSaga),
  takeLatest(jobPostType.GET_JOB_INFO_PENDING, jobInfoSaga),
  takeLatest(jobPostType.POST_JOB_APPLY_PENDING, postApplyJobSaga),
  takeLatest(jobPostType.GET_APPLIED_JOB_PENDING, appliedJobsSaga),
  takeLatest(jobPostType.GET_POSTED_JOB_PENDING, postedJobSaga),
  takeLatest(jobPostType.GET_POSTED_JOB_DETAILS_PENDING, postedJobDetailsSaga),
  takeLatest(jobPostType.GET_APPLICANT_JOB_DETAILS_PENDING, applicantDeatailsSaga),

];

export default myJobPostSaga;
