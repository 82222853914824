import { Switch } from "@mui/material";

import { styled } from "@mui/material/styles";

const ISwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#1B436E" : "#64E562",
        opacity: 1,
        border: "1px solid #D9D9D9",
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        backgroundColor: "#FFFFFF",
        border: "1px solid #D9D9D9",
        margin: 0,
        marginLeft: 4,
        marginTop: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "#DDE2E5",
    border: "1px solid #DDE2E5",
    margin: 0,
    marginLeft: 5,
    marginTop: 0,
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#FFFFFF",
    border: "1px solid #DDE2E5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    margin: 0,
    marginLeft: 4,
  },
}));

export default ISwitch;
