import React from "react";
import "./Footer.scss";
import matchupITLogo from "../../assets/Landing/matchupITLogo.png";
import fbLogo from "../../assets/Landing/fbLogo.svg";
import linkedinIcon from "../../assets/Landing/linkedinIcon.svg";
import twitterIcon from "../../assets/Landing/twitterIcon.svg";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

function Footer() {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <Grid className="footer-logo">
        <a href="/">
          <img src={matchupITLogo} alt="logo" className="img-fluid" />
        </a>
      </Grid>
      <Grid className="footer-top">
        <Grid className="container">
          <ul className="connectDetail">
            <li className="rightstyleLi">
              <a className="rightstyle" >
                All Rights Reserved © MatchupIT
              </a>
            </li>
            <li>
              <a
                className="aboutUsStyle"
                onClick={() => handleClick("/about-us")}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                className="tcStyle"
                onClick={() => handleClick("/terms-conditions")}
              >
                Terms & Conditions
              </a>
            </li>
            <li>
              <a
                className="privacyStyle"
                onClick={() => handleClick("/Privacy-policy")}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid className="footer-bottom">
        <Grid className="container">
          <Grid className="float-left">
            <ul className="footer_social">
              <li className="SoIcon">
                <a
                  href="https://www.facebook.com/matchupIT/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fbLogo} className="fb-logo" alt="Facebook logo" />
                </a>
              </li>
              <li className="SoIcon">
                <a
                  href="https://www.linkedin.com/company/matchupit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedinIcon}
                    className="linkedin-logo"
                    alt="LinkedIn logo"
                  />
                </a>
              </li>
              <li className="SoIcon">
                <a
                  href="https://twitter.com/MatchupIT"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={twitterIcon}
                    className="Twitter-logo"
                    alt="Twitter logo"
                  />
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
