import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
//   import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data }) => {
//   const labels = [
//     "1",
//     "2",
//     "3",
//     "4",
//     "5",
//     "6",
//     "7",
//     "8",
//     "9",
//     "10",
//     "11",
//     "12",
//   ];
  const [labels, setlabels] = useState([]);
  const [loginData, setloginData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  useEffect(() => {
    mapData();
  }, [data]);

  // const mappData = mapData()
  function mapData() {
    let loginArr = [];
    let searchArr = [];
    let messageArr = [];
    let communityArr = [];
    let labelsArr = [];
    data.map((i,j) => {
      loginArr.push(i[1]);
      searchArr.push(i[2]);
      messageArr.push(i[3]);
      communityArr.push(i[4]);
      labelsArr.push(`${j+1}`)
    });
    setloginData(loginArr);
    setSearchData(searchArr);
    setMessageData(messageArr);
    setCommunityData(communityArr);
    setlabels(labelsArr)
  }

  const options = {
    responsive: true,
    scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of hits',
            font: {
              size: 14,
              weight: 'bold',
            },
          },
        },
      },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const values = {
    labels,
    datasets: [
      {
        label: "Login",
        data: loginData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Search",
        data: searchData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Community",
        data: communityData,
        borderColor: "blue",
        backgroundColor: "blue",
      },
      {
        label: "Message",
        data: messageData,
        borderColor: "red",
        backgroundColor: "red",
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={values} />
    </div>
  );
};

export default LineChart;
