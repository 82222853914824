import { Grid } from "@mui/material";
import React from "react";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import MentorUserCard from "../../../library/custom/mentorUserCard/MentorUserCard";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import { MentorShipRequestCard } from "../../../library/custom/mentorshipRequestCard/MentorShipRequestCard";
import Buttons from "../../../library/custom/button/Button";
import "./ManageMentorship.scss";
import { useNavigate } from "react-router-dom";
import { MENTORSHIP_CALENDAR, MESSAGES } from "../../../constant/routeContant";
import { useEffect } from "react";
import { useState } from "react";
import SessionUserCard from "./SessionUserCard";
import NoDataDisplayCard from "../../../library/common/NoDataDisplayCard";
import { useSelector } from "react-redux";

const ManageMentorship = ({
  myMenteesData,
  onAccept,
  onReject,
  upcomingSessionsData,
  userName,
  setjoinMeeting,
  onAcceptSession,
  onRejectSession,
  onCancelSession,
}) => {
  const navigate = useNavigate();
  const [notAcceptedMentees, setnotAcceptedMentees] = useState([]);
  const [acceptedMentees, setAcceptedMentees] = useState([]);
  const [sessionRequests, setsessionRequests] = useState([]);
  const [myMentees, setmyMentees] = useState([]);
  const [searchedMyMetee, setsearchedMyMetee] = useState([]);
  const [searchbarInput, setsearchbarInput] = useState("");
  useEffect(() => {
    if (!myMenteesData.isLoading && myMenteesData.status === "SUCCESS") {
      let notAcceptedMentees = [];
      let acceptedMentees = [];
      let sessionRequestsList = [];
      notAcceptedMentees = myMenteesData?.data?.response?.rows?.filter(
        (i) => i.action === "Pending"
      );
      acceptedMentees = myMenteesData?.data?.response?.rows?.filter(
        (i) =>
          i.action === "Accepted" ||
          i.action === "Session Request Accepted" ||
          i.action === "Session Request Pending"
      );
      sessionRequestsList = myMenteesData?.data?.response?.rows?.filter(
        (i) => i.action === "Accepted" || i.action === "Session Request Pending"
      );
      setnotAcceptedMentees(notAcceptedMentees);
      setAcceptedMentees(acceptedMentees);
      setsessionRequests(sessionRequestsList);
    }
  }, [myMenteesData]);
  useEffect(() => {
    let arr = [];
    acceptedMentees.forEach((i) => {
      const exists = arr.some((element) => element.menteeId === i.menteeId);
      if (!exists) {
        arr.push(i);
      }
    });
    setmyMentees(arr);
  }, [acceptedMentees]);
  useEffect(() => {
    let arr = [];
    myMentees.forEach((i) => {
      const exists = myMentees.some(
        (element) =>
          element.menteeFelementrstName == searchbarInput ||
          element.menteelastelementame ||
          searchbarInput
      );
      console.log(exists);
      if (exists) {
        arr.push(i);
      }
    });
    setsearchedMyMetee(arr);
  }, [searchbarInput]);
  const timeZone = useSelector(
    (state) =>
      state?.userDetails?.data?.response?.profile?.personal_details?.timezone
  );
  const timeZoneLoading = useSelector((state) => state?.userDetails?.isLoading);
  return (
    <div className="overrideManageMentorship">
      <Grid container justifyContent={"space-between"}>
        <h1 className="pageName">Manage mentorship</h1>
        <div className="calendorBtnGrid">
          <Buttons
            variantCalendorBtn={true}
            label={"VIEW CALENDAR"}
            onClick={() => navigate(MENTORSHIP_CALENDAR)}
          />
        </div>
      </Grid>
      <Grid container>
        <FormLabels label={`Current Mentees: ${myMentees.length}`} />
      </Grid>

      <Grid gap={1} container mt={2} item lg={12} xs={12}>
        {myMentees?.length > 0 ? (
          myMentees?.map((i) => {
            return (
              <MentorUserCard
                name={`${i.menteeFirstName} ${i.menteelastName}`}
                profilePic={i.menteeProfilePic}
                designation={i.menteeDesignation}
                companyName={i.menteeCompany}
                state={""}
                city={""}
                country={""}
                location={i.menteeLocation ?? ""}
                showSkills={false}
                // skills={i?.mentorship?.subjects?.join(", ") ?? ""}
                label="Message"
                onClick={() => {
                  navigate(MESSAGES, {
                    state: { id: i.menteeId },
                  });
                }}
              />
            );
          })
        ) : (
          <NoDataDisplayCard />
        )}
      </Grid>

      {/* <Grid container mt={4}>
        <FormLabels label={"Search Mentees"} />
      </Grid>
      <Grid container mt={1} item lg={8} xs={12}>
        <SearchBar
          placeholder={"Search"}
          searchbarInput={searchbarInput}
          handleSearch={(e) => {
            setsearchbarInput(e);
          }}
        />
      </Grid> */}
      <Grid gap={1} container mt={2} item lg={12} xs={12}>
        {searchedMyMetee.length > 0 ? (
          searchedMyMetee?.map((i) => (
            <MentorUserCard
              name={`${i.menteeFirstName} ${i.menteelastName}`}
              profilePic={i.menteeProfilePic}
              label="Message"
              onClick={() => {}}
            />
          ))
        ) : (
          <NoDataDisplayCard />
        )}
      </Grid>

      <Grid container mt={4}>
        <FormLabels label={"Mentorship Requests"} />
      </Grid>
      <Grid mt={4} container item lg={12} md={12} sm={12} xs={12}>
        <Grid container gap={2}>
          {notAcceptedMentees?.length > 0 ? (
            notAcceptedMentees.map((i) => (
              <MentorShipRequestCard
                acceptLabel="Accept"
                isAccept={true}
                cancelLabel="Cancel"
                name={`${i.menteeFirstName} ${i.menteelastName}`}
                profilePic={i.menteeProfilePic}
                skills={i.skills}
                onAccept={() => onAccept(i.uuid)}
                onReject={() => onReject(i.uuid)}
              />
            ))
          ) : (
            <NoDataDisplayCard />
          )}
        </Grid>
      </Grid>
      <Grid mt={4} container item lg={8} xs={12}>
        <FormLabels label={"Manage Sessions"} />
      </Grid>
      <Grid mt={2} container item lg={12} md={12} sm={12} xs={12}>
        <Grid container gap={2}>
          {sessionRequests?.length > 0 ? (
            sessionRequests.map((i) =>
              i.session_start_time === null ? (
                ""
              ) : (
                <MentorShipRequestCard
                  name={`${i.menteeFirstName} ${i.menteelastName}`}
                  profilePic={i.menteeProfilePic}
                  skills={i.skills}
                  time={`${i.session_date} ${i.session_start_time}-${i.session_end_time}`}
                  isAccept={false}
                  onAccept={() => {
                    onAcceptSession(i.uuid);
                  }}
                  onReject={() => onRejectSession(i.uuid)}
                  isCancel={true}
                  isBook={false}
                  isAcceptMentor={true}
                  bookLabel={"Book your Session"}
                  timeZone={timeZone}
                />
              )
            )
          ) : (
            <NoDataDisplayCard />
          )}
        </Grid>
      </Grid>

      <Grid container mt={4}>
        <FormLabels label={"Upcoming Mentorship sessions"} />
      </Grid>{timeZoneLoading ? "" :
      <Grid container item gap={1} mt={2}>
        {upcomingSessionsData?.length > 0  ? (
          upcomingSessionsData.map((i) =>
            i.menteeFirstName === userName ? (
              ""
            ) : (
              <SessionUserCard
                name={`${i.menteeFirstName} ${i.menteelastName}`}
                profilePic={i.menteeProfilePic}
                designation={i.menteeDesignation}
                date={i.session_date}
                time={i.session_start_time}
                endTime={i.session_end_time}
                onCancel={() => {
                  onRejectSession(i.uuid);
                }}
                joinMeeting={() => {
                  setjoinMeeting(i.uuid);
                }}
                timeZone={timeZone}
              />
            )
          )
        ) : (
          <NoDataDisplayCard />
        )}
      </Grid>}
    </div>
  );
};

export default ManageMentorship;
