import React from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../library/custom/textArea/TextArea";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../library/custom/textBox/InputField";
import { BOARD_TYPE, E_YEAR, MONTHS, S_YEAR } from "../../../constant/constant";
import RadioButton from "../../../library/custom/radioButton/RadioButton";

const BoardExperianceForm = ({
  singleBoard,
  handleBoardFormChange,
  setUnsavedChanges,
}) => {
  let years = [];
  for (let i = E_YEAR; i >= S_YEAR; i--) {
    years.push(i);
  }

  let endYears = [];

  for (let i = E_YEAR + 20; i >= singleBoard.boardStartY; i--) {
    endYears.push(i);
  }
  return (
    <>
      <Grid className="workExpirience">
        <Grid item container lg={11} xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="Board Name"
            id="boardName"
            isRequired={true}
            placeholder="Enter here"
            value={singleBoard?.boardName}
            onChange={(e) => {
              handleBoardFormChange(e, singleBoard, "boardName");
              setUnsavedChanges(true);
            }}
            isError={singleBoard.isboardName}
            errorMsg={
              singleBoard.isboardName ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid mt={2} item container lg={11} xs={12} sm={12} md={12}>
          <FormLabels isRequired={true} label="Board Type" />

          <Grid
            className="fName"
            item
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <SelectMenu
              disabledDefault={false}
              // label="Start Year"
              id={"boardType"}
              value={singleBoard?.boardType}
              name={"boardType"}
              onchangehandler={(e) => {
                handleBoardFormChange(e, singleBoard, "select");
                setUnsavedChanges(true);
              }}
              listData={BOARD_TYPE}
              countries={true}
              isError={singleBoard.isboardType}
              errorMsg={
                singleBoard.isboardType
                  ? "Please enter this required field"
                  : ""
              }
            />
          </Grid>
        </Grid>

        <Grid textAlign={"left"} item lg={11} gap={"10px"} xs={12}>
          <Grid container item lg={6} mt={1.5}>
            <FormLabels label={"Currently a member here?"} isRequired={true} />
          </Grid>

          <Grid container>
            <Grid item lg={2}>
              <RadioButton
                value={"No"}
                id={"stillMember"}
                onChange={(e) => {
                  handleBoardFormChange(e, singleBoard, "stillMember");
                  setUnsavedChanges(true);
                }}
                label="No"
                checked={singleBoard.stillMember === "No"}
              />
            </Grid>
            <Grid item lg={2}>
              <RadioButton
                value={"Yes"}
                onChange={(e) => {
                  handleBoardFormChange(e, singleBoard, "stillMember");
                  setUnsavedChanges(true);
                }}
                label="Yes"
                id={"stillMember"}
                checked={singleBoard?.stillMember === "Yes"}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid textAlign={"left"} lg={11} mt={2}>
          <FormLabels isRequired={true} label="Experiance" />

          <Grid
            className="fName"
            item
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={true}
                label="Start Month"
                id={"boardStartM"}
                value={singleBoard?.boardStartM}
                name={"boardStartM"}
                onchangehandler={(e) => {
                  handleBoardFormChange(e, singleBoard, "select");
                  setUnsavedChanges(true);
                }}
                listData={MONTHS}
                countries={true}
                isError={singleBoard.isboardStartM}
                errorMsg={
                  singleBoard.isboardStartM
                    ? "Please enter this required field"
                    : ""
                }
              />
            </Grid>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="Start Year"
                id={"boardStartY"}
                value={singleBoard?.boardStartY}
                name={"boardStartY"}
                onchangehandler={(e) => {
                  handleBoardFormChange(e, singleBoard, "select");
                  setUnsavedChanges(true);
                }}
                listData={years}
                countries={true}
                isError={singleBoard.isboardStartY}
                errorMsg={
                  singleBoard.isboardStartY
                    ? "Please enter this required field"
                    : ""
                }
              />
            </Grid>
          </Grid>

          <Grid
            className="fName"
            mt={2}
            item
            container
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="End Month"
                id={"boardEndM"}
                name={"boardEndM"}
                value={singleBoard?.boardEndM}
                onchangehandler={(e) => {
                  handleBoardFormChange(e, singleBoard, "select");
                  setUnsavedChanges(true);
                }}
                listData={MONTHS}
                countries={true}
                isError={singleBoard.isboardEndM}
                errorMsg={
                  singleBoard.isboardEndM
                    ? "Please enter this required field"
                    : ""
                }
              />
            </Grid>
            <Grid item lg={5.8} xs={12}>
              <SelectMenu
                disabledDefault={false}
                label="End Year"
                id={"boardEndY"}
                name={"boardEndY"}
                value={singleBoard?.boardEndY}
                onchangehandler={(e) => {
                  handleBoardFormChange(e, singleBoard, "select");
                }}
                listData={endYears}
                countries={true}
                isError={singleBoard.isboardEndY}
                errorMsg={
                  singleBoard.isboardEndY
                    ? "Please enter this required field"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BoardExperianceForm;
