import { Grid } from "@mui/material";
import React from "react";
import "../../../search/component/ViewProfile.scss";
import defaultLoginImage from "../../../../assets/defaultLoginImage.svg";
import facebookColorIcon from "../../../../assets/facebookColorIcon.svg";
import pintrestColorIcon from "../../../../assets/pintrestColorIcon.svg";
import linkedInColorIcon from "../../../../assets/linkedInColorIcon.svg";
import githubColorIcon from "../../../../assets/githubColorIcon.svg";
import twitterColorIcon from "../../../../assets/twitterColorIcon.svg";
import instaColorIcon from "../../../../assets/instaColorIcon.svg";
import CallIcon from "../../../../assets/callIcon.svg";
import MailIcon from "../../../../assets/mailIcon.svg";
import editBtnIcon from "../../../../assets/editBtnIcon.svg";
import BlackLocationIcon from "../../../../assets/blackLocationIcon.svg";
import Buttons from "../../../../library/custom/button/Button";
import ISwitch from "../../../../library/custom/switch/Switch";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as editProfileAction from "../../../../redux/actions/editProfileAction";

const CorporateProfileHeader = ({ searchUserData, NavigateProfileFrom }) => {
  const dispatch = useDispatch();
  const handleClickEditProfile = (event) => {
    NavigateProfileFrom();
  };

  const [checked, setChecked] = useState(
    searchUserData?.basicDetails?.currently_hiring
  );
  const handleChange = (event) => {
    setChecked(event.target.checked);
    // setIsStatusId(1);

    dispatch(
      editProfileAction.putEditProfile({
        basic_details: {
          currently_hiring: event.target.checked,
        },
      })
    );
  };
  return (
    <div>
      <Grid container className="profileHeader" p={2}>
        <Grid container item lg={7.5} className="leftDiv" alignItems={"center"}>
          <Grid container item className="imageAndContactDetails">
            <Grid item className="img">
              <img
                src={
                  searchUserData?.basicDetails?.logo === null ||
                  searchUserData?.basicDetails?.logo === "" ||
                  searchUserData?.basicDetails?.logo === undefined
                    ? defaultLoginImage
                    : searchUserData?.basicDetails?.logo
                }
                alt=""
                height="200px"
                width="200px"
              />
            </Grid>
            <Grid ml={2} item className="contactDetails" textAlign={"left"}>
              <Grid mt={4}>
                <Grid container gap={2} mt={0.5}>
                  <p className="font700 font24">
                    {searchUserData?.basicDetails?.name === null ||
                    searchUserData?.basicDetails?.name === "" ||
                    searchUserData?.basicDetails?.name === undefined
                      ? "MatchupIT User"
                      : searchUserData?.basicDetails?.name}
                  </p>
                </Grid>

                <Grid container gap={2} mt={0.5}>
                  <p className="font400 font16 lineHeight0">
                    {searchUserData?.basicDetails?.website === null ||
                    searchUserData?.basicDetails?.website === "" ||
                    searchUserData?.basicDetails?.website === undefined
                      ? "NA"
                      : searchUserData?.basicDetails?.website}
                  </p>
                </Grid>
              </Grid>

              <Grid mt={2}>
                <Grid container gap={2} mt={0.5}>
                  <img className="Icons" src={CallIcon} alt="call icon" />
                  <p className="font400 font16 lineHeight20">
                    {searchUserData?.basicDetails?.telephone === "" ||
                    searchUserData?.basicDetails?.telephone === null ||
                    searchUserData?.basicDetails?.telephone === undefined
                      ? "NA"
                      : `${searchUserData?.basicDetails?.dial_code} ${searchUserData?.basicDetails?.telephone}`}
                  </p>
                </Grid>
                <Grid container gap={2} mt={0.5}>
                  <img className="Icons" src={MailIcon} alt="mail icon" />
                  <p className="font400 font16 lineHeight20">
                    {searchUserData?.basicDetails?.email === "" ||
                    searchUserData?.basicDetails?.email === null ||
                    searchUserData?.basicDetails?.email === undefined
                      ? "NA"
                      : searchUserData?.basicDetails?.email}
                  </p>
                </Grid>
                <Grid container gap={2} mt={0.5}>
                  <img
                    className="Icons"
                    src={BlackLocationIcon}
                    alt="mail icon"
                  />
                  <p className="font400 font16 lineHeight20">
                    {searchUserData?.basicDetails?.city === "" ||
                    searchUserData?.basicDetails?.city === null ||
                    searchUserData?.basicDetails?.city === undefined
                      ? "NA"
                      : `${searchUserData?.basicDetails.city}, ${searchUserData?.basicDetails.country_name}`}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="socialIconsDivCorp" gap={2} mt={4}>
            {searchUserData?.profile?.social_links?.instagram?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://instagram.com/${searchUserData?.profile?.social_links?.instagram}`}
                target="_blank"
              >
                <img
                  src={instaColorIcon}
                  className="instaLogo socialIcons"
                  alt="Instagram Logo"
                />
              </a>
            )}
            {searchUserData?.profile?.social_links?.facebook?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://facebook.com/${searchUserData?.profile?.social_links?.facebook}`}
                target="_blank"
              >
                <img
                  src={facebookColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
              </a>
            )}
            {searchUserData?.profile?.social_links?.linkedin?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://linkedin.com/in/${searchUserData?.profile?.social_links?.linkedin}`}
                target="_blank"
              >
                <img
                  src={linkedInColorIcon}
                  className="facebookLogo socialIcons"
                  alt="linkedin Logo"
                />
              </a>
            )}

            {searchUserData?.profile?.social_links?.twitter?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://twitter.com/${searchUserData?.profile?.social_links?.twitter}`}
                target="_blank"
              >
                <img
                  src={twitterColorIcon}
                  style={{ height: "42px", width: "42px", margin: "-5px" }}
                  alt="Facebook Logo"
                />
              </a>
            )}

            {searchUserData?.profile?.social_links?.pinterest?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://pinterest.com/${searchUserData?.profile?.social_links?.pinterest}`}
                target="_blank"
              >
                <img
                  src={pintrestColorIcon}
                  alt="Facebook Logo"
                  style={{ height: "45px", width: "45px", margin: "-7px" }}
                />
              </a>
            )}

            {searchUserData?.profile?.social_links?.github?.length > 0 && (
              <a
                rel="noopener noreferrer"
                href={`https://github.com/${searchUserData?.profile?.social_links?.github}`}
                target="_blank"
              >
                <img
                  src={githubColorIcon}
                  className="facebookLogo socialIcons"
                  alt="Facebook Logo"
                />
              </a>
            )}
          </Grid>
        </Grid>

        {/* center Div for sliders and experiance */}

        <Grid
          container
          item
          ml={1}
          lg={2}
          className="centerDiv"
          textAlign={"left"}
          alignItems={"center"}
        >
          <Grid>
            <Grid item>
              <p className="font700 font16">No. of Employees</p>
              <p className="font400 font16">
                {searchUserData?.basicDetails.employee_count === "" ||
                searchUserData?.basicDetails.employee_count === null ||
                searchUserData?.basicDetails.employee_count === undefined
                  ? "NA"
                  : searchUserData?.basicDetails.employee_count}
              </p>
            </Grid>
            <Grid item mt={0}>
              <p className="font700 font16">Company Type</p>
              <p className="font400 font16">
                {searchUserData?.basicDetails.type === "" ||
                searchUserData?.basicDetails.type === null ||
                searchUserData?.basicDetails.type === undefined
                  ? "NA"
                  : `${searchUserData?.basicDetails.type} Organisation`}
              </p>
            </Grid>
          </Grid>
        </Grid>

        {/* rightDiv for create edit button */}
        <Grid
          container
          item
          lg={2.3}
          className="centerDiv"
          alignItems={"center"}
        >
          <Grid
            className="profileCompletionSlider"
            container
            item
            lg={12}
            justifyContent={"right"}
          >
            <Grid item>
              <Buttons
                varientText={true}
                label={"EDIT PROFILE"}
                onClick={(e) => handleClickEditProfile(e)}
                icon={editBtnIcon}
              />
            </Grid>

            <Grid container item justifyContent={"right"} gap={"10px"}>
              <ISwitch
                checked={checked}
                onChange={handleChange}
                name="status"
              />
              <p className="switchLabel">Currently Hiring</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CorporateProfileHeader;
