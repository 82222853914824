import { Grid } from "@mui/material";
import React from "react";
import GridTable from "../../library/custom/gridTable/GridTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustry } from "../../redux/actions/searchAction";
import { useState } from "react";
import "./Managing.scss";
import Loader from "../../library/common/Loader";
import DeleteIcon from "@mui/icons-material/Delete";

const IndustrySkills = ({ onSubmit }) => {
  const industryData = useSelector((state) => state?.industry?.data?.response);
  const [state, setState] = useState({
    industryOptions: [],
    skillsOptions: [],
    initialIndustry: "",
    initialSkill: "",
    addIndustry: false,
    addSkill: false,
    updatedIndustry: "",
    updatedSkill: "",
    newIndustry: "",
    newSkill: "",
    deleteIconIndustry: false,
    deleteIconSkill: false,
  });
  useEffect(() => {
    setState({
      ...state,
      industryOptions: industryData?.industries,
      skillsOptions: industryData?.skills,
    });
  }, [industryData]);
  const deleteIndustry = (industry) => {
    var delItem = industry;
    var newOptions = [...state.industryOptions];
    var index = newOptions.indexOf(delItem);
    if (index !== -1) {
      newOptions.splice(index, 1);
      setState({
        ...state,
        industryOptions: newOptions,
        deleteIconIndustry: false,
      });
    }
  };

  const deleteSkill = (skill) => {
    var delItem = skill.i;
    var newOptions = [...state.skillsOptions];
    var index = newOptions.indexOf(delItem);
    if (index !== -1) {
      newOptions.splice(index, 1);
      setState({
        ...state,
        skillsOptions: newOptions,
        deleteIconSkill: false,
      });
    }
  };
  const onFocusIndustry = (e) => {
    setState({
      ...state,
      initialIndustry: e.target.getAttribute("name"),
      deleteIconIndustry: true,
      updatedIndustry: e.target.value,
    });
  };
  const handleChangeIndustry = (e) => {
    setState({
      ...state,
      updatedIndustry: e.target.value,
    });
  };
  const handleChangeSkills = (e) => {
    setState({
      ...state,
      updatedSkill: e.target.value,
    });
  };
  const onBlurIndustry = (e) => {
    // var { initialIndustry, updatedIndustry } = this.state;
    e.preventDefault();
    if (state.updatedIndustry !== "") {
      var newOptions = [...state.industryOptions];
      var updatedIndex = newOptions.indexOf(state.updatedIndustry);
      var initIndex = newOptions.indexOf(state.initialIndustry);
      if (updatedIndex === -1 || updatedIndex === initIndex) {
        newOptions[initIndex] = state.updatedIndustry;
        // this.setState({
        //   industryOptions: newOptions,
        //   deleteIconIndustry: false,
        //   updatedIndustry: "",
        // });
        // setindustryOptions(newOptions)
        // setdeleteIconIndustry(false)
        // setupdatedIndustry(false)
        setState({
          ...state,
          industryOptions: newOptions,
          deleteIconIndustry: false,
          updatedIndustry: "",
        });
      } else {
        // toast.error('Industry Already Exists')
        // this.setState({ industryOptions: newOptions, deleteIconIndustry: false, updatedIndustry: initialIndustry })
      }
    } else {
      // toast.error('Input cannot be Empty')
      // this.setState({ deleteIconIndustry: false })
    }
  };
  const handleChangeAdd = (e) => {
    var { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  const onBlurAddIndustry = (e) => {
    var newElement = e.target.value;
    if (newElement !== "") {
      var index = state.industryOptions.indexOf(newElement);
      if (index === -1) {
        // this.setState({
        //   industryOptions: [newElement, ...industryOptions],
        //   addIndustry: false,
        //   newIndustry: "",
        // });
        setState({
          ...state,
          industryOptions: [newElement, ...state.industryOptions],
          addIndustry: false,
          newIndustry: "",
        });
      } else {
        // toast.error('Industry already exists')
        // this.setState({ newIndustry: '' })
      }
    } else {
      // toast.error('Input cannot be empty')
    }
  };
  const onSubmitIndustries = () => {
    let bodyIndustries = {
      schema: "industries",
      payload: state.industryOptions,
    };
    onSubmit(bodyIndustries);
    // postCall(ADMIN_MANAGE_UPDATE, bodyIndustries, { sfn: this.successIndustryUpdate, efn: this.errorUpdate })
  };
  const onSubmitSkill = () => {
    let bodySkills = {
      schema: "skills",
      payload: this.state.skillOptions,
    };
    // postCall(ADMIN_MANAGE_UPDATE, bodySkills, { sfn: this.successSkillsUpdate, efn: this.errorUpdate })
  };
  //   const [indList, setIndList] = useState([])
  //   const [indList, setIndList] = useState([])
  //   const [showLoader, setshowLoader] = useState(false)
  // let industriesList = []
  //   useEffect(() => {
  //     // setshowLoader(true)
  //     // setIndList(state.industryOptions)
  //     // setshowLoader(false)
  //     industriesList=state.industryOptions
  //   }, [state.industryOptions])

  return (
    <Grid container className="overrideManaging">
      {/* {showLoader ? <Loader />: */}
      <Grid container gap={"50px"}>
        <Grid item mt={2} lg={5}>
          <div className="matchup-secs matchup-secs-update">
            <h3>Industries List</h3>
            <div className="table-overflow">
              <button
                className="btn-update"
                style={{ float: "left" }}
                onClick={() => {
                  // let arr = state.industryOptions;
                  // arr.splice(0,0,"");
                  setState({
                    ...state,
                    //   industryOptions: arr,
                    addIndustry: true,
                  });
                }}
              >
                <span className="fa fa-plus">+</span>
              </button>
              <button
                className="btn-update-submit"
                onClick={() => onSubmitIndustries()}
              >
                Save
              </button>
              <table
                style={{ border: "1px solid black" }}
                className="table table-bordered"
              >
                <tbody>
                  {state.addIndustry && (
                    <tr style={{ border: "1px solid black" }}>
                      <td style={{ border: "1px solid blue" }}>
                        <input
                          className="general-input-noborder"
                          value={state.newIndustry}
                          name="newIndustry"
                          autoFocus
                          onBlur={(e) => onBlurAddIndustry(e)}
                          onChange={(e) => handleChangeAdd(e)}
                        />
                        <button
                          className="btn-add-delete"
                          onClick={() =>
                            setState({
                              ...state,
                              addIndustry: true,
                            })
                          }
                        >
                          <span className="fa fa-trash-o">
                            <DeleteIcon />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )}
                  {state.industryOptions?.map((i, index) => (
                    <tr key={index} className="inputfocus">
                      <td>
                        <input
                          className="general-input-noborder"
                          defaultValue={i}
                          name={i}
                          onFocus={(e) => onFocusIndustry(e)}
                          onBlur={(e) => onBlurIndustry(e)}
                          onChange={(e) => handleChangeIndustry(e)}
                        />
                        <button
                          className="btn-delete"
                          onClick={() => deleteIndustry(i)}
                        >
                          <span className="fa fa-trash-o"></span>
                          <DeleteIcon fontSize="small" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
        <Grid item mt={2} lg={5}>
          <div className="matchup-secs matchup-secs-update">
            <h3>Skills List List</h3>
            <div className="table-overflow">
              <button
                className="btn-update"
                style={{ float: "left" }}
                onClick={() => {
                  let arr = state.industryOptions;
                  arr.push("");

                  setState({
                    ...state,
                    industryOptions: arr,
                    //   addIndustry: true,
                  });
                }}
              >
                <span className="fa fa-plus">+</span>
              </button>
              <button
                className="btn-update-submit"
                onClick={() => onSubmitSkill()}
              >
                Save
              </button>
              <table
                style={{ border: "1px solid black" }}
                className="table table-bordered"
              >
                <tbody>
                  {/* {state.addIndustry && (
                  <tr style={{ border: "1px solid black" }}>
                    <td style={{ border: "1px solid blue" }}>
                      <input
                        className="general-input-noborder"
                        value={state.newIndustry}
                        name="newIndustry"
                        autoFocus
                        // onBlur={(e) => onBlurAddIndustry(e)}
                        onChange={(e) => handleChangeAdd(e)}
                      />
                      <button
                        className="btn-add-delete"
                        onClick={() =>
                          setState({
                            ...state,
                            addIndustry: true,
                          })
                        }
                      >
                        <span className="fa fa-trash-o"><DeleteIcon/></span>
                      </button>
                    </td>
                  </tr>
                )} */}
                  {state?.skillsOptions?.map((i, index) => (
                    <tr key={index} className="inputfocus">
                      <td>
                        <input
                          className="general-input-noborder"
                          defaultValue={i}
                          name={i}
                          // onFocus={(e) => onFocusIndustry(e)}
                          // onBlur={(e) => onBlurIndustry(e)}
                          onChange={(e) => handleChangeSkills(e)}
                        />
                        <button
                          className="btn-delete"
                          onClick={() => deleteSkill({ i })}
                        >
                          <span className="fa fa-trash-o"></span>
                          <DeleteIcon fontSize="small" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* } */}
    </Grid>
  );
};

export default IndustrySkills;
