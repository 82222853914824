import { get, post, put } from "./axiosRequest";
import { getApiUrl } from "../config";

export const login = async ({ data }) => {
  const url = `${getApiUrl().baseUrl}/user/login`;
  try {
    return await post(url, data);
  } catch (err) {
    return err;
  }
};

export const loginAdmin = async ({ data }) => {
  const url = `${getApiUrl().baseUrl}/admin/admin-login`;
  try {
    return await post(url, data);
  } catch (err) {
    return err;
  }
};

export const getUserProfile = async ({ params }) => {
  const id = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/profile/${sessionStorage.getItem(
    "id"
  )}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${id}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getForgotPasswordOTP = async ({ email }) => {
  const id = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/forgot-password/otp?email=${email}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${id}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getRecentSearches = async ({ params }) => {
  const id = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/search/recent-ind`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${id}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const putResetPassword = async ({ data }) => {
  const id = sessionStorage.getItem("token");
  const url = `${getApiUrl().baseUrl}/user/forgot-password`;
  try {
    return await put(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${id}`,
      },
    });
  } catch (err) {
    return err;
  }
};
