import { conversationType } from "../constant/conversationType";

const initialState = {
  flow: "Conversation",
  isError: false,
  isLoading: false,
};

export const getAllConversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case conversationType.GET_CONVERSATION_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case conversationType.GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case conversationType.GET_CONVERSATION_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAllMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case conversationType.GET_MESSAGE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case conversationType.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case conversationType.GET_MESSAGE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUnreadMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case conversationType.GET_UNREAD_MESSAGE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case conversationType.GET_UNREAD_MESSAGE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case conversationType.GET_UNREAD_MESSAGE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postCreateConversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case conversationType.POST_CREATE_CONVERSATION_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case conversationType.POST_CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case conversationType.POST_CREATE_CONVERSATION_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postSendMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case conversationType.POST_ADD_MESSAGE_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case conversationType.POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case conversationType.POST_ADD_MESSAGE_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case conversationType.GET_USER_SEARCH_PENDING:
      return {
        ...state,
        status: "PENDING",
        isError: false,
        isLoading: true,
      };
    case conversationType.GET_USER_SEARCH_SUCCESS:
      return {
        ...state,
        status: "SUCCESS",
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case conversationType.GET_USER_SEARCH_FAILURE:
      return {
        ...state,
        status: "FAILURE",
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
