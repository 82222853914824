import { combineReducers } from "redux";
import {
  getCommunityReducer,
  getMyCommunityReducer,
  getTopCommunityReducer,
  postJoinCommunityReducer,
  postSearchCommunityReducer,
  getPostsCommunityWiseReducer,
  addPostCommunityReducer,
  addPostCommunityCommentsReducer,
  postLeaveCommunityReducer,
  postAddReactToPostReducer,
  postAddReactToPostCommentReducer,
  postDeletePostCommentReducer,
  getCommunityAdminReducer,
  postToggleCommunityReducer,
  postCreateCommunityReducer,
  postEditCommunityReducer,
  resumeBuilderReducer,
} from "./communityReducer";
import {
  getIndustriesReducer,
  getCountriesReducer,
  putEditProfileReducer,
  getSalaryReducer,
  postSendInviteReducer,
  getJobTypeReducer,
  postTagUserReducer,
  getUserDetailsReducer,
  updateCompansationReducer,
} from "./editProfileReducer";
import {
  getForgotPasswordOTPReducer,
  getRecentSearchesReducer,
  loginAdminReducer,
  // getRecentSearchesReducer,
  loginReducer,
  putResetPasswordReducer,
  userDetailsReducer,
} from "./loginReducer";
// import { getUsersReducers } from "./userReducer";
import {
  getAllConversationReducer,
  getAllMessageReducer,
  getUnreadMessageReducer,
  postCreateConversationReducer,
  postSendMessageReducer,
  getUserSearchReducer,
} from "./conversationReducer";
import {
  checkUserExistReducer,
  otpSignupReducer,
  signupReducer,
  otpVerifySignupReducer,
  signupWithGoogleReducer,
} from "./SignupReducer ";
// import { postSearchMapReducer, postSearchUserReducer } from "./searchReducer";
import { createUploadFilesReducer } from "./UploadFilesReducer";
import {
  getIndustryReducer,
  getPopularSearchesCorporateReducer,
  getPopularSearchesReducer,
  getRecentSearchesCorporateReducer,
  postSearchCorporateProfileReducer,
  postSearchMapCorporateReducer,
  postSearchMapReducer,
  postSearchUserReducer,
  postUserSearchReducer,
} from "./searchReducer";
import {
  getRecoveryEmailVerifyReducer,
  deactivateAccountReducer,
  changePasswordReducer,
  paymentDetailsReducer,
  updateEmailReducer,
  addCorporateMemberReducer,
  removeCorporateMemberReducer,
} from "./settingReducer";
import {
  createUploadVideoReducer,
  createUploadContentReducer,
} from "./UploadVideoReducer";
import {
  postedJobDetailsReducer,
  postedJobReducer,
  getAppliedJobsReducer,
  getJobFindReducer,
  getJobInfoReducer,
  postJobApplyReducer,
  putJobPostReducer,
  updateJobPostReducer,
  getApplicantDetailsReducer,
} from "./jobPostReducer";
import {
  getAdminAboutToExpireUsersReducer,
  getAdminCountReducer,
  getAdminReportReducer,
  postAdminUpdateIndustryReducer,
} from "./adminReducer";
import {
  getMentorshipSubjectsReducer,
  getMentorshipUpcomingSessionsReducer,
  getMentorsListReducer,
  getMyMenteesReducer,
  getMyMentorsReducer,
  postMentorshipBookSessionReducer,
  postMentorshipRequestAcceptReducer,
  postMentorshipRequestCancelReducer,
  postMentorshipRequestReducer,
  postMentorshipRequestRejectReducer,
  postMentorshipSessionAcceptReducer,
  postMentorshipSessionCancelReducer,
  postMentorshipSessionRejectReducer,
} from "./mentorshipReducer";

const reducers = combineReducers({
  login: loginReducer,
  userDetails: userDetailsReducer,
  topCommunity: getTopCommunityReducer,
  myCommunity: getMyCommunityReducer,
  getCommunity: getCommunityReducer,
  postJoinCommunity: postJoinCommunityReducer,
  postSearchCommunity: postSearchCommunityReducer,
  getAllConversation: getAllConversationReducer,
  getAllMessage: getAllMessageReducer,
  getUnreadMessage: getUnreadMessageReducer,
  createCommunication: postCreateConversationReducer,
  sendMessage: postSendMessageReducer,
  getUserSearch: getUserSearchReducer,
  recentSearches: getRecentSearchesReducer,
  checkUserExistData: checkUserExistReducer,
  otpSignupData: otpSignupReducer,
  signupData: signupReducer,
  getForgotPasswordOTP: getForgotPasswordOTPReducer,
  resetPassword: putResetPasswordReducer,
  otpVerifySignupData: otpVerifySignupReducer,
  searchMap: postSearchMapReducer,
  searchUserReducer: postSearchUserReducer,
  getPostsCommunityWise: getPostsCommunityWiseReducer,
  addPostCommunity: addPostCommunityReducer,
  addPostCommunityComments: addPostCommunityCommentsReducer,
  uploadFiles: createUploadFilesReducer,
  popularSearches: getPopularSearchesReducer,
  postLeaveCommunity: postLeaveCommunityReducer,
  postAddReactToPost: postAddReactToPostReducer,
  industries: getIndustriesReducer,
  countries: getCountriesReducer,
  postAddReactToPostComment: postAddReactToPostCommentReducer,
  postDeletePostComment: postDeletePostCommentReducer,
  userSearchReducer: postUserSearchReducer,
  editProfile: putEditProfileReducer,
  industry: getIndustryReducer,
  getSalary: getSalaryReducer,
  postSendInvite: postSendInviteReducer,
  recoveryEmailVerify: getRecoveryEmailVerifyReducer,
  deactivateAccount: deactivateAccountReducer,
  createUploadVideoData: createUploadVideoReducer,
  putJobPostData: putJobPostReducer,
  updateJobPostData: updateJobPostReducer,
  changePassword: changePasswordReducer,
  paymentDetails: paymentDetailsReducer,
  updateEmail: updateEmailReducer,
  signupWithGoogleData: signupWithGoogleReducer,
  addCorporateMember: addCorporateMemberReducer,
  removeCorporateMember: removeCorporateMemberReducer,
  postSearchMapCorporate: postSearchMapCorporateReducer,
  getPopularSearchesCorporate: getPopularSearchesCorporateReducer,
  getRecentSearchesCorporate: getRecentSearchesCorporateReducer,
  postSearchCorporateProfile: postSearchCorporateProfileReducer,
  getCommunityAdmin: getCommunityAdminReducer,
  postToggleCommunity: postToggleCommunityReducer,
  postCreateCommunity: postCreateCommunityReducer,
  postEditCommunity: postEditCommunityReducer,
  resumeBuilderData: resumeBuilderReducer,
  postTagUser: postTagUserReducer,
  // admin
  loginAdmin: loginAdminReducer,
  getAdminReport: getAdminReportReducer,
  getAdminAboutToExpireUsers: getAdminAboutToExpireUsersReducer,
  getAdminCount: getAdminCountReducer,
  postAdminUpdateIndustry: postAdminUpdateIndustryReducer,
  getJobTypeData: getJobTypeReducer,
  getUserDetailsData: getUserDetailsReducer,
  updateCompansationData: updateCompansationReducer,
  createUploadContentData: createUploadContentReducer,
  postedJobData: postedJobReducer,
  postedJobDetailsData: postedJobDetailsReducer,
  getJobFind: getJobFindReducer,
  getJobInfo: getJobInfoReducer,
  postJobApply: postJobApplyReducer,
  getAppliedJobs: getAppliedJobsReducer,
  getApplicantDetailsData: getApplicantDetailsReducer,
  getMentorshipSubjects: getMentorshipSubjectsReducer,
  getMentorsList: getMentorsListReducer,
  postMentorshipRequest: postMentorshipRequestReducer,
  getMyMentees: getMyMenteesReducer,
  postMentorshipRequestReject: postMentorshipRequestRejectReducer,
  postMentorshipRequestAccept: postMentorshipRequestAcceptReducer,
  getMyMentors: getMyMentorsReducer,
  postMentorshipRequestCancel: postMentorshipRequestCancelReducer,
  postMentorshipBookSession: postMentorshipBookSessionReducer,
  getMentorshipUpcomingSessions: getMentorshipUpcomingSessionsReducer,
  postMentorshipSessionAccept: postMentorshipSessionAcceptReducer,
  postMentorshipSessionReject: postMentorshipSessionRejectReducer,
  postMentorshipSessionCancel:postMentorshipSessionCancelReducer
});
export default reducers;
