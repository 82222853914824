export const mentorshipType = {
  GET_MENTORSHIP_SUBJECTS_PENDING:
    "[MENTORSHIP_SUBJECTS][REQUEST] GET_MENTORSHIP_SUBJECTS_PENDING",
  GET_MENTORSHIP_SUBJECTS_SUCCESS:
    "[MENTORSHIP_SUBJECTS][RESPONSE]   GET_MENTORSHIP_SUBJECTS_SUCCESS",
  GET_MENTORSHIP_SUBJECTS_FAILURE:
    "[MENTORSHIP_SUBJECTS][RESPONSE] GET_MENTORSHIP_SUBJECTS_FAILURE",

  GET_MENTORS_LIST_PENDING: "[MENTORS_LIST][REQUEST] GET_MENTORS_LIST_PENDING",
  GET_MENTORS_LIST_SUCCESS:
    "[MENTORS_LIST][RESPONSE]   GET_MENTORS_LIST_SUCCESS",
  GET_MENTORS_LIST_FAILURE: "[MENTORS_LIST][RESPONSE] GET_MENTORS_LIST_FAILURE",
  GET_MENTORS_LIST_CLEAR: "[MENTORS_LIST][RESPONSE] GET_MENTORS_LIST_CLEAR",

  POST_MENTORSHIP_REQUEST_PENDING:
    "[MENTORSHIP_REQUEST][REQUEST] POST_MENTORSHIP_REQUEST_PENDING",
  POST_MENTORSHIP_REQUEST_SUCCESS:
    "[MENTORSHIP_REQUEST][RESPONSE]   POST_MENTORSHIP_REQUEST_SUCCESS",
  POST_MENTORSHIP_REQUEST_FAILURE:
    "[MENTORSHIP_REQUEST][RESPONSE] POST_MENTORSHIP_REQUEST_FAILURE",

  GET_MY_MENTEES_PENDING: "[MY_MENTEES][REQUEST] GET_MY_MENTEES_PENDING",
  GET_MY_MENTEES_SUCCESS: "[MY_MENTEES][RESPONSE]   GET_MY_MENTEES_SUCCESS",
  GET_MY_MENTEES_FAILURE: "[MY_MENTEES][RESPONSE] GET_MY_MENTEES_FAILURE",

  GET_MY_MENTORS_PENDING: "[MY_MENTORS][REQUEST] GET_MY_MENTORS_PENDING",
  GET_MY_MENTORS_SUCCESS: "[MY_MENTORS][RESPONSE]   GET_MY_MENTORS_SUCCESS",
  GET_MY_MENTORS_FAILURE: "[MY_MENTORS][RESPONSE] GET_MY_MENTORS_FAILURE",

  POST_MENTORSHIP_REQUEST_REJECT_PENDING:
    "[MENTORSHIP_REQUEST_REJECT][REQUEST_REJECT] POST_MENTORSHIP_REQUEST_REJECT_PENDING",
  POST_MENTORSHIP_REQUEST_REJECT_SUCCESS:
    "[MENTORSHIP_REQUEST_REJECT][RESPONSE]   POST_MENTORSHIP_REQUEST_REJECT_SUCCESS",
  POST_MENTORSHIP_REQUEST_REJECT_FAILURE:
    "[MENTORSHIP_REQUEST_REJECT][RESPONSE] POST_MENTORSHIP_REQUEST_REJECT_FAILURE",

  POST_MENTORSHIP_REQUEST_ACCEPT_PENDING:
    "[MENTORSHIP_REQUEST_ACCEPT][REQUEST_ACCEPT] POST_MENTORSHIP_REQUEST_ACCEPT_PENDING",
  POST_MENTORSHIP_REQUEST_ACCEPT_SUCCESS:
    "[MENTORSHIP_REQUEST_ACCEPT][RESPONSE]   POST_MENTORSHIP_REQUEST_ACCEPT_SUCCESS",
  POST_MENTORSHIP_REQUEST_ACCEPT_FAILURE:
    "[MENTORSHIP_REQUEST_ACCEPT][RESPONSE] POST_MENTORSHIP_REQUEST_ACCEPT_FAILURE",

  POST_MENTORSHIP_REQUEST_CANCEL_PENDING:
    "[MENTORSHIP_REQUEST_CANCEL][REQUEST_CANCEL] POST_MENTORSHIP_REQUEST_CANCEL_PENDING",
  POST_MENTORSHIP_REQUEST_CANCEL_SUCCESS:
    "[MENTORSHIP_REQUEST_CANCEL][RESPONSE]   POST_MENTORSHIP_REQUEST_CANCEL_SUCCESS",
  POST_MENTORSHIP_REQUEST_CANCEL_FAILURE:
    "[MENTORSHIP_REQUEST_CANCEL][RESPONSE] POST_MENTORSHIP_REQUEST_CANCEL_FAILURE",

  POST_MENTORSHIP_BOOK_SESSION_PENDING:
    "[MENTORSHIP_BOOK_SESSION][REQUEST_BOOK_SESSION] POST_MENTORSHIP_BOOK_SESSION_PENDING",
  POST_MENTORSHIP_BOOK_SESSION_SUCCESS:
    "[MENTORSHIP_BOOK_SESSION][RESPONSE]   POST_MENTORSHIP_BOOK_SESSION_SUCCESS",
  POST_MENTORSHIP_BOOK_SESSION_FAILURE:
    "[MENTORSHIP_BOOK_SESSION][RESPONSE] POST_MENTORSHIP_BOOK_SESSION_FAILURE",

  GET_MENTORSHIP_UPCOMING_SESSIONS_PENDING:
    "[MENTORSHIP_UPCOMING_SESSIONS][REQUEST] GET_MENTORSHIP_UPCOMING_SESSIONS_PENDING",
  GET_MENTORSHIP_UPCOMING_SESSIONS_SUCCESS:
    "[MENTORSHIP_UPCOMING_SESSIONS][RESPONSE]   GET_MENTORSHIP_UPCOMING_SESSIONS_SUCCESS",
  GET_MENTORSHIP_UPCOMING_SESSIONS_FAILURE:
    "[MENTORSHIP_UPCOMING_SESSIONS][RESPONSE] GET_MENTORSHIP_UPCOMING_SESSIONS_FAILURE",

  POST_MENTORSHIP_SESSION_ACCEPT_PENDING:
    "[MENTORSHIP_SESSION_ACCEPT][REQUEST_SESSION_ACCEPT] POST_MENTORSHIP_SESSION_ACCEPT_PENDING",
  POST_MENTORSHIP_SESSION_ACCEPT_SUCCESS:
    "[MENTORSHIP_SESSION_ACCEPT][RESPONSE]   POST_MENTORSHIP_SESSION_ACCEPT_SUCCESS",
  POST_MENTORSHIP_SESSION_ACCEPT_FAILURE:
    "[MENTORSHIP_SESSION_ACCEPT][RESPONSE] POST_MENTORSHIP_SESSION_ACCEPT_FAILURE",

  POST_MENTORSHIP_SESSION_REJECT_PENDING:
    "[MENTORSHIP_SESSION_REJECT][REQUEST_SESSION_REJECT] POST_MENTORSHIP_SESSION_REJECT_PENDING",
  POST_MENTORSHIP_SESSION_REJECT_SUCCESS:
    "[MENTORSHIP_SESSION_REJECT][RESPONSE]   POST_MENTORSHIP_SESSION_REJECT_SUCCESS",
  POST_MENTORSHIP_SESSION_REJECT_FAILURE:
    "[MENTORSHIP_SESSION_REJECT][RESPONSE] POST_MENTORSHIP_SESSION_REJECT_FAILURE",

  POST_MENTORSHIP_SESSION_CANCEL_PENDING:
    "[MENTORSHIP_SESSION_CANCEL][REQUEST_SESSION_CANCEL] POST_MENTORSHIP_SESSION_CANCEL_PENDING",
  POST_MENTORSHIP_SESSION_CANCEL_SUCCESS:
    "[MENTORSHIP_SESSION_CANCEL][RESPONSE]   POST_MENTORSHIP_SESSION_CANCEL_SUCCESS",
  POST_MENTORSHIP_SESSION_CANCEL_FAILURE:
    "[MENTORSHIP_SESSION_CANCEL][RESPONSE] POST_MENTORSHIP_SESSION_CANCEL_FAILURE",
};
