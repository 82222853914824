import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../../library/custom/textBox/InputField";
import {
  API_STATUS,
  E_YEAR,
  MONTHS,
  S_YEAR,
} from "../../../../constant/constant";
import FormDropBox from "../../../../library/formDropBox/FormDropBox";
import { useSelector } from "react-redux";
import File from "../../../../library/media/File";

const AdressForm = ({
  singleAdress,
  handleAdresFormChange,
  setUnsavedChanges,
}) => {
  let years = [];
  for (let i = E_YEAR; i >= S_YEAR; i--) {
    years.push(i);
  }

  return (
    <>
      <Grid className="workExpirience">
        <Grid item container lg={12} xs={12} sm={12} md={6}>
          <InputField
            fullWidth={true}
            type="text"
            label="Address Line"
            id="addressO"
            isRequired={true}
            placeholder="Enter here"
            value={singleAdress?.addressO ?? ""}
            onChange={(e) => {
              handleAdresFormChange(e, singleAdress, "addressO");
              setUnsavedChanges(true);
            }}
            isError={singleAdress.isaddressO}
            errorMsg={
              singleAdress.isaddressO ? "Please enter this required field" : ""
            }
          />
        </Grid>

        <Grid className="fName" item container lg={12} xs={12} sm={12} md={6} mt={2}>
          <Grid item lg={5.8} xs={12}>
            <InputField
              fullWidth={true}
              type="text"
              label="City"
              id="cityO"
              isRequired={true}
              placeholder="Enter here"
              value={singleAdress?.cityO ?? ""}
              onChange={(e) => {
                handleAdresFormChange(e, singleAdress, "cityO");
                setUnsavedChanges(true);
              }}
              isError={singleAdress.iscityO}
              errorMsg={
                singleAdress.iscityO ? "Please enter this required field" : ""
              }
            />
          </Grid>
          <Grid item lg={5.8} xs={12}>
            <InputField
              fullWidth={true}
              type="text"
              label="State/Province"
              id="stateO"
              isRequired={true}
              placeholder="Enter here"
              value={singleAdress?.stateO ?? ""}
              onChange={(e) => {
                handleAdresFormChange(e, singleAdress, "stateO");
                setUnsavedChanges(true);
              }}
              isError={singleAdress.iscityO}
              errorMsg={
                singleAdress.iscityO ? "Please enter this required field" : ""
              }
            />
          </Grid>
        </Grid>
        <Grid className="fName" item container lg={12} xs={12} sm={12} md={6} mt={2}>
          <Grid item lg={5.8} xs={12}>
            <InputField
              fullWidth={true}
              type="text"
              label="Country/Region"
              id="countryO"
              isRequired={true}
              placeholder="Enter here"
              value={singleAdress?.countryO ?? ""}
              onChange={(e) => {
                handleAdresFormChange(e, singleAdress, "countryO");
                setUnsavedChanges(true);
              }}
              isError={singleAdress.iscountryO}
              errorMsg={
                singleAdress.iscountryO
                  ? "Please enter this required field"
                  : ""
              }
            />
          </Grid>
          <Grid item lg={5.8} xs={12}>
            <InputField
              fullWidth={true}
              type="text"
              label="Zip/Post Code"
              id="zipcodeO"
              isRequired={true}
              placeholder="Enter here"
              value={singleAdress?.zipcodeO ?? ""}
              onChange={(e) => {
                handleAdresFormChange(e, singleAdress, "zipcodeO");
                setUnsavedChanges(true);
              }}
              isError={singleAdress.iszipcodeO}
              errorMsg={
                singleAdress.iszipcodeO
                  ? "Please enter this required field"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdressForm;
