import React from "react";
import "./JobChip.scss";

export default function Card({
  cardData,
  cardClickHandler,
  staticCardData = false,
  selectedVehicleAccident,
}) {
  return (
    <div className="newCards">
      <div className="blueCardBody">
        {cardData?.map((data) => {
          return staticCardData ? (
            <div
              key={data.jobId}
              onClick={() => cardClickHandler(data)}
              className={
                selectedVehicleAccident?.jobId === data?.jobId
                  ? "staticDataCard borderGreen"
                  : "staticDataCard"
              }
            >
              <div className="staticCardContent">
                <h5>{data.jobcode}</h5>
              </div>
            </div>
          ) : (
            <div className="newCards">
              <div
                key={data.name}
                className={data.isSelected ? "selectedCard" : "defaultCard"}
                onClick={() => cardClickHandler(data)}
              >
                {data.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
