import { Grid, FormControl } from "@mui/material";
import React, { useState } from "react";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../../library/custom/textBox/InputField";

import { DIALCODES } from "../../../../constant/countries";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import {
  COMPANY_TYPE,
  CURRENCY_TYPE,
  GENDER,
} from "../../../../constant/constant";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Buttons from "../../../../library/custom/button/Button";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import RichTextEditor from "../../../../library/custom/richTextEditor/RichTextEditor";
import AdressAccordian from "./AdressAccordian";

import TextButton from "../../../../library/custom/button/Buttons";
const CorporateBasicDetailsForm = ({
  basicDetails,
  setBasicDetails,
  Countries,
  personalDetails,
  setPersonalDetails,
  submitBasicDetails,
  handleTabClick,
  media,
  setmedia,
  unsavedChanges,
  setUnsavedChanges,
  cancelForm,
  allIndustries,
  address_detailsArr,
  setAddress_detailsArr,
  handleAdresFormChange,
  addNEwAdressForm,
}) => {
  const [isName, setisName] = useState(false);
  const [isEmail, setisEmail] = useState(false);
  const [isWebsite, setisWebsite] = useState(false);
  const [isTelephone, setisTelephone] = useState(false);
  const [isdial_code, setisdial_code] = useState(false);
  const [isType, setisType] = useState(false);
  const [isindustry, setisindustry] = useState(false);
  const [isCity, setisCity] = useState(false);
  const [isState, setisState] = useState(false);
  const [iscountry_name, setiscountry_name] = useState(false);
  const [isZipCode, setIsZipCode] = useState(false);
  const [isemployee_count, setisemployee_count] = useState(false);
  const [isestablishment_date, setisestablishment_date] = useState(false);
  const handleError = (exit=false) => {
    let error = false;
    const adressError = mapAdressErrors();
    if (
      basicDetails.name === "" ||
      basicDetails.name === undefined ||
      basicDetails.name === null
    ) {
      setisName(true);
      error = true;
    }
    if (
      basicDetails.recovery_email === "" ||
      basicDetails.recovery_email === undefined ||
      basicDetails.recovery_email === null
    ) {
      setisEmail(true);
      error = true;
    }
    if (
      basicDetails.website === "" ||
      basicDetails.website === undefined ||
      basicDetails.website === null
    ) {
      setisWebsite(true);
      error = true;
    }
    if (
      basicDetails.telephone === "" ||
      basicDetails.telephone === undefined ||
      basicDetails.telephone === null
    ) {
      setisTelephone(true);
      error = true;
    }
    if (
      basicDetails.industry === "" ||
      basicDetails.industry === "0" ||
      basicDetails.industry === undefined ||
      basicDetails.industry === null
    ) {
      
      setBasicDetails({
        ...basicDetails,
        industry: "",
      });
      setisindustry(true);
      error = true;
    }
    if (
      basicDetails.dial_code === "" ||
      basicDetails.dial_code === undefined ||
      basicDetails.dial_code === null
    ) {
      setisTelephone(true);
      error = true;
    }
    if (
      basicDetails.type === "" ||
      basicDetails.type === "0" ||
      basicDetails.type === undefined ||
      basicDetails.type === null
    ) {
      setBasicDetails({
        ...basicDetails,
        type: "",
      });
      setisType(true);
      error = true;
    }

    if (
      basicDetails.city === "" ||
      basicDetails.city === undefined ||
      basicDetails.city === null
    ) {
      setisCity(true);
      error = true;
    }
    if (
      basicDetails.state === "" ||
      basicDetails.state === undefined ||
      basicDetails.state === null
    ) {
      setisState(true);
      error = true;
    }
    if (
      basicDetails.country_name === "" ||
      basicDetails.country_name === undefined ||
      basicDetails.country_name === null
    ) {
      setiscountry_name(true);
      error = true;
    }
    if (
      basicDetails.zipcode === undefined ||
      basicDetails.zipcode === "" ||
      basicDetails.zipcode === null
    ) {
      setIsZipCode(true);
      error = true;
    }
    if (
      basicDetails.employee_count === "" ||
      basicDetails.employee_count === undefined ||
      basicDetails.employee_count === null
    ) {
      setisemployee_count(true);
      error = true;
    }
    if (
      basicDetails.establishment_date === "" ||
      basicDetails.establishment_date === undefined ||
      basicDetails.establishment_date === null
    ) {
      setisestablishment_date(true);
      error = true;
    }
    if (!error && !adressError) {
      submitBasicDetails(exit);
    }
  };

  function mapAdressErrors() {
    let updatedaddress_detailsArr = [...address_detailsArr];
    let error = false;
    updatedaddress_detailsArr.forEach((data) => {
      if (
        data.cityO === "" ||
        data.isaddressO === undefined ||
        data.isaddressO === null
      ) {
        data.isaddressO = true;
        error = true;
      }
      if (
        data.cityO === "" ||
        data.cityO === undefined ||
        data.cityO === null
      ) {
        data.iscityO = true;
        error = true;
      }
      if (
        data.stateO === "" ||
        data.stateO === undefined ||
        data.stateO === null
      ) {
        data.isstateO = true;
        error = true;
      }
      if (
        data.countryO === "" ||
        data.countryO === undefined ||
        data.countryO === null
      ) {
        data.iscountryO = true;
        error = true;
      }
      if (
        data.zipcodeO === "" ||
        data.zipcodeO === undefined ||
        data.zipcodeO === null
      ) {
        data.iszipcodeO = true;
        error = true;
      }
    });

    setAddress_detailsArr(updatedaddress_detailsArr);
    return error;
  }
  return (
    <>
      <Grid item lg={10.8} mt={2}>
        <InputField
          fullWidth={true}
          type="text"
          label="Company Name"
          placeholder="Enter here"
          value={basicDetails.name}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              name: e.target.value,
            });
          }}
          isRequired={true}
          errorMsg={isName ? "Please enter this required field" : ""}
          isError={isName}
        />
      </Grid>
      <Grid item lg={10.8} mt={2}>
        <InputField
          fullWidth={true}
          type="text"
          label="Website"
          placeholder="Enter here"
          value={basicDetails.website}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              website: e.target.value,
            });
          }}
          isRequired={true}
          errorMsg={isWebsite ? "Please enter this required field" : ""}
          isError={isWebsite}
        />
      </Grid>
      <Grid textAlign={"left"} lg={12} mt={2}>
        {/* <Grid className="label">Contact Number</Grid> */}
        <FormLabels label={"Contact Number"} isRequired={true} />
        <Grid className="fName" lg={12}>
          <Grid lg={1.5}>
            <SelectMenu
              disabledDefault={false}
              listData={DIALCODES}
              countries={true}
              value={basicDetails.dial_code}
              onchangehandler={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  dial_code: e.target.value,
                });
              }}
              errorMsg={isdial_code ? "Please enter this required field" : ""}
              isError={isdial_code}
            />
          </Grid>
          <Grid lg={7.7}>
            <InputField
              fullWidth={true}
              type="text"
              value={basicDetails.telephone}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  telephone: e.target.value,
                });
              }}
              errorMsg={isTelephone ? "Please enter this required field" : ""}
              isError={isTelephone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Contact Email"
          placeholder="Enter here"
          value={basicDetails.recovery_email}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              recovery_email: e.target.value,
            });
          }}
          isRequired={true}
          errorMsg={isEmail ? "Please enter this required field" : ""}
          isError={isEmail}
        />
      </Grid>
      <Grid item lg={11} xs={12} sm={12} md={6} mt={2}>
        <FormControl fullWidth className="selectLabel">
          <FormLabels label="Company Type" isRequired={true} />
          <SelectMenu
            disabledDefault={false}
            listData={COMPANY_TYPE}
            label={"Please Select"}
            isKeyValue={true}
            value={basicDetails.type}
            onchangehandler={(e) => {
              setBasicDetails({
                ...basicDetails,
                type: e.target.value,
              });
              setisType(false);
            }}
            isRequired={true}
            errorMsg={isType ? "Please enter this required field" : ""}
            isError={isType}
          />
        </FormControl>
      </Grid>

      <Grid mt={2} className="selectLabel" lg={11}>
        <FormControl fullWidth textAlign="left">
          <FormLabels label="About" />{" "}
          <RichTextEditor
            value={basicDetails?.culture}
            placeholder={"Enter here"}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                culture: e,
              });
            }}
            // editorHtml={editorHtml}
            // setEditorHtml={setEditorHtml}
          />
        </FormControl>
      </Grid>

      <Grid className="fName" item container lg={11} xs={12} sm={12} md={6}>
        <Grid item lg={5} xs={12}>
          <FormLabels label="Industry" isRequired={true} />
          <SelectMenu
            disabledDefault={true}
            label={"Please select"}
            listData={allIndustries?.industries ?? []}
            countries={true}
            value={basicDetails.industry}
            onchangehandler={(e) => {
              setBasicDetails({
                ...basicDetails,
                industry: e.target.value,
              });
              setisindustry(false);
            }}
            isRequired={true}
            errorMsg={isindustry ? "Please enter this required field" : ""}
            isError={isindustry}
          />
        </Grid>
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="HQ Address"
          placeholder="Please select"
          value={basicDetails.address_line}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              address_line: e.target.value,
            });
          }}
        />
      </Grid>

      <Grid textAlign={"left"} lg={11} mt={2}>
        <Grid className="fName" item container lg={12} xs={12} sm={12} md={6}>
          <Grid item lg={5}>
            <FormLabels label="Country" isRequired={true} />
            <SelectMenu
              disabledDefault={true}
              label={"Please select"}
              listData={Countries}
              countries={true}
              value={basicDetails.country_name}
              onchangehandler={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  country_name: e.target.value,
                });
                setiscountry_name(false);
              }}
              isRequired={true}
              errorMsg={
                iscountry_name ? "Please enter this required field" : ""
              }
              isError={iscountry_name}
            />
          </Grid>
          <Grid item lg={5}>
            {/* <FormLabels label="City" /> */}
            <InputField
              fullWidth={true}
              type="text"
              label="City"
              placeholder="Enter here"
              value={basicDetails.city}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  city: e.target.value,
                });
              }}
              isRequired={true}
              errorMsg={isCity ? "Please enter this required field" : ""}
              isError={isCity}
            />
          </Grid>
        </Grid>

        <Grid
          className="fName"
          mt={2}
          item
          container
          lg={12}
          xs={12}
          sm={12}
          md={6}
        >
          <Grid item lg={5}>
            <InputField
              fullWidth={true}
              type="text"
              label="State"
              placeholder="Enter here"
              value={basicDetails.state}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  state: e.target.value,
                });
              }}
              isRequired={true}
              errorMsg={isState ? "Please enter this required field" : ""}
              isError={isState}
            />
          </Grid>
          <Grid item lg={5}>
            <InputField
              fullWidth={true}
              type="text"
              label="Zipcode"
              placeholder="Enter here"
              value={basicDetails.zipcode}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  zipcode: e.target.value,
                });
              }}
              isRequired={true}
              errorMsg={isZipCode ? "Please enter this required field" : ""}
              isError={isZipCode}
            />
          </Grid>
        </Grid>
      </Grid>

      {address_detailsArr?.map((i, j) => (
        <AdressAccordian
          singleAdress={i}
          handleAdresFormChange={handleAdresFormChange}
          address_detailsArr={address_detailsArr}
          setAddress_detailsArr={setAddress_detailsArr}
          index={j}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          cancelForm={cancelForm}
        />
      ))}

      <Grid container justifyContent={"left"} mb={2} mt={2}>
        <TextButton
          className="textButton"
          label={"Add Location"}
          onClick={() => addNEwAdressForm()}
        />
      </Grid>

      <Grid textAlign={"left"} lg={11} mt={2}>
        <Grid className="fName" item container lg={12} xs={12} sm={12} md={6}>
          <Grid item lg={5}>
            <InputField
              fullWidth={true}
              type="text"
              label="Employee Count"
              placeholder="Enter here"
              value={basicDetails.employee_count}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  employee_count: e.target.value,
                });
              }}
              isRequired={true}
              errorMsg={
                isemployee_count ? "Please enter this required field" : ""
              }
              isError={isemployee_count}
            />
          </Grid>

          <Grid item lg={5}>
            <FormLabels label="Establishment Date" isRequired={true} />

            <DatePickers
              value={basicDetails.establishment_date}
              // value={"10/15/2018"}
              onChangeHandler={(e) => {
                setBasicDetails({ ...basicDetails, establishment_date: e });
                setisestablishment_date(false);
              }}
              isRequired={true}
              errorMsg={
                isestablishment_date ? "Please enter this required field" : ""
              }
              isError={isestablishment_date}
            />
          </Grid>
        </Grid>

        <Grid
          className="fName"
          mt={2}
          item
          container
          lg={12}
          xs={12}
          sm={12}
          md={6}
        >
          <Grid item lg={5}>
            <InputField
              fullWidth={true}
              type="text"
              label="Total Revenue"
              placeholder="Enter here"
              value={basicDetails.revenue}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  revenue: e.target.value,
                });
              }}
              // isError={isZipCode}
              // errorMsg={isZipCode ? "Please enter this required field" : ""}
            />
          </Grid>

          <Grid item lg={5}>
            <FormLabels label="Currency" />
            <SelectMenu
              disabledDefault={false}
              label="Please select"
              id={"endY"}
              name={"endY"}
              value={basicDetails.revenue_currency}
              onchangehandler={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  revenue_currency:e.target.value === "0" ? "" : e.target.value,
                });
              }}
              listData={CURRENCY_TYPE}
              isKeyValue={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid mt={2} className="selectLabel" lg={11}>
        <FormControl fullWidth textAlign="left">
          <FormLabels label="Tools / Technologies / Methods Used" />{" "}
          <TextArea
            fullWidth={true}
            value={basicDetails.current_road_map}
            placeholder={"Enter here"}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                current_road_map: e.target.value,
              });
            }}
            // isError={isAbout}
            // errorMsg={isAbout ? "Please enter this required field" : ""}
          />
        </FormControl>
      </Grid>
      <Grid mt={2} className="selectLabel" lg={11}>
        <FormControl fullWidth textAlign="left">
          <FormLabels label="Latest News" />{" "}
          <TextArea
            fullWidth={true}
            placeholder={"Enter here"}
            value={basicDetails.future_road_map}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                future_road_map: e.target.value,
              });
            }}
            // isError={isAbout}
            // errorMsg={isAbout ? "Please enter this required field" : ""}
          />
        </FormControl>
      </Grid>

      <Grid item lg={9} className="editProfileBtn">
      <Grid className="saveBtn" mr={1}>
          <Grid>
            <Buttons
              varientContained={true}
              label="Save"
              onClick={() => {
                handleError(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid className="saveBtn">
          <Grid>
            <Buttons
              varientContained={true}
              label="Save and Continue"
              onClick={() => {
                handleError();
              }}
            />
          </Grid>
        </Grid>
        <Grid className="cancelBtn">
          <Grid>
            <Buttons
              varientContained={true}
              onClick={cancelForm}
              label="Cancel"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CorporateBasicDetailsForm;
