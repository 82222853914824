import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCommunities,
  getMyCommunities,
  getTopCommunities,
  joinCommunity,
  searchCommunity,
  getMyCommunitiesPosts,
  CreateCommunityPost,
  CreateCommunityPostComments,
  LeaveCommunity,
  addReactToPostCommunity,
  addReactToPostCommentCommunity,
  deletePostsCommunity,
  getCommunitiesAdmin,
  toggleCommunity,
  createCommunity,
  editCommunity,
  resumeBuilderServices,
} from "../../services/communtyService";
import { apiError } from "../../utils/helper";
import { communityType } from "../constant/communityType";
import * as communityAction from "../actions/communityAction";

export function* getTopCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(getTopCommunities, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      communityAction.getTopCommunitySuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Top Communities Failed" };
    yield put(communityAction.getTopCommunityFailure(data));
  }
}

export function* getMyCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(getMyCommunities, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      communityAction.getMyCommunitySuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Top Communities Failed" };
    yield put(communityAction.getMyCommunityFailure(data));
  }
}

export function* getCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(getCommunities, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      communityAction.getCommunitySuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Communities Failed" };
    yield put(communityAction.getCommunityFailure(data));
  }
}

export function* getCommunityAdminSaga(params) {
  try {
    const { data, status, error } = yield call(getCommunitiesAdmin, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      communityAction.getCommunityAdminSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Communities Admin Failed" };
    yield put(communityAction.getCommunityAdminFailure(data));
  }
}

export function* postJoinCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(joinCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postJoinCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post join community Failed" };
    yield put(communityAction.postJoinCommunityFailure(data));
  }
}
//addPostCommunitySaga
export function* postSearchCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(searchCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postSearchCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Closed Ticket Failed" };
    yield put(communityAction.postSearchCommunityFailure(data));
  }
}
// get all posts community wise
export function* getpostsCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(getMyCommunitiesPosts, params);
    if (!data && status !== 200) throw apiError(error);
    // const page = loginType.ASSET_DETAILS_PAGE;
    yield put(
      communityAction.getPostsCommunityWisesSuccess({
        // page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Posts Communities Failed" };
    yield put(communityAction.getPostsCommunityWisesFailure(data));
  }
}
export function* addPostCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(CreateCommunityPost, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.addPostCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Posts community Failed" };
    yield put(communityAction.addPostCommunityFailure(data));
  }
}
export function* addPostCommunityCommentsSaga(params) {
  try {
    const { data, status, error } = yield call(
      CreateCommunityPostComments,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.addPostCommunityCommentSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Posts Comments community Failed" };
    yield put(communityAction.addPostCommunityCommentFailure(data));
  }
}

export function* postLeaveCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(LeaveCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postLeaveCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Leave community Failed" };
    yield put(communityAction.postLeaveCommunityFailure(data));
  }
}
export function* postAddReactToPostCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(addReactToPostCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postAddReactToPostSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Post react community Failed" };
    yield put(communityAction.postAddReactToPostFailure(data));
  }
}

export function* postAddReactToPostCommentCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(
      addReactToPostCommentCommunity,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postAddReactToPostCommentSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Post react Comment community Failed" };
    yield put(communityAction.postAddReactToPostCommentFailure(data));
  }
}

export function* postDeletePostsCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(deletePostsCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postDeletePostCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "delete Post community Failed" };
    yield put(communityAction.postDeletePostCommunityFailure(data));
  }
}

export function* postToggleCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(toggleCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postToggleCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Toggle community Failed" };
    yield put(communityAction.postToggleCommunityFailure(data));
  }
}

export function* postCreateCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(createCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postCreateCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create community Failed" };
    yield put(communityAction.postCreateCommunityFailure(data));
  }
}

export function* postEditCommunitySaga(params) {
  try {
    const { data, status, error } = yield call(editCommunity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.postEditCommunitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Edit community Failed" };
    yield put(communityAction.postEditCommunityFailure(data));
  }
}
export function* resumeBuilderServicesSaga(params) {
  try {
    const { data, status, error } = yield call(resumeBuilderServices, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      communityAction.resumeBuildingSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post join community Failed" };
    yield put(communityAction.resumeBuildingFailure(data));
  }
}
const myCommunitySaga = [
  takeLatest(communityType.GET_TOP_COMMUNITY_PENDING, getTopCommunitySaga),
  takeLatest(communityType.GET_MY_COMMUNITY_PENDING, getMyCommunitySaga),
  takeLatest(communityType.GET_COMMUNITY_PENDING, getCommunitySaga),
  takeLatest(communityType.GET_COMMUNITY_ADMIN_PENDING, getCommunityAdminSaga),
  takeLatest(communityType.POST_JOIN_COMMUNITY_PENDING, postJoinCommunitySaga),
  takeLatest(
    communityType.POST_SEARCH_COMMUNITY_PENDING,
    postSearchCommunitySaga
  ),
  takeLatest(communityType.GET_POSTS_COMMUNITY_PENDING, getpostsCommunitySaga),
  takeLatest(
    communityType.POST_COMMUNITY_ADD_POST_PENDING,
    addPostCommunitySaga
  ),
  takeLatest(
    communityType.POST_COMMUNITY_ADD_POST_COMMENTS_PENDING,
    addPostCommunityCommentsSaga
  ),
  takeLatest(
    communityType.POST_LEAVE_COMMUNITY_PENDING,
    postLeaveCommunitySaga
  ),
  takeLatest(
    communityType.POST_ADD_REACT_TO_POST_COMMUNITY_PENDING,
    postAddReactToPostCommunitySaga
  ),
  takeLatest(
    communityType.POST_ADD_REACT_TO_POST_COMMENT_COMMUNITY_PENDING,
    postAddReactToPostCommentCommunitySaga
  ),
  takeLatest(
    communityType.POST_DELETE_COMMUNITY_PENDING,
    postDeletePostsCommunitySaga
  ),
  takeLatest(
    communityType.POST_TOGGLE_COMMUNITY_PENDING,
    postToggleCommunitySaga
  ),
  takeLatest(
    communityType.POST_CREATE_COMMUNITY_PENDING,
    postCreateCommunitySaga
  ),
  takeLatest(communityType.POST_EDIT_COMMUNITY_PENDING, postEditCommunitySaga),
  takeLatest(
    communityType.POST_RESUME_BULDING_PENDING,
    resumeBuilderServicesSaga
  ),
];

export default myCommunitySaga;
