import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "#26B893",
  height: 5,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    color: "#D9D9D9",
  },
  "& .MuiSlider-thumb": {
    height: 0,
    width: 0,
    backgroundColor: "#fff",
    border: "none",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "#26B893",
    padding: "2px",
    fontSize: "14px",
  },
});
function valueLabelFormat(value) {
  return `${value}%`;
}

function valuetext(value) {
  return `${value}%`;
}
export default function CustomizedSlider({ value, defaultValue, step }) {
  return (
    // <Box sx={{ width: 320 }}>
    <PrettoSlider
      valueLabelDisplay="on"
      aria-label="pretto slider"
      value={value}
      defaultValue={defaultValue}
      // getAriaValueText={valuetext}

      getAriaValueText={valueLabelFormat}
      valueLabelFormat={valueLabelFormat}
      step={step}
    />
    // </Box>
  );
}
