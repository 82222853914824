import { Divider, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./CreateResume.scss";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { resumeBuilding } from "../../../redux/actions/communityAction";
import ResumeTemplate from "./ResumeTemplate";
import ResumeTemplate2 from "./ResumeTemplate2";
import ResumeTemplate3 from "./ResumeTemplate3";
import ResumeTemplate4 from "./ResumeTemplate4";

const CreateResume = () => {
  const searchUserData = useSelector(
    (state) => state?.userDetails?.data?.response
  );

  const resumeBuilderData = useSelector((state) => state?.resumeBuilderData);
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state?.userDetails?.isLoading);
  const [selectResumeData, setSelectResumeData] = useState("resume-template1");

  // let skillsOShow = [];
  // if (
  //   searchUserData?.profile.work_experience &&
  //   searchUserData?.profile.work_experience.skillsO &&
  //   searchUserData?.profile.work_experience.skillsO.length
  // ) {
  //   let ski = [];
  //   for (
  //     let i = 0;
  //     i < searchUserData?.profile.work_experience.skillsO.length;
  //     i++
  //   ) {
  //     ski = [
  //       ...new Set([
  //         ...ski,
  //         ...searchUserData?.profile.work_experience.skillsO[i],
  //       ]),
  //     ];
  //   }
  //   if (ski.length > 10) {
  //     skillsOShow = ski.splice(0, 10);
  //   } else {
  //     skillsOShow = ski;
  //   }
  // }
  const { basicInfo, profile } = searchUserData || {};
  const {
    education,
    certifications,
    work_experience,
    personal_details,
    social_links,
    board_experience,
  } = profile || {};

  let Pskills = [],
    Oskills = [];
  for (let i = 0; i < work_experience?.orgNames?.length; i++) {
    work_experience?.skillsP && work_experience?.skillsP?.length
      ? (Pskills = [...new Set([...work_experience?.skillsP[i], ...Pskills])])
      : (Pskills = [...Pskills]);
    work_experience?.skillsO && work_experience?.skillsO.length
      ? (Oskills = [...new Set([...work_experience?.skillsO[i], ...Oskills])])
      : (Oskills = [...Oskills]);
  }

  let skillsPShow = [];
  // if (
  //   searchUserData?.profile &&
  //   searchUserData?.profile?.work_experience &&
  //   searchUserData?.profile?.work_experience?.skillsP &&
  //   searchUserData?.profile?.work_experience?.skillsP?.length
  // ) {
  //   let ski = [];
  //   for (
  //     let i = 0;
  //     i < searchUserData?.profile.work_experience.skillsP.length;
  //     i++
  //   ) {
  //     ski = [
  //       ...new Set([
  //         ...ski,
  //         ...searchUserData?.profile.work_experience.skillsP[i],
  //       ]),
  //     ];
  //   }
  //   if (ski.length > 5) {
  //     skillsPShow = ski.splice(0, 5);
  //   } else {
  //     skillsPShow = ski;
  //   }
  // }
  const [uniqueIndustry, setuniqueIndustry] = useState([]);
  useEffect(() => {
    setuniqueIndustry([
      ...new Set(
        searchUserData?.profile?.work_experience?.industry?.map((i) => i)
      ),
    ]);
  }, [searchUserData]);
  const componentRef = useRef(null);
  const [downloadRES, setdownloadRES] = useState(false)
  const [pdfloader, setPdfLoader] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Resume",
    fonts: ["Montserrat"],
    bodyClass: "bodyPdf",
    onBeforeGetContent: () => setPdfLoader(true),
    onAfterPrint: () => setPdfLoader(false),
    onPrintError: () => setPdfLoader(false),
  });

  const generatePDF = (first_name, last_name, docType) => {
    let eles;
    setdownloadRES(true)
    eles = document.getElementById("TempHolder").innerHTML;
    let content = `<html><head><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"><style>body{font-size:0.6em;font-family:Arial,Halvetica,sans-serif}table{font-size:1em}</style></head><body>${eles}</body></html>`;
    let payload = {
      type: docType,
      content: content,
      name: `${first_name}-${last_name}`,
    };
    const sdsd = dispatch(resumeBuilding({ payload }));
  };
  useEffect(() => {
    if (resumeBuilderData.status == "SUCCESS" && downloadRES) {
      pdfGenerated(resumeBuilderData.data);
      setdownloadRES(false)
    }
  }, [resumeBuilderData]);

  const pdfGenerated = (data) => {
    window.open(data.response, "_blank");
  };
  const pdfGenFailed = () => {
    window.error("Server failed to generate PDF");
  };

  const resumeArray = [
    "resume-template1",
    "resume-template2",
    "resume-template4",
    "resume-template5",
  ];
  return (
    <>
      <Grid item container className="overrideCreateResume">
        <Grid item md={3}>
          <h3>Select Format</h3>
          <ul className="resume-selector">
            {resumeArray.map((i) => (
              <li
                id={i}
                key={i}
                onClick={() => setSelectResumeData(i)}
                className={selectResumeData === i ? "this-resume" : ""}
              >
                <img
                  className="fit-layout"
                  src={require(`../../../assets/${i}.jpg`)}
                  alt=""
                />
              </li>
            ))}
          </ul>
        </Grid>
        {/* <Grid item mb={2}> */}
        {/* */}
        {/* </Grid> */}

        {/* <Grid item> */}

        {/* </Grid> */}

        <Grid item md={8}>
          <div className="createResumeBtn">
            <button
              className="btn-general-mobile"
              style={{ boxShadow: "3px 3px 8px 0 rgb(63 71 90 / 47%)" }}
              onClick={() =>
                generatePDF(
                  searchUserData?.basicDetails?.first_name,
                  searchUserData?.basicDetails?.last_name,
                  "pdf"
                )
              }
            >
              Save PDF
            </button>

            <button
              className="btn-general-mobile"
              style={{ boxShadow: "3px 3px 8px 0 rgb(63 71 90 / 47%)" }}
              onClick={() =>
                generatePDF(
                  searchUserData?.basicDetails?.first_name,
                  searchUserData?.basicDetails?.last_name,
                  "docx"
                )
              }
            >
              Save Doc
            </button>
          </div>
          <div className="rightPanel">
            {selectResumeData === "resume-template1" ? (
              <ResumeTemplate
                basicDetails={searchUserData?.basicDetails}
                profile={searchUserData?.profile}
                skillsPShow={skillsPShow}
                Pskills={Pskills}
                Oskills={Oskills}
              />
            ) : selectResumeData === "resume-template2" ? (
              <ResumeTemplate2
                basicDetails={searchUserData?.basicDetails}
                profile={searchUserData?.profile}
                skillsPShow={skillsPShow}
                Pskills={Pskills}
                Oskills={Oskills}
              />
            ) : selectResumeData === "resume-template4" ? (
              <ResumeTemplate3
                basicDetails={searchUserData?.basicDetails}
                profile={searchUserData?.profile}
                skillsPShow={skillsPShow}
                Pskills={Pskills}
                Oskills={Oskills}
                work_experience={work_experience}
                board_experience={board_experience}
                education={education}
                certifications={certifications}
              />
            ) : (
              <ResumeTemplate4
                basicDetails={searchUserData?.basicDetails}
                profile={searchUserData?.profile}
                skillsPShow={skillsPShow}
                Pskills={Pskills}
                Oskills={Oskills}
                work_experience={work_experience}
                board_experience={board_experience}
                education={education}
                certifications={certifications}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateResume;
