import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./AdminDashboard.scss";
import { Grid } from "@mui/material";
import GridTable from "../../library/custom/gridTable/GridTable";
import DownloadCsv from "../../library/custom/download_file/DownloadCsv";
import { useDispatch, useSelector } from "react-redux";
import { getAdminReport } from "../../redux/actions/adminAction";
import { getTopCommunity } from "../../redux/actions/communityAction";
import Buttons from "../../library/custom/button/Button";
import StatusPopover from "./StatusPopover";
import { deactivateAccount } from "../../redux/actions/settingAction";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminReport());
    dispatch(getTopCommunity());
  }, []);

  const reportData = useSelector(
    (state) => state?.getAdminReport?.data?.response?.data
  );
  const topCommunity = useSelector((state) => state?.topCommunity?.data?.data);
  const deactivateAccountData = useSelector(
    (state) => state?.deactivateAccount
  );
  const getAdminReportLoading = useSelector(
    (state) =>
      state?.getAdminReport.isLoading || state?.deactivateAccount.isLoading
  );
  useEffect(() => {
    if (
      deactivateAccountData.isLoading === false &&
      deactivateAccountData.status === "SUCCESS"
    )
      dispatch(getAdminReport());
  }, [deactivateAccountData]);

  const [individualList, setIndividualList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [communityList, setcommunityList] = useState([]);

  useEffect(() => {
    fetchList();
  }, [reportData]);

  const fetchList = (data) => {
    let individualList = [];
    let corporateList = [];
    let communityList = [];
    reportData?.map((i) => {
      if (i.userType === "individual") {
        delete i.expired;
        delete i.orderNumber;
        delete i.plan;
        delete i.planValue;
        delete i.transactionId;
        delete i.userType;
        i.planStartDate = moment(i.planStartDate).format("MMM Do YYYY");
        i.planEndDate = moment(i.planEndDate).format("MMM Do YYYY");
        i.id = i;
        individualList = [...individualList, i];
      }
      if (i.userType === "corporate") {
        delete i.expired;
        delete i.orderNumber;
        delete i.plan;
        delete i.planValue;
        delete i.transactionId;
        delete i.userType;
        i.planStartDate = moment(i.planStartDate).format("MMM Do YYYY");
        i.planEndDate = moment(i.planEndDate).format("MMM Do YYYY");
        corporateList = [...corporateList, i];
      }
    });
    topCommunity?.map((i) => {
      i.createdOn = moment(i.createdOn).format("MMM Do YYYY");
      i.is_active = i.is_active ? "Active" : "Inactive";
      communityList = [...communityList, i];
    });
    corporateList.sort((a, b) => (a.userName > b.userName && 1) || -1);
    setIndividualList(individualList);
    setCorporateList(corporateList);
    setcommunityList(communityList);
  };
  const [individualTableRowData, setIndividualTableRowData] = useState("");
  const columns = [
    { field: "userName", headerName: "User Name", flex: 1 },
    { field: "userEmail", headerName: "User Email", flex: 1 },
    {
      field: "planStartDate",
      headerName: "Start Date",
      flex: 1,
    },
    { field: "planEndDate", headerName: "End Date", flex: 1 },
    {
      field: "Statfdus",
      renderHeader: (rows) => <h4>Status</h4>,
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <div>
            <button
              className={rows?.row?.is_Active ? "greenBtn" : "redBtn"}
              onClick={() => {
                setIndividualTableRowData(rows?.row);
                setOpenModal(true);
              }}
            >
              {rows?.row?.is_Active
                ? "Active"
                : rows?.row?.admin_Reason === "NO"
                ? "Inactive"
                : "Deactivate"}
            </button>
          </div>
        );
      },
    },
  ];
  const columnsCorporate = [
    { field: "userName", headerName: "User Name", flex: 1 },
    { field: "userEmail", headerName: "User Email", flex: 1 },
    {
      field: "planStartDate",
      headerName: "Start Date",
      flex: 1,
    },
    { field: "planEndDate", headerName: "End Date", flex: 1 },
  ];
  const columnsCommunities = [
    { field: "title", headerName: "Name", flex: 2 },
    { field: "createdOn", headerName: "Created On", flex: 1 },
    {
      field: "userCount",
      headerName: "Members",
      flex: 1,
    },
    { field: "postsCount", headerName: "Topics", flex: 0.7 },
    { field: "commentCount", headerName: "Comments", flex: 0.8 },
    { field: "is_active", headerName: "Status", flex: 0.5 },
  ];
  const dowloadCsv = useRef(null);
  const dowloadCsvCorp = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [formvalue, setFormvalue] = useState({
    confirm: "",
    reason: "",
  });
  const [isConfirm, setisConfirm] = useState(false);
  const [isreason, setisReason] = useState(false);
  function handleUserToggle() {
    let error = false;
    if (formvalue.confirm !== "confirm") {
      setisConfirm(true);
      error = true;
    }
    if (formvalue.reason === "") {
      setisReason(true);
      error = true;
    }

    if (!error) {
      dispatch(
        deactivateAccount({
          type: "admin",
          userId: individualTableRowData?.userId,
          admin_reason: formvalue.reason,
        })
      );
      setFormvalue({
        ...formvalue,
        confirm: "",
        reason: "",
      });
      setOpenModal(false);
    }
  }
  function handleOnChange(e) {
    setFormvalue({
      ...formvalue,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="overrideDashboard">
      {openModal && (
        <StatusPopover
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          // deletehandle={deletehandle}
          handleConfirm={() => handleUserToggle()}
          content={
            "Are you sure you want to Cancel? All unsaved progress will be lost"
          }
          view
          label1={"Cancel"}
          label2={"Confirm"}
          exitHandle={() => setOpenModal(false)}
          handleOnChange={handleOnChange}
          formvalue={formvalue}
          isreason={isreason}
          isConfirm={isConfirm}
        />
      )}
      <Grid conatiner>
        <Grid container alignItems={"center"} item gap={2} mt={2} mb={1}>
          <h2>Individual Dashboard</h2>
          <Buttons
            label={"Download Report"}
            varientContained={true}
            onClick={() =>
              individualList.length > 0
                ? dowloadCsv.current.link.click()
                : undefined
            }
          />

          <Buttons
            varientContained={true}
            label={`User Count : ${individualList?.length}`}
          />
        </Grid>
        <DownloadCsv
          ref={dowloadCsv}
          data={individualList}
          filename={"professionalList.csv"}
        />
        <GridTable
          getRowId={(r) => r.userId}
          rows={individualList ?? []}
          columns={columns}
          loading={getAdminReportLoading}
          backIconButtonProps={{ disabled: false }}
          disableSelectionOnClick
        />
        <Grid container alignItems={"center"} item gap={2} mt={2} mb={1}>
          <h2>Corporate Dashboard</h2>
          <Buttons
            label={"Download Report"}
            varientContained={true}
            onClick={() =>
              corporateList.length > 0
                ? dowloadCsvCorp.current.link.click()
                : undefined
            }
          />

          <Buttons
            label={`User Count : ${corporateList?.length}`}
            varientContained={true}
          />
        </Grid>
        <DownloadCsv
          ref={dowloadCsvCorp}
          data={corporateList}
          filename={"corporateList.csv"}
        />
        <GridTable
          getRowId={(r) => r.userId}
          rows={corporateList ?? []}
          columns={columnsCorporate}
          backIconButtonProps={{ disabled: false }}
          disableSelectionOnClick
        />
      </Grid>

      <Grid container item gap={2} mt={3} mb={2}>
        <Grid container>
          <h2 style={{ margin: 0 }}>Top Communities</h2>
        </Grid>
        <h3 style={{ margin: 0 }}>Five most active communities</h3>

        <GridTable
          getRowId={(r) => r._id}
          rows={communityList ?? []}
          columns={columnsCommunities}
          backIconButtonProps={{ disabled: false }}
          disableSelectionOnClick
        />
      </Grid>
    </div>
  );
};

export default AdminDashboard;
