import React, { useEffect } from "react";
import ViewJob from "./ViewJob";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import * as jobPostAction from "../../../redux/actions/jobPostAction";

function ViewJobContainer() {
  const dispatch = useDispatch();

  const showLoader = useSelector(
    (state) => state?.postedJobDetailsData.isLoading || state?.getApplicantDetailsData.isLoading
  );

  const postedJobDatas = useSelector((state) => state.postedJobData);
  const getApplicantDetailsData = useSelector((state) => state.getApplicantDetailsData);

  const getApplicatntDetails = (data) => {
    dispatch(jobPostAction.getApplicantDetailsJobs(data));
  };

  const getPostedJob = (data) => {
    dispatch(jobPostAction.postedJobs(data));
  };


  const defaultData = {
    page: 1,
    limit: 1000,
  };

  useEffect(() => {
    getPostedJob(defaultData);
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <ViewJob postedJobDatas={postedJobDatas?.data?.response?.rows}
      getApplicatntDetails={getApplicatntDetails}
      getApplicantDetailsData={getApplicantDetailsData?.data?.response} />
    </>
  );
}

export default ViewJobContainer;
