import { all } from "redux-saga/effects";
import myCommunitySaga from "./communitySaga";
import myLoginSaga, { loginRequest } from "./loginSaga";
import myConversionSaga from "./conversationSaga";
import mysignupSaga from "./SignupSaga ";
import mySearchSaga from "./searchSaga";
import myFileUploadSaga from "./UploadFilesSaga";
import myEditProfileSaga from "./editProfileSaga";
import myVideoUploadSaga from "./UploadVideoSaga";
import myJobPostSaga from "./jobPostSaga";

import mySettingSaga from "./settingSaga";
import myAdminSaga from "./adminSaga";
import myMentorshipSaga from "./mentorshipSaga";
export default function* rootSaga() {
  yield all([
    ...myLoginSaga,
    ...myCommunitySaga,
    ...myConversionSaga,
    ...mysignupSaga,
    ...mySearchSaga,
    ...myFileUploadSaga,
    ...myEditProfileSaga,
    ...myVideoUploadSaga,
    ...myJobPostSaga,
    ...mySettingSaga,
    ...myAdminSaga,
    ...myMentorshipSaga,
  ]);
}
