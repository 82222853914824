export const FORGOT_PASSWORD = "/forgot-password";
export const VIEW_PROFILE = "/view-profile";
export const EDIT_PROFILE = "/edit-profile";
export const MESSAGES = "/messages";
export const SEARCH = "/search";
export const PROFILE = "/profile";
export const CREATE_RESUME = "/create-resume";
export const CREATE_JOB = "/Job-Post";
export const ADMIN_LOGN = "/admin/admin-login";
export const ADMIN_DASHBOARD = "/admin/admin-dashboard";
export const ADMIN_COMMUNITY = "/admin/admin-community";
export const CREATE_COMMUNITY = "/admin/create-community";
export const ADMIN_ANALYTICS = "/admin/admin-analytics";
export const ADMIN_MENTORSHIP = "/admin/admin-mentorship";
export const ADMIN_EXPIRING_USERS = "/admin/admin-expiring-users";
export const ADMIN_MANAGING = "/admin/admin-managing";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const SEARCH_JOB = "/search-job";
export const VIEW_JOB = "/view-jobs-details";
export const SEARCH_MENTOR = "/search-mentor";
export const MANAGE_MENTORSHIP = "/manage-mentorship";
export const MENTORSHIP_CALENDAR = "/mentorship-calendar";
export const MENTORSHIP_BOOK_SESSION = "/mentorship-book-session";
export const MEETING = "/meeting";