import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchAutoComplete.scss";

export default function SearchAutoComplete({
  isUserDetails,
  optionsData,
  onChange,
  value,
  name,
  isError = false,
  errorMsg = "",
  id,
  disabled,
  typeOnchange,
  isLoading = false,
  placeholderText = "",
  noOptionsTextMsg = "No Data",
  autoCompClear = null,
}) {
  const onChangehandler = (event, value) => {
    onChange(event, value);
  };
  return (
    <div className="overideSearchAuto">
      <Autocomplete
        popupIcon={<SearchIcon />}
        disablePortal
        disabled={disabled}
        name={name ? name : "defaultName"}
        id={id}
        loading={isLoading}
        options={optionsData ?? []}
        style={{ padding: "0px", borderRadius: "15px" }}
        size="small"
        noOptionsText={noOptionsTextMsg}
        clearOnEscape={true}
        ref={autoCompClear}
        onChange={(event, value) => onChangehandler(event, value)}
        getOptionLabel={(option) => option}
        className="formControlRoot"
        renderInput={(params) => (
          <TextField
            onChange={(event, newInputValue) => {
              typeOnchange(event, newInputValue);
            }}
            error={isError}
            placeholder={placeholderText}
            helperText={errorMsg}
            value={value}
            className="formControlRoot"
            style={{ borderRadius: "15px" }}
            InputProps={{
              className: "inputRoot",
            }}
            {...params}
          />
        )}
      />
    </div>
  );
}
