import { get, post } from "./axiosRequest";
import { getApiUrl } from "../config";

export const getAdminReport = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const url = `${getApiUrl().baseUrl}/admin/report?email=${email}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAdminAboutToExpireUsers = async () => {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const url = `${
    getApiUrl().baseUrl
  }/admin/get-about-to-expire-users?email=${email}`;
  try {
    return await get(url, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateIndustry = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/admin/update`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAdminCount = async ({ data }) => {
  const token = sessionStorage.getItem("token");
  const url = `${getApiUrl()?.baseUrl}/admin/user-count`;
  try {
    return await post(url, data, {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
