import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackButton from "../../../library/custom/backBtn/BackButton";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import MyCalendar from "./Calendar";
import { MONTHS } from "../../../constant/constant";
import CloseIcon from "@mui/icons-material/Close";
import TextArea from "../../../library/custom/textArea/TextArea";
import "./BookSessionForm.scss";
import Buttons from "../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertToUTC, convertUTCToTimezone } from "../../../utils/helper";
const BookSessionForm = ({ slots = [], uuid, onBook }) => {
  const navigate = useNavigate();
  const [sessionFormValue, setsessionFormValue] = useState({
    skills: [],
    selectedSkill: "",
    slot: "",
    slotView: "",
    message: "",
  });
  const [slotsList, setslotsList] = useState([]);
  function handleBack(params) {}
  const timeZone = useSelector(
    (state) =>
      state?.userDetails?.data?.response?.profile?.personal_details?.timezone ??
      ""
  );
  useEffect(() => {
    let arr = [];
    slots?.forEach((i) => {
      let itemarr = i.split(" ");
      let dateArr = itemarr[0].split("-");
      const startHour = itemarr[1].split(":")[0];
      const endHour = itemarr[1].split(":")[1].split("-")[1];
      const startMin = itemarr[1].split(":")[1].split("-")[0];
      const endMin = itemarr[1].split(":")[2];
      arr.push({
        booked: false,
        time: itemarr[1],
        year: dateArr[0],
        month: dateArr[1],
        day: dateArr[2],
        title: "Available",
        start: new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          parseInt(startHour),
          parseInt(startMin)
        ), // June 6, 2024 10:30 AM
        end: new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          parseInt(endHour),
          parseInt(endMin)
        ), // June 6, 2024 12:00 PM
      });
    });

    setslotsList(arr);
  }, [slots]);
  const [slotErr, setslotErr] = useState(false);

  useEffect(() => {
    if (sessionFormValue.slot !== "") {
      const dateTimeArr = sessionFormValue.slot.split(" ");
      const dateArr = dateTimeArr[0].split("-");
      const date = dateArr[1];
      const monthName = MONTHS[date - 1];
      const day = dateArr[2];
      setslotErr(false);
      setsessionFormValue({
        ...sessionFormValue,
        slotView: `${monthName} ${day} | ${dateTimeArr[1]}`,
      });
    }
  }, [sessionFormValue.slot]);

  return (
    <div className="overrideBookSessionForm">
      <Grid container>
        <BackButton
          label={"Back to Mentorship"}
          route={-1}
          fromSerach={false}
          onClick={handleBack()}
        />
      </Grid>
      <Grid textAlign={"left"}>
        <h1>Book your Mentorship Session</h1>
      </Grid>

      <MyCalendar
        slots={slotsList}
        handleSelectEvent={(e) => {
          const dateArr = e.startDate.split("-");
          const startDate = `${dateArr[0]}-${
            dateArr[1] > 9 ? dateArr[1] : dateArr[1].slice(1)
          }-${dateArr[2] > 9 ? dateArr[2] : dateArr[2].slice(1)}`;
          setsessionFormValue({
            ...sessionFormValue,
            slot: `${startDate} ${e.startTime.slice(0, 5)}-${e.endTime.slice(
              0,
              5
            )}`,
          });
        }}
      />
      {sessionFormValue.slot !== "" && (
        <Grid container mt={4}>
          <Grid container>
            <FormLabels label={"Selected Slot"} />
          </Grid>
          <Grid
            container
            item
            lg={6}
            alignItems="left"
            justifyContent={"space-between"}
            textAlign={"left"}
            className="slot"
          >
            <p>{sessionFormValue.slotView}</p>
            <IconButton
              onClick={() =>
                setsessionFormValue({
                  ...sessionFormValue,
                  slot: "",
                  slotView: "",
                })
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid textAlign={"left"} mt={4}>
        <FormLabels label="Add Message  (Why do you want to be mentored?)" />
        <TextArea
          id={"description"}
          name={"description"}
          placeholder={"Enter here"}
          value={sessionFormValue.message}
          onChange={(e) => {
            setsessionFormValue({
              ...sessionFormValue,
              message: e.target.value,
            });
          }}
        />
      </Grid>

      <Grid mt={4} container lg={6} gap={1}>
        <Grid container>
          {slotErr && <span className="errMsg">Please select slot</span>}
        </Grid>
        <Grid className="btn">
          <Buttons
            onClick={() => {
              if (!sessionFormValue.slot) setslotErr(true);
              else
                onBook({
                  uuid: uuid,
                  timeSlot: timeZone
                    ? convertToUTC(sessionFormValue.slot,timeZone)
                    : sessionFormValue.slot,
                  message: sessionFormValue.message,
                });
            }}
            varientContained={true}
            label={"Request Mentorship"}
          />
        </Grid>
        <Buttons
          onClick={() => navigate(-1)}
          varientLeaveContained={true}
          label={"Cancel"}
        />
      </Grid>
    </div>
  );
};

export default BookSessionForm;
