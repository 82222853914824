import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import "./RadioButton.scss"
export default function RadioButton({
  value,
  onChange,
  label,
  checked,
  id,
  disabled,
}) {
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      className="radioColor"
      control={
        <Radio
          checked={checked}
          id={id ? id : "defaultId"}
          onChange={(e) => onChange(e)}
        />
      }
      label={label}
    />
  );
}
