import { Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import "./Chat.scss";
import { useDispatch, useSelector } from "react-redux";
import MessageUserCard from "./MessageUserCard";
import * as FileUpload from "../../../redux/actions/UploadVideoAction";
import {
  ConvertDate,
  formatTime,
  getCurrentDate,
  getCurrentTime,
} from "../../../utils/helper";
import replySendButtonIcon from "../../../assets/replySendButtonIcon.svg";
import attachFile from "../../../assets/attach_file.svg";
import TextArea from "../../../library/custom/textArea/TextArea";
import { messageIcons } from "../../../assets/index";
import mood from "../../../assets/mood.svg";
// import attachFile from "../../../assets/attachFile.svg";
import "./Chat.scss";
import {
  API_STATUS,
  PAGE_SIZE,
  docMaxSize,
  imgMaxSize,
  videoMaxSize,
} from "../../../constant/constant";
const Chat = ({
  sendersName,
  profilePic,
  allMessage,
  conversationId,
  startMessaging,
  getAllMessages,
  sendMessage,
  bottomRef,
  uploadedImage,
  conversationUser,
}) => {
  const initialState = {
    conversationId: "",
    message: {
      text: "",
      first_name: "",
      reciever: "",
      owner: "",
      sender: "",
      attachments: "",
      messageType: "text",
      timeStamp: "",
    },
  };
  const createUploadVideoDat = useSelector(
    (state) => state.createUploadVideoData
  );
  const [formValue, setFormValue] = useState(initialState);
  useEffect(() => {
    if (sendMessage && sendMessage.responseCode === 200) {
      const {
        msg: { conversationId },
      } = sendMessage;

      const messageData = {
        conversationId: conversationId,
        page: 1,
        limit: PAGE_SIZE,
      };
      getAllMessages(messageData);
    }
  }, [sendMessage]);
  const dispatch = useDispatch();
  const handleCoverUploadMethod = (event) => {
    dispatch(FileUpload.createVideoUpload({ file: event.target.files[0] }));
  };
  function mappedData(datas) {
    if (!formValue.text && !datas) return false;
    const data = {
      conversationId: conversationId,
      message: {
        text: datas ? null : formValue.text,
        owner: sessionStorage.getItem("id"),
        reciever: conversationUser?.receiver,
        sender: conversationUser?.sender,
        attachments: uploadedImage?.fileUrl,
        attachments: datas ? { path: datas.fileUrl, _type: datas.type } : "",
        messageType: datas ? datas.type : "text",
        timeStamp: `${new Date().toISOString()}`,
      },
    };
    return data;
  }

  const submitData = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      startMessaging(data);
      setFormValue({ ...formValue, text: "" });
    }
  };
  function submitOnEnterPress(e) {
    if (e.key === "Enter") {
      submitData(e);
    }
  }
  useEffect(() => {
    if (createUploadVideoDat.status === API_STATUS.SUCCESS);
    {
      const data = mappedData(createUploadVideoDat.data);
      if (!data) {
      } else {
        startMessaging(data);
      }
    }
  }, [createUploadVideoDat]);
  useEffect(() => {
    if (allMessage?.data?.responseCode === 200) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [allMessage]);

  const uploadAttachment = (input) => {
    console.log("sdsfdf turi");
    if (input.target.files && input.target.files[0]) {
      let attachType = input.target.files[0].type.split("/")[0];
      if (
        (attachType === "application" || attachType === "document") &&
        input.target.files[0].size > docMaxSize
      ) {
        console.log("File size is too large to be sent");
        return false;
      } else if (
        attachType === "video" &&
        input.target.files[0].size > videoMaxSize
      ) {
        console.log("File size is too large to be sent");
        return false;
      } else if (
        attachType === "image" &&
        input.target.files[0].size > imgMaxSize
      ) {
        console.log("File size is too large to be sent");
        return false;
      } else {
        dispatch(FileUpload.createVideoUpload({ file: input.target.files[0] }));
      }
    }
  };

  const renderMessage = () => {
    const messages = Object.entries(allMessage?.data?.msg);
    const userId = sessionStorage.getItem("id");
    const renderData =
      messages &&
      messages.map((data) => {
        return (
          <Grid container item className="replies" ref={bottomRef}>
            {data[1].map((x) => {
              const today = new Date();
              const todayDate = today?.getDate();
              const compareDate = new Date(x?.message?.createdOn);
              const comTodatDateDate = compareDate?.getDate();
              const fomatedTime = formatTime(x?.message?.createdOn);
              const formattedDate = ConvertDate(x?.message?.createdOn);
              return (
                <>
                  {x.message.text ? (
                    <Grid
                      mt={0}
                      container
                      lg={12}
                      item
                      className={
                        x.message.owner === userId
                          ? "sentMsgssss"
                          : "receivedMsgssss"
                      }
                    >
                      <p className="sendMessag">{x.message.text}</p>
                    </Grid>
                  ) : null}

                  {x.message?.attachments?.path ? (
                    <div
                      className={
                        x.message.owner === userId
                          ? "imageBoxSender"
                          : "imageBoxReceiver"
                      }
                    >
                      <a href={x.message?.attachments?.path} target="_blank">
                        <img
                          className="image"
                          src={x.message?.attachments?.path}
                        />
                      </a>
                    </div>
                  ) : null}

                  <div
                    className={
                      x.message.owner === userId
                        ? "sendMessageDates"
                        : "receiveMessageDate"
                    }
                  >
                    {comTodatDateDate === todayDate ? "Today" : formattedDate}{" "}
                    {fomatedTime}
                  </div>
                </>
              );
            })}
          </Grid>
        );
      });
    return renderData;
  };

  return (
    <div className="overrideChat">
      <Grid container>
        <Grid container item lg={12} mt={2}>
          <MessageUserCard
            date={false}
            userName={sendersName}
            image={profilePic}
            message={false}
            unReadText={false}
          />
        </Grid>
        <Grid className="chatBox">
          <Grid container item className="chatSections">
            <Grid container item className="conversations">
              {renderMessage()}
            </Grid>
          </Grid>
          <Grid
            className="overrideChatInputField"
            // justifyContent={"right"}
            container
            // fullWidth
            p={1}
            mb={1}
          >
            <Grid item lg={8} className="messageInput">
              <TextArea
                onChange={(e) => {
                  setFormValue({ ...formValue, text: e.target.value });
                }}
                placeholder="Write a Message"
                value={formValue.text}
                onKeyDown={(e) => submitOnEnterPress(e)}
              />
            </Grid>
            <Grid className="messageIcons" lg={4}>
              <IconButton
                onClick={submitData}
                className="sendButton"
                aria-label="delete"
              >
                <img src={replySendButtonIcon} alt="" />
              </IconButton>
              <div className="file-input">
                <input
                  type="file"
                  accept="image/*"
                  name="file-input"
                  id="file-input"
                  className="file-input__input"
                  onChange={(e) => uploadAttachment(e)}
                />
                <label className="file-input__label" for="file-input">
                  <img src={attachFile} alt="" />
                </label>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
