import { uploadVideoType } from "../constant/UploadVideoType";

export const createVideoUpload = (data) => ({
  type: uploadVideoType.POST_UPLOAD_VIDEO_PENDING,
  data,
});

export const createVideoUploadSuccess = (data) => ({
  type: uploadVideoType.POST_UPLOAD_VIDEO_SUCCESS,
  data,
});

export const createVideoUploadFailure = (error) => ({
  type: uploadVideoType.POST_UPLOAD_VIDEO_FAILURE,
  error,
});

export const clearVideo = (error) => ({
  type: uploadVideoType.CLEAR,
  error,
});
// UPLOAD CONTENT IMAGE
export const createContentUpload = (data) => ({
  type: uploadVideoType.POST_UPLOAD_CONTENT_PENDING,
  data,
});

export const createContentUploadSuccess = (data) => ({
  type: uploadVideoType.POST_UPLOAD_CONTENT_SUCCESS,
  data,
});

export const createContentUploadFailure = (error) => ({
  type: uploadVideoType.POST_UPLOAD_CONTENT_FAILURE,
  error,
});
