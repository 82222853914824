import * as React from "react";
import Button from "@mui/material/Button";
import "./Button.scss";
import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
export default function Buttons({
  label,
  disabled,
  onClick,
  varientText,
  varientContained,
  id,
  type,
  varientLeaveContained,
  variantOutlineSections,
  varientPostContained,
  varientCommentContained,
  icon,
  variantJobApply,
  variantCalendorBtn,
}) {
  return (
    <div className="overideBtn">
      {varientText && (
        <Button
          disabled={disabled}
          className="textButton"
          variant="text"
          onClick={onClick}
        >
          {icon && <img className="icon" src={icon} alt="" />}
          {label}
        </Button>
      )}
      {varientContained && (
        <Button
          id={id}
          disabled={disabled}
          className="containedButton"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}
      {varientLeaveContained && (
        <Button
          id={id}
          disabled={disabled}
          className="containedLeaveButton"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}
      {variantOutlineSections && (
        <Button
          disabled={disabled}
          variant="outlined"
          className="outlinedButton"
          onClick={onClick}
          startIcon={
            <AddIcon
            //className={disabled ? "addIconGrey" : "addIconBtnsblue"}
            />
          }
        >
          {label}
        </Button>
      )}
      {varientPostContained && (
        <Button
          id={id}
          disabled={disabled}
          className="containedPostButton"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}
      {varientCommentContained && (
        <Button
          id={id}
          disabled={disabled}
          className="containedCommentButton"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}

      {variantJobApply && (
        <Button
          disabled={disabled}
          variant="outlined"
          className="outlinedButton"
          onClick={(e) => onClick(e)}
          startIcon={
            <OpenInNewIcon
            //className={disabled ? "addIconGrey" : "addIconBtnsblue"}
            />
          }
        >
          {label}
        </Button>
      )}
      {variantCalendorBtn && (
        <Button
          disabled={disabled}
          variant="contained"
          className="containedButton"
          onClick={(e) => onClick(e)}
          startIcon={
            <CalendarTodayIcon
            //className={disabled ? "addIconGrey" : "addIconBtnsblue"}
            />
          }
        >
          {label}
        </Button>
      )}
    </div>
  );
}
