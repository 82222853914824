import React from "react";
import SearchJob from "../compenent/SearchJob";
import * as jobAction from "../../../redux/actions/jobPostAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../../library/common/Loader";

const SearchJobContainer = () => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const getJobFindData = useSelector((state) => state?.getJobFind);
  const getJobInfoData = useSelector((state) => state?.getJobInfo);
  const applyJobData = useSelector((state) => state?.postJobApply);
  const appliedJobsData = useSelector((state) => state?.getAppliedJobs);
  const isLoading = useSelector(
    (state) =>
      state?.getAppliedJobs?.isLoading ||
      state?.postJobApply?.isLoading ||
      state?.getJobFind?.isLoading
  );
  const [appliedJobIds, setappliedJobIds] = useState([]);
  useEffect(() => {
    if (
      appliedJobsData?.isLoading === false &&
      appliedJobsData?.status === "SUCCESS"
    ) {
      pushArrpliedJobIds();
    }
  }, [appliedJobsData]);

  function pushArrpliedJobIds(params) {
    let arr = [];
    appliedJobsData?.data?.response?.map((i) => arr.push(i?.jobId));
    setappliedJobIds(arr);
  }
  useEffect(() => {
    if (!getJobFindData?.isLoading && getJobFindData?.status === "SUCCESS") {
      setItems(getJobFindData?.data?.response?.rows);
      setPage((prevPage) => prevPage + 1);
    }
  }, [getJobFindData]);

  function getJobs(params) {
    dispatch(
      jobAction.getJobFind({
        location: "",
        title,
        page: 1,
      })
    );
    dispatch(
      jobAction.getAppliedJobs({
        title,
        page: 1,
      })
    );
  }
  useEffect(() => {
    getJobs();
  }, []);

  function handleLocation(params) {
    setLocation(params);
    dispatch(
      jobAction.getJobFind({
        location: params,
        title: title,
        page: page,
      })
    );
  }
  function handleTitle(params) {
    setTitle(params);
    dispatch(
      jobAction.getJobFind({
        title: params,
        location: location,
        page: page,
      })
    );
  }
  function getJobInfo(e, params) {
    dispatch(jobAction.getJobInfo(params));
  }
  function applyJob(e, params) {
    e.stopPropagation();
    dispatch(
      jobAction.postApplyJob({
        jobid: params,
      })
    );
  }
  return (
    <div>
      {isLoading && <Loader />}
      <SearchJob
        items={items}
        handleLocation={handleLocation}
        handleTitle={handleTitle}
        getJobInfo={getJobInfo}
        getJobInfoData={getJobInfoData}
        applyJob={applyJob}
        appliedJobIds={appliedJobIds}
        applyJobData={applyJobData}
        getJobs={getJobs}
      />
    </div>
  );
};

export default SearchJobContainer;
