import React, { useEffect, useState } from "react";
import Settings from "../component/settings";
import { useDispatch, useSelector } from "react-redux";
import * as settingAction from "../../../redux/actions/settingAction";
import * as editProfileAction from "../../../redux/actions/editProfileAction";
import * as loginAction from "../../../redux/actions/loginAction";
import { useNavigate } from "react-router-dom";

import Loader from "../../../library/common/Loader";
const SettingsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchUserData = useSelector(
    (state) => state?.userDetails?.data?.response
  );

  const primaryOtp = useSelector((state) => state?.otpVerifySignupData);
  const allCountries = useSelector((state) => state?.countries);
  const paymentDetail = useSelector((state) => state?.paymentDetails?.data);
  const userDetails = useSelector((state) => state.userDetails?.data?.response);
  const [secondaryEmail, setSecondaryEmail] = useState(
    searchUserData?.basicDetails?.recovery_email
  );
  const [primaryEmail, setPrimaryEmail] = useState("");
  const getUserDetailsData = useSelector(
    (state) => state?.getUserDetailsData?.data
  );
  const updateEmail = useSelector((state) => state.updateEmail);

  const jobTypeData = useSelector(
    (state) => state?.getJobTypeData?.data?.response
  );
  const joTypeFormatData = jobTypeData?.map((job) => {
    return {
      key: job.id,
      value: job.Name,
    };
  });
  const paymentDetailLoader = useSelector(
    (state) => state?.paymentDetails?.isLoading
  );
  const recoveryEmailVerifys = useSelector(
    (state) => state?.recoveryEmailVerify
  );
  console.log(recoveryEmailVerifys);

  const getDeactivateAccount = (data) => {
    dispatch(settingAction.deactivateAccount(data));
  };
  const changePassword = (data) => {
    dispatch(settingAction.changePassword(data));
  };
  useEffect(() => {
    if (updateEmail?.status === "SUCCESS") {
      dispatch(loginAction.getUserDetails());
    }
  }, [updateEmail]);

  useEffect(() => {
    dispatch(editProfileAction?.getCountries());
    dispatch(settingAction.paymentDetails());
    dispatch(loginAction.getUserDetails());
    dispatch(editProfileAction?.getJobType());
    dispatch(editProfileAction.getUserDetails());
  }, []);

  useEffect(() => {
    if (recoveryEmailVerifys.status === "SUCCESS") {
      const updateEmailDataPrimary = {
        email: secondaryEmail,
        type: "recovery",
      };
      dispatch(settingAction.updateEmail(updateEmailDataPrimary));
    }
  }, [recoveryEmailVerifys]);
  useEffect(() => {
    if (primaryOtp.status === "SUCCESS") {
      const updateEmailDataPrimary = {
        email: primaryEmail,
        type: "email",
      };
      dispatch(settingAction.updateEmail(updateEmailDataPrimary));
    }
  }, [primaryOtp]);
  const onSubmitCompensation = (data) => {
    dispatch(editProfileAction?.updateCompansation(data));
  };

  const putEditProfiles = (data) => {
    dispatch(editProfileAction.putEditProfile(data));
  };
  const deactivateAccount = useSelector((state) => state.deactivateAccount);
  const editProfileData = useSelector((state) => state.editProfile);
  const updateCompansationData = useSelector(
    (state) => state.updateCompansationData
  );
  const showLoader = useSelector(
    (state) =>
      state?.countries?.isLoading ||
      state?.paymentDetails?.isLoading ||
      state?.userDetails?.isLoading ||
      state?.changePassword?.isLoading ||
      state?.updateEmail?.isLoading ||
      state?.updateCompansationData?.isLoading ||
      state?.editProfile?.isLoading ||
      state?.getUserDetailsData?.isLoading
  );
  const showOverLoader = useSelector(
    (state) =>
      state?.otpVerifySignupData?.isLoading ||
      state?.recoveryEmailVerify?.isLoading
  );

  useEffect(() => {
    if (!editProfileData.isLoading && editProfileData.status === "SUCCESS") {
      window.location.reload();
    }
  }, [editProfileData]);
  useEffect(() => {
    if (
      !updateCompansationData.isLoading &&
      updateCompansationData.status === "SUCCESS"
    ) {
      window.location.reload();
    }
  }, [updateCompansationData]);

  const otpVerifydata = primaryOtp?.responseCode;

  return (
    <div className="overrideSettingsContainer">
      {showOverLoader && <Loader />}
      {showLoader ? (
        <Loader color="inherit" />
      ) : (
        <Settings
          paymentDetail={showLoader ? [] : paymentDetail?.response}
          deactivateAccount={deactivateAccount}
          getDeactivateAccount={getDeactivateAccount}
          changePassword={changePassword}
          allCountries={allCountries}
          searchUserData={searchUserData}
          jobTypeData={joTypeFormatData}
          onSubmitCompensation={onSubmitCompensation}
          getUserDetailsData={getUserDetailsData}
          userDetails={userDetails}
          putEditProfiles={putEditProfiles}
          recoveryEmailVerifys={recoveryEmailVerifys}
          otpVerifySignupData={primaryOtp}
          setSecondaryEmail={setSecondaryEmail}
          setPrimaryEmail={setPrimaryEmail}
          primaryEmail={primaryEmail}
          secondaryEmail={secondaryEmail}
        />
      )}
    </div>
  );
};

export default SettingsContainer;
