import { Grid } from "@mui/material";
import React from "react";
import matchupITLogo from "../../assets/Landing/matchupITLogo.png";
import "./Navbar.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { LOGIN, SIGNUP } from "../../constant/routeContant";

function Navbar() {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(LOGIN);
  };
  const goToSignup = () => {
    navigate(SIGNUP);
  };

  return (
    <div className="override-Navbar">
      <div onClick={() => navigate("/")} className="logo">
        <img src={matchupITLogo} className="match-logo" alt="logo" />
      </div>
      <div className="buttonBody">
        <div className="btnLogin">
          <Button variant="text" className="loginbtn" onClick={goToLogin}>
            Login
          </Button>
        </div>
        <div className="btnSignup">
          <Button
            variant="contained"
            className="signupbtn"
            onClick={goToSignup}
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
