import React from "react";
import { InputAdornment, TextField } from "@mui/material";
// import FormLabels from "../formLabel/FormLabels";
import "./inputField.scss"
import SearchIcon from "@mui/icons-material/Search";;

export function InputField({
  fullWidth,
  value,
  disabled,
  type,
  label,
  placeholder,
  onChange,
  isRequired,
  id,
  isError = false,
  errorMsg = "",
  name,
  isIcon=false
}) {
  return (
    <div className="overideSearchInputField">
      <div className="input_field_box">
        <TextField
          id={id ? id : "defaultId"}
          style={{ width: "100%", margin: 0 }}
          variant="standard"
          placeholder={placeholder}
          
          autoComplete="off"
          disabled={disabled}
          type={type}
          value={value}
          className="formControlRoot"
          onChange={(e) => onChange(e)}
          fullWidth={fullWidth}
          size="small"
          InputProps={{
            className: "inputRoot",
            disableUnderline: true,
            
          startAdornment: isIcon && (
            <InputAdornment position="start">
            <SearchIcon className="icon"/>
            </InputAdornment>
          ),
          }}
          error={isError}
          helperText={value ? "" : errorMsg}
          name={name}
        />
      </div>
    </div>
  );
}
