import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MessagesDashboard from "../component/MessagesDashboard";
import * as conversationAction from "../../../redux/actions/conversationAction";
import * as UploadFilesAction from "../../../redux/actions/UploadFilesAction";
import Loader from "../../../library/common/Loader";
import { useLocation } from "react-router-dom";

const MessagesContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const chatId = location?.state?.id;
  const uploadProfileFile = (data) => {
    dispatch(
      UploadFilesAction.createFileUpload({
        filename: "string",
        file: data,
      })
    );
  };

  const allConversation = useSelector(
    (state) => state?.getAllConversation?.data?.conversations
  );

  const userData = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails
  );

  const uploadedImage = useSelector((state) => state?.createUploadVideoData);

  const allMessage = useSelector((state) => state.getAllMessage);
  const unReadMessage = useSelector((state) => state.getUnreadMessage);

  const getAllMessages = (data) => {
    dispatch(conversationAction.getAllMessage(data));
    dispatch(conversationAction.getUnreadMessage());
  };

  const userSearch = useSelector((state) => state?.getUserSearch);
  const headShot = useSelector(
    (state) => state?.uploadFiles?.data?.response?.location
  );
  const handleUserSearch = (type, searchText) => {
    const data = {
      searchText: searchText,
      accounType: type,
    };
    dispatch(conversationAction.getUserSearch(data));
  };

  const isLoading = userSearch?.isLoading;

  useEffect(() => {
    dispatch(conversationAction.getAllConversation());
    dispatch(conversationAction.getUnreadMessage());
  }, []);

  const showLoader = useSelector(
    (state) =>
      state?.getAllConversation?.isLoading ||
      state?.createUploadVideoData?.isLoading ||
      state?.sendMessage?.isLoading
  );
  const conversationsLoading = useSelector(
    (state) => state?.getAllConversation?.isLoading
  );

  const sendMessage = useSelector((state) => state.sendMessage?.data);

  const startMessaging = (data) => {
    dispatch(conversationAction.postAddMessage(data));
  };

  return (
    <div>
      {showLoader && <Loader color="inherit" />}
      {conversationsLoading ? (
        <Loader color="inherit" />
      ) : (
        <MessagesDashboard
          allConversation={allConversation}
          getAllMessages={getAllMessages}
          allMessage={allMessage}
          handleUserSearch={handleUserSearch}
          userSearch={userSearch?.data?.users ?? []}
          isLoading={isLoading}
          userData={userData}
          sendMessage={sendMessage}
          startMessaging={startMessaging}
          unReadMessage={unReadMessage}
          chatId={chatId}
          uploadProfileFile={uploadProfileFile}
          headShot={headShot}
          uploadedImage={uploadedImage?.data}
        />
      )}
    </div>
  );
};

export default MessagesContainer;
