import * as React from "react";
import Card from "@mui/material/Card";
import "./CommunityCard.scss";
import Button from "../button/Button";
import CommunityPerson from "../../../../src/assets/CommunityPerson.svg";
import { useNavigate } from "react-router-dom";
import CommunityIcons from "../../../assets/communityCommonIcon.svg";

export default function CommunityCard({
  heading = "Community Name",
  CommunityContent = "Community Content",
  userCount = "",
  handleClick,
  id,
  id_member = true,
  usrcount,
  icon,
}) {
  const navigate = useNavigate();
  return (
    <div className="CommunityCardContainer">
      <Card
        className="CommunityCard"
        onClick={() => {
          navigate("/view/community", {
            state: {
              data: id,
              dataid: id,
              usercountJoin: userCount,
              icon: icon,
            },
          });
        }}
      >
        <div className="communityheader">
          <div className="communityicn">
            <img src={icon ? icon : CommunityIcons} alt="" />
          </div>
          <div className="CommunitysName">
            <h1>{heading}</h1>
          </div>
        </div>
        <p className="CommunityContent">{CommunityContent}</p>
        <div className="CommunityBtnStyle">
          <div className="CommunityPersonCount">
            <img src={CommunityPerson} alt="CommunityPerson" /> {userCount}
          </div>
          <div className="joinbtn">
            {!id_member && (
              <Button
                className="ButtonStyle"
                varientContained={true}
                label="Join"
                onClick={() => handleClick(id, userCount, icon)}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
