import { adminType } from "../constant/adminType";

export const getAdminReport = (data) => ({
  type: adminType.GET_ADMIN_REPORT_PENDING,
  data,
});

export const getAdminReportSuccess = (data) => ({
  type: adminType.GET_ADMIN_REPORT_SUCCESS,
  data,
});

export const getAdminReportFailure = (error) => ({
  type: adminType.GET_ADMIN_REPORT_FAILURE,
  error,
});

export const getAdminAboutToExpireUsers = (data) => ({
  type: adminType.GET_ADMIN_ABOUT_TO_EXPIRE_PENDING,
  data,
});

export const getAdminAboutToExpireUsersSuccess = (data) => ({
  type: adminType.GET_ADMIN_ABOUT_TO_EXPIRE_SUCCESS,
  data,
});

export const getAdminAboutToExpireUsersFailure = (error) => ({
  type: adminType.GET_ADMIN_ABOUT_TO_EXPIRE_FAILURE,
  error,
});

export const postAdminUpdateIndustry = (data) => ({
  type: adminType.POST_ADMIN_UPDATE_INDUSTRY_PENDING,
  data,
});

export const postAdminUpdateIndustrySuccess = (data) => ({
  type: adminType.POST_ADMIN_UPDATE_INDUSTRY_SUCCESS,
  data,
});

export const postAdminUpdateIndustryFailure = (error) => ({
  type: adminType.POST_ADMIN_UPDATE_INDUSTRY_FAILURE,
  error,
});

export const getAdminCount = (data) => ({
  type: adminType.GET_ADMIN_COUNT_PENDING,
  data,
});

export const getAdminCountSuccess = (data) => ({
  type: adminType.GET_ADMIN_COUNT_SUCCESS,
  data,
});

export const getAdminCountFailure = (error) => ({
  type: adminType.GET_ADMIN_COUNT_FAILURE,
  error,
});
