import { settingType } from "../constant/settingType";

export const recoveryEmailVerify = (data) => ({
  type: settingType.GET_RECOVERY_EMAIL_VERIFY_PENDING,
  data,
});

export const recoveryEmailVerifySuccess = (data) => ({
  type: settingType.GET_RECOVERY_EMAIL_VERIFY_SUCCESS,
  data,
});
export const recoveryEmailVerifyFailure = (data) => ({
  type: settingType.GET_RECOVERY_EMAIL_VERIFY_FAILURE,
  data,
});

export const deactivateAccount = (data) => ({
  type: settingType.GET_DEACTIVATE_ACCOUNT_PENDING,
  data,
});

export const deactivateAccountSuccess = (data) => ({
  type: settingType.GET_DEACTIVATE_ACCOUNT_SUCCESS,
  data,
});
export const deactivateAccountFailure = (data) => ({
  type: settingType.GET_DEACTIVATE_ACCOUNT_FAILURE,
  data,
});

export const changePassword = (data) => ({
  type: settingType.PUT_CHANGE_PASSWORD_PENDING,
  data,
});

export const changePasswordSuccess = (data) => ({
  type: settingType.PUT_CHANGE_PASSWORD_SUCCESS,
  data,
});
export const changePasswordFailure = (data) => ({
  type: settingType.PUT_CHANGE_PASSWORD_FAILURE,
  data,
});

export const paymentDetails = (data) => ({
  type: settingType.GET_PAYMENT_DETAILS_PENDING,
  data,
});

export const paymentDetailsSuccessful = (data) => ({
  type: settingType.GET_PAYMENT_DETAILS_SUCCESS,
  data,
});
export const paymentDetailsFailure = (data) => ({
  type: settingType.GET_PAYMENT_DETAILS_FAILURE,
  data,
});

export const updateEmail = (data) => ({
  type: settingType.GET_UPDATE_EMAIL_PENDING,
  data,
});

export const updateEmailSuccessful = (data) => ({
  type: settingType.GET_UPDATE_EMAIL_SUCCESS,
  data,
});
export const updateEmailFailure = (data) => ({
  type: settingType.GET_UPDATE_EMAIL_FAILURE,
  data,
});

export const addCorporateMemeber = (data) => ({
  type: settingType.POST_ADD_CORPORATE_MEMBER_PENDING,
  data,
});

export const addCorporateMemberSuccessful = (data) => ({
  type: settingType.POST_ADD_CORPORATE_MEMBER_SUCCESS,
  data,
});
export const addCorporateMemeberFailure = (data) => ({
  type: settingType.POST_ADD_CORPORATE_MEMBER_FAILURE,
  data,
});

export const removeCorporateMemeber = (data) => ({
  type: settingType.POST_REMOVE_CORPORATE_MEMBER_PENDING,
  data,
});

export const removeCorporateMemberSuccessful = (data) => ({
  type: settingType.POST_REMOVE_CORPORATE_MEMBER_SUCCESS,
  data,
});
export const removeCorporateMemeberFailure = (data) => ({
  type: settingType.POST_REMOVE_CORPORATE_MEMBER_FAILURE,
  data,
});
