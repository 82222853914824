import { useEffect, useState } from "react";
import { Button, Grid, RadioGroup } from "@mui/material";
import matchupITLogo from "../../../assets/Landing/matchupITLogo.png";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import { InputField } from "../../../library/custom/textBox/InputField";
import "./Login.scss";
import SuccessScreen from "../../../library/custom/success/successScreen";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PasswordPic from "../../../assets/PasswordPic.jpg";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../../library/custom/passwordField/PasswordField";

const ForgotPassword = ({
  sendOTP,
  forgotPasswordData,
  resetPassword,
  resetPasswordData,
  resetPasswordErrorMsg,
  setpasswordREsetCalled,
  getOTPErrorMsg,
}) => {
  const navigate = useNavigate();
  let defaultErrorStates = {
    password: false,
    confirmPassword: true,
    otp: false,
  };

  const style = {
    boxShadow: "rgb(204 204 204) 2px 2px 3px 3px",
    position: "absolute",
    marginLeft: "390px",
    background: "#fff",
    borderRadius: "4px",
    padding: "10px",
  };
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [successScreens, setSuccessScreens] = useState(false);
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isOtpError, setisOtpError] = useState(false);
  const [emailEmptyError, setEmptyEmailError] = useState(false);

  const [errorList, setErrorList] = useState(defaultErrorStates);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [accountType, setaccountType] = useState("individual");
  function handleAccountType(e) {
    setaccountType(e);
  }

  function sendForgotPasswordOTP() {
    let error = false;
    if (password !== confirmPassword) {
      setIsConfirmPasswordError(true);
      // return;
      error = true;
    }

    if (password.trim() === "" || errorList.password === true) {
      setIsPasswordError(true);
      setErrorList({ ...errorList, password: true });
      // return;
      error = true;
    }
    if (otp.trim() === "") {
      setisOtpError(true);
      setErrorList({ ...errorList, otp: true });
      // return;
      error = true;
    }

    if (showPasswordFields && !error) {
      setSuccessScreens(true);
      setpasswordREsetCalled(true);
      setShowPasswordFields(false);
      resetPassword(email, accountType, password, otp);
    }
  }
  function sendEmailOTP() {
    sendOTP(email);
    setotpError(false);
  }
  const [otpError, setotpError] = useState(false);
  useEffect(() => {
    console.log(forgotPasswordData)
    if (
      forgotPasswordData?.data?.responseCode === 200 &&
      forgotPasswordData?.data.msg === "OTP succssfully sent"
    ) {
      setShowPasswordFields(true);
      setotpError(false);
    } else if (forgotPasswordData?.error?.message?.data?.msg !== 200) {
      setotpError(true);
    }
  }, [forgotPasswordData]);

  const successScreen = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={" "}
        route={"/login"}
        label1="Password Reset"
        label2="Successfully!"
        label4="Back to login in"
      ></SuccessScreen>
    );
  };
  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });

  const handleOnKeyUp = (e) => {
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdlength = value.length >= 8;
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    const whitespace = /\s/g.test(value);
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setShowErrors(false);
      setErrorList({
        ...errorList,
        password: false,
      });
    } else {
      setShowErrors(true);
      setErrorList({
        ...errorList,
        password: true,
      });
    }
  };

  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";
  return (
    <div className="overideLogin">
      {resetPasswordData.status === "SUCCESS" &&
      resetPasswordData.data &&
      successScreens ? (
        successScreen(resetPasswordData?.data?.id, "C Successfully")
      ) : (
        <Grid container>
          <Grid
            container
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
            }}
            item
            lg={5}
            xs={12}
            sm={6}
            md={6}
          >
            <img src={PasswordPic} className="leftPic" alt="no logo" />
          </Grid>

          <Grid
            className="formCon"
            container
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"left"}
            item
            lg={6}
            sm={12}
            md={6}
            p={5}
          >
            <Grid item lg={12} sm={9} xs={12} md={12}>
              <Grid
                justifyContent={"left"}
                item
                lg={8}
                xs={12}
                sm={12}
                md={12}
                mt={4}
              >
                <img
                  src={matchupITLogo}
                  style={{ width: "240px", height: "102px", cursor: "pointer" }}
                  className="light-logo"
                  alt="no logo"
                  onClick={() => navigate("/")}
                />
              </Grid>

              {!showPasswordFields && (
                <Grid item lg={10} xs={12} sm={12} mt={2}>
                  <h1
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "16px",
                      margin: "0px",
                      color: "#083A50",
                    }}
                  >
                    Account Type
                  </h1>
                  <FormLabels label={""} />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    <RadioButton
                      checked={accountType === "individual"}
                      value="individual"
                      label="Individual"
                      onChange={(e) => handleAccountType(e.target.value)}
                    ></RadioButton>
                    <RadioButton
                      checked={accountType === "corporate"}
                      value="corporate"
                      label="Corporate"
                      onChange={(e) => handleAccountType(e.target.value)}
                    ></RadioButton>
                  </RadioGroup>
                </Grid>
              )}

              <Grid con item lg={10} mt={6}>
                <h1
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "36px",
                    margin: "0px",
                    color: " #043133",
                  }}
                >
                  Reset Password
                </h1>
              </Grid>

              <Grid container item lg={11} md={12} xs={12} sm={12}>
                {!showPasswordFields && (
                  <Grid mt={2} item lg={12} md={12} xs={12}>
                    <h1
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "16px",
                        margin: "0px",
                        color: "#083A50",
                      }}
                    >
                      Email
                    </h1>
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <InputField
                        placeholder="Enter your Email here"
                        // label="email"
                        value={email}
                        onChange={(e) => {
                          setEmptyEmailError(false);
                          setEmail(e.target.value);
                        }}
                      />
                    </Grid>
                    {emailEmptyError && (
                      <p className="errorMsg">Please enter valid email</p>
                    )}
                  </Grid>
                )}

                {showPasswordFields && (
                  <Grid mt={2} item lg={11} md={12} xs={12} sm={12}>
                    <h1
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "16px",
                        margin: "0px",
                        color: "#083A50",
                      }}
                    >
                      OTP as sent on Email
                    </h1>
                    <Grid item lg={10.8} md={11} sm={12} xs={12}>
                      <InputField
                        placeholder="Enter OTP"
                        // label="email"
                        type={"number"}
                        value={otp}
                        onChange={(e) => {
                          setisOtpError(false);
                          setOtp(e.target.value);
                        }}
                      />
                    </Grid>
                    {isOtpError && showPasswordFields && (
                      <p className="errorMsg">Please enter valid OTP</p>
                    )}
                  </Grid>
                )}
                {showPasswordFields && (
                  <Grid mt={2} item lg={12} md={12} sm={12} xs={12}>
                    <h1
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "16px",
                        margin: "0px",
                        color: "#083A50",
                      }}
                    >
                      Password
                    </h1>

                    <Grid item lg={10} md={11} sm={12} xs={12}>
                      <PasswordField
                        value={password}
                        showPassword={showPassword}
                        placeholder="Enter your Password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setIsPasswordError(false);
                          setErrorList({
                            ...errorList,
                            password: false,
                          });
                        }}
                        id="password"
                        onFocus={() => setShowErrors(true)}
                        onBlur={() => setShowErrors(false)}
                        onKeyUp={() => handleOnKeyUp}
                        onEyeIconClick={() => setShowPassword(!showPassword)}
                      />
                    </Grid>
                  </Grid>
                )}
                {isPasswordError && showPasswordFields && (
                  <p className="errorMsg">Please enter valid password</p>
                )}
                {showErrors && (
                  <div style={style}>
                    <form>
                      <p className={pwdlenClass}>
                        <CheckCircleIcon className="checkbox" />
                        At least 8 characters
                      </p>
                      <p className={capsClass}>
                        <CheckCircleIcon className="checkbox" />
                        At least 1 uppercase letter
                      </p>
                      <p className={sletterClass}>
                        <CheckCircleIcon className="checkbox" />
                        At least 1 lowercase letter
                      </p>
                      <p className={specClass}>
                        <CheckCircleIcon className="checkbox" />
                        At least 1 special character
                      </p>
                      <p className={numcheckClass}>
                        <CheckCircleIcon className="checkbox" />
                        At least 1 number{" "}
                      </p>
                    </form>
                  </div>
                )}
                {showPasswordFields && (
                  <Grid mt={2} item lg={12} md={12} sm={12} xs={12}>
                    <h1
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "16px",
                        margin: "0px",
                        color: "#083A50",
                      }}
                    >
                      Confirm Password
                    </h1>

                    <Grid item lg={10} md={11} sm={12} xs={12}>
                      <PasswordField
                        placeholder="Enter your Password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setIsConfirmPasswordError(false);
                        }}
                        showPassword={showConfirmPassword}
                        onEyeIconClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                      {isConfirmPasswordError && (
                        <p className="errorMsg">Password don't match</p>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid container justifyContent={"left"} mt={2} item lg={10}>
                  {resetPasswordErrorMsg && (
                    <p className="errorMsg">{resetPasswordErrorMsg}</p>
                  )}
                  <Grid container>
                    {otpError && <p className="errorMsg">{getOTPErrorMsg}</p>}
                  </Grid>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      let validEMail =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      showPasswordFields
                        ? sendForgotPasswordOTP(email)
                        : email.match(validEMail)
                        ? sendEmailOTP()
                        : setEmptyEmailError(true);
                    }}
                    style={{
                      width: "200px",
                      height: "41px",
                      border: "none",
                      background: "#778DFF",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }}
                  >
                    {showPasswordFields ? "Reset Password" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ForgotPassword;
