import React, { useState } from "react";
import "./Accordion.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import p from "@mui/material/p";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordions from "../../../library/custom/accordion/Accordion";
import { useEffect } from "react";
import Delete from "../../../assets/delete.svg";
import EditBtn from "../../../assets/EditBtn.svg";
import ArrowUp from "../../../assets/arrow_up.svg";
import ArrowDown from "../../../assets/arrow_down.svg";
import WorAuthorizationForm from "./WorAuthorizationForm";

function WorkAuthorizationAccordion({
  summary,
  itemKey,
  skip,
  catError,
  singleAuthorization,
  handleAuthorizationFormChange,
  personalDetails,
  setPersonalDetails,
  index,
  setUnsavedChanges,
}) {
  const [expanded, setExpanded] = useState(false);
  function handleChange() {
    expanded === singleAuthorization.id
      ? setExpanded()
      : setExpanded(singleAuthorization.id);
  }

  function deleteForm() {
    const newArr = personalDetails.filter(
      (i) => i.id != singleAuthorization.id
    );
    setPersonalDetails(newArr);
  }
  function moveFormUp() {
    let newArr = [...personalDetails];

    let elementIndex = index;
    if (elementIndex === 0) return;

    let newIndex = index - 1;

    let element = newArr.splice(elementIndex, 1)[0];

    newArr.splice(newIndex, 0, element);
    setPersonalDetails(newArr);
    setExpanded(false);
  }

  function moveFormDown() {
    let newArr = [...personalDetails];

    let elementIndex = index;
    if (elementIndex >= newArr.length - 1) return;

    let newIndex = index + 1;

    let element = newArr.splice(elementIndex, 1)[0];

    newArr.splice(newIndex, 0, element);
    setPersonalDetails(newArr);
    setExpanded(false);
  }
  const maxIndex = personalDetails.length - 1;
  return (
    <div>
      <Accordion
        className={[
          "accordion_body",
          skip?.IsCategory && "disable_accordian",
        ].join(" ")}
        expanded={expanded === singleAuthorization.id}
        // onChange={() => (skip?.IsCategory ? "" : handleChange())}
      >
        <AccordionSummary
          // expandIcon={<img src={EditBtn} alt="" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="parentAccordion"
          style={{ flexBasis: "90%" }}
        >
          <div className="accordian_header_content">
            <p>
              {singleAuthorization.countryList}{" "}
              {singleAuthorization.countryList !== "" &&
                singleAuthorization.authType !== "" &&
                " @ "}
              {singleAuthorization.authType}
            </p>
            <div className="accordian_header_content_child">
              {index > 0 && (
                <button onClick={(e) => moveFormUp()}>
                  {" "}
                  <img src={ArrowUp} alt="" />
                </button>
              )}
              {index < maxIndex && (
                <button onClick={(e) => moveFormDown()}>
                  {" "}
                  <img src={ArrowDown} alt="" />
                </button>
              )}
              <button onClick={(e) => deleteForm()}>
                <img src={Delete} alt="" />
              </button>
              <button onClick={(e) => handleChange()}>
                <img src={EditBtn} alt="" />
              </button>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails
          //    key={data.key}
          className="childAccordion"
        >
          <WorAuthorizationForm
            singleAuthorization={singleAuthorization}
            handleAuthorizationFormChange={handleAuthorizationFormChange}
            setUnsavedChanges={setUnsavedChanges}
          />
          {/* <CertificateForm
            handleCertificateFormChange={handleCertificateFormChange}
            singleAuthorization={singleAuthorization}
            setUnsavedChanges={setUnsavedChanges}
          /> */}
        </AccordionDetails>
        {/* );
          })} */}
      </Accordion>
      {catError && (
        <p className="error mb-1">Please enter this required field.</p>
      )}
    </div>
  );
}

export default React.memo(WorkAuthorizationAccordion);
