import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import "./navbar.scss";
import { Avatar, Grid } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, login } from "../../redux/actions/loginAction";
import logoutButton from "../../assets/logoutButton.svg";
import { useNavigate } from "react-router-dom";

const pages = [];
const ResponsiveAppBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    dispatch(getUserDetails("dcads"));
  }, []);

  const userName = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.first_name
  );

  const corporateName = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.name
  );

  const profilePic = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.profile_pic
  );
  const email = sessionStorage.getItem("email");

  // const email = sessionStorage.getItem("email");

  const [anchorEl, setAnchorEl] = React.useState(null);

  let timeNow = new Date().getHours();
  let greeting =
    timeNow >= 5 && timeNow < 12
      ? "Good Morning"
      : timeNow >= 12 && timeNow < 18
      ? "Good Afternoon"
      : "Good Evening";
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const accountType = sessionStorage.getItem("account_type");

  return (
    <div className="header-over">
      <AppBar className="topHeader header-box" position="static">
        <Container maxWidth="xl" disableGutters>
          <Toolbar disableGutters className="headerMain">
            <Grid container className="topHeaderBox">
              {/* <Grid item md={7} xs={10} lg={11} className="leftBox">
                <h1 className="welcomeUser">
                  {greeting},{" "}
                  {userName ? userName : corporateName ? corporateName : email}
                </h1>
              </Grid> */}
              <Grid
                // onClick={(e) => {
                //   // sessionStorage.clear();
                //   // window.reload();
                //   // navigate("/login");

                //   setAnchorEl(e.currentTarget);
                // }}
                item
                md={7}
                xs={10}
                lg={1}
                className="leftBox"
              >
                {/* <img src={logoutButton} alt="" /> */}
                {/* <Avatar
                  className="profilePic"
                  alt="Remy Sharp"
                  src={profilePic}
                  onClick={handleClick}
                /> */}
                {/* <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                      setAnchorEl(null);
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      // dispatch(logoOutUser())

                      sessionStorage.clear();
                      window.location.reload();
                      // navigate("/login");
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu> */}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default ResponsiveAppBar;
